import moment from 'moment';
import { Constants } from '../../constants/Constants';

export default class TimestampNormalizer {
	public input: any;
	public dateOnly: boolean = false;

	constructor(input: any, dateOnly: boolean = false) {
		this.input = input;
		this.dateOnly = dateOnly;
	}

	convertToLocal(timestampField?: string) {
		if (Array.isArray(this.input)) {
			return this.arrayToLocal(this.input, timestampField as string);
		}

		// default input is string
		return this.toLocal(this.input);
	}

	private arrayToLocal(array: any[], field: string) {
		return array.map(element => {
			element[field] = this.toLocal(element[field]);
			return element;
		});
	}

	private toLocal(timestamp: string) {
		if (!timestamp) {
			return '';
		}
		const local = moment(timestamp);
		if (this.dateOnly) {
			return local.format(Constants.DATE_FORMAT);
		}
		return local.toISOString(true).replace('.000', '');
	}
}
