import React from 'react';
import { connect } from 'react-redux';
import { Container, Button, Tab, Tabs, Paper, Grid, styled } from '@material-ui/core';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import BasicInformation from './BasicInformation';
import AuthorizedUsers from './AuthorizedUsers';
import AdditionalDetails from './AdditionalDetails';
import NotificationSettings from './NotificationSettings';
import NotificationActivity from './NotificationActivity';
import CustomerProductListComponent from './customer-products';
import BasePage from '../../common/base';
import { PracticePageStyles } from '../../../hooks/styles';
import { Constants } from '../../../constants/Constants';
import { AlertActions } from '../../../redux/actions';
import { getCustomerDetailsPageTitle } from '../../../hooks/functions';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	...theme.typography.body2,
	padding: '15px',
	textAlign: 'center',
	color: theme.palette.text.secondary,
	textDecoration: 'none',
	'&:hover': {
		color: '#aaa',
	},
}));

const CustomerDetailsSelector = (props: any) => {
	const classes = PracticePageStyles();

	if (props.tenants.length === 1) {
		return <Redirect to={`customer-details/${props.tenants[0].customerId}`} />;
	}

	return (
		<BasePage title={'Accounts'}>
			<div>
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					<Grid container spacing={2}>
						{props.tenants.map((tenant: any) => (
							<Grid item xs={12} sm={6} md={4}>
								<Link className={classes.customerDetailsLink} to={`customer-details/${tenant.customerId}`}>
									<Item>
										<div className={classes.customerDetailsLinkName}>
											{tenant.name} ({tenant.customerId})
										</div>
										{tenant.address1} {tenant.address2}
										<br />
										{tenant.city}, {tenant.state} {tenant.zip}
									</Item>
								</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {
		authUser: state.user.authUser,
		tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	};
};

export default connect(mapStateToProps)(CustomerDetailsSelector);
