import * as React from 'react';
import { CommonStyles } from '../../../hooks/styles';
import {
	Button,
	TextField,
	FormControl,
	Grid,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Loading from '../../../components/loading';
import { useParams } from 'react-router-dom';
import { SelectBox } from '../../../components/selectBox';
import AuthUser from '../../../redux/lib/authUser';
import moment from 'moment';

interface ManualCorrectionComponentProps {
	authUser: any;
	flexConfig: any;
	sendManualCorrection: (payload: any) => void;
}

const ManualCorrectionComponent: React.FC<any> = (props: ManualCorrectionComponentProps) => {
	const classes = CommonStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [correctionType, setCorrectionType] = React.useState<string>('insert');
	const [inputs, setInputs] = React.useState<any>({});
	const [serializedProductsFlag, setSerializedProductsFlag] = React.useState<string>('NO');
	const [formErrors, setFormErrors] = React.useState<any>({});

	const submitManualCorrection = () => {
		let hasErrors = false;
		let errors: any = {};
		let inputKeys = ['drawerNumber', 'productId', 'expiryDate', 'lotNumber', 'poNumber'];
		if (serializedProductsFlag === 'YES') inputKeys.push('serialNumber');
		if (serializedProductsFlag === 'NO') inputKeys.push('count');
		inputKeys.forEach((key: string) => {
			if (!inputs[key]) {
				hasErrors = true;
				errors[key] = true;
			}
		});

		if (hasErrors) {
			setFormErrors(errors);
			return;
		}
		


		let requestBody: any = {
			userId: props.authUser.record.userId,
			drawerNumber: +inputs.drawerNumber,
			productId: inputs.productId,
			expiryDate: moment(inputs.expiryDate).format('MM/DD/YYYY'),
			lotNumber: inputs.lotNumber,
			poNumber: inputs.poNumber,
			count: serializedProductsFlag === "YES" ? 1 : +inputs.count,
			serialNumber: inputs.serialNumber,
		};

		const payload = {
			requestBody: requestBody,
			type: correctionType,
			cabinetId: cabinetId,
			success: () => {
				// clear form
				setInputs({});
				setSerializedProductsFlag('NO');
			},
		};

		props.sendManualCorrection(payload);
	};

	return (
		<>
			{!props.flexConfig ? (
				<Loading message="" />
			) : (
				<>
					{props.flexConfig.message || ''}
					{props.flexConfig.config && (
						<Grid container>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<ToggleButtonGroup
										style={{ margin: '5px 0 8px 0' }}
										size="small"
										value={correctionType}
										exclusive
										onChange={(event: any, value: any) => setCorrectionType(value)}
									>
										<ToggleButton style={{ fontSize: '10px' }} value="insert" aria-label="insert">
											Insert
										</ToggleButton>
										<ToggleButton style={{ fontSize: '10px' }} value="remove" aria-label="remove">
											Remove
										</ToggleButton>
									</ToggleButtonGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<SelectBox
										style={{ width: 200 }}
										inputLabel={'Tray'}
										emptyItemLabel={'Select Tray'}
										listItems={props.flexConfig.config.data.compartments[0].trays.map((tray: any, index: number) => {
											return { label: `${tray.trayName} (${tray.sections[0].productId})`, value: `${index}` };
										})}
										selected={inputs.drawerNumber || ''}
										error={formErrors.drawerNumber ? true : false}
										errorText={formErrors.drawerNumber ? 'A tray is required' : ''}
										onChangeItem={(value: any) => {
											const productId = value
												? props.flexConfig.config.data.compartments[0].trays[value].sections[0].productId
												: '';
											setInputs({ ...inputs, drawerNumber: value, productId: productId });
											setFormErrors({ ...formErrors, drawerNumber: null });
										}}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl component="fieldset" className={classes.formControl}>
									<FormLabel component="legend">Serialized Product</FormLabel>
									<RadioGroup
										className={classes.radioGroup}
										aria-label="serialized-products"
										name="serialized-products"
										value={serializedProductsFlag}
										onChange={(event: any) => setSerializedProductsFlag(event.target.value)}
									>
										<FormControlLabel
											className={classes.radioLabel}
											value={'YES'}
											control={<Radio color="default" />}
											label="Yes"
										/>
										<FormControlLabel value={'NO'} control={<Radio color="default" />} label="No" />
									</RadioGroup>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											autoOk
											format="YYYY-MM-DD"
											variant="inline"
											inputVariant="outlined"
											label="Expiry"
											style={{ width: 200 }}
											inputProps={{ readOnly: false }}
											required
											InputAdornmentProps={{ position: 'end' }}
											error={formErrors.expiryDate ? true : false}
											helperText={formErrors.expiryDate ? 'Please enter a shipment date' : 'YYYY-MM-DD'}
											value={inputs.expiryDate || null}
											onChange={(date: any) => {
												setInputs({ ...inputs, expiryDate: date?.isValid() ? date.format('YYYY-MM-DD') : '' });
												setFormErrors({ ...formErrors, expiryDate: null });
											}}
										/>
									</MuiPickersUtilsProvider>
								</FormControl>
							</Grid>
							<Grid item xs={2}>
								<FormControl className={classes.formControl}>
									<TextField
										label="Lot"
										variant="outlined"
										style={{ width: 200 }}
										value={inputs.lotNumber || ''}
										error={formErrors.lotNumber ? true : false}
										helperText={formErrors.lotNumber ? 'Please enter a lot' : ''}
										onChange={event => {
											setInputs({ ...inputs, lotNumber: event.target.value });
											setFormErrors({ ...formErrors, lotNumber: null });
										}}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<TextField
										value={inputs.poNumber || ''}
										label="PO No"
										variant="outlined"
										style={{ width: 200 }}
										onChange={event => {
											setInputs({ ...inputs, poNumber: event.target.value });
											setFormErrors({ ...formErrors, poNumber: null });
										}}
										error={formErrors.poNumber ? true : false}
										helperText={formErrors.poNumber ? 'Please enter a PO number' : ''}
										required
									/>
								</FormControl>
							</Grid>
							{serializedProductsFlag === 'NO' && (
								<>
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<TextField
												label="Count"
												variant="outlined"
												type="number"
												style={{ width: 90 }}
												InputProps={{ inputProps: { min: 1 } }}
												value={inputs.count || ''}
												error={formErrors.count ? true : false}
												helperText={formErrors.count ? 'Please enter a total count' : ''}
												onChange={event => {
													setInputs({ ...inputs, count: event.target.value });
													setFormErrors({ ...formErrors, count: null });
												}}
												required
											/>
										</FormControl>
									</Grid>
								</>
							)}

							{serializedProductsFlag === 'YES' && (
								<>
									<Grid item xs={12}>
										<FormControl className={classes.formControl}>
											<TextField
												label="Serial No"
												variant="outlined"
												style={{ width: 200 }}
												value={inputs.serialNumber || ''}
												error={formErrors.serialNumber ? true : false}
												helperText={formErrors.serialNumber ? 'Please enter an order number' : ''}
												onChange={event => {
													setInputs({ ...inputs, serialNumber: event.target.value });
													setFormErrors({ ...formErrors, serialNumber: null });
												}}
												required
											/>
										</FormControl>
									</Grid>
								</>
							)}

							<Grid item xs={12}>
								<Button
									onClick={submitManualCorrection}
									type="button"
									className={`${classes.searchButton}`}
									variant="contained"
									color="primary"
									style={{ marginTop: 20 }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</>
	);
};

export default ManualCorrectionComponent;
