import React from 'react';
import { Container, Paper, Button } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';
import { globalColors } from '../../hooks/styles/muiTheme';

const SessionTimeout = (props: any) => {
	const goToLogin = () => {
		window.location.href = '/';
	};

	return (
		<Container component="main" maxWidth="xs">
			<Paper
				style={{
					marginTop: '2em',
					padding: '1.5em',
					textAlign: 'center',
					fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
				}}
			>
				<img style={{ width: '100%' }} src={IMAGES_URL.RNI_LOGO} alt="FFF Logo" />
				<h2>Your session has expired.</h2>
				<Button
					onClick={goToLogin}
					type="button"
					variant="contained"
					color="primary"
					style={{ backgroundColor: globalColors.FFF_BLUE }}
				>
					LOGIN AGAIN
				</Button>
			</Paper>
		</Container>
	);
};

export default SessionTimeout;
