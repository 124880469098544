//import { withOktaAuth } from '@okta/okta-react';
import React from 'react';
import {firebase} from '../../auth/firebase';
import { getAuth } from "firebase/auth";

const Logout: React.FC = (props: any) => {
	const [logoutStarted, setLogoutStarted] = React.useState(false);
	const clearSession = async () => {
		await getAuth(firebase).signOut();
		sessionStorage.clear();
		localStorage.clear();
	}

	(async () => {
		if (!logoutStarted) {
			setLogoutStarted(true);
		}
		if (logoutStarted) {
			return;
		}
		const params = new URLSearchParams(props.location.search);
		let logoutPath: any = null;
		if (params.get('sessionTimeout')) {
			logoutPath = '/session-timeout';
		}
		if (params.get('authError')) {
			logoutPath = '/auth-error';
		}

		if (logoutPath) {
			await clearSession();
			window.location.href = logoutPath;
			return;
		}

		await clearSession();
		window.location.href = '/';

	})();

	return <div></div>;
};

export default Logout;
