import { connect } from 'react-redux';
import { PersonasManagementComponent } from './personas-management.component';
import { UserManagementActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';
import { Persona } from '../../redux/models/persona/persona';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	allAvailablePersonas: state.user.allAvailablePersonas || null,
	personSaveSuccess: state.user.personSaveSuccess,
	personSaveInProgress: state.user.personSaveInProgress || false,
	personaSaveErrorCode: state.user.personaSaveErrorCode || null,
});

const mapDispatchToProps = (dispatch: any) => ({
	setSnackbarOpen: (isOpen: boolean) => dispatch(UserActions.setSnackbarOpen(isOpen)),
	setSnackbarMessage: (payload: object) => dispatch(UserActions.setSnackbarMessage(payload)),
});

const PersonasManagementContainer = connect(mapStateToProps, mapDispatchToProps)(PersonasManagementComponent);

export default PersonasManagementContainer;
