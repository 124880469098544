import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const CabinetStyles = makeStyles(theme => ({
	cabinetDetailsHeading: {
		margin: '0 0 5px 15px',
	},
	formControlFreeMargin: {
		marginBottom: '12px',
		marginLeft: 0,
		marginRight: '20px',
	},
	formControlMobileFreeMargin: {
		marginBottom: '12px',
		marginLeft: 0,
		marginRight: '0px',
	},
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
	formControlLabel: {
		fontSize: '10px', 
		color: 'rgba(0, 0, 0, 0.54)',
		margin: 0
	},
	formControlMobile: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '0px!important',
	},
	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	dateRange: {
		width: '75%',
		position: 'absolute',
		top: '60px',
		zIndex: 9999,
	},
	dateQuickSelect: {
		paddingTop: 2,
		paddingBottom: 2,
	},
	viewAllBtn: {
		margin: '0px 0 0 10px',
		fontSize: '10px',
	},
	searchButton: {
		margin: '0 5px 0 0',
		padding: '6px 5px',
		fontSize: '10px!important',
	},
	addNewProperty: {
		margin: '0 0 25px 0',
		padding: '6px 10px',
		fontSize: '10px!important',
	},
	propertyMetaInputItem: {
		marginBottom: 20,
	},
	propertyDropdownOptionsHeading: {
		margin: 0,
	},
	cancelBtn: {
		margin: '12px 0 0 10px',
		color: 'white',
		fontSize: '10px!important',
	},
	cancelPropertyEditBtn: {
		margin: '0 5px 0 15px',
		padding: '6px 5px',
		color: 'white',
		fontSize: '10px!important',
		backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
	},
	buttonFormControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '16px!important',
		minWidth: '40px!important',
		verticalAlign: 'bottom',
	},
	buttonFormControlMobile: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '0px!important',
		minWidth: '60px!important',
		verticalAlign: 'bottom',
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	alertTooltip: {
		border: '1px solid #2f7ed8',
		backgroundColor: '#fcfcfc',
		opacity: 0.8500000000000001,
		padding: '4px 12px',
		'border-radius': '3px',
		position: 'absolute',
		top: '-100px',
		'box-shadow': '1px 1px 3px #666',
		fontSize: '11px',
	},
	detailsTab: {
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			fontSize: '0.7em',
		},
	},
	checkbox: {
		'&.MuiCheckbox-colorSecondary.Mui-checked': {
			color: globalColors.FFF_BLUE,
		},
	},
	cabinetFormHeading: {
		marginBottom: 0,
	},
	cabinetFormSubHeading: {
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	cabinetFormError: {
		color: '#f44336',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	summaryCol: {
		flexBasis: 'fit-content',
		marginRight: 15,
	},
	summaryColMobile: {
		marginRight: '14px',
		lineHeight: '12px',
	},
	lotSummaryHeading: {
		color: '#948f8f',
		fontSize: 14,
	},
	lotSummaryValue: {
		fontSize: '0.8rem',
		fontWeight: 'bold',
	},
	lotSummaryHeadingMobile: {
		fontSize: 12,
	},
	lotSummaryValueMobile: {
		fontSize: '0.7rem',
	},
	inventoryActionHeading: {
		width: 'fit-content',
		margin: '-28px 0 15px 0',
		padding: '0 5px',
		fontSize: '1.2em',
		backgroundColor: theme.palette.background.paper,
	},
	addLotBtn: {
		color: globalColors.FFF_BLUE,
		fontWeight: 'bold',
		width: '240px',
		marginTop: 15,
	},
	selectedLotState: {
		backgroundColor: globalColors.FFF_BLUE,
		color: 'white',
		'&:hover': {
			background: globalColors.FFF_BLUE,
		},
	},
	reportedInvProductHeading: {
		fontWeight: 'bold',
		margin: '25px 0 5px 0',
	},
	confirmationCheck: {
		color: '#3bb155',
		fontSize: '15px',
		marginRight: '10px',
		verticalAlign: 'middle',
	},
	suspendedIcon: {
		color: '#f44336',
		fontSize: '15px',
		marginRight: '10px',
		verticalAlign: 'middle',
	},
	tmpGraphTitle: {
		display: 'inline',
	},
	tmpGraphTitleDiv: {
		textAlign: 'center',
		marginBottom: 20,
		position: 'relative',
	},
	tmpGraphActionButton: {
		position: 'absolute',
		left: 5,
	},
	leftTableCell: {
		color: '#948f8f',
		fontSize: 12,
	},
	rightTableCell: {
		marginBottom: 16,
		fontSize: 13,
	},
	packToLumNote: {
		textAlign: 'center',
		fontSize: 13,
	},
	backButton: {
		backgroundColor: globalColors.FFF_BLUE,
		fontSize: '10px',
		'&:hover': {
			backgroundColor: globalColors.FFF_BLUE,
		},
	},
	bigButton: {
		minWidth: 200,
		minHeight: 40,
		fontSize: '15px !important',
		marginRight: 16,
	},
	graphExportButton: {
		position: 'relative',
		left: '93%',
		top: '25px',
		zIndex: 999
	}
}));
