import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { FormControl, Button, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { InventoryReportsStyles } from '../../../../hooks/styles';
import { ReportActions } from '../../../../redux/actions';
import { TransactionLogFilter, ReportFilter } from '../../../../redux/models/reports/Inventory';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import CancelIcon from '@material-ui/icons/Cancel';
import { UIAction } from '../../../../redux/actions';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import GlobalFiltersComponentContainer from '../../../../components/globalFiltersComponent';
import { ConvertDateTimeForRequest } from '../../../../hooks/functions/ConvertDateTimeForRequest';

const moment = extendMoment(Moment as any);

interface SearchProps {
	tenantsList?: any;
	filter: TransactionLogFilter;
	urlFilter?: any;
	onFilterUpdate: (filter: any) => void;
	onChangePractice: (filter?: any) => void;
	authUser: any;
	selectedCustomer: any;
	setSelectedCustomer: (customer: any) => void;
	reportFilters: ReportFilter;
	transactionLogs: any;
	selectedDateRange: any;
	selectedFilterValues: any;
	properties: any;
	disableSerialNumberFilter: (disabled: boolean) => void;
	disableOwnershipFilter: (disabled: boolean) => void;
}

const SearchActions = (props: SearchProps) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	let dispatch = useDispatch();
	const [filter, setFilter] = React.useState(props.filter);
	const classes = InventoryReportsStyles();
	const [groupBy, setGroupBy] = React.useState(null as any);
	const styles = InventoryReportsStyles();

	/*
	 * execute a search when at least a customer and date range have been selected
	 */
	const handleSearchClick = () => {
		const filterValues = props.selectedFilterValues || {};

		//todo: can this be made generic for any page with filters? RMM
		filter.cabinetId = filterValues.CABINET_ID || undefined;
		filter.reason = filterValues.REASON || undefined;
		filter.type = filterValues.ACTION || undefined;
		filter.invNo = filterValues.INVOICED || undefined;
		filter.lot = filterValues.LOT_NUMBER || undefined;
		filter.productId = filterValues.PRODUCT_NAME?.productItemId || undefined;
		filter.productGroup = filterValues.PRODUCT_GROUP || undefined;

		//pending and re-insert are "hybrid" states that aren't explicitly designated on backend
		if (filter.reason === 'PENDING') {
			filter.reason = 'UNKNOWN';
			filter.type = 'REMOVAL';
		} else if (filter.reason === 'RE-INSERT') {
			filter.reason = 'UNKNOWN';
			filter.type = 'INSERT';
		}

		//global date range which is stored in global UI state!
		const dates = props.selectedDateRange || null;
		filter.date = dates
			? [
					ConvertDateTimeForRequest(props.properties, dates.startDate),
					ConvertDateTimeForRequest(props.properties, dates.endDate),
			  ]
			: undefined;

		filter.customerId = props.selectedCustomer ? props.selectedCustomer.customerId : undefined;
		filter.serialNo = filterValues.SERIAL_NUMBER || undefined;
		filter.rfidTag = filterValues.RFID_TAG || undefined;

		setFilter(filter);

		props.reportFilters.transaction_date = dates ? dates : undefined;

		dispatch(ReportActions.setFilter(props.reportFilters));

		if (filter.date) {
			props.onFilterUpdate(filter);
		} else {
			return;
		}
	};

	const handleGroupBy = (key: string) => {
		if (!props.transactionLogs || props.transactionLogs?.result.length === 0) {
			return;
		}

		if (key === groupBy) {
			setGroupBy(null);
			return;
		}
		setGroupBy(key);
	};

	React.useEffect(() => {
		filter.groupBy = groupBy;

		if (!groupBy) {
			delete filter.groupBy;
		}

		setFilter(filter);
		props.onFilterUpdate(filter);
	}, [groupBy]);

	React.useEffect(() => {
		props.disableSerialNumberFilter(false);
		props.disableOwnershipFilter(false);
	}, []);

	return (
		<div>
			<Grid container>
				{/* new filter controls */}
				<Grid item xs={12}>
					<GlobalFiltersComponentContainer
						pageName="TRANSACTION_LOG"
						executeSearch={handleSearchClick}
						handleChangeDatePicker={() => {}} //nothing needed; we set date in global state
						onReset={() => {
							setGroupBy(null);
						}}
					/>
				</Grid>

				{!props.transactionLogs?.result || props.transactionLogs?.result?.length === 0 ? null : (
					<div
						style={{
							position: 'relative',
							width: '100%',
						}}
					>
						<MediaQuery minDeviceWidth={1224}>
							<div className={`${classes.groupByWrap} ${groupBy ? classes.groupByWrapGrouped : ''}`}>
								<h4 className={classes.groupByHeading}>Group By</h4>

								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('cabinet')}
										variant={groupBy === 'cabinet' ? 'contained' : 'text'}
										endIcon={groupBy === 'cabinet' ? <CancelIcon></CancelIcon> : ''}
									>
										Device
									</Button>
								</FormControl>
								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('product')}
										variant={groupBy === 'product' ? 'contained' : 'text'}
										endIcon={groupBy === 'product' ? <CancelIcon></CancelIcon> : ''}
									>
										Product
									</Button>
								</FormControl>
								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('lot')}
										variant={groupBy === 'lot' ? 'contained' : 'text'}
										endIcon={groupBy === 'lot' ? <CancelIcon></CancelIcon> : ''}
									>
										Lot
									</Button>
								</FormControl>
								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('type')}
										variant={groupBy === 'type' ? 'contained' : 'text'}
										endIcon={groupBy === 'type' ? <CancelIcon></CancelIcon> : ''}
									>
										Action
									</Button>
								</FormControl>
								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('serial_no')}
										variant={groupBy === 'serial_no' ? 'contained' : 'text'}
										endIcon={groupBy === 'serial_no' ? <CancelIcon></CancelIcon> : ''}
									>
										Serial Number
									</Button>
								</FormControl>
								<FormControl className={`${classes.groupByLinks} groupByLink`}>
									<Button
										onClick={() => handleGroupBy('rfid_tag')}
										variant={groupBy === 'rfid_tag' ? 'contained' : 'text'}
										endIcon={groupBy === 'rfid_tag' ? <CancelIcon></CancelIcon> : ''}
									>
										RFID Tag
									</Button>
								</FormControl>
							</div>
						</MediaQuery>

						<div>
							<MediaQuery maxDeviceWidth={1223}>
								<FormControl variant="outlined" style={{ marginTop: 25 }} className={classes.formControl}>
									<InputLabel id="demo-simple-select-outlined-label">Group By</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										style={{ width: '200px' }}
										value={groupBy}
										onChange={(event: any) => handleGroupBy(event.target.value)}
										label="Group By"
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										<MenuItem value={'Cabinet_ID'}>Device</MenuItem>
										<MenuItem value={'Cabinet_Type'}>Device Type</MenuItem>
										<MenuItem value={'Product_Label_Name'}>Product</MenuItem>
										<MenuItem value={'Lot'}>Lot</MenuItem>
										<MenuItem value={'serial_no'}>Serial Number</MenuItem>
										<MenuItem value={'rfid_tag'}>RFID Tag</MenuItem>
									</Select>
								</FormControl>
							</MediaQuery>
						</div>
					</div>
				)}
			</Grid>
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	setSelectedCustomer: (customerList: any) => dispatch(UIAction.setSelectedCustomer(customerList)),
	disableSerialNumberFilter: (disabled: boolean) => dispatch(UIAction.disableSerialNumberFilter(disabled)),
	disableOwnershipFilter: (disabled: boolean) => dispatch(UIAction.disableOwnershipFilter(disabled)),
});

const mapStateToProps = (state: any) => {
	return {
		reportFilters: state.reports.reportFilters ? state.reports.reportFilters : new ReportFilter(),
		authUser: state.user.authUser,
		tenantsList: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
		selectedCustomer: state.ui.selectedCustomer,
		selectedDateRange: state.ui.selectedDateRange,
		transactionLogs: state.reports.recentlyVended,
		selectedFilterValues: state.ui.selectedFilterValues,
		urlFilter: state.ui.urlFilter,
		properties:
			state.user &&
			state.user.authUser &&
			state.user.authUser.record &&
			state.user.authUser.record.properties &&
			state.user.authUser.record.properties instanceof Array
				? state.user.authUser.record.properties
				: [],
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchActions);
