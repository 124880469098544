import React from 'react';
import { connect } from 'react-redux';
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { getExpandedReportTable, getReportExportOptions, getTableColumns, resetPagination } from '../report-utils';
import { ReportActions, UserActions } from '../../../redux/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonStyles from '../../../hooks/styles/common';
import GlobalFiltersComponentContainer from '../../../components/globalFiltersComponent';
import EmptyContent from '../../../components/emptyContent';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import dataList from '../../../constants/dataList';
import { GetUserTimezone, UserDateFormat, UserTimezoneFormat } from '../../../hooks/functions';
import { ConvertDateTimeForRequest } from '../../../hooks/functions/ConvertDateTimeForRequest';

const WarehouseShipments = (props: any) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(true);
	const [expandedIndex, setExpandedIndex] = React.useState<any>(null);
	const [pagination, setPagination] = React.useState({
		page: 1,
		limit: 10,
		offset: 0,
		order: 'ASC',
	});

	const tableColumnNames = [
		'deliveryRecord',
		'warehouse',
		'productName',
		'productId',
		'lot',
		'datetime',
		'expiration',
		'qty',
	];
	const tableColumnLabels = ['Delivery Record', 'Warehouse', 'Product', 'Item', 'Lot', 'Ship Time', 'Expiry', 'Qty'];
	const customRenderFunctions = {
		datetime: (value: any) => {
			return UserTimezoneFormat(props.userProperties, value);
		},
		expiration: (value: any) => {
			return UserDateFormat(props.userProperties, value);
		},
	};
	const hints = {
		warehouse: `
      (NCDC)North Carolina\n
      (SCDC)Southern California\n
      (TXDC)Texas
    `,
	};
	const tableColumns = getTableColumns(tableColumnNames, tableColumnLabels, { hints, customRenderFunctions });

	const constructFilterObj = (currentPagination?: any) => {
		const filter: any = {
			customerId: props.selectedCustomer.customerId,
			startDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.startDate),
			endDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.endDate),
			limit: currentPagination ? currentPagination.limit : 99999,
			offset: currentPagination ? currentPagination.offset : 0,
			order: currentPagination ? currentPagination.order : 'ASC',
		};
		if (props.selectedFilterValues?.PRODUCT_NAME) {
			filter.productId = props.selectedFilterValues.PRODUCT_NAME.productItemId;
		}
		if (props.selectedFilterValues?.LOT_NUMBER) {
			filter.lot = props.selectedFilterValues.LOT_NUMBER;
		}
		if (props.selectedFilterValues?.ORDER_NUMBER) {
			filter.orderNumber = props.selectedFilterValues.ORDER_NUMBER;
		}
		if (props.selectedFilterValues?.WAREHOUSE) {
			filter.warehouse = props.selectedFilterValues.WAREHOUSE;
		}

		return filter;
	};

	const handleSearchClick = (currentPagination: any, doExport: boolean = false) => {
		if (!props.selectedCustomer || !props.selectedDateRange) {
			return;
		}

		setExpandedIndex(null);
		const filter = constructFilterObj(currentPagination);
		props.getWarehouseShipments(filter);
	};

	const handlePagination = (tableEvent: any, value: any) => {
		setExpandedIndex(null);
		const offset = (value - 1) * pagination.limit;
		const newPagination = { page: value, offset: offset, limit: pagination.limit, order: pagination.order };
		setPagination(newPagination);
		handleSearchClick(newPagination);
	};

	/*`
  send all groups on page to queue!
  */
	const handleExportDownload = () => {
		//cant pick columns on global export, so give all
		sendToQueue(null, tableColumns);
	};

	const handleExportOfWarehouseShipmentGroup = (orderNumber: any, columns: any[]) => {
		sendToQueue(orderNumber, columns);
	};

	const sendToQueue = (orderNumber?: any, userSelectedColumns?: any[]) => {
		const filter = constructFilterObj(null);

		const columnsRequested: string[] = [];
		let theseColumns: any;

		if (!orderNumber) {
			theseColumns = tableColumns; //global list for global export

			theseColumns.forEach((tc: any) => {
				if (tc.options.display === 'true' || tc.options.display === true) {
					columnsRequested.push(tc.name);
				}
			});
		} else {
			theseColumns = userSelectedColumns;

			theseColumns.forEach((tc: any) => {
				if (tc.display === 'true' || tc.display === true) {
					columnsRequested.push(tc.name);
				}
			});
		}

		const params: any = {
			filter_object_from_ui: filter,
			report_type: 'RNI_UIEXPORT_WarehouseShipmentsReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.userProperties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				columnsRequested: columnsRequested,
				orderNumber: orderNumber || undefined,
			},
		};

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (props.selectedCustomer && props.selectedDateRange && !firstRender.current) {
			handleSearchClick(pagination);
		} else {
			firstRender.current = false;
		}
	}, []);

	return (
		<div>
			<div>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer
							pageName="WAREHOUSE_SHIPMENTS"
							executeSearch={() => {
								resetPagination(setPagination);
								handleSearchClick({ limit: 10, offset: 0, order: 'ASC' });
							}}
							doExport={
								props.warehouseShipments && props.warehouseShipments.results.length > 0 ? handleExportDownload : null
							}
							handleChangeDatePicker={() => {}}
						/>
					</Grid>
				</Grid>
			</div>
			{props.warehouseShipments && props.warehouseShipments.results.length > 0 ? (
				<Grid container>
					<Grid item xs={12}>
						{props.warehouseShipments.results.map((order: any, index: number) => (
							<Accordion
								key={index}
								expanded={expandedIndex === index ? true : false}
								onChange={() => {
									setExpandedIndex(expandedIndex === index ? null : index);
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
									<Typography className={classes.expansionHeading}>Order Number: {order.orderNumber}</Typography>
									<Typography className={classes.expansionSecondary}>Order Total: {order.orderTotal}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{getExpandedReportTable(order.shipments, tableColumns, dataList.PageLimitOptions, (cols: any) =>
										handleExportOfWarehouseShipmentGroup(order.orderNumber, cols),
									)}
								</AccordionDetails>
							</Accordion>
						))}
						<br />
						<Pagination
							count={Math.ceil(props.warehouseShipments.total / pagination.limit)}
							page={pagination.page}
							onChange={handlePagination}
						/>
					</Grid>
				</Grid>
			) : (
				<EmptyContent message="Select the filter criteria above to view warehouse shipment information." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	warehouseShipments: state.reports.warehouseShipments,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	userProperties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
	authUser: state.user.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
	getWarehouseShipments: (filter: any) => dispatch(ReportActions.getWarehouseShipments(filter)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseShipments);
