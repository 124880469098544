import * as React from 'react';
import TelemetryListComponent from './telemetry-list.component';
import { Paper } from '@material-ui/core';

const TelemetryComponent: React.FC<any> = (props: any) => {
	const [selectedTab] = React.useState('notifications' as string);

	return (
		<div>
			<Paper style={{ marginBottom: '25px' }}>
				{/* <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabSelect}
          aria-label="cabinet inventory tabs">
          <Tab value="notifications" label="Notifications" />
        </Tabs> */}
			</Paper>
			{selectedTab === 'notifications' && <TelemetryListComponent {...props} />}
		</div>
	);
};

export default TelemetryComponent;
