import * as React from 'react';
import Loading from '../../components/loading';
import { MuiThemeProvider, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getMuiTheme } from '../../hooks/styles';
import { InventoryManagementStyles } from '../inventory-management/inventory-management.styles';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GridOnIcon from '@material-ui/icons/GridOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AlertChartComponent from './alert.chart.component';
import { useMediaQuery } from 'react-responsive';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UserTimezoneFormat } from '../../hooks/functions';
import StyledChip from '../../components/styledChip';

const TelemetryListComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const firstRender = React.useRef(true);
	const classes = InventoryManagementStyles();
	const tableTheme = getMuiTheme();
	const alertColorMap: any = {
		Notice: '#f2820a',
		Info: '#0a8df2',
		Warning: '#edcb07',
		Error: '#e30926',
		Fatal: '#ff1212',
	};
	const [selectedTenant, setSelectedTenant] = React.useState(null as any);
	const [filter, setFilter] = React.useState({
		cabinet: null,
		level: null,
	} as any);
	const [chartData, setChartData] = React.useState<any>([]);
	const [userView, setUserView] = React.useState('GRID');
	const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
	const [chosenCabinet, setChosenCabinet] = React.useState(null as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);

	React.useEffect(() => {
		if (props.selectedCustomer) {
			setSelectedTenant(props.selectedCustomer);
		}
	}, [props.selectedCustomer]);

	React.useEffect(() => {
		if (!props.alertsList) {
			setChartData([]);
			return;
		}
		const sortedList = [...props.alertsList].sort((a: any, b: any) => (a.tempTimestamp > b.tempTimestamp ? 1 : -1));
		setChartData(sortedList);
	}, [props.alertsList]);

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			fetchAlerts();
		} else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder]);

	React.useEffect(() => {
		setFilter({ ...filter, cabinet: chosenCabinet?.cabinetId || null });
	}, [chosenCabinet]);

	const onCustomerSelect = (event: any, value: any) => {
		setChosenCabinet(null);
		if (!value) {
			props.setSelectedCustomer(null);
			setSelectedTenant(null);
			window.history.pushState({}, '', `/telemetry/`);
			return;
		}
		props.setSelectedCustomer(value);
		setSelectedTenant(value);
		window.history.pushState({}, '', `/telemetry/${value.customerId}`);
	};

	const handleSearch = () => {
		setPagination({ offset: 0, limit: pagination.limit, page: 0 });
	};
	const fetchAlerts = () => {
		const where: any = {};
		const fetchFilter: any = {
			offset: pagination.offset,
			limit: props.globalRowsPerPage,
			fields: {
				id: true,
				deviceId: true,
				customerId: true,
				messageId: true,
				apiVersion: true,
				alertTimestamp: true,
				sourceTypeId: true,
				alertTypeId: true,
				alertLevel: true,
				alertDetails: true,
				createdAt: true,
			},
			include: [{ relation: 'sourceType' }],
		};

		if (props.selectedCustomer) {
			where.customerId = props.selectedCustomer.customerId;
		}

		if (props.selectedFilterValues && props.selectedFilterValues.CABINET_ID) {
			where.deviceId = props.selectedFilterValues.CABINET_ID;
		}

		if (props.selectedFilterValues && props.selectedFilterValues.TELEMETRY_ALERT_LEVEL) {
			where.alertLevel = props.selectedFilterValues.TELEMETRY_ALERT_LEVEL;
		}

		fetchFilter.where = where;
		fetchFilter.order = sortOrder ? [sortOrder] : ['alertTimestamp desc'];
		props.getAlerts(JSON.stringify(fetchFilter));
	};

	// Handle event to change user view change
	const handleUserViewChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		if (newAlignment) {
			setUserView(newAlignment);
		}
	};

	const showLoadingMsg = () => !props.authUser && props.tenants.length === 0;

	const getColumns = () => {
		const columns = [
			{
				label: 'Customer Id',
				name: 'customerId',
			},
			{
				label: 'Device Id',
				name: 'deviceId',
			},
			{
				label: 'Device Type',
				name: 'cabinetType',
			},
			{
				label: 'Alert Level',
				name: 'alertLevel',
				customRender: (value: string) => {
					return <StyledChip color={alertColorMap[value]} label={value} />;
				},
			},
			{
				label: 'Alert Text',
				name: 'message',
			},
			{
				label: 'Timestamp',
				name: 'alertTimestamp',
				customRender: (value: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
		];
		return columns.map((column, index) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					customBodyRender: column.customRender ? column.customRender : undefined,
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : true,
					change: false,
				},
			};
		});
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								<GlobalFiltersComponentContainer
									pageName="TELEMETRY"
									executeSearch={handleSearch}
									//handleTenantSelect={onCustomerSelect}
								/>
							</Grid>

							<Grid
								container
								item
								xs={isDesktopOrLaptop ? 2 : 12}
								alignItems={'center'}
								style={{ marginTop: isDesktopOrLaptop ? 0 : 25, marginBottom: isDesktopOrLaptop ? 0 : -20 }}
							>
								<ToggleButtonGroup value={userView} exclusive onChange={handleUserViewChange}>
									<ToggleButton value="GRID" aria-label="list">
										<GridOnIcon />
									</ToggleButton>
									<ToggleButton value="CHART" aria-label="module">
										<AssessmentIcon />
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
					</div>

					{userView === 'GRID' ? (
						props.alertsList && props.alertsList.length > 0 ? (
							<div>
								<MUIDataTable
									data={props.alertsList || []}
									columns={getColumns()}
									options={{
										rowsPerPage: props.globalRowsPerPage,
										rowsPerPageOptions: dataList.PageLimitOptions,
										onChangeRowsPerPage: (numberOfRows: number) => {
											setPagination({ ...pagination, limit: numberOfRows });
											props.setGlobalRowsPerPage(numberOfRows);
										},
										filterType: 'dropdown',
										responsive: 'simple',
										filter: false,
										search: false,
										download: true,
										print: true,
										selectableRows: 'none',
										serverSide: true,
										page: pagination.page,
										count: props.totalAlerts,
										fixedHeader: true, // This locks the table headers at the top
										tableBodyHeight: tableHeight + 'px', // Set the height for the table body
										onTableChange: (tableAction: any, tableState: any) => {
											if (tableAction == 'changePage' || tableAction == 'changeRowsPerPage') {
												setPagination(tablePagination(tableAction, tableState, pagination));
											} else if (tableAction == 'sort') {
												setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
											} else if (tableAction == 'viewColumnsChange') {
												setTableStatePersist(tableState);
											}
										},
									}}
								/>
							</div>
						) : (
							<EmptyContent message="Select the filter criteria above to view telemetry information." />
						)
					) : (
						<AlertChartComponent data={chartData} />
					)}
				</div>
			)}
		</div>
	);
};

export default TelemetryListComponent;
