import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const Styles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		marginRight: '5px!important',
		minWidth: 200,
	},
	Btn: {
		marginTop: 10,
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
		width: '300px',
	},
	profileInput: {
		width: '300px',
		fontSize: '16px',
	},
	profilePropertyWrap: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		// color: theme.palette.text.primary
	},
	profileExampleText: {
		minWidth: '200px',
		width: 'fit-content',
		fontSize: '16px',
	},
	profileFormControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		marginRight: '5px!important',
		minWidth: 200,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	profileBox: {
		boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
		padding: 30,
		paddingBottom: 50,
	},
	inputLabel: {
		fontSize: '16px!important',
	},
	iconFormControl: {
		margin: theme.spacing(1),
		cursor: 'pointer',
		color: globalColors.FFF_BLUE,
	},
	formLabel: {
		padding: 0,
		fontSize: '14px !important',
	},
	formControlIcon: {
		marginLeft: '8px',
		maxWidth: '20px',
		maxHeight: '20px',
		color: globalColors.FFF_BLUE,
	},
}));
export default Styles;
