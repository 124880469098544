import { call, put, select, delay } from 'redux-saga/effects';
import { InventoryManagementActions, UIAction, UserActions } from '../actions/index';
import { InventoryManagementService } from '../services';
import { Constants } from '../../constants/Constants';

/*
 * used by Restock Recommendations among others
 */
export function* getParInventory(action: ReturnType<typeof InventoryManagementActions.parInventoryRequestStartAction>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		const { data } = yield call(InventoryManagementService.getInstance().getParInventory, action.payload);
		if (!action.payload.groupBy) {
			yield put(InventoryManagementActions.parInventoryRequestCompleteAction(data));
			if (!data.result || data.result.length === 0) {
				yield put(
					UserActions.setSnackbarMessage({
						message: `An error occurred: ${Constants.ALERT.NO_RECORD_FOUND}`,
						type: 'info',
					}),
				);
			}
		} else {
			yield put(InventoryManagementActions.parInventoryGroupRequestCompleteAction(data));
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield put(UIAction.showLoader(false));
		yield put(InventoryManagementActions.parInventoryRequestCompleteAction([]));
		console.log('par inventory saga error', err);

		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getParInventory`,
				type: 'error',
			}),
		);
	}
}

export function* getRestockRecommendations(action: ReturnType<typeof InventoryManagementActions.getRestockRecommendations>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		const { data } = yield call(InventoryManagementService.getInstance().getRestockRecommendations, action.payload);
		
		if (!data || data.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: `An error occurred: ${Constants.ALERT.NO_RECORD_FOUND}`,
					type: 'info',
				}),
			);
		} else {
			yield put(InventoryManagementActions.setRestockRecommendations(data));
		}
		
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield put(UIAction.showLoader(false));
		yield put(InventoryManagementActions.setRestockRecommendations([]));
		console.log('Get restock recommendations saga error', err);

		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getRestockRecommendations`,
				type: 'error',
			}),
		);
	}
}

export function* getTenantInventory(
	action: ReturnType<typeof InventoryManagementActions.tenantInventoryRequestStartAction>,
) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		const callback = action.payload.callback;
		if (callback) delete action.payload.callback;
		const { data } = yield call(InventoryManagementService.getInstance().getTenantInventory, action.payload);
		if (callback) {
			yield call(callback);
		}
		if (data.inventories) {
			yield put(InventoryManagementActions.groupedInventoryRequestCompleteAction(data));
		} else {
			yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction(data));
		}

		yield put(InventoryManagementActions.setInventoryFilterAction(action.payload));
		yield put(UIAction.showLoader(false));

		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err: any) {
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield put(UIAction.showLoader(false));
		yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction([]));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getTenantInventory`,
				type: 'error',
			}),
		);
	}
}

export function* getCartridgeInventory(
	action: ReturnType<typeof InventoryManagementActions.cartridgeInventoryRequestStartAction>,
) {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		const callback = action.payload.callback;
		delete action.payload.callback;
		const { data } = yield call(InventoryManagementService.getInstance().getCartridgeInventory, action.payload);
		if (callback) {
			yield call(callback);
		}

		if (data.inventories) {
			yield put(InventoryManagementActions.groupedCartridgeInventoryRequestCompleteAction(data));
		} else {
			yield put(InventoryManagementActions.cartridgeInventoryRequestCompleteAction(data));
		}

		yield put(UIAction.showLoader(false));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction([]));
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield handleInventorySagaError(err);
	}
}

export function* getSerialInventory(
	action: ReturnType<typeof InventoryManagementActions.serialInventoryRequestStartAction>,
) {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		const callback = action.payload.callback;
		delete action.payload.callback;
		const { data } = yield call(InventoryManagementService.getInstance().getSerialInventory, action.payload);
		if (callback) {
			yield call(callback);
		}

		if (data.inventories) {
			yield put(InventoryManagementActions.groupedSerialInventoryRequestCompleteAction(data));
		} else {
			yield put(InventoryManagementActions.serialInventoryRequestCompleteAction(data));
		}

		yield put(UIAction.showLoader(false));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction([]));
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield handleInventorySagaError(err);
	}
}

export function* getInventoryCsvExport(action: ReturnType<typeof InventoryManagementActions.inventoryLogExport>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().getInventoryCsv, action.payload);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* postInventoryUsage(
	action: ReturnType<typeof InventoryManagementActions.cabinetInventoryQtyRequestStartAction>,
): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		yield call(InventoryManagementService.getInstance().patchInventoryUsage, action.payload);
		const filter = yield select(state => state.inventoryManagement.inventoryFilter);
		const { data } = yield call(InventoryManagementService.getInstance().getTenantInventory, filter);
		yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield put(UIAction.showLoader(false));
		console.log('cabinet inventory saga error', err);
	}
}

export function* patchInventoryReceipt(
	action: ReturnType<typeof InventoryManagementActions.cabinetInventoryReceiptRequestStartAction>,
): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(InventoryManagementActions.inventoryManagementError(false));
		yield call(InventoryManagementService.getInstance().patchInventoryReceipt, action.payload);
		const filter = yield select(state => state.inventoryManagement.inventoryFilter);
		const { data } = yield call(InventoryManagementService.getInstance().getTenantInventory, filter);
		yield put(InventoryManagementActions.tenantInventoryRequestCompleteAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield put(InventoryManagementActions.inventoryManagementError(true));
		yield put(UIAction.showLoader(false));
		console.log('cabinet inventory saga error', err);
	}
}

export function* getSuspendedTransactions(
	action: ReturnType<typeof InventoryManagementActions.getSuspendedTransactions>,
) {
	try {
		const useLoader = action.payload.useLoader ?? false
		if(useLoader) yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(InventoryManagementService.getInstance().getSuspendedTransactions, action.payload.filter);
		
		//dont show a "none found" message bc this is called in conjunction with other calls that will usually have results

		yield put(InventoryManagementActions.setSuspendedTransactions(data));
		if(useLoader) yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* updateSuspendedTransactions(
	action: ReturnType<typeof InventoryManagementActions.updateSuspendedTransactions>,
) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(
			InventoryManagementService.getInstance().updateSuspendedTransaction,
			action.payload.transactionId,
			action.payload.payload,
		);
		if (action.payload.callback) {
			action.payload.callback();
		}
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

function* handleInventorySagaError(err: any) {
	yield put(UIAction.showLoader(false));
	const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

	console.log('INVENTORY SAGA ERROR', err);
	yield put(
		UserActions.setSnackbarMessage({
			message: `An error occurred: ${errorMessage} in get inventory`,
			type: 'error',
		}),
	);
}

export function* getTags(action: ReturnType<typeof InventoryManagementActions.getTags>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(InventoryManagementService.getInstance().getTags, [
			{ key: 'filter', value: JSON.stringify(action.payload) },
		]);
		yield put(InventoryManagementActions.setTags(data));
		yield put(UIAction.showLoader(false));
		if (!data.result || data.result.length === 0) {
		  yield put(UserActions.setSnackbarMessage({
		    message: Constants.ALERT.NO_RECORD_FOUND,
		    type: 'info'
		  }));
		}
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* getTagLifecycle(action: ReturnType<typeof InventoryManagementActions.getTagLifecycle>) {
	try {
		const { data } = yield call(
			InventoryManagementService.getInstance().getTagLifecycle,
			action.payload.id,
			action.payload.additionalLogs,
		);
		yield put(InventoryManagementActions.setTagLifecycle(data));
		action.payload.success();
	} catch (err) {
		yield handleInventorySagaError(err);
		action.payload.error();
	}
}

export function* updateProductTagSerials(action: ReturnType<typeof InventoryManagementActions.updateProductTagSerials>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().updateProductTagSerials, action.payload.id, action.payload.requestBody);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* forceVend(action: ReturnType<typeof InventoryManagementActions.forceVend>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().forceVend, action.payload);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* revertTag(action: ReturnType<typeof InventoryManagementActions.revertTag>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().revertTag, action.payload);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* returnProductTagSerialsRecord(action: ReturnType<typeof InventoryManagementActions.returnProductTagSerialsRecord>) {
	console.log(`returnProductTagSerialsRecord: action: ${JSON.stringify(action)}`);
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().returnProductTagSerialsRecord, action.payload.id, action.payload.requestBody);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* setProductOwnerForTag(action: ReturnType<typeof InventoryManagementActions.setProductOwnerForTag>) {
	try {
		const { tagId, owner } = action.payload;
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().setProductOwnerForTag, tagId, owner);
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* bulkUpdateProductTagSerials(action: ReturnType<typeof InventoryManagementActions.bulkUpdateProductTagSerials>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().bulkUpdateProductTagSerials, action.payload.requestBody);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* bulkForceVend(action: ReturnType<typeof InventoryManagementActions.bulkForceVend>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().bulkForceVend, action.payload.requestBody);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* bulkRevertTag(action: ReturnType<typeof InventoryManagementActions.bulkRevertTag>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().bulkRevertTag, action.payload.requestBody);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}

export function* bulkReturnProductTagSerialsRecord(action: ReturnType<typeof InventoryManagementActions.bulkReturnProductTagSerialsRecord>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(InventoryManagementService.getInstance().bulkReturnProductTagSerialsRecord, action.payload.requestBody);
		action.payload.success();
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleInventorySagaError(err);
	}
}
