import { connect, useDispatch } from 'react-redux';
import { UserActions, CabinetActions } from '../../redux/actions';
import dataList from '../../constants/dataList';
import { ScheduledReportsComponentShared } from '@fffenterprises/scheduled-reports-screen/lib/scheduled-reports-screen';

const mapStateToProps = (state: any, ownProps: any) => ({
	authUser: state.user.authUser,
	customers: state.tenants?.tenantsList?.result || [],
	cabinets: state.cabinet?.cabinetCollection || [],
	filteredCustomersOrSuppliers: getFilteredCustomersOrSuppliers(state, ownProps),
	userSubscriptions: state.user.authUser?.record.reportSubscriptions || {},
	token: state.user.firebaseToken,
	dataList: dataList,
	sourceApplication: 'RNI',
	thirdPartyUserBeingEdited: ownProps.thirdPartyUserBeingEdited || null,
	productGroups: state.product.productGroups || [],
});

const getFilteredCustomersOrSuppliers = (state: any, ownProps: any) => {
	const isThirdParty = Boolean(ownProps.thirdPartyUserBeingEdited);
	const claims = isThirdParty ? ownProps.thirdPartyUserBeingEdited.claims : state.user.authUser?.record?.claims;
	const allTenants = state.tenants?.tenantsList?.result || [];

	if (isThirdParty) {
		if (claims?.accessGroups?.customer.includes('ALL')) {
			return allTenants;
		} else {
			return state.tenants?.tenantsList?.result.filter((c: any) => {
				return claims?.accessGroups?.customer && claims.accessGroups.customer.includes(c.customerId);
			});
		}
	} else {
		if (claims && claims['ALL']) {
			return allTenants;
		} else {
			return state.tenants?.tenantsList?.result.filter((c: any) => {
				return Object.keys(claims).includes(c.customerId);
			});
		}
	}
};

const mapDispatchToProps = (dispatch: any) => ({
	sendCreateReportSubscriptionRequest: (params: any) => dispatch(UserActions.createReportSubscription(params)),
	sendDeleteReportSubscriptionRequest: (params: any) => dispatch(UserActions.deleteReportSubscription(params)),
	sendEditReportSubscriptionRequest: (params: any) => dispatch(UserActions.editReportSubscription(params)),
	setSnackbarMessage: (payload: object) => dispatch(UserActions.setSnackbarMessage(payload)),
	searchCabinets: (payload: object) => dispatch(CabinetActions.cabinetRequestStartAction(payload)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

const ScheduledReportsContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduledReportsComponentShared);

export default ScheduledReportsContainer;
