import * as React from 'react';
import { FormControl, Paper, Grid, Button, InputLabel, Select, MenuItem, AppBar, Tabs, Tab } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { TenantInventoryRow } from '../../redux/models/inventory-management/inventory-management';
import { InventoryManagementStyles } from './inventory-management.styles';
import AuthUser from '../../redux/lib/authUser';
import TableWrap from './table-wrap';
import Loading from '../../components/loading';
import EmptyContent from '../../components/emptyContent';
import CancelIcon from '@material-ui/icons/Cancel';
import MediaQuery from 'react-responsive';
import CartridgeInventoryTable from './cartridge-inventory-table';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import InventoryGroupTable from './inventory-group-table';
import CartridgeInventoryGroupTable from './cartridge-inventory-group-table';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import {
	GetCartridgeInventoryColumns,
	GetInventoryColumns,
	GetSerialInventoryColumns,
	GetUserDatetimeFormat,
	GetUserDefaultInventory,
	GetUserTimezone,
} from '../../hooks/functions';

const { useEffect } = React;

interface InventoryManagementProps {
	authUser?: AuthUser;
	tenants: any[];
	tenantInventory?: TenantInventoryRow[];
	tenantInventoryRecords: any;
	tenantGroupInventoriesRecords: any;
	tenantGroupInventories: any[];
	tenantGroupResults: any[];
	cartridgeInventory?: any[];
	cartridgeInventoryRecords: any;
	cartridgeGroupResults: any[];
	cartridgeGroupInventories: any[];
	cartridgeGroupInventoriesRecords: any;
	serialInventory?: any[];
	serialInventoryRecords: any;
	serialGroupResults: any[];
	serialGroupInventories: any[];
	serialGroupInventoriesRecords: any;
	completeInventory?: any;
	urlFilter?: any;
	isLoading: boolean;
	selectedCustomer: any;
	errorMessage: any;
	selectedCabinetType: any;
	selectedExpiryDate: any;
	selectedFilterValues: any;
	autoCompleteProducts: any[];
	properties: any;
	globalRowsPerPage: any;
	suspendedTransactions: any;
	searchTenants: (filter: any) => void;
	resetTenants: () => void;
	getTenantInventory: (payload: any) => void;
	getCartridgeInventory: (payload: any) => void;
	getSerialInventory: (payload: any) => void;
	clearCabinetList: () => void;
	setSelectedCustomer: (customer: any) => void;
	getAutoCompleteProducts: (filter: any) => void;
	getInventoryCsv: (filter: any) => void;
	getSuspendedTransactions: (filter: any) => void;
	disableSerialNumberFilter: (disabled: boolean) => void;
	disableOwnershipFilter: (disabled: boolean) => void;
	sendOneTimeReportRequest: Function;
}

export const InventoryManagementComponent: React.FC<InventoryManagementProps> = props => {
	const classes = InventoryManagementStyles();
	const firstRender = React.useRef(true);
	const firstTableRender = React.useRef(true);
	const [selectedTenant, setSelectedTenant] = React.useState(null as any);
	const [tabView, setTabView] = React.useState<string>(GetUserDefaultInventory(props.properties).toLowerCase());
	const [groupBy, setGroupBy] = React.useState('' as any);
	const [pagination, setPagination] = React.useState({ offset: 0, limit: 10, page: 0 } as any);
	const [groupPagination, setGroupPagination] = React.useState({ offset: 0, limit: 5, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [tablePersist, setTablePersist] = React.useState({} as any);
	const [filter, setFilter] = React.useState({} as any);

	const handleTabChange = (event: any, newValue: string) => {
		//Stop request being sent twice on tab change
		// firstRender.current = true;
		if (newValue === tabView) {
			return;
		}
		setSortOrder('');
		setTabView(newValue);
		setGroupPagination({ ...groupPagination, offset: 0, page: 0 });
	};

	const handleInventorySearch = () => {
		setGroupPagination({ ...groupPagination, offset: 0, page: 0 });
	};

	/*
	 * execute search on the server side
	 * using currently selected filters which are stored in global state!
	 */
	const fetchTenantInventory = (tenant: string) => {
		let payload: any = generateFilterPayload();

		if (tenant) {
			payload.push({
				key: 'customerId',
				value: tenant,
			});
		}

		if (groupBy) {
			payload.push({
				key: 'groupBy',
				value: groupBy,
			});

			if (groupPagination) {
				payload.push({
					key: 'offset',
					value: groupPagination.offset,
				});
				payload.push({
					key: 'limit',
					value: groupPagination.limit,
				});
			}
		} else {
			if (pagination) {
				payload.push({
					key: 'offset',
					value: pagination.offset,
				});
				payload.push({
					key: 'limit',
					value: props.globalRowsPerPage,
				});
			}
			if (sortOrder) {
				payload.push({
					key: 'order',
					value: sortOrder,
				});
			}
		}

		firstRender.current = true;
		const callback = () => {
			firstRender.current = false;
		};
		payload.callback = callback;
		setFilter(payload);

		//actual server calls!!
		tabView === 'lot' && props.getTenantInventory(payload);
		tabView === 'cartridge' && props.getCartridgeInventory(payload);
		tabView === 'serial' && props.getSerialInventory(payload);
	};

	const generateFilterPayload = () => {
		let payload: any = [];
		const filterValues = props.selectedFilterValues || {};

		if (props.selectedCabinetType) {
			payload.push({
				key: 'cabinetType',
				value: props.selectedCabinetType,
			});
		}

		if (props.selectedExpiryDate) {
			payload.push({
				key: 'expiration',
				value: props.selectedExpiryDate,
			});
		}

		if (filterValues['LOT_NUMBER']) {
			payload.push({
				key: 'lot',
				value: filterValues['LOT_NUMBER'],
			});
		}

		if (filterValues['PRODUCT_NAME']) {
			payload.push({
				key: 'productLabelName',
				value: filterValues['PRODUCT_NAME'].productLabelName,
			});
		}

		if (filterValues['PRODUCT_GROUP']) {
			payload.push({
				key: 'productGroup',
				value: filterValues['PRODUCT_GROUP'],
			});
		}

		if (filterValues['CABINET_ID']) {
			payload.push({
				key: 'cabinetId',
				value: filterValues['CABINET_ID'],
			});
		}

		if (filterValues['SERIAL_NUMBER'] && tabView === 'serial') {
			payload.push({
				key: 'serialNo',
				value: filterValues['SERIAL_NUMBER'],
			});
		}

		if (filterValues['PRODUCT_OWNERSHIP'] && (tabView === 'serial' || tabView === 'lot')) {
			payload.push({
				key: 'productOwnership',
				value: filterValues['PRODUCT_OWNERSHIP'],
			});
		}

		return payload;
	};

	const fetchSuspendedTransactions = () => {
		const filter = [];
		if (props.selectedCustomer) {
			filter.push({ key: 'customerId', value: props.selectedCustomer.customerId });
		}

		const filterValues = props.selectedFilterValues || {};

		if (filterValues['CABINET_ID']) {
			filter.push({ key: 'cabinetId', value: filterValues['CABINET_ID'] });
		}

		filter.push({ key: 'status', value: 'Suspended' });
		filter.push({ key: 'limit', value: 100 });
		props.getSuspendedTransactions({ useLoader: false, filter });
	};

	/*
	 * used by multiple table components in this folder
	 */
	const handleGetCsv = (tableColumns: any, groupByName?: string, groupByVal?: string) => {
		let filterPayloadAsArray: any[] = generateFilterPayload();
		const filterPayloadAsObj: any = {};

		filterPayloadAsObj.customerId = props.selectedCustomer ? props.selectedCustomer.customerId : undefined;
		filterPayloadAsObj.limit = 9999;
		filterPayloadAsObj.groupBy = groupByName || undefined;

		//need to convert filters to a different format to agree with backend datasource query object
		filterPayloadAsArray.forEach(fo => {
			filterPayloadAsObj[fo.key] = fo.value;
		});

		if (groupByName) {
			filterPayloadAsObj[groupByName] = groupByVal;
		}

		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);

		const params = {
			filter_object_from_ui: filterPayloadAsObj,
			report_type: 'RNI_UIEXPORT_InventoryReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				viewType: tabView,
				groupByName: groupByName || undefined,
				groupByVal: groupByVal || undefined,
				columnsRequested: columnsRequested,
			},
		};

		props.sendOneTimeReportRequest(params);

		return false;
	};

	const handleGroupBy = (key: string) => {
		if (key === groupBy) {
			setGroupBy('');
			setSortOrder('');
			setPagination({ offset: 0, limit: 10, page: 0 });
			return;
		}
		setGroupPagination({ ...groupPagination, offset: 0, page: 0 });
		setGroupBy(key);
		firstRender.current = false;
	};

	const handleGroupPagination = (tableEvent: any, value: any) => {
		const offset = (value - 1) * groupPagination.limit;
		setGroupPagination({ offset: offset, limit: groupPagination.limit, page: value });
	};

	const handleTenantPagination = (tenantPagination: any, tenantSortOrder: any) => {
		setPagination(tenantPagination);
		setSortOrder(tenantSortOrder);
	};

	const displayTable = () => {
		let inventory: any = [];
		let records = 0;
		let columns: any = [];
		let groupResults: any = [];
		switch (tabView) {
			case 'lot':
				inventory = groupBy ? props.tenantGroupInventories : props.tenantInventory;
				groupResults = groupBy ? props.tenantGroupResults : [];
				records = groupBy ? props.tenantGroupInventoriesRecords : props.tenantInventoryRecords;
				columns = GetInventoryColumns(tablePersist, props.properties, props.suspendedTransactions);
				break;
			case 'cartridge':
				inventory = groupBy ? props.cartridgeGroupInventories : props.cartridgeInventory;
				records = groupBy ? props.cartridgeGroupInventoriesRecords : props.cartridgeInventoryRecords;
				columns = GetCartridgeInventoryColumns(props.properties, props.suspendedTransactions);
				groupResults = groupBy ? props.cartridgeGroupResults : [];
				break;
			case 'serial':
				inventory = groupBy ? props.serialGroupInventories : props.serialInventory;
				records = groupBy ? props.serialGroupInventoriesRecords : props.serialInventoryRecords;
				columns = GetSerialInventoryColumns(props.properties, props.suspendedTransactions);
				groupResults = groupBy ? props.serialGroupResults : [];
				break;
		}
		if (!groupBy && inventory) {
			return (
				<Paper style={{ borderRadius: 0 }}>
					<CartridgeInventoryTable
						data={inventory}
						totalRecord={records}
						tablePersist={tablePersist}
						setTablePersist={setTablePersist}
						onPagination={handleTenantPagination}
						columns={columns}
						onGetCsv={(tableColumns: any, groupByName: string = '', groupByVal: string = '') =>
							handleGetCsv(tableColumns, groupByName, groupByVal)
						}
					/>
				</Paper>
			);
		}
		if (groupBy && inventory) {
			return groupResults?.map((tableData: any, index: number) => {
				return (
					<div className={classes.tableWrap} key={index}>
						<CartridgeInventoryGroupTable
							inventory={inventory[index] ?? []}
							data={tableData}
							properties={props.properties}
							tablePersist={tablePersist}
							columns={columns}
							onGetCsv={(tableColumns: any, groupByName: string = '', groupByVal: string = '') =>
								handleGetCsv(tableColumns, groupByName, groupByVal)
							}
							groupBy={groupBy}
						/>
					</div>
				);
			});
		}
	};

	const isInventoryLoaded = () => {
		if (groupBy) {
			return (
				(props.tenantGroupInventories && props.tenantGroupInventories.length > 0) ||
				(props.cartridgeGroupInventories && props.cartridgeGroupInventories.length > 0) ||
				(props.serialGroupInventories && props.serialGroupInventories.length > 0)
			);
		} else {
			return (
				(props.tenantInventory && props.tenantInventory.length > 0) ||
				(props.cartridgeInventory && props.cartridgeInventory.length > 0) ||
				(props.serialInventory && props.serialInventory.length > 0)
			);
		}
	};

	useEffect(() => {
		//Stop request from being sent when the page is first loaded.
		if (!firstRender.current) {
			fetchTenantInventory(props.selectedCustomer?.customerId ?? null);
			fetchSuspendedTransactions();
		} else {
			firstRender.current = false;
		}
	}, [groupPagination, pagination, sortOrder]);

	useEffect(() => {
		if (!props.tenantInventory || !props.cartridgeInventory || !props.serialInventory) {
			if (groupBy) {
				firstRender.current = true;
			}
			firstTableRender.current = true;
			setGroupBy('');
		}

		if (props.tenantInventory || props.cartridgeInventory || props.serialInventory) {
			firstRender.current = false;
		}

		if (firstRender.current) {
			setTabView(GetUserDefaultInventory(props.properties).toLowerCase());
		}
	}, [props.tenantInventory, props.cartridgeInventory, props.serialInventory]);

	useEffect(() => {
		tabView === 'serial' ? props.disableSerialNumberFilter(false) : props.disableSerialNumberFilter(true);
		tabView === 'cartridge' ? props.disableOwnershipFilter(true) : props.disableOwnershipFilter(false);
	}, [tabView]);

	return (
		<div>
			{!props.authUser && props.tenants.length === 0 ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								<GlobalFiltersComponentContainer
									pageName="INVENTORY"
									executeSearch={handleInventorySearch}
									onReset={() => {
										setTimeout(() => {
											firstRender.current = false;
										}, 1);
									}}
								/>
							</Grid>
						</Grid>
					</div>
					{isInventoryLoaded() ? (
						<div
							style={{
								position: 'relative',
							}}
						>
							<MediaQuery maxDeviceWidth={1223}>
								<div className={classes.groupByWrapperSmall}>
									<FormControl variant="outlined" className={classes.formControl}>
										<InputLabel id="demo-simple-select-outlined-label">Group By</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											style={{ width: '200px' }}
											value={groupBy}
											onChange={(event: any) => handleGroupBy(event.target.value)}
											label="Group By"
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											<MenuItem value={'cabinetId'}>Device</MenuItem>
											<MenuItem value={'cabinetType'}>Device Type</MenuItem>
											<MenuItem value={'productLabelName'}>Product</MenuItem>
											<MenuItem value={'lot'}>Lot</MenuItem>
										</Select>
									</FormControl>
								</div>
							</MediaQuery>
							<MediaQuery minDeviceWidth={1224}>
								{isInventoryLoaded() ? (
									<div className={classes.groupByWrapper}>
										<h4 className={classes.groupByHeading}>Group by</h4>

										<FormControl className={classes.groupByLinks}>
											<Button
												variant={groupBy === 'cabinetId' ? 'contained' : 'text'}
												endIcon={
													groupBy === 'cabinetId' ? <CancelIcon className={classes.groupByButtons}></CancelIcon> : ''
												}
												onClick={() => handleGroupBy('cabinetId')}
											>
												<span className={classes.groupByButtons}>Device</span>
											</Button>
										</FormControl>
										<FormControl className={classes.groupByLinks}>
											<Button
												onClick={() => handleGroupBy('productLabelName')}
												variant={groupBy === 'productLabelName' ? 'contained' : 'text'}
												endIcon={
													groupBy === 'productLabelName' ? (
														<CancelIcon className={classes.groupByButtons}></CancelIcon>
													) : (
														''
													)
												}
											>
												<span className={classes.groupByButtons}>Product</span>
											</Button>
										</FormControl>
										<FormControl className={classes.groupByLinks}>
											<Button
												onClick={() => handleGroupBy('cabinetType')}
												variant={groupBy === 'cabinetType' ? 'contained' : 'text'}
												endIcon={
													groupBy === 'cabinetType' ? <CancelIcon className={classes.groupByButtons}></CancelIcon> : ''
												}
											>
												<span className={classes.groupByButtons}>Device Type</span>
											</Button>
										</FormControl>
										<FormControl className={classes.groupByLinks}>
											<Button
												onClick={() => handleGroupBy('lot')}
												variant={groupBy === 'lot' ? 'contained' : 'text'}
												endIcon={groupBy === 'lot' ? <CancelIcon className={classes.groupByButtons}></CancelIcon> : ''}
											>
												<span className={classes.groupByButtons}>Lot</span>
											</Button>
										</FormControl>
									</div>
								) : null}
							</MediaQuery>

							<AppBar position="static" color="default">
								<Tabs
									variant="scrollable"
									scrollButtons="on"
									aria-label="scrollable tabs"
									value={tabView}
									indicatorColor="secondary"
									textColor="primary"
									onChange={handleTabChange}
								>
									<Tab value="lot" label="Lot Inventory" />
									<Tab value="cartridge" label="Dispenser Inventory" />
									<Tab value="serial" label="Serial Inventory" />
								</Tabs>
							</AppBar>

							{tabView && displayTable()}
						</div>
					) : (
						<EmptyContent message="Select the filter criteria above to view current inventory information." />
					)}
				</div>
			)}
			{tabView === 'lot' && groupBy && (
				<Pagination
					count={Math.ceil(props.tenantGroupInventoriesRecords / groupPagination.limit)}
					onChange={handleGroupPagination}
				/>
			)}
			{tabView === 'cartridge' && groupBy && (
				<Pagination
					count={Math.ceil(props.cartridgeGroupInventoriesRecords / groupPagination.limit)}
					onChange={handleGroupPagination}
				/>
			)}
			{tabView === 'serial' && groupBy && (
				<Pagination
					count={Math.ceil(props.serialGroupInventoriesRecords / groupPagination.limit)}
					onChange={handleGroupPagination}
				/>
			)}
		</div>
	);
};
