import { makeStyles } from '@material-ui/core/styles';

export const AuditLogStyles = makeStyles(theme => ({
	searchWrap: {
		'margin-bottom': '2em',
		padding: 9,
	},
	cardHeading: {
		textAlign: 'center',
		background: '#f2f2f2',
		padding: 5,
	},
	oldValue: {
		color: 'red',
	},
	newValue: {
		color: 'green',
	},
}));
