import { action } from 'typesafe-actions';
import { ALERTS } from '../constants/actions';

export const alertsRequestStartAction = (data: any) => action(ALERTS.LIST_REQUEST, data);
export const alertsRequestCompletedAction = (data: any) => action(ALERTS.LIST_RESPONSE, data);

export const getNotificationTypesAction = () => action(ALERTS.GET_NOTIFICATION_TYPES);
export const setNotificationTypesAction = (data: any) => action(ALERTS.SET_NOTIFICATION_TYPES, data);

export const getNotificationChannelGroupsAction = (customerId: string) =>
	action(ALERTS.GET_NOTIFICATION_CHANNEL_GROUPS, customerId);
export const setNotificationChannelGroupsAction = (data: any) => action(ALERTS.SET_NOTIFICATION_CHANNEL_GROUPS, data);

export const getChannelTypesAction = () => action(ALERTS.GET_CHANNEL_TYPES);
export const setChannelTypesAction = (data: any) => action(ALERTS.SET_CHANNEL_TYPES, data);

export const submitChannelGroupAction = (data: any) => action(ALERTS.SUBMIT_CHANNEL_GROUP, data);
export const deleteChannelGroupAction = (data: any) => action(ALERTS.DELETE_CHANNEL_GROUP, data);

export const getIncidents = (payload: any) => action(ALERTS.GET_INCIDENTS, payload);
export const setIncidents = (payload: any) => action(ALERTS.SET_INCIDENTS, payload);

export const getIncidentHistory = (payload: any) => action(ALERTS.GET_INCIDENT_HISTORY, payload);
export const setIncidentHistory = (payload: any) => action(ALERTS.SET_INCIDENT_HISTORY, payload);

export const getIncidentRules = (payload: any) => action(ALERTS.GET_INCIDENT_RULES, payload);
export const setIncidentRules = (payload: any) => action(ALERTS.SET_INCIDENT_RULES, payload);

export const updateIncident = (payload: any) => action(ALERTS.UPDATE_INCIDENT, payload);
