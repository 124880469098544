import React from 'react';
import { Container, Paper, Button } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';

const AuthError = (props: any) => {
	const goToLogin = () => {
		window.location.href = '/';
	};

	return (
		<Container component="main" maxWidth="xs">
			<Paper
				style={{
					marginTop: '2em',
					padding: '1.5em',
					textAlign: 'center',
				}}
			>
				<img src={IMAGES_URL.RNI_LOGO_SM} alt="FFF Logo" />
				<h2>We've encountered an authentication error. Please login again.</h2>
				<Button onClick={goToLogin} type="button" variant="contained" color="primary">
					LOGIN AGAIN
				</Button>
			</Paper>
		</Container>
	);
};

export default AuthError;
