import * as React from 'react';
import { connect } from 'react-redux';
import BasePage from '../common/base';
import { Constants } from '../../constants/Constants';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import AuditLogsContainer from './audit-logs-container';
const AuditLogs: React.FC<any> = (props: any) => {
	const classes = DashboardStyles();
	return (
		<BasePage title={Constants.PAGE_TITLE.AUDIT_LOGS}>
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<AuditLogsContainer />
			</Container>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => ({
	//cabinetInventory: state.cabinet.cabinetProductInventory ? state.cabinet.cabinetProductInventory.result : [],
	//cabinetInventoryRecords: state.cabinet.cabinetProductInventory ? state.cabinet.cabinetProductInventory.total_record : 0,
	//error: state.cabinet.error
});

const mapDispatchToProps = (dispatch: any) => ({
	// getCabinetInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	//updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogs);
