import createReducer from './createReducer';
import { ProductActions } from '../actions';
import { PRODUCTS } from '../constants/actions';

export const product = createReducer(
	{},
	{
		[PRODUCTS.LIST_RESPONSE](state = {}, action: ReturnType<typeof ProductActions.setProductList>) {
			return { ...state, productList: action.payload };
		},
		[PRODUCTS.LIST_AUTOCOMPLETE](state = {}, action: ReturnType<typeof ProductActions.setAutoCompleteProductList>) {
			return { ...state, autoCompleteList: action.payload };
		},
		[PRODUCTS.SET_ALL_PRODUCTS_LIST](state = {}, action: ReturnType<typeof ProductActions.setAllProductsList>) {
			return { ...state, allProductsList: action.payload };
		},
		[PRODUCTS.SET_PRODUCT_GROUPS](state = {}, action: ReturnType<typeof ProductActions.setProductGroups>) {
			return { ...state, productGroups: action.payload };
		},
	},
);
