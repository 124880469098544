import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { InventoryManagementContainer } from './inventory-management.container';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';
const Inventory = () => {
	const classes = DashboardStyles();
	const isFirstRender = useRef(true);
	const dispatch = useDispatch();
	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	return (
		<BasePage title={Constants.PAGE_TITLE.INVENTORY}>
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<InventoryManagementContainer />
			</Container>
		</BasePage>
	);
};

export default Inventory;
