import { makeStyles, createMuiTheme, Theme, createStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const UsersStyles = makeStyles(theme => ({
	searchWrap: {
		'margin-bottom': '2em',
		padding: 9,
	},
	viewAllBtn: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
		color: 'white',
	},
	viewAllBtnMobile: {
		margin: '12px 0 0 0',
		padding: '5px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
	},
	chip: {
		margin: '3px',
	},
	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
	formControlMobile: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '10px!important',
	},
	closeButton: {
		position: 'absolute',
		right: 18,
		top: 18,
		color: '#aaa',
		backgroundColor: '#fff',
		fontWeight: 800,
	},
}));

export const UsersTableTheme = (createMuiTheme as any)({
	overrides: {
		MuiTableCell: {
			root: {
				cursor: 'pointer',
			},
		},
		MuiTableSortLabel: {
			root: {
				'margin-top': '7px',
			},
		},
		MUIDataTableHeadCell: {
			root: {
				fontSize: 12,
			},
		},
		MUIDataTableBodyCell: {
			root: {
				fontSize: 12,
			},
		},
		MuiTypography: {
			h6: {
				fontSize: 14,
			},
		},
		MUIDataTableFilterList: {
			chip: {
				display: 'none',
			},
		},
	},
});

export const userModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			borderRadius: '3px',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		input: {
			margin: '0.5em',
			minWidth: '125px',
		},
		addPermBtn: {
			margin: '10px 0 0 10px',
			backgroundColor: `${globalColors.FFF_BLUE} !important`,
			fontSize: '10px',
		},
		cancelBtn: {
			margin: '10px 0 0 10px',
			backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
			fontSize: '10px',
			color: 'white',
		},
		permPaper: {
			marginTop: '1em',
			maringBottom: '0.5em',
			padding: '1em',
			'& h3': {
				margin: '0px 0 0.5em 0',
			},
		},
		deletePermIcon: {
			fontSize: '1.9em',
			color: '#9a9a9a',
			verticalAlign: 'middle',
			marginLeft: '0.3em',
			cursor: 'pointer',
		},
		permissionsError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
		importResultBox: {
			height: '200px',
			width: '100%',
			border: '2px solid #e0e0e0',
			borderRadius: '5px',
			backgroundColor: '#e8e8e8',
			overflow: 'scroll',
			padding: '10px',
			fontSize: '14px',
			marginBottom: '5px',
		},
		importSuccess: {
			color: 'green',
			fontWeight: 'bold',
		},
		createUserError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
	}),
);
