import moment from 'moment';
import dataList from '../../constants/dataList';
import { UserProperty } from '../../redux/models/user/user';

export const UserTimezoneFormat = (properties: UserProperty[], dateTime: any, showDateOnly: boolean = false) => {
	const defaultTimezone = moment.tz.guess();
	const defaultDateLabel = dataList.DateFormats[0].label;
	const defaultTimeLabel = dataList.TimeFormats[0].label;

	const timezone_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'TIMEZONE',
	);
	const timezone =
		timezone_key_index > -1 && properties[timezone_key_index].propertyValue != 'LOCAL'
			? properties[timezone_key_index].propertyValue
			: defaultTimezone;

	const dateFormat_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'DATEFORMAT',
	);
	const dateFormatLabel =
		dateFormat_key_index > -1 && properties[dateFormat_key_index].propertyValue != 'LOCAL'
			? properties[dateFormat_key_index].propertyValue
			: defaultDateLabel;
	const dateFormat = dataList.DateFormats.find(format => {
		return format.label === dateFormatLabel;
	})?.value;

	const timeFormat_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'TIMEFORMAT',
	);
	const timeFormatLabel =
		timeFormat_key_index > -1 && properties[timeFormat_key_index].propertyValue != 'LOCAL'
			? properties[timeFormat_key_index].propertyValue
			: defaultTimeLabel;
	const timeFormat = dataList.TimeFormats.find(format => {
		return format.label === timeFormatLabel;
	})?.value;

	const dateTimeFormat = showDateOnly ? dateFormat : `${dateFormat} ${timeFormat}`;

	// TODO: dateTime arg should be UTC e.g. moment.utc(dataTime) and then apply the tz
	return moment.utc(dateTime).tz(timezone).format(dateTimeFormat);
};
