import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import AlarmIcon from '@material-ui/icons/Alarm';
import { CommonStyles } from '../../../hooks/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays, addMinutes } from 'date-fns';
import { connect } from 'react-redux';
import { SelectBox } from '../../selectBox';
import dataList from '../../../constants/dataList';
import { FormControl } from '@material-ui/core';

interface IncidentIgnoreTimePickerProps {
	ignoreExpiration: MaterialUiPickersDate;
	setIgnoreExpiration: (value: MaterialUiPickersDate) => void;
	properties: any;
}

const IncidentIgnoreTimePicker = (props: IncidentIgnoreTimePickerProps) => {
	const commonStyles = CommonStyles();

	/* Leaving this block here in case we decide to go with the time picker instead of the dropdown

    //Check selectedDateTime to make sure it is always a future value. Removes a day from selectedDateTime if it's more than 24 hours.
    const handleDateChange = (selectedDateTime: MaterialUiPickersDate) => {
        const currentDateTime: MaterialUiPickersDate = new Date();
        const deltaDateTime = new Date(Math.abs(selectedDateTime!.valueOf() - currentDateTime.valueOf()));
        let deltaHours = deltaDateTime.getUTCHours() + (deltaDateTime.getUTCMinutes() / 60);
        deltaHours *= selectedDateTime!.getTime() > currentDateTime.getTime() ? 1 : -1;
        const hoursUntilMidnight = 24 - (currentDateTime.getHours() + (currentDateTime.getMinutes() / 60));
        const isSelectedDayToday = currentDateTime.getDay() === selectedDateTime?.getDay();
        const adjustDay = deltaHours < 0 ? 1 : deltaHours < hoursUntilMidnight && !isSelectedDayToday ? -1 : 0;
        props.setIgnoreExpiration(addDays(selectedDateTime!, adjustDay));
    };
    */

	return (
		<FormControl className={commonStyles.tableFormControl}>
			<SelectBox
				className={commonStyles.tableTimePicker}
				inputLabel={'Ignore Expiration'}
				listItems={dataList.IgnoreDurations}
				selected={dataList.IgnoreDurations[0].value}
				hideEmptyLabel={true}
				onChangeItem={(value: any) => {
					props.setIgnoreExpiration(addMinutes(new Date(), value) as any);
				}}
			/>
		</FormControl>

		/* Leaving this block here in case we decide to go with the time picker instead of the dropdown

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                className={commonStyles.tableTimePicker}
                id="ignore-expiration-time-picker"
                label="Ignore Expiration"
                value={props.ignoreExpiration}
                format="MMM dd yyyy hh:mma zzzz"
                onChange={handleDateChange}
                keyboardIcon={<AlarmIcon />}
                InputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
            />
        </MuiPickersUtilsProvider>
        */
	);
};
const mapStateToProps = (state: any) => ({
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

export default connect(mapStateToProps)(IncidentIgnoreTimePicker);
