import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { MuiThemeProvider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserManagementActions } from '../../redux/actions';
import { getUserResources, getEditedUserHelpViewerPermissions } from '../users/users.helper';
import AuthUser from '../../redux/lib/authUser';
import dataList from '../../constants/dataList';

interface HelpVideosProps {
	vimeoAppToken: string;
	getVimeoAppAuthToken: Function;
	authUser?: AuthUser;
}

export const HelpVideosComponent: React.FC<HelpVideosProps> = props => {
	const key = Object.keys(props.authUser?.record.claims)[0];
	const helpViewsAllowed = props.authUser?.record.claims[key]['help_viewer'];
	const helpTopics = (props.authUser?.record.helpTopics as any) || {};

	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleAccordionChange = (panel: string) => (event: any, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div>
			<h2>Please select a topic below to view help videos.</h2>

			{helpViewsAllowed?.map((topicName: any, index: number) => {
				const topicData = helpTopics[topicName];

				if (!topicData) {
					console.warn("Couldn't find data for help topic", topicName);
					return null;
				}

				const panelName = 'panel_' + String(index);
				const embedUrl = `https://vimeo.com/showcase/${topicData.vimeoShowcaseId}/embed`;

				return (
					<Accordion expanded={expanded === panelName} onChange={handleAccordionChange(panelName)} key={panelName}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} id={panelName}>
							<h3>{topicData.displayName} Videos</h3>
						</AccordionSummary>
						<AccordionDetails>
							<div
								style={{
									width: '100%',
									textAlign: 'center',
								}}
							>
								<iframe
									style={{
										border: 0,
									}}
									src={embedUrl}
									allowFullScreen={true}
									width="800"
									height="600"
								/>
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
};
