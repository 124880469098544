import React, { useEffect, useRef, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Chip, Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getMuiTheme, globalColors } from '../../../../hooks/styles';
import { InventoryReportsStyles } from '../../../../hooks/styles';
import SearchActions from './searchAction';
import TableColumns from './tableColumns.json';
import dataList from '../../../../constants/dataList';
import { ReportActions, UIAction, UserActions } from '../../../../redux/actions';
import { TransactionLogFilter } from '../../../../redux/models/reports/Inventory';
import {
	FixViewColumns,
	GetUserDatetimeFormat,
	GetUserTimezone,
	UserDateFormat,
	UserTimezoneFormat,
} from '../../../../hooks/functions';
import LinkableActions from '../../../../components/linkableActions';
import StorageIcon from '@material-ui/icons/Storage';
import { Constants } from '../../../../constants/Constants';
import EmptyContent from '../../../../components/emptyContent';
import momentFn from 'moment';
import * as moment from 'moment';
import 'moment-timezone';
import TransactionGroupTable from './transactionGroupTable';

interface TransactionProps {
	transactionLogs: any;
	transactionGroupData: any;
	transactionGroupNames?: any;
	properties: any;
	globalRowsPerPage: any;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
	selectedCustomer: any;
	authUser: any;
	sendOneTimeReportRequest: Function;
}

const TransactionLogs = (props: TransactionProps) => {
	const [searchClicked, setSearchClicked] = React.useState(false);
	const dispatch = useDispatch();
	const muiThemeStyle = getMuiTheme();
	const [isActionDialog, setActionDialog] = React.useState(false);
	let [links, setLinks] = React.useState(null as any);
	const [tableColumns, setColumns] = React.useState(TableColumns);
	const [filter] = React.useState(new TransactionLogFilter());
	const [searchCount, setSearchCount] = React.useState(0);
	const isFirstRender = useRef(true);
	const [transactionLogs, setTransactionLogs] = React.useState([]);
	const [pagination, setPaginationData] = React.useState({
		//offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	});
	let [columnsOrder, setColumnsOrder] = React.useState(null as any);

	const customBodyRender = (value?: any, tableMeta?: any, updateValue?: any) => {
		let cabinetIndex = dataList.CabinetTypeDropdownList.findIndex((obj: { value: string }) => obj.value === value);
		return dataList.CabinetTypeDropdownList[cabinetIndex]
			? dataList.CabinetTypeDropdownList[cabinetIndex].label
			: value;
	};

	const localDateFormat = (value?: any) => {
		const timestamp = moment.utc(value, 'YYYY/MM/DD HH:mm:ss');
		return timestamp.local().toISOString(true).replace('.000', '');
	};

	React.useEffect(() => {
		setTransactionLogs(props.transactionLogs?.result || []);
	}, [props.transactionLogs]);

	// remove grid filter
	const onFilterChanged = (filter: any) => {
		setSearchClicked(true);
		setSearchCount(searchCount + 1);
		tableColumns[1].options.customBodyRender = (value?: any, tableMeta?: any) => {
			let materialNo = tableMeta.rowData[1];
			if (!materialNo) {
				materialNo = tableMeta.rowData[0];
			}
			return materialNo;
		};
		tableColumns[4].options.customBodyRender = customBodyRender;
		tableColumns[7].options.customBodyRender = (value: any) => {
			return UserTimezoneFormat(props.properties, value);
		};
		tableColumns[8].options.customBodyRender = (value?: any) => {
			return value ? UserDateFormat(props.properties, value) : '';
		};
		tableColumns[12].options.customBodyRender = (value?: any) => {
			return (
				<Chip
					label={value || ''}
					size="small"
					variant="outlined"
					color={value === 'INSERT' ? 'primary' : value === 'REMOVAL' ? 'secondary' : undefined}
				/>
			);
		};
		tableColumns[13].options.customBodyRender = (value?: any, tableMeta?: any) => {
			const cabinetType = tableMeta.rowData[3];
			const qty = tableMeta.rowData[10];
			const type = tableMeta.rowData[11];
			const reasonCode = tableMeta.rowData[15];
			if (cabinetType === 'RFID' && type === 'REMOVAL' && qty === 0 && reasonCode === '20') {
				return 'PENDING';
			}
			if (cabinetType === 'RFID' && type === 'INSERT' && qty === 0 && reasonCode === '10') {
				return 'RE-INSERT';
			}

			return value || '';
		};
		setColumns(tableColumns);
		setPaginationData({ limit: props.globalRowsPerPage, page: 0 });
	};

	const onChangePractice = () => {
		//setServerFilterList([]);
		//updateFilterPayload();
	};

	const changePage = () => {
		const paging = {
			limit: props.globalRowsPerPage,
			page: pagination.page + 1,
		};

		filter.order = columnsOrder ? columnsOrder : 'date desc';
		filter.paging = paging;
		if (filter.date) {
			dispatch(ReportActions.getRecentlyVended(filter));
		}
	};

	const formatLog = (log: any[]) => {
		const output = log.map((el: any) => {
			const datetime = `${el.date.split('T')[0]} ${el.time}`;
			const timestamp = moment.utc(datetime, 'YYYY/MM/DD HH:mm:ss');
			el.timestamp = timestamp.local().toISOString(true).replace('.000', '');
			return el;
		});
		return output;
	};

	const changePageCallback = useCallback(changePage, [pagination]);
	useEffect(() => {
		if (!isFirstRender.current) {
			changePageCallback();
		}
	}, [changePageCallback]);

	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	const handleClose = () => {
		setActionDialog(false);
	};
	const handleOpen = (rowData: any) => {
		setActionDialog(true);
		const queryString = `?filter=${JSON.stringify({ product: rowData[1] })}`;
		links = [
			{
				title: Constants.PAGE_TITLE.INVENTORY,
				suffix: `For ${rowData[1]}`,
				url: `/inventory-management/${rowData[2]}${queryString}`,
				icon: <StorageIcon />,
			},
		];
		setLinks(links);
	};

	const onTableChange = (action: any, tableState: any) => {
		switch (action) {
			case 'changePage':
				setPaginationData({ limit: props.globalRowsPerPage, page: tableState.page });
				break;
			case 'changeRowsPerPage':
				setPaginationData({ limit: tableState.rowsPerPage, page: tableState.page });
				props.setGlobalRowsPerPage(tableState.rowsPerPage);
				break;
		}
	};

	const getGroupName = (groupKey: string) => {
		const groupKeyMap: any = {
			cabinet: 'cabinetId',
			product: 'productItemId',
			lot: 'lot',
			type: 'type',
			serial_no: 'serial_no',
		};
		const groupNameMap: any = {
			cabinet: 'cabinetName',
			product: 'productLabelName',
			lot: 'lot',
			type: 'type',
			serial_no: 'serial_no',
		};
		const group = props.transactionGroupNames.find((groupNameData: any) => {
			return groupNameData[groupKeyMap[filter.groupBy]] === groupKey;
		});
		if (group) {
			return group[groupNameMap[filter.groupBy]];
		}
		return '';
	};

	const enqueueCsvExportForCurrentSearch = (selectedGroupData?: any, tableColumns?: any) => {
		const filtersForQueue: any = { ...filter };
		filtersForQueue.paging.limit = 9999;

		const columnsRequested: string[] = [];

		tableColumns.forEach((tc: any) => {
			if (tc.display === 'true' || tc.display === true) {
				columnsRequested.push(tc.name);
			}
		});

		const params = {
			filter_object_from_ui: filtersForQueue,
			report_type: 'RNI_UIEXPORT_TransactionLogReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				columnsRequested: columnsRequested,
			},
		};

		props.sendOneTimeReportRequest(params);
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 68; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	const styles = InventoryReportsStyles();
	return (
		<div>
			<div className={styles.searchWrap}>
				<Grid container>
					<Grid item xs={12}>
						<SearchActions onFilterUpdate={onFilterChanged} filter={filter} onChangePractice={onChangePractice} />
					</Grid>
				</Grid>
			</div>
			{props.transactionLogs && props.transactionLogs.result.length > 0 ? (
				<Grid container>
					<Grid item xs={12}>
						{!filter.groupBy && (
							<MUIDataTable
								data={formatLog(props.transactionLogs.result)}
								columns={tableColumns}
								options={{
									onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
										enqueueCsvExportForCurrentSearch(null, cols);
										return false;
									},
									onColumnSortChange: (changedColumn: string, direction: string) => {
										if (changedColumn === 'timestamp') {
											changedColumn = 'date';
										}
										columnsOrder = `${changedColumn} ${direction}`;
										setColumnsOrder(columnsOrder);
										changePage();
									},
									setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
										if (!props.transactionLogs.result[dataIndex].expiration) {
											return;
										}
										const expiration = momentFn(props.transactionLogs.result[dataIndex].expiration, 'YYYY-MM-DD');
										const trxDate = props.transactionLogs.result[dataIndex].date
											? props.transactionLogs.result[dataIndex].date.split('T')[0]
											: null;
										const trxTime = props.transactionLogs.result[dataIndex].time || '00:00:00';
										if (!trxDate) {
											return;
										}
										const trxTimestamp = momentFn(`${trxDate} ${trxTime}`, 'YYYY-MM-DD HH:mm:ss');
										if (trxTimestamp.diff(expiration, 'minute') >= 0) {
											return { style: { backgroundColor: globalColors.TABLE_WARNING } };
										}
										return;
									},
									onRowClick: handleOpen,
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									download: true,
									print: true,
									selectableRows: 'none',
									serverSide: true,
									count:
										props.transactionLogs && props.transactionLogs.total_record
											? props.transactionLogs.total_record
											: 0,
									search: false,
									page: pagination.page,
									onColumnViewChange: (changedColumn: string, direction: string) => {
										setColumns(FixViewColumns(changedColumn, direction, tableColumns));
									},
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									textLabels: {
										body: {
											noMatch: searchClicked ? 'Sorry, no matching records found' : '',
										},
									},
									onTableChange: (action: any, tableState: any) => onTableChange(action, tableState),
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
								}}
							/>
						)}
						{filter && filter.groupBy && (
							<div
								style={{
									marginTop: 30,
								}}
							>
								{Object.keys(props.transactionGroupData || {}).map((groupKey: any, index: number) => {
									return (
										<div key={index}>
											<TransactionGroupTable
												data={props.transactionGroupData[groupKey].result || []}
												totalRecords={props.transactionGroupData[groupKey].total_record}
												filter={filter}
												groupName={getGroupName(groupKey)}
												groupBy={filter.groupBy}
												groupKey={groupKey}
												searchClicked={searchClicked}
												handleOpen={handleOpen}
												filterChanged={searchCount}
											/>
										</div>
									);
								})}
							</div>
						)}
					</Grid>
				</Grid>
			) : (
				<EmptyContent message="Select the filter criteria above to view transaction information." />
			)}
			<LinkableActions open={isActionDialog} onClose={handleClose} links={links} />
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		transactionLogs: state.reports.recentlyVended,
		transactionGroupData: state.reports.transactionGroups,
		transactionGroupNames: state.reports.transactionGroupNames,
		properties:
			state.user &&
			state.user.authUser &&
			state.user.authUser.record &&
			state.user.authUser.record.properties &&
			state.user.authUser.record.properties instanceof Array
				? state.user.authUser.record.properties
				: [],
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
		authUser: state.user.authUser,
		selectedCustomer: state.ui.selectedCustomer,
		selectedFilterValues: state.ui.selectedFilterValues,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionLogs);
