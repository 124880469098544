import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { PracticePageStyles, getMuiTheme, getModalStyle, getModalClasses } from '../../../hooks/styles';
import { Paper, Grid, MuiThemeProvider, Modal, makeStyles, createStyles, Theme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import MUIDataTable from 'mui-datatables';
import { TenantsActions } from '../../../redux/actions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { GridContainer } from '../../../hooks/functions';

const NotificationActivity = (props: any) => {
	const classes = PracticePageStyles();
	const modalClasses = getModalClasses();
	const muiTheme = getMuiTheme();
	const { customerId } = useParams() as any;
	const [notificationDataFetched, setNotificationDataFetched] = React.useState(false);
	const [messageDetails, setMessageDetails] = React.useState(null as any);
	const [modalOpen, setModalOpen] = React.useState(false);

	const getFormattedTimestamp = (value: string) => {
		const timestamp = moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ');
		return timestamp.local().toISOString(true).replace('.000', '');
	};

	const handleModalOpen = (rowData: string[], rowMeta: { rowIndex: number; dataIndex: number }) => {
		const messageId = props.notificationActivity[rowMeta.dataIndex].msg_id;
		props.getMessageDetails(messageId);
	};

	const handleModalClose = () => {
		setMessageDetails(null);
		setModalOpen(false);
	};

	React.useEffect(() => {
		if (props.messageDetails && !messageDetails) {
			setMessageDetails(props.messageDetails);
			setModalOpen(true);
		}
	}, [props.messageDetails]);

	React.useEffect(() => {
		if (!notificationDataFetched) {
			props.getNotificationActivity(customerId);
			setNotificationDataFetched(true);
		}
	}, [props, notificationDataFetched]);

	return (
		<div>
			{notificationDataFetched && props.notificationActivity && (
				<Paper className={clsx(classes.paper, 'container-wrapper')} style={{ flexGrow: 1 }}>
					<Grid container>
						<Grid item xs={12}>
							<MUIDataTable
								className={clsx(classes.paper, 'container-wrapper')}
								title={<h3>Notification Messages (past 30 days)</h3>}
								data={props.notificationActivity}
								columns={[
									{ label: 'From', name: 'from_email' },
									{ label: 'To', name: 'to_email' },
									{
										label: 'Subject',
										name: 'subject',
										options: {
											customBodyRender: (value: string) => {
												return (
													<div
														style={{
															minWidth: '300px',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
													>
														{value}
													</div>
												);
											},
										},
									},
									{ label: 'Status', name: 'status' },
									{
										label: 'Message Read',
										name: 'opens_count',
										options: {
											customBodyRender: (value: number) => {
												return value > 0 ? 'Yes' : 'No';
											},
										},
									},
									{
										label: 'Last Event Timestamp',
										name: 'last_event_time',
										options: {
											customBodyRender: getFormattedTimestamp,
										},
									},
								]}
								options={{
									search: false,
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									download: false,
									print: false,
									selectableRows: 'none',
									serverSide: false,
									onRowClick: handleModalOpen,
									count: props.notificationActivity ? props.notificationActivity.length : 0,
									page: 0,
									rowsPerPage: 10,
									rowsPerPageOptions: [10, 25, 50],
								}}
							/>
						</Grid>
					</Grid>
					<Modal
						open={modalOpen}
						onClose={handleModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<div>
							{messageDetails && (
								<div style={getModalStyle()} className={modalClasses.paper}>
									<div className={modalClasses.closeBtn} onClick={handleModalClose}>
										<CancelIcon color="disabled"></CancelIcon>
									</div>
									<div>
										<h2>Message Details</h2>
										{GridContainer([
											{ label: 'From', value: messageDetails.from_email },
											{ label: 'To', value: messageDetails.to_email },
										])}
										{GridContainer([
											{ label: 'Category', value: messageDetails.categories[1] },
											{ label: 'Staus', value: messageDetails.status },
										])}
										{GridContainer([{ label: 'Subject', value: messageDetails.subject }])}
										<br></br>
										<b>Events</b>
										<br></br>
										<Grid container>
											{messageDetails.events.map((event: any, index: number) => (
												<Grid key={index} item xs={6}>
													{GridContainer([{ label: event.event_name, value: getFormattedTimestamp(event.processed) }])}
												</Grid>
											))}
										</Grid>
									</div>
								</div>
							)}
						</div>
					</Modal>
				</Paper>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	notificationActivity: state.tenants.notificationActivity,
	messageDetails: state.tenants.notificationMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
	getNotificationActivity: (customerId: string) => dispatch(TenantsActions.getNotificationActivity(customerId)),
	getMessageDetails: (messageId: string) => dispatch(TenantsActions.getNotificationMessage(messageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationActivity);
