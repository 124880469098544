import local from './config/local.json';
import devApp from './config/dev-app.json';
import devOps from './config/dev-ops.json';
import devInternal from './config/dev-internal.json';
import qaApp from './config/qa-app.json';
import qaOps from './config/qa-ops.json';
import app from './config/app.json';
import ops from './config/ops.json';
import devTrack from './config/dev-track.json';
import qaTrack from './config/qa-track.json';
import track from './config/track.json';
import demoTrack from './config/demo-track.json';

const configFiles: any = {
	'dev-app': devApp,
	'dev-ops': devOps,
	'dev-internal': devInternal,
	'qa-app': qaApp,
	'qa-ops': qaOps,
	app: app,
	ops: ops,
	'dev-track': devTrack,
	'qa-track': qaTrack,
	track: track,
	'demo-track': demoTrack,
};

function getConfig() {
	// @ts-ignore
	const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

	if (!subdomain) {
		return local;
	}

	return configFiles[subdomain];
}

export default getConfig();
