import createReducer from './createReducer';
import { AlertActions } from '../actions';
import { ALERTS } from '../constants/actions';

export const alerts = createReducer(
	{},
	{
		[ALERTS.LIST_RESPONSE](state = {}, action: ReturnType<typeof AlertActions.alertsRequestCompletedAction>) {
			return { ...state, alertsList: action.payload };
		},

		[ALERTS.SET_NOTIFICATION_TYPES](state = {}, action: ReturnType<typeof AlertActions.setNotificationTypesAction>) {
			return { ...state, notificationTypes: action.payload };
		},

		[ALERTS.SET_NOTIFICATION_CHANNEL_GROUPS](
			state = {},
			action: ReturnType<typeof AlertActions.setNotificationChannelGroupsAction>,
		) {
			return { ...state, channelGroups: action.payload };
		},

		[ALERTS.SET_CHANNEL_TYPES](state = {}, action: ReturnType<typeof AlertActions.setChannelTypesAction>) {
			return { ...state, channelTypes: action.payload };
		},

		[ALERTS.SET_INCIDENTS](state = {}, action: ReturnType<typeof AlertActions.setIncidents>) {
			return { ...state, incidents: action.payload };
		},

		[ALERTS.SET_INCIDENT_HISTORY](state = {}, action: ReturnType<typeof AlertActions.setIncidentHistory>) {
			return { ...state, incident: action.payload };
		},

		[ALERTS.SET_INCIDENT_RULES](state = {}, action: ReturnType<typeof AlertActions.setIncidentRules>) {
			return { ...state, incidentRules: action.payload };
		},
	},
);
