import * as React from 'react';
import { Button, FormControl, Grid, Paper, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import { SelectBox } from '../../components/selectBox';

const SuspendedTransactionModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [memo, setMemo] = React.useState<any>('');
	const [qty, setQty] = React.useState<any>(props.suspendedTransaction.attemptedQty);
	const [status, setStatus] = React.useState<any>('');
	const [errors, setErrors] = React.useState<any>({});

	const submit = () => {
		const formErrors: any = {};
		if (!status) {
			formErrors['status'] = 'A resolution status is required.';
		}
		if (isNaN(qty)) {
			formErrors['qty'] = 'Please enter a valid qty.';
		}
		setErrors(formErrors);

		if (Object.keys(formErrors).length > 0) {
			return;
		}

		props.updateSuspendedTransaction({
			memo: memo,
			qty: qty,
			status: status,
		});
	};

	React.useEffect(() => {
		setErrors({});
	}, [qty, status]);

	return (
		<div
			style={{
				...getModalStyle(),
				width: isDesktopOrLaptop ? 'auto' : '90%',
				maxHeight: 600,
				maxWidth: 750,
				overflow: 'scroll',
			}}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={props.close}
			>
				<CancelIcon />
			</div>
			<h2>Resolve Transaction</h2>
			<Grid container spacing={3}>
				<Grid item xs={3}>
					Customer Id: <br />
					<b>{props.suspendedTransaction.customerId}</b>
				</Grid>
				<Grid item xs={3}>
					Device Id: <br />
					<b>{props.suspendedTransaction.cabinetId}</b>
				</Grid>
				<Grid item xs={3}>
					Product Id: <br />
					<b>{props.suspendedTransaction.productItemId}</b>
				</Grid>
				<Grid item xs={3}>
					Lot: <br />
					<b>{props.suspendedTransaction.lot}</b>
				</Grid>
				<Grid item xs={3}>
					Order Number: <br />
					<b>{props.suspendedTransaction.orderNumber}</b>
				</Grid>
				<Grid item xs={3}>
					Shipment Id: <br />
					<b>{props.suspendedTransaction.shipmentId}</b>
				</Grid>
				<Grid item xs={3}>
					Shipped Qty: <br />
					<b>{props.suspendedTransaction.shipQty}</b>
				</Grid>
				<Grid item xs={3}>
					Attempted Qty: <br />
					<b>{props.suspendedTransaction.attemptedQty}</b>
				</Grid>
				<Grid item xs={3}>
					Difference: <br />
					<b>{props.suspendedTransaction.diffQty}</b>
				</Grid>
				<Grid item xs={3}>
					Trx Type: <br />
					<b>{props.suspendedTransaction.transactionType}</b>
				</Grid>
				<Grid item xs={3}>
					Reason: <br />
					<b>{props.suspendedTransaction.transactionReason}</b>
				</Grid>
				<Grid item xs={3}>
					Reason Suspended: <br />
					<b>{props.suspendedTransaction.suspendedReason}</b>
				</Grid>
				<Grid item xs={3}>
					Resolution Status: <br />
					<b>{props.suspendedTransaction.resolutionStatus}</b>
				</Grid>
			</Grid>
			{props.suspendedTransaction.resolutionStatus !== 'Suspended' && (
				<div>
					<p>
						Memo: <br />
						{props.suspendedTransaction.memo}
					</p>
				</div>
			)}
			{props.suspendedTransaction.resolutionStatus === 'Suspended' && (
				<div>
					<div style={{ margin: '20px 0 0 0' }}>
						<FormControl style={{ width: '100%' }}>
							<TextField
								label="Memo"
								placeholder="Add a memo (optional)"
								variant="outlined"
								multiline
								rows={2}
								rowsMax={2}
								onChange={e => {
									setMemo(e.target.value);
								}}
							/>
						</FormControl>
					</div>
					<div style={{ margin: '20px 0 0' }}>
						<FormControl variant="outlined" style={{ marginRight: 10 }}>
							<TextField
								variant="outlined"
								type="number"
								label="Qty"
								style={{ width: 105 }}
								InputProps={{ inputProps: { min: 0, step: 1 } }}
								value={qty >= 0 ? qty : null}
								error={errors.qty ? true : false}
								helperText={errors.qty || ''}
								onChange={event => {
									setQty(parseInt(event.target.value));
								}}
								required
							/>
						</FormControl>
						<FormControl variant="outlined">
							<SelectBox
								required={true}
								style={{ width: 150 }}
								inputLabel={'Resolution Status'}
								emptyItemLabel={'Select Status'}
								listItems={[
									{ label: 'Approved', value: 'Approved' },
									{ label: 'Rejected', value: 'Rejected' },
								]}
								onChangeItem={(value: string) => {
									setStatus(value);
								}}
								error={errors.status ? true : false}
								errorText={errors.status || ''}
							/>
						</FormControl>
					</div>
					<div>
						<FormControl>
							<Button
								type="button"
								className={commonStyles.searchButton}
								variant="contained"
								color="primary"
								onClick={submit}
							>
								SUBMIT
							</Button>
						</FormControl>
					</div>
				</div>
			)}
		</div>
	);
};

export default SuspendedTransactionModal;
