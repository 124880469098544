import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getMuiTheme } from '../../hooks/styles';
import { ReportActions } from '../../redux/actions';
import dataList from '../../constants/dataList';
import { ParUsageStyles } from './par-usage.styles';
import { UserTimezoneFormat } from '../../hooks/functions';
import {
	Table,
	TableBody,
	TableRow,
	TableCell,
	Grid,
	Card,
	CardHeader,
	CardContent
} from '@material-ui/core';
import NotesIcon from '@mui/icons-material/TextSnippet';

interface ParUsageGroupTableProps {
	data?: any;
	totalRecord?: any;
	filter?: any;
	groupName?: any;
	groupBy?: any;
	groupKey?: any;
	searchClicked?: any;
	handleOpen?: any;
	transactionGroupData: any;
	filterChanged?: any;
	inventory: any;
	properties: any;
	enqueueCsvReportForCurrentQuery: Function;
}

const ParUsageGroupTable: React.FC<any> = (props: ParUsageGroupTableProps) => {
	const muiThemeStyle = getMuiTheme();
	// muiThemeStyle.overrides.MuiPaper = {
	//   root: {
	//     width: '100%'
	//   }
	// }
	const cabinetClasses = ParUsageStyles();
	const [toolTopPosition, setToolTipPosition] = React.useState({x: 0, y: 0} as any);
	const [hoverData, setHoverData] = React.useState<any>(null);
	const [showToolTip, setShowToolTip] = React.useState(false);

	const [parRecords, setParRecords] = React.useState<any[]>([]);

	React.useEffect(() => {
		setParRecords(props.inventory || [])
	}, [props.inventory, parRecords])

	const formatTitle = (data: any) => {
		return data.cabinetId ? (data.groupName as string) : (data.groupName as string) + ' - VIPc';
	};

	const columns = [
		{
			name: 'customerId',
			label: 'Customer Id',
			options: {
				display: false,
				viewColumns: false,
			},
		},
		{
			name: 'customerName',
			label: 'Customer Name',
			options: {
				display: false,
				viewColumns: false,
			},
		},
		{
			name: 'groupName',
			label: 'Group Name',
			options: {
				display: false,
				viewColumns: false,
			},
		},
		{
			name: 'productId',
			label: 'Product Id',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'productName',
			label: 'Product Name',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'ndc',
			label: 'NDC',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'crl',
			label: 'CRL',
			options: {
				filter: true,
				sort: true,
				hint: `CRL: Clinical Reserve Level- Minimal clinical quantity to care according to provider policy`,
				customBodyRenderLite: (dataIndex: number) => {
					const parRecord = parRecords[dataIndex];
					if (parRecord?.productCrl) {
						return <div style={{ display: 'flex', alignItems: 'center' }}>
							{parRecord?.crl}
							<NotesIcon
								style={{ marginLeft: 5 }}
								onMouseMove={(e: any) => {
									setToolTipPosition({ x: e.pageX, y: e.pageY })
								}}
								onMouseEnter={(e: any) => {
									const productCrl = `Product CRL: ${parRecord.productCrl}`;
									setHoverData(<div style={{ whiteSpace: 'pre-line' }}>{productCrl}</div>)
									setShowToolTip(true)
								}}
								onMouseLeave={(e: any) => {
									setShowToolTip(false)
								}}
							/>
						</div>
					} else {
						return <div style={{ display: 'flex', alignItems: 'center' }}>
							{parRecord?.crl}
						</div>
					}
				}
			},
		},
		{
			name: 'minQty',
			label: 'Min PAR',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'maxQty',
			label: 'Max PAR',
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: 'maxFill',
			label: 'Max Fill',
			options: {
				filter: true,
				sort: true,
				customBodyRenderLite: (dataIndex: number) => {
					const parRecord = parRecords[dataIndex];
					if (parRecord?.productMaxFill) {
						return <div style={{ display: 'flex', alignItems: 'center' }}>
							{parRecord?.maxFill}
							<NotesIcon
								style={{ marginLeft: 5 }}
								onMouseMove={(e: any) => {
									setToolTipPosition({ x: e.pageX, y: e.pageY })
								}}
								onMouseEnter={(e: any) => {
									const productMaxFill = `Product Max Fill: ${parRecord.productMaxFill}`;
									setHoverData(<div style={{ whiteSpace: 'pre-line' }}>{productMaxFill}</div>)
									setShowToolTip(true)
								}}
								onMouseLeave={(e: any) => {
									setShowToolTip(false)
								}}
							/>
						</div>
					} else {
						return <div style={{ display: 'flex', alignItems: 'center' }}>
							{parRecord?.maxFill}
						</div>
					}
				}
			},
		},
		{
			name: 'lastUpdate',
			label: 'Last Updated',
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value: any) => {
					return UserTimezoneFormat(props.properties, value);
				},
			},
		},
		{
			name: '',
			label: '',
			viewColumns: false,
			display: true,
			customBodyRenderLite: (dataIndex: number) => (
				<ParUsageGroupTable dataIndex={dataIndex} />
			),
			setCellProps: () => {
				return { onMouseEnter: () => {
					setShowToolTip(false)
				}}
			}
		},
	];

	const expandedRowView = (row: any) => {
		const colSpan = columns.length + 1;
		return (
			<TableRow>
				<TableCell colSpan={colSpan}>
					<Grid container spacing={6}>
						<Grid item xs={2} />
						<Grid item xs={4}>
							<Card className={cabinetClasses.card}>
								<CardHeader
									className={cabinetClasses.cardHeader}
									titleTypographyProps={{ variant: 'subtitle1' }}
									title="Usage / DOH"
								/>
								<CardContent>
									<Grid container spacing={1}>
										<Grid item xs={2} />
										<Grid item xs={8}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>1 Yr</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{`${row.usageY}/${row.ydoh}`}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>2 Mos</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{`${row.usage2M}/${row.mdoh}`}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>2 Wks</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{`${row.usage2W}/${row.wdoh}`}</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</Grid>
										<Grid item xs={2} />
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={4}>
							<Card className={cabinetClasses.card}>
								<CardHeader
									className={cabinetClasses.cardHeader}
									titleTypographyProps={{ variant: 'subtitle1' }}
									title="Inventory"
								/>
								<CardContent>
									<Grid container spacing={1}>
										<Grid item xs={2} />
										<Grid item xs={8}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>On Hand</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{row.salesQty}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>In Transit</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{row.inTransitQty}</TableCell>
													</TableRow>
													<TableRow>
														<TableCell className={cabinetClasses.tableCell}>On Order</TableCell>
														<TableCell className={cabinetClasses.tableCell}>{row.onOrderQty}</TableCell>
													</TableRow>												
												</TableBody>
											</Table>
										</Grid>
										<Grid item xs={2} />
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={2} />
					</Grid>
				</TableCell>
			</TableRow>
		);
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
		<MUIDataTable
			title={<h3>{props.data ? formatTitle(props.data) : ''}</h3>}
			data={props.inventory ?? []} //props.data ? props.data.rows : []}
			columns={columns}
			options={{
				onRowClick: props.handleOpen, //new
				responsive: 'simple',
				filter: false,
				download: true,
				print: true,
				selectableRows: 'none',
				serverSide: false,
				//count: props.totalRecord ? props.totalRecord : 0,
				search: false,
				//page: pagination.page,
				//rowsPerPage: pagination.limit,
				rowsPerPageOptions: dataList.PageLimitOptions,
				sortOrder: { name: 'productLabelName', direction: 'asc' },
				viewColumns: false,
				filterType: 'multiselect',
				expandableRows: true, //new
				expandableRowsOnClick: true, //new
				fixedHeader: true, // This locks the table headers at the top
				tableBodyHeight: tableHeight + 'px', // Set the height for the table body
				onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
					const columnIndex = cols.findIndex((item: any) => item.name === 'groupName');
					const groupName = data[0].data[columnIndex]
					props.enqueueCsvReportForCurrentQuery(cols, groupName);
					return false;
				},
				textLabels: {
					body: {
						noMatch: props.searchClicked ? 'Sorry, no matching records found' : '',
					},
				},
				//onTableChange: (action: any, tableState: any) => onTableChange(action, tableState),
				renderExpandableRow: (rowData: any, rowMeta: any) => expandedRowView(props.inventory[rowMeta.dataIndex]),
			}}
		/>
		{showToolTip &&
			<div style={{
				position: 'absolute',
				top: toolTopPosition.y,
				left: toolTopPosition.x + 20,
				borderRadius: '5px',
				height: 'auto',
				width: 'auto',
				backgroundColor: '#444',
				opacity: 0.8,
				color: '#fff',
				padding: 10
			}}>
				{hoverData}
			</div>
		}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	//inventory: state.inventoryManagement.parInventory ? state.inventoryManagement.parInventory.result : [],
	//totalRecord: state.inventoryManagement.parInventory ? state.inventoryManagement.parInventory.total_record : 0,
	transactionGroupData: state.reports.transactionGroups,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});
const mapDispatchToProps = (dispatch: any) => ({
	getTransactionGroupLog: (payload: any) => dispatch(ReportActions.getTransactionGroupLog(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParUsageGroupTable);
