import React from 'react';
import { connect } from 'react-redux';
import { InventoryManagementActions } from '../../redux/actions';
import {
	Button,
	Grid,
	FormControl,
	TextField,
	MenuItem,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

const EditTagModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());
	const [productCustomer, setTagCustomer] = React.useState<any>(
		props.customers.find((customer: any) => customer.customerId === props.product.customerId) || null,
	);
	const [productCustomerId, setProductCustomerId] = React.useState<string | null>(props.product.customerId || null);
	const [productOrderNo, setProductOrderNo] = React.useState<string | null>(props.product.orderNo || null);
	const [productOwnership, setProductOwnership] = React.useState<string | null>(props.product.productOwnership || null);
	const [productActive, setProductActive] = React.useState<number | null>(props.product.active || null);
	const [formErrors, setFormErrors] = React.useState<any>({});

	const handleClose = () => {
		props.closeModal();
	};

	const handleSave = () => {
		if (!productCustomerId) {
			setFormErrors({
				...formErrors,
				customer: 'A customer is required.',
			});
			return;
		}

		const requestBody = {
			customerId: productCustomerId,
			orderNo: productOrderNo || undefined,
			active: productActive,
			productOwnership: productOwnership,
		};

		props.updateProductTagSerials({
			id: props.product.id,
			requestBody: requestBody,
			success: () => {
				props.refreshList();
			},
		});

		handleClose();
	};

	return (
		<div style={{ ...modalStyle }} className={modalClasses.paper}>
			<div>
				<h3 id="simple-modal-title" style={{ marginBottom: 5 }}>
					Edit Record
				</h3>
				{props.product.tagId && <h5 style={{ marginTop: 0 }}>Tag Id: {props.product.tagId}</h5>}
				{props.product.serialNo && <h5 style={{ marginTop: 0 }}>Serial No: {props.product.serialNo}</h5>}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Autocomplete
							id="customer-combo-box"
							options={props.customers}
							value={productCustomer || null}
							getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
							fullWidth
							onChange={(e: any, value: any) => {
								if (!value) {
									setTagCustomer(null);
									setProductCustomerId(null);
									return;
								}
								setTagCustomer(value);
								setProductCustomerId(value.customerId);
								setFormErrors({
									...formErrors,
									customer: null,
								});
							}}
							renderInput={(params: any) => (
								<TextField
									value={productCustomer || ''}
									{...params}
									label="Search Customers"
									variant="outlined"
									error={formErrors.customer ? true : false}
									helperText={formErrors.customer || ''}
									required
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Owner"
							id="outlined-select-ownership"
							select
							variant="outlined"
							value={productOwnership || ''}
							error={formErrors.productOwnership ? true : false}
							onChange={event => {
								setProductOwnership(event.target.value as string);
							}}
						>
							<MenuItem value={'FFF Enterprises'}>FFF Enterprises</MenuItem>
							<MenuItem value={'Customer'}>Customer</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="Order Number"
							variant="outlined"
							value={productOrderNo || ''}
							error={formErrors.orderNo ? true : false}
							helperText={formErrors.orderNo ? formErrors.orderNo : ''}
							onChange={event => {
								setProductOrderNo(event.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch color="primary"
										checked={productActive === 1 ? true : false}
										onChange={event => {
											setProductActive(event.target.checked ? 1 : 0);
										}}
									/>
								}
								label={'Active'}
								labelPlacement='end'
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleSave}
								type="button"
								style={{ fontSize: '10px' }}
								variant="contained"
								color="primary"
							>
								SAVE
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleClose}
								type="button"
								className={classes.cancelBtn}
								variant="contained"
								color="default"
							>
								CANCEL
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	customers: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

const mapDispatchToProps = (dispatch: any) => ({
	updateProductTagSerials: (payload: any) => dispatch(InventoryManagementActions.updateProductTagSerials(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTagModal);
