import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const InvoiceStyles = makeStyles(theme => ({
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
	viewAllBtn: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
	},

	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	searchButton: {
		margin: '12px 0 0 10px',
		fontSize: '10px!important',
	},
	buttonFormControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '16px!important',
		minWidth: '60px!important',
		verticalAlign: 'bottom',
	},
	dateRange: {
		position: 'absolute',
		left: '0',
		zIndex: 9999,
	},
}));
