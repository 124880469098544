import React from 'react';
import { connect } from 'react-redux';
import { InventoryManagementActions } from '../../redux/actions';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';
import Loading from '../../components/loading';
import CancelIcon from '@material-ui/icons/Cancel';
import MUIDataTable from 'mui-datatables';

const ProductLifecycleModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());
	const [importLogsFlag, setImportLogsFlag] = React.useState(false);
	const [auditLogsFlag, setAuditLogsFlag] = React.useState(false);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const handleClose = () => {
		props.closeModal();
	};

	React.useEffect(() => {
		props.getTagLifecycle({
			id: props.product.id,
			success: () => {
				setIsLoading(false);
			},
			error: () => {
				setIsLoading(false);
				handleClose();
			},
		});
	}, []);

	React.useEffect(() => {
		setIsLoading(true);
		props.getTagLifecycle({
			id: props.product.id,
			additionalLogs: {
				import: importLogsFlag,
				audit: auditLogsFlag,
			},
			success: () => {
				setIsLoading(false);
			},
			error: () => {
				setIsLoading(false);
				handleClose();
			},
		});
	}, [importLogsFlag, auditLogsFlag]);

	return (
		<div style={{ ...modalStyle, maxWidth: 800 }} className={modalClasses.paper}>
			<div className={modalClasses.closeBtn} onClick={handleClose}>
				<CancelIcon color="disabled"></CancelIcon>
			</div>
			<div>
				<h3 id="simple-modal-title" style={{ marginBottom: 5 }}>
					Tag Life Cycle
				</h3>
				<h5 style={{ marginTop: 0 }}>Tag Id: {props.product.tagId}</h5>
				<Grid container>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Product Id:</b>
						<br />
						{props.product.productId}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Product NDC:</b>
						<br />
						{props.product.ndc}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Lot:</b>
						<br />
						{props.product.lot}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Expiration:</b>
						<br />
						{props.product.expiration.split('T')[0]}
					</Grid>
					{props.product.orderNo && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Order No:</b>
							<br />
							{props.product.orderNo}
						</Grid>
					)}
					{props.product.igShipmentId && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Shipment Id:</b>
							<br />
							{props.product.igShipmentId}
						</Grid>
					)}
					{props.product.received && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Received:</b>
							<br />
							{props.product.received}
						</Grid>
					)}
					<Grid item xs={12} style={{ marginBottom: 15 }}></Grid>
					<Grid item xs={4} style={{ marginBottom: 15 }}>
						<FormControlLabel
							control={
								<Checkbox
									className={classes.checkbox}
									checked={importLogsFlag}
									onChange={() => setImportLogsFlag(!importLogsFlag)}
									name="importLogs"
								/>
							}
							label="Include Import Logs"
						/>
					</Grid>
					<Grid item xs={4} style={{ marginBottom: 15 }}>
						<FormControlLabel
							control={
								<Checkbox
									className={classes.checkbox}
									checked={auditLogsFlag}
									onChange={() => setAuditLogsFlag(!auditLogsFlag)}
									name="auditLogs"
								/>
							}
							label="Include Audit Logs"
						/>
					</Grid>
					{isLoading ? (
						<Loading message="" />
					) : (
						<Grid item xs={12}>
							<MUIDataTable
								title={<h5>Tag History</h5>}
								data={props.tagLifecycle.productLifeCycle || []}
								columns={[
									{
										label: 'Source',
										name: 'source',
										change: true,
										viewColumns: true,
										display: true,
									},
									{
										label: 'Customer Id',
										name: 'customerId',
										change: true,
										viewColumns: true,
										display: true,
									},
									{
										label: 'Device Id',
										name: 'cabinetId',
										change: true,
										viewColumns: true,
										display: true,
									},
									{
										label: 'Timestamp',
										name: 'timestamp',
										change: true,
										viewColumns: true,
										display: true,
									},
									{
										label: 'Notes',
										name: 'notes',
										change: true,
										viewColumns: true,
										display: true,
									},
								]}
								options={{
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: true,
									print: true,
									selectableRows: 'none',
								}}
							/>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	tagLifecycle: state.inventoryManagement.tagLifecycle,
});

const mapDispatchToProps = (dispatch: any) => ({
	getTagLifecycle: (payload: any) => dispatch(InventoryManagementActions.getTagLifecycle(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductLifecycleModal);
