import React from 'react';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { getMuiTheme } from '../../../hooks/styles';
import dataList from '../../../constants/dataList';
import { tablePagination } from '../../../hooks/functions/TablePagination';
import { UserDateFormat } from '../../../hooks/functions';
import { UIAction } from '../../../redux/actions';

interface RestockOrderDetailProps {
	// inventoryHistoryRes: any;
	// totalRecord: any;
	pagination?: any;
	// sortOrder: any;
	// tableStatePersist: any;
	// searchClicked: boolean;
	// tabView: string;
	properties: any;
	globalRowsPerPage: any;
	contentType: string;
	columns: any[];
	tableData: any[];
	// setGlobalRowsPerPage: (rowsPerPage: number) => void;
	// onFilterUpdate: (pagination: any, sortOrder: any, tableStatePersist: any) => void;
}

// const customBodyRenderFn = (value?: any, tableMeta?: any, updateValue?: any) => {
//   let cabinetIndex = dataList.CabinetTypeDropdownList.findIndex((obj: { value: string; }) => obj.value === value);
//   return dataList.CabinetTypeDropdownList[cabinetIndex] ? dataList.CabinetTypeDropdownList[cabinetIndex].label : value;
// }

const RestockOrderDetailTable = (props: RestockOrderDetailProps) => {
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);

	React.useEffect(() => {
		//setTableStatePersist(props.tableStatePersist);
	}, []);

	const handlePagination = (newPagination: any) => {
		//props.onFilterUpdate(newPagination, props.sortOrder, tableStatePersist);
	};

	const handleSorting = (newSortOrder: any) => {
		//props.onFilterUpdate({...props.pagination, limit: props.globalRowsPerPage}, newSortOrder, tableStatePersist);
	};

	return (
		<>
			<MUIDataTable
				data={props.tableData}
				columns={props.columns}
				options={{
					rowsPerPage: props.globalRowsPerPage,
					rowsPerPageOptions: dataList.PageLimitOptions,
					onChangeRowsPerPage: (numberOfRows: number) => {
						handlePagination({ ...props.pagination, limit: numberOfRows });
						//props.setGlobalRowsPerPage(numberOfRows);
					},
					search: false,
					filterType: 'dropdown',
					responsive: 'simple',
					filter: false,
					download: true,
					print: true,
					selectableRows: 'none',
					serverSide: true,
					page: props?.pagination?.page,
					//count: props.totalRecord,
					//sortOrder: {name: props.sortOrder.split(" ")[0], direction: props.sortOrder.split(" ")[1]},
					// textLabels: {
					//   body: {
					//     noMatch: props.searchClicked
					//       ? "Sorry, no matching records found"
					//       : "",
					//   },
					// },
					onTableChange: (tableAction: any, tableState: any) => {
						// if(tableAction == "changePage"){
						//   handlePagination(tablePagination(tableAction, tableState, {...props.pagination, limit: props.globalRowsPerPage}));
						// }
						// else if(tableAction == "sort"){
						//   handleSorting(tableState.sortOrder.name + " " + tableState.sortOrder.direction);
						// }
						// else if(tableAction == "viewColumnsChange"){
						//   setTableStatePersist(tableState)
						// }
					},
				}}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	// inventoryHistoryRes: state.reports.inventoryHistory,
	// totalRecord: state.reports.inventoryHistoryCount,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestockOrderDetailTable);
