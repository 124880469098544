import axios from 'axios';
import { store } from '../store/store';
import Api from '../lib/api';
import { USER, USER_PROPERTY, SUPPORT, REPORTS } from '../constants/api.url';
import { Constants } from '../../constants/Constants';
import { UserRecord, Claims } from '../models/user/user';
import { Persona } from '../models/persona/persona';
import { ReportSubscription } from '../models/reports/scheduled-reports-types';
import csv from 'csvtojson';

/*
 * user service also includes services related to personas and help videos
 */
export class UserService {
	private static instance: UserService;
	private constructor() {}

	public RESOURCES = Constants.RESOURCES;

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}
		return UserService.instance;
	}

	public createUser = async (userData: UserRecord): Promise<any> => {
		await Api.post(USER.URL, userData);
	};

	public setupUser = async (payload: any): Promise<any> => {
		await Api.post(USER.URL + '/setup', payload);
	};

	public importUser = async (userObj: any, claims: any): Promise<any> => {
		const state = store.getState() as { user: { firebaseToken: string } };
		const token = state.user.firebaseToken;

		try {
			const response = await axios.post(
				`${USER.URL}/importJson`,
				{
					userJson: userObj,
					claims: claims,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				},
			);

			return response.data[0];
		} catch (e) {
			console.log('user import failed', userObj.email);

			return {
				userEmail: userObj.email,
				imported: false,
				errors: e,
			};
		}
	};

	public importUsers = async (payload: any): Promise<any> => {
		const state = store.getState() as { user: { firebaseToken: string } };
		const token = state.user.firebaseToken;

		const fileAsText = await payload.file.text();
		const jsonData = await csv().fromString(fileAsText);

		const importResults: any = [];

		//*** We must use a "for" loop here as forEach will not work asyncronously!
		for (const userObj of jsonData) {
			userObj.personas = userObj.personas || '';

			try {
				const response = await axios.post(
					`${USER.URL}/importJson`,
					{
						userJson: userObj,
						claims: payload.claims,
					},
					{
						headers: {
							Authorization: 'Bearer ' + token,
						},
					},
				);

				importResults.push(response.data[0]);
			} catch (e) {
				console.log('user import failed', userObj.email);

				importResults.push({
					userEmail: userObj.email,
					imported: false,
					errors: e,
				});
			}
		}

		return importResults;
	};

	public getVimeoAppAuthToken = async (): Promise<any> => {
		return await axios.post(
			'https://api.vimeo.com/oauth/authorize/client',
			{
				grant_type: 'client_credentials',
				scope: 'public',
			},
			{
				headers: {
					Authorization: Constants.VIMEO_APP_AUTH_KEY,
					'Content-Type': 'application/json',
					Accept: 'application/vnd.vimeo.*+json;version=3.4',
				},
			},
		);
	};

	public sendSupportRequest = async (supportFormData: any): Promise<any> => {
		const pl = {
			notification_type: 'rni_web_feedback',
			payload: {
				Subject: 'RNI Web Feedback',
				Message: supportFormData.messageBody,
			},
		};

		await Api.post(SUPPORT.URL, pl);
	};

	public updateUser = async (userData: UserRecord): Promise<any> => {
		const id = userData.id;
		delete userData.id;
		await Api.patch(USER.URL + '/' + id, userData);
	};

	public getUserRecord = async (uid: string): Promise<UserRecord> => {
		const response = await Api.get(USER.URL + '/' + uid);
		const record = response.data;
		const userRecord: UserRecord = record;
		return userRecord;
	};

	/*
	 * get the list of all available personas that might be applied to any given user
	 */
	public getPersonas = async (): Promise<any> => {
		const response = await Api.get(USER.URL + '/personas');
		return response.data;
	};

	public updatePersona = async (personaData: Persona): Promise<any> => {
		const response = await Api.patch(USER.URL + '/personas', personaData);
		return response.data;
	};

	public createPersona = async (personaData: Persona): Promise<any> => {
		const response = await Api.post(USER.URL + '/personas', personaData);
		return response.data;
	};

	public createReportSubscription = async (reportSubscriptionData: ReportSubscription): Promise<any> => {
		const response = await Api.post(REPORTS.CREATE_SUBSCRIPTION, reportSubscriptionData);
		return response.data;
	};

	public editReportSubscription = async (reportSubscriptionData: ReportSubscription): Promise<any> => {
		const response = await Api.patch(REPORTS.EDIT_SUBSCRIPTION, reportSubscriptionData);
		return response.data;
	};

	public updateUserQueueItem = async (params: object): Promise<any> => {
		const response = await Api.patch(REPORTS.UPDATE_QUEUE_ITEM, params);
		return response;
	};

	public deleteReportSubscription = async (params: object): Promise<any> => {
		const response = await Api.post(REPORTS.DELETE_SUBSCRIPTION, params);
		return response.data;
	};

	public sendOneTimeReportRequest = async (params: object): Promise<any> => {
		const response = await Api.post(REPORTS.SEND_ONE_TIME_REPORT, params);
		return {
			success: true,
		};
	};

	public getUserByEmail = async (email: string): Promise<UserRecord> => {
		const response = await Api.get(USER.URL + '/exists?email=' + email);
		const record = response.data;
		const userRecord: UserRecord = record;
		return userRecord;
	};

	public getUserNav = (claims: Claims) => {
		const tenantIds = this.deriveUserTenants(claims);
		let userAllowedResources: string[] = [];
		tenantIds.forEach(tenantId => {
			userAllowedResources = userAllowedResources.concat(Object.keys(claims[tenantId]));
		});
		const uniqueResourceSet = new Set(userAllowedResources);
		userAllowedResources = Array.from(uniqueResourceSet);

		return userAllowedResources.filter(resource => {
			return this.RESOURCES.includes(resource);
		});
	};

	public getUserList = async (params: any | null) => {
		//const url = USER.URL + (tenantId ? `?tenantId=${tenantId}` : '');
		return (await Api.get(USER.URL, params)).data;
		//return await (Api.get(USER.URL)).data;
	};

	private deriveUserTenants = (claims: Claims = {}) => {
		return Object.keys(claims);
	};

	public createUserProperty = async (payload: any | null) => {
		return await Api.post(`${USER_PROPERTY.URL}/${payload.email}?userId=${payload.userId}`, payload.body);
	};

	private createQueryParams = (filter: any) => {
		let paramString: string = '';
		Object.keys(filter).forEach(function (key: any, index: number) {
			if (filter[key] !== undefined && filter[key] !== 'undefined') {
				paramString += index > 0 ? '&' + key + '=' + filter[key] : '?' + key + '=' + filter[key];
			}
		});
		return paramString;
	};

	public getUserProperties = async (params: any | null) => {
		return await Api.get(`${USER_PROPERTY.URL}${this.createQueryParams(params)}`);
	};

	public resendInviteEmail = async (emailAddress: string): Promise<any> => {
		await Api.post(USER.URL + '/sendUserInvite', { email: emailAddress });
	};

	public setPassword = async (payload: { email: string; tempPassword: string }): Promise<any> => {
		await Api.post(USER.URL + '/setOneTimePassword', payload);
	};

	public deleteUser = async (user: any): Promise<any> => {
		await Api.delete(USER.URL + '/' + user.id, null);
	};

	public getInvoiceLinkUserList = async (params: any | null) => {
		return (await Api.get(USER.CUSTOMER_USER, params)).data;
	};

	public createInvoiceLinkUser = async (payload: any | null): Promise<any> => {
		await Api.post(USER.CUSTOMER_USER, payload.requestBody);
	};

	public deleteInvoiceLinkUser = async (payload: any | null): Promise<any> => {
		await Api.delete(USER.CUSTOMER_USER + `/${payload.deleteId}`, payload.deleteId);
	};
	public downloadUsersCsv = async (params: any): Promise<any> => {
		const { data } = await Api.get(USER.URL, params);
		var blob = new Blob([data]);
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'users.csv';
		link.click();
	};

	public getCustomToken = async (payload: any): Promise<any> => {
		return await Api.post(USER.URL + '/login', payload);
	};

	public getUserReportQueue = async (): Promise<any> => {
		return await Api.get(USER.URL + '/queue/userreportqueue');
	};
}
