import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Link } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from '../../hooks/styles';
import { CommonStyles } from '../../hooks/styles';
import SearchActions from './search';
import { InvoicesActions, UIAction, UserActions } from '../../redux/actions';
import LinkableActions from '../../components/linkableActions';
import { orderBy } from 'lodash';
import PeopleIcon from '@material-ui/icons/People';
import KitchenIcon from '@material-ui/icons/Kitchen';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import StorageIcon from '@material-ui/icons/Storage';
import HistoryIcon from '@material-ui/icons/History';
import PaymentIcon from '@material-ui/icons/Payment';
import dataList from '../../constants/dataList';
import { FixViewColumns, UserDateFormat } from '../../hooks/functions';
import EmptyContent from '../../components/emptyContent';
import { Constants } from '../../constants/Constants';
import CancelIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';
import {Severities, SendLog} from '../../hooks/functions/SendLog';
import { InvoicesService } from '../../redux/services';
import { Alert } from '@mui/material';

interface InvoiceProps {
	invoicesList?: any;
	openPaymentPage?: any;
	getInvoiceList?: any;
	properties: any;
	globalRowsPerPage: any;
  isCCPaymentMode?: boolean;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
}

const InvoiceHistory: React.FC<InvoiceProps> = (props: InvoiceProps) => {
	const muiThemeStyle = getMuiTheme();
	const classes = CommonStyles();
  const [isCCPaymentMode , setIsCCPaymentMode] = React.useState(props.isCCPaymentMode || false);

	const [isActionDialog, setActionDialog] = React.useState(false);
  const [ccEligbleProductIds, setCcEligbleProductIds] = React.useState([]);
  const [ccPayableInvoicesList, setCcPayableInvoicesList] = React.useState([]);
  const [recentlyPaidInvoicesIdList, setRecentlyPaidInvoicesIdList] = React.useState([]);
  const [ccPayableMetadata, setCcPayableMetadata] = React.useState({} as any);
  const [alertNoCCPayableInvoices, setAlertNoCCPayableInvoices] = React.useState(false);

	let [links, setLinks] = React.useState(null as any);

  React.useEffect(() => {
    if (isCCPaymentMode) {
      filterForCCPayable();
      let paidList = localStorage['recentlyPaidInvoiceIds'] || '';

      if (paidList) {
        setRecentlyPaidInvoicesIdList(localStorage['recentlyPaidInvoiceIds'].split(','))
      }
      
    }
  }, [props.invoicesList]);

	const fetchResult = (filters: any) => {
		props.getInvoiceList(filters);
	};

	const onSearch = (filters: any) => {
		fetchResult(filters);
	};

	const handleClose = () => {
		setActionDialog(false);
	};

	const handleOpen = (rowData: any, rowMeta: { rowIndex: number; dataIndex: number }) => {
		const invoice = props.invoicesList[rowMeta.dataIndex];

		setActionDialog(true);
		links = [
			{ title: Constants.PAGE_TITLE.INVENTORY, url: `/inventory-management/${rowData[0]}`, icon: <StorageIcon /> },
			{ title: Constants.PAGE_TITLE.CABINETS, url: `/cabinet-list/${rowData[0]}`, icon: <KitchenIcon /> },
			{
				title: Constants.PAGE_TITLE.TRANSACTION_LOG,
				url: `/transaction-log/${rowData[0]}`,
				icon: <IconLibraryBooks />,
			},
			{ title: Constants.PAGE_TITLE.CUSTOMERS, url: `/customer-list/${rowData[0]}`, icon: <PeopleIcon /> },
		];

		links.push({ title: Constants.PAGE_TITLE.INVOICE_HISTORY, url: '', callback: invoice, icon: <PaymentIcon /> });

		setLinks(orderBy(links, [(link: any) => link.title.toLowerCase()], ['asc']));
	};

	const handlePayClick = (rowIndex: any) => {
		const inv: any = ccPayableInvoicesList[rowIndex];

    let paidList = localStorage['recentlyPaidInvoiceIds'] || '';
    paidList += `${inv.InvoiceNumber},`;
    localStorage['recentlyPaidInvoiceIds'] = paidList;
    setRecentlyPaidInvoicesIdList(paidList.split(','));

    //look up manufacturerName, if available, by checking the info we got from productMetadata
    const productId: string = inv.InvoiceLines[0]?.ProductNumber || '';
    let manufacturerName = (productId && ccPayableMetadata[productId]) ? ccPayableMetadata[productId].manufacturer : '';

    const payloadForToken = {...inv};
    //on the backend, it will contact elevon based on terminal creds specified in env variables
    //in order for it to know which terminal, we need `manufacturerName` in the payload
     if (manufacturerName) {
       payloadForToken.manufacturerName = manufacturerName
     }

     console.log('mfr name:', manufacturerName);

		props.openPaymentPage(payloadForToken);
		setActionDialog(false);
	};

	const getInvoicesList = () => {
		return props.invoicesList;
	};

  const setCCMetadata = (metadata: object) => {
    setCcPayableMetadata(metadata);
  }

  const filterForCCPayable = () => {

    if (!props.invoicesList || !Array.isArray(props.invoicesList)) {
      return;
    }

    const invoiceList: any[] = [...props.invoicesList];
    const payableList: any = [];

    //console.log('****checking for invoices payable with an id of', JSON.stringify(ccEligbleProductIds));
    SendLog(`****checking for invoices payable with an id of  ${JSON.stringify(ccEligbleProductIds)}`, Severities.Log, 'invoicePayableCheck');

    invoiceList.forEach((inv: any) => {
      if (determineIfInvoiceIsCCPayable(inv, ccEligbleProductIds)) {
        payableList.push(inv);
      }
    })
    
    console.log('payableList', payableList);
    if (ccEligbleProductIds.length > 0 && payableList.length === 0) {
      setAlertNoCCPayableInvoices(true)
    }
    setCcPayableInvoicesList(payableList);


    function determineIfInvoiceIsCCPayable(inv: any, eligibleProductIds: any[]) {
      return Boolean(inv?.CCRRelevant); //flag has value "X" or ""

      //DEPRECATED METHOD
      // if (!inv?.InvoiceLines?.length) {
      //   return false;
      // }

      // for (let i=0; i<inv.InvoiceLines.length; i++) {

      //   //if we find one ineligble product id, you cant pay this invoice via CC!
      //   if (eligibleProductIds.indexOf( inv.InvoiceLines[i].ProductNumber ) === -1 ) {
      //     return false
      //   }
      // }

      // return true;
    }
  }

  const columns = [
    {
      name: 'Customer',
      label: 'Customer #',
    },
    {
      name: 'InvoiceNumber',
      label: 'Invoice #',
    },
    {
      name: 'InvoiceDate',
      label: 'Invoice Date',
      options: {
        customBodyRender: (value?: any) => {
          return value ? UserDateFormat(props.properties, value) : '';
        },
      },
    },
    {
      name: 'InvoiceAmount',
      label: 'Amount',
    },
    {
      name: 'PaymentTermsDesc',
      label: 'Payment Terms',
    },
    {
      name: 'DaysInArrears',
      label: 'Days Outstanding',
    },
    {
      name: 'InvoiceNumber',
      label: ' ',
      options: {
        customBodyRender: (value?: any) => {
          return <Link 
            className={classes.link}
            href="#" 
            onClick={() => {
              InvoicesService.getInstance().downloadInvoicePdf(value)
            }}>Download PDF</Link>
        },
      },
    },
  ];

  if (props.isCCPaymentMode) {
    const cbr: any = (value: any, tableMeta: any) => {

      const inv: any = ccPayableInvoicesList[tableMeta.rowIndex];
      let isRecentPaymentAttempt: boolean = false;

      const paidIdsArr: string[] = recentlyPaidInvoicesIdList; //localStorage['recentlyPaidInvoiceIds'];
      let tooltipText = "Click to pay now. This will open a new tab, so you may need to disable your pop-up blocker."

          if (paidIdsArr?.length && paidIdsArr.indexOf(inv.InvoiceNumber) > -1) {
            isRecentPaymentAttempt = true;
            tooltipText = 'You recently attempted to pay this invoice- are you sure you want to try again?'
          }

      return <Tooltip title={tooltipText}>
        <span>
          {isRecentPaymentAttempt && <CancelIcon />}
          <PaymentIcon 
            onClick={() => {
              handlePayClick(tableMeta.rowIndex)}
            }
            style={{
              cursor: 'pointer',
              color: isRecentPaymentAttempt ? 'orange' : undefined
            }}
          />
          </span>
        </Tooltip>;  
    };

    columns.push({
      name: 'isCCPaymentEligible',
      label: 'Pay by Credit Card',
      options: {
        customBodyRender: cbr
      }
    });
  }

	return (
		<>
			<Container maxWidth={false} className={classes.container}>
        {props.isCCPaymentMode && alertNoCCPayableInvoices && <Alert color="error">No credit card payable invoices found.</Alert>}
				<Grid item xs={12} className={classes.searchWrap}>
					<SearchActions 
            onSearch={onSearch} 
            isCCPaymentMode={isCCPaymentMode}
            setCCMetadata={setCCMetadata}
          />
				</Grid>

				{props.invoicesList && props.invoicesList.length > 0 ? (
					<Grid item xs={12}>
						<MUIDataTable
							data={props.isCCPaymentMode ? ccPayableInvoicesList : props.invoicesList}
							columns={columns}
							options={{
								rowsPerPage: props.globalRowsPerPage,
								rowsPerPageOptions: dataList.PageLimitOptions,
								onChangeRowsPerPage: (numberOfRows: number) => {
									props.setGlobalRowsPerPage(numberOfRows);
								},
								selectableRows: 'none',
								filter: false,
								search: false,
							}}
						/>
					</Grid>
				) : (
					<EmptyContent message="Select the filter criteria above to view invoice information." />
				)}
			</Container>
			<LinkableActions onCallBack={handlePayClick} open={isActionDialog} onClose={handleClose} links={links} />
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		invoicesList: state.invoices.invoicesList ? state.invoices.invoicesList.results : [],
		properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	openPaymentPage: (invoice: any) => dispatch(InvoicesActions.openInvoicePaymentPage(invoice)),
	getInvoiceList: (filters: any) => dispatch(InvoicesActions.getInvoiceList(filters)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);
