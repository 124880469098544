import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ReportActions, UserActions } from '../../../redux/actions';
import GlobalFiltersComponent from '../../../components/globalFiltersComponent';
import EmptyContent from '../../../components/emptyContent';
import moment from 'moment';
import dataList from '../../../constants/dataList';
import { GetUserTimezone, UserTimezoneFormat } from '../../../hooks/functions';
import MUIDataTable from 'mui-datatables';
import { ConvertDateTimeForRequest } from '../../../hooks/functions/ConvertDateTimeForRequest';

const CycleCounts = (props: any) => {
	const firstRender = React.useRef(true);
	const [pagination, setPagination] = React.useState({
		page: 0,
		limit: 10,
		offset: 0,
		order: 'createdAt ASC',
	});
	const cycleCountFields = {
		customerId: true,
		resourceId: true,
		eventType: true,
		createdBy: true,
		createdAt: true,
		data: true,
	};

	const fetchCycleCounts = (page: any) => {
		if (!props.selectedDateRange) {
			return;
		}

		let payload: any = {
			offset: page.offset,
			limit: page.limit,
			skip: 0,
			order: page.order,
			where: {},
			fields: cycleCountFields,
		};
		const filterValues = props.selectedFilterValues || {};
		payload.where['customerId'] =
			props.selectedCustomer && props.selectedCustomer.customerId ? props.selectedCustomer.customerId : undefined;
		payload.where['eventType'] = 'Cycle Count';
		payload.where['createdAt'] = props.selectedDateRange
			? {
					between: [
						ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.startDate),
						ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.endDate),
					],
			  }
			: undefined;
		props.getCycleCounts({ filter: payload });
		firstRender.current = false;
	};

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			fetchCycleCounts(pagination);
		}
	}, [pagination]);

	const renderCycleCountDate = (value: string) => {
		if (!value) {
			return '';
		}

		const lastCycleCount = UserTimezoneFormat(props.userProperties, value);
		const redDeadline = UserTimezoneFormat(props.userProperties, moment().startOf('week').subtract(11, 'days'));
		const orangeDeadline = UserTimezoneFormat(props.userProperties, moment().startOf('week').subtract(4, 'days'));
		let color = 'green';
		if (moment(lastCycleCount).isBefore(orangeDeadline)) {
			color = 'orange';
		}
		if (moment(lastCycleCount).isBefore(redDeadline)) {
			color = 'red';
		}
		return <span style={{ fontWeight: 'bold', color: color }}>{lastCycleCount}</span>;
	};

	const tableColumns = [
		{
			label: 'Customer Id',
			name: 'customerId',
			options: {
				filter: true,
				display: true,
				change: false,
			},
		},
		{
			label: 'Device Id',
			name: 'resourceId',
			options: {
				filter: true,
				display: true,
				change: false,
			},
		},
		{
			label: 'User',
			name: 'createdBy',
			options: {
				filter: true,
				display: true,
				change: false,
			},
		},
		{
			label: 'Product Id',
			name: 'data',
			options: {
				filter: true,
				display: true,
				change: false,
				sort: true, 
				customBodyRender: (value: any) => {
					try {
						if (value) {
							const auditData = JSON.parse(value)
							if(auditData.cycleCountProduct) return auditData.cycleCountProduct.productItemId.__new
							else if(auditData.cycleCountProduct__added) return auditData.cycleCountProduct__added.productItemId
							else return ""
						}
						return '';
					} catch (error) {
						console.log(error);
						return '';
					}
				},
			},
		},
		{
			label: 'Lot',
			name: 'data',
			options: {
				filter: true,
				display: true,
				change: false,
				sort: true, 
				customBodyRender: (value: any) => {
					try {
						if (value) {
							const auditData = JSON.parse(value)
							if(auditData.cycleCountProduct) return auditData.cycleCountProduct.lot.__new
							else if(auditData.cycleCountProduct__added) return auditData.cycleCountProduct__added.lot
							else return ""
						}
						return '';
					} catch (error) {
						console.log(error);
						return '';
					}
				},
			},
		},
		{
			label: 'Last Cycle Count',
			name: 'createdAt',
			options: {
				filter: true,
				display: true,
				change: false,
				customBodyRender: renderCycleCountDate,
			},
		},
	];

	return (
		<div>
			<div>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponent
							pageName="CYCLE_COUNT"
							executeSearch={() => {
								fetchCycleCounts({ limit: 10, offset: 0, order: 'createdAt ASC' });
							}}
							handleChangeDatePicker={() => {}}
						/>
					</Grid>
				</Grid>
			</div>
			{props.cycleCounts && props.cycleCounts.total_count > 0 ? (
				<div>
					<MUIDataTable
						data={props.cycleCounts.data}
						columns={tableColumns}
						options={{
							rowsPerPage: props.globalRowsPerPage,
							rowsPerPageOptions: dataList.PageLimitOptions,
							onChangeRowsPerPage: (numberOfRows: number) => {
								setPagination({ ...pagination, limit: numberOfRows });
							},
							onColumnSortChange: (changedColumn: string, direction: string) => {
								let order = `${changedColumn} ${direction}`;
								setPagination({ ...pagination, order: order });
							},
							onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
								if (!props.selectedDateRange) {
									return false;
								}

								const columnsRequested: string[] = [];
								cols.forEach((tc: any) => {
									//tableColumns passed by table comp
									if (tc.display === 'true' || tc.display === true) {
										//sometimes no `options`

										if (tc.label === 'Product Id') {
											columnsRequested.push('productId');
										} else if (tc.label === 'Lot') {
											columnsRequested.push('lot');
										} else {
											columnsRequested.push(tc.name);
										}
									}
								});

								let payload: any = {
									order: 'createdAt ASC',
									where: {},
									fields: cycleCountFields,
									limit: 9999,
								};
								const filterValues = props.selectedFilterValues || {};
								payload.where['customerId'] =
									props.selectedCustomer && props.selectedCustomer.customerId
										? props.selectedCustomer.customerId
										: undefined;
								payload.where['eventType'] = 'Cycle Count';
								payload.where['createdAt'] = props.selectedDateRange
									? {
											between: [
												ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.startDate),
												ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.endDate),
											],
									  }
									: undefined;

								const params = {
									filter_object_from_ui: payload,
									report_type: 'RNI_UIEXPORT_CycleCountsReport',
									report_frequency: 'OneTime',
									delivery_method: 'UserQueue',
									file_type: 'csv',
									user_name: props.authUser?.record.email,
									recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
									customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
									customers_reported_on_name: undefined,
									timezone: GetUserTimezone(props.userProperties),
									additional_filters: undefined,
									custom_start_date: undefined,
									custom_end_date: undefined,
									origin_application: 'RNI',
									report_additional_metadata: {
										columnsRequested: columnsRequested,
									},
								};

								props.sendOneTimeReportRequest(params);
								return false;
							},
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: false,
							download: true,
							print: true,
							selectableRows: 'none',
							serverSide: true,
							page: pagination.page,
							count: props.cycleCounts.total_count,
							textLabels: {
								body: {
									noMatch: props.cycleCounts.total_count === 0 ? 'Sorry, no matching records found' : '',
								},
							},
							onTableChange: (tableAction: any, tableState: any) => {
								switch (tableAction) {
									case 'changePage':
										if (tableState.page > pagination.page) {
											setPagination({
												offset: pagination.offset + pagination.limit,
												limit: pagination.limit,
												page: tableState.page,
												order: pagination.order,
											});
										} else if (tableState.page < pagination.page) {
											setPagination({
												offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit,
												limit: pagination.limit,
												page: tableState.page,
												order: pagination.order,
											});
										}
										break;
								}
							},
						}}
					/>
				</div>
			) : (
				<EmptyContent message="Select the filter criteria above to view device information." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cycleCounts: state.reports.cycleCounts,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	authUser: state.user.authUser,
	userProperties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getCycleCounts: (payload: any) => dispatch(ReportActions.getCycleCounts(payload)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleCounts);
