import moment from 'moment';
import dataList from '../../constants/dataList';
import { UserProperty } from '../../redux/models/user/user';

export const UserDateFormat = (properties: UserProperty[], dateTime: any, utc: boolean = true) => {
	const defaultDateLabel = dataList.DateFormats[0].label;

	const dateFormat_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'DATEFORMAT',
	);
	const dateFormatLabel =
		dateFormat_key_index > -1 && properties[dateFormat_key_index].propertyValue != 'LOCAL'
			? properties[dateFormat_key_index].propertyValue
			: defaultDateLabel;
	const dateFormat = dataList.DateFormats.find(format => {
		return format.label === dateFormatLabel;
	})?.value;

	return utc ? moment(dateTime).utc().format(dateFormat) : moment(dateTime).local().format(dateFormat);
};
