import { UserActions } from '../../redux/actions';

export const mapStateToProps = (state: any) => ({
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	user_properties:
		state.user && state.user.user_properties && state.user.user_properties instanceof Array
			? state.user.user_properties
			: [],
	user: state.user && state.user.authUser && state.user.authUser.record ? state.user.authUser.record : [],
});

export const mapDispatchToProps = (dispatch: any) => ({
	userPropertiesCreate: (data: any) => dispatch(UserActions.userPropertiesCreateStartAction(data)),
	setUserTheme: (theme: string) => dispatch(UserActions.setUserTheme(theme)),
});
