import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { UIAction, UserActions } from '../../redux/actions';
import _ from 'lodash';
import config from '../../config';
import Loading from '../../components/loading';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { IMAGES_URL } from '../../constants/Images';

const ProcessAuth: React.FC = (props: any) => {
	const [isProcessing, setIsProcessing] = React.useState(true);
	const [authError, setAuthError] = React.useState<any>(false);

	const doRniAuth = () => {
		const queryParams = new URLSearchParams(window.location.search);
		const authTokenInUrl = queryParams.get('authToken')
		// check if we have an auth token in storage
		if (!authTokenInUrl) {
			const idToken = localStorage.getItem('id_token');
			const authTokenInSession = localStorage.getItem('rniAuthToken');
			if (idToken && authTokenInSession) {
				props.handleRniAuthPageRefresh();
				return true;
			}
			return false;
		}
		window.history.pushState({}, document.title, window.location.pathname)
		localStorage.setItem('rniAuthToken', authTokenInUrl as string);
		props.handleRniAuthToken({
      token: authTokenInUrl
    });
		return true;
	}

	const redirectToSignOn = () => {
		const authUrl = config.authUrl;
		const callbackUrl = config.authCallbackUrl || window.location.origin;
		localStorage.clear();
		sessionStorage.clear();
		window.location.href=`${authUrl}?callbackUrl=${callbackUrl}`;
	};

	const bypassAuth = () => {
		if (window.location.pathname.includes('logout')) {
			return true;
		}
		if (window.location.pathname.includes('session-timeout')) {
			return true;
		}
		if (window.location.pathname.includes('auth-error')) {
			return true;
		}
		if (window.location.pathname.includes('user-setup')) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		if (isProcessing) {
			props.dispatch(UIAction.setAuthProcessing(true));
		}

		if (bypassAuth()) {
			props.dispatch(UIAction.setAuthProcessing(false));
			return;
		}

		if (props.authUser) {
			setIsProcessing(false);
			props.dispatch(UIAction.setAuthProcessing(false));
			return;
		}

		if (!doRniAuth()) {
			redirectToSignOn();
		}
	});

	const authFailureContent = authError ? (
		<div style={{ width: '50%', margin: 'auto' }}>
			<Alert
				severity="error"
				action={
					<Button color="inherit" size="small" variant="text" onClick={redirectToSignOn}>
						Log In
					</Button>
				}
			>
				<AlertTitle>Authentication Failure</AlertTitle>
				We were unable to authenticate you at this time. Please try logging in again.
			</Alert>
		</div>
	) : (
		''
	);

	return (
		<div>
			{!bypassAuth() && isProcessing ? (
				<>
					<img src={IMAGES_URL.RNI_LOGO} className="loading-logo" alt="right now inventory" />
					<Loading message="Processing..." />
				</>
			) : (
				// <div>{authFailureContent}</div> // hiding this alert for now until we can reproduce the error that causes this to trigger
				<div></div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
	handleRniAuthToken: (payload: any) => dispatch(UserActions.handleRniAuthToken(payload)),
	handleRniAuthPageRefresh: (payload: any) => dispatch(UserActions.handleRniAuthPageRefresh(payload)),
	dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessAuth);
