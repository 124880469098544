import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { groupBy } from 'lodash';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment as any);

const AlertChartComponent: React.FC<any> = ({ data }) => {
	const [categories, setCategories] = React.useState<String[]>([]);
	const [series, setSeries] = React.useState<any[]>([]);

	React.useEffect(() => {
		const groupedData = groupBy(data, (b: any) => moment(b['alertTimestamp']).format('MM/DD/yyyy'));
		setCategories(Object.keys(groupedData));
		const levelGrouped = groupBy(data, (b: any) => b['alertLevel']);

		console.log('groupedData', JSON.stringify(groupedData));
		console.log('levelGrouped', JSON.stringify(levelGrouped));

		let chartData: any[] = [];
		Object.keys(levelGrouped).forEach(value => {
			console.log('key', value);
			let alertCount: any[] = [];
			Object.keys(groupedData).forEach(dateValue =>
				alertCount.push(
					levelGrouped[value].filter((b: any) => moment(b['alertTimestamp']).format('MM/DD/yyyy') === dateValue).length,
				),
			);

			chartData.push({
				name: value,
				data: alertCount,
			});
		});

		setSeries(chartData);
	}, [data]);

	console.log('series', series);

	const options = {
		chart: {
			type: 'column',
		},
		title: {
			text: 'Devices Alert',
			fill: '#fff',
		},
		xAxis: {
			categories: categories,
			crosshair: true,
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Alert Level',
			},
		},
		tooltip: {
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat:
				'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
			footerFormat: '</table>',
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		series: series,
	};

	return (
		<>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</>
	);
};

export default AlertChartComponent;
