import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { Paper, makeStyles } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { addDays, startOfMonth, addMonths, endOfMonth, startOfYear, addYears, endOfYear } from 'date-fns';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';
import { GetUserPickerTimeFormat } from '../../hooks/functions';
import { format } from 'date-fns';
const moment = extendMoment(Moment as any);

const styles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		zIndex: 9999,
	},
	dateQuickSelect: {
		paddingTop: 2,
		paddingBottom: 2,
	},
}));

interface DesktopDateRangePopupProps {
	setOpen: (value: boolean) => void;
	onSelect: (dates: any) => void;
	dates: any;
	excludedRanges?: any;
	properties: any;
}

const DesktopDateRangePopup: React.FC<DesktopDateRangePopupProps> = (props: any) => {
	const classes = styles();
	const todayDate = moment();
	const getDefinedRanges = () => {
		const ranges = [
			{
				label: 'Last 7 days',
				range: () => ({
					startDate: addDays(new Date(), -7),
					endDate: new Date(),
					label: 'Last 7 days',
				}),
				isSelected() {
					return true;
				},
			},

			{
				label: 'Last 30 days',
				range: () => ({
					startDate: addDays(new Date(), -30),
					endDate: new Date(),
					label: 'Last 30 days',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Last 60 days',
				range: () => ({
					startDate: addDays(new Date(), -60),
					endDate: new Date(),
					label: 'Last 60 days',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Last 90 days',
				range: () => ({
					startDate: addDays(new Date(), -90),
					endDate: new Date(),
					label: 'Last 90 days',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Last 180 days',
				range: () => ({
					startDate: addDays(new Date(), -180),
					endDate: new Date(),
					label: 'Last 180 days',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Current Month',
				range: () => ({
					startDate: startOfMonth(new Date()),
					endDate: new Date(),
					label: 'Current Month',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Previous Month',
				range: () => ({
					startDate: startOfMonth(addMonths(new Date(), -1)),
					endDate: endOfMonth(addMonths(new Date(), -1)),
					label: 'Previous Month',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Current Year',
				range: () => ({
					startDate: startOfYear(new Date()),
					endDate: new Date(),
					label: 'Current Year',
				}),
				isSelected() {
					return true;
				},
			},
			{
				label: 'Last Year',
				range: () => ({
					startDate: startOfYear(addYears(new Date(), -1)),
					endDate: endOfYear(addYears(new Date(), -1)),
					label: 'Last Year',
				}),
				isSelected() {
					return true;
				},
			},
		];
		const excludedRanges = props.excludedRanges ? props.excludedRanges : [];
		return ranges.filter(range => !excludedRanges.includes(range.label));
	};

	const [state, setState] = React.useState<any>([
		{
			startDate: props.dates?.startDate || new Date(),
			endDate: props.dates?.endDate || new Date(),
			key: 'selection',
		},
	]);

	const handleRangeChange = (ranges: any) => {
		if (
			format(ranges.selection.startDate, 'dddd, mmmm dS, yyyy') ==
			format(ranges.selection.endDate, 'dddd, mmmm dS, yyyy')
		) {
			ranges.selection.label = undefined;
		}
		setState([ranges.selection]);
		const dates = {
			startDate: addDays(ranges.selection.startDate.setHours(0, 0, 0, 0), 0),
			endDate: addDays(ranges.selection.endDate.setHours(23, 59, 59, 0), 0),
			label: ranges.selection.label,
		};
		props.onSelect(dates);
	};

	return (
		<div>
			<ClickAwayListener
				onClickAway={(event: any) => {
					if (event.target.id !== 'date_range') {
						props.setOpen(false);
					}
				}}
			>
				<Paper className={`${classes.root} date-range-picker`} style={{ width: 'auto', maxWidth: '100%' }}>
					<div style={{ float: 'left' }}>
						<DateRangePicker
							onChange={handleRangeChange}
							months={1}
							maxDate={new Date()}
							direction="vertical"
							scroll={{ enabled: true }}
							ranges={state}
							editableDateInputs={true}
							shownDate={props.dates?.startDate || new Date()}
							dateDisplayFormat={GetUserPickerTimeFormat(props.properties)}
							startDatePlaceholder="Start: YYYY-MM-DD"
							endDatePlaceholder="End: YYYY-MM-DD"
							staticRanges={getDefinedRanges()}
							inputRanges={[]}
						/>
					</div>
				</Paper>
			</ClickAwayListener>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

export default connect(mapStateToProps)(DesktopDateRangePopup);
