import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { ParUsageContainer } from './par-usage.container';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';

const ParUsage = () => {
	const dispatch = useDispatch();
	const classes = DashboardStyles();

	const isFirstRender = useRef(true);
	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	return (
		<BasePage title={Constants.PAGE_TITLE.PAR_UGAGES}>
			<div className={classes.appBarSpacer}></div>
			<Container maxWidth={false} className={classes.container}>
				<ParUsageContainer />
			</Container>
		</BasePage>
	);
};

export default ParUsage;
