import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { snakeCase, camelCase } from 'lodash';
import { CabinetActions } from '../../../redux/actions';
import { globalColors } from '../../../hooks/styles';
import { formatCabinetPropertyKey, formatCabinetPropertyValue } from '../../../hooks/functions/CabinetPropertyUtils';

const CabinetPropertiesComponent = (props: any) => {
	const [properties, setProperties] = React.useState(null as any);
	const [selectedProperties, setSelectedProperties] = React.useState(null as any);
	const [initValueIndex, setInitValueIndex] = React.useState(null as any);
	const [value, setValue] = React.useState([] as any[]);

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		stringify: (option: any) => option.formattedPropertyKey,
	});

	React.useEffect(() => {
		props.getPropertyList();
	}, []);

	React.useEffect(() => {
		let optionList = null;
		if (Array.isArray(props.propertyList) && props.propertyList.length > 0) {
			optionList = props.propertyList.sort(
				(a: any, b: any) => -b.propertyKey.localeCompare(a.propertyKey) || a.propertyValue - b.propertyValue,
			);
		}

		setProperties(optionList);

		if (!initValueIndex && props.selectedFilterValues && props.selectedFilterValues['CABINET_PROPERTIES']) {
			const selectedPropertyFilterKey = camelCase(Object.keys(props.selectedFilterValues['CABINET_PROPERTIES'])[0]);
			const optionIndex = optionList.findIndex((property: any) => {
				return (
					property.propertyKey === selectedPropertyFilterKey &&
					property.propertyValue ===
						props.selectedFilterValues['CABINET_PROPERTIES'][snakeCase(selectedPropertyFilterKey)]
				);
			});
			setInitValueIndex(optionIndex);
			setValue([optionList[optionIndex]]);
		}
	}, [props.propertyList]);

	React.useEffect(() => {
		if (selectedProperties && props.selectedFilterValues && !props.selectedFilterValues['CABINET_PROPERTIES']) {
			setValue([]);
			setSelectedProperties(null);
		}
	}, [props.selectedFilterValues]);

	const handleOnChange = (event: any, value: any, reason: any, details: any) => {
		setValue(value);
		if (!value || value.length === 0) {
			setSelectedProperties(null);
			props.onChange(null);
			return;
		}
		const output = value.reduce(
			(obj: any, item: any) => ((obj[snakeCase(item.propertyKey)] = item.propertyValue), obj),
			{},
		);
		setSelectedProperties(output);
		props.onChange(output);
	};

	return (
		<>
			{properties && (
				<Autocomplete
					multiple
					id="grouped-demo"
					options={properties}
					filterOptions={filterOptions}
					getOptionDisabled={options => {
						return selectedProperties ? true : false;
					}}
					groupBy={option => option.formattedPropertyKey}
					getOptionLabel={option => option.formattedPropertyValue.toString()}
					style={{ width: 375 }}
					defaultValue={initValueIndex ? [properties[initValueIndex]] : undefined}
					value={value}
					onChange={handleOnChange}
					renderInput={params => <TextField {...params} label="Properties" />}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								variant="outlined"
								label={`${option.formattedPropertyKey}: ${option.formattedPropertyValue}`}
								size="small"
								{...getTagProps({ index })}
							/>
						))
					}
				/>
			)}
			{!properties && (
				<Autocomplete
					multiple
					id="grouped-demo"
					options={[]}
					loading={true}
					style={{ width: 375 }}
					value={initValueIndex ? [initValueIndex] : undefined}
					onChange={handleOnChange}
					renderInput={params => <TextField {...params} label="Properties" />}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	propertyList: state.cabinet.propertyList,
	propertyListError: state.cabinet.propertyListError,
	selectedFilterValues: state.ui.selectedFilterValues,
});

const mapDispatchToProps = (dispatch: any) => ({
	getPropertyList: () => dispatch(CabinetActions.getPropertyList()),
});

export const CabinetProperties = connect(mapStateToProps, mapDispatchToProps)(CabinetPropertiesComponent);
