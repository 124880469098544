import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { CabinetActions } from '../../../redux/actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Constants } from '../../../constants/Constants';
import { setApiError } from '../../../redux/actions/ui.actions';

interface cabinetProps {
	cabinets?: any;
	onChangeCabinet: (practice: any) => void;
	customerId?: any;
	componentKey?: any;
	style?: any;
	selectedCabinetId?: any;
	label?: string;
	error?: boolean;
	helperText?: string | null;
	required?: boolean;
	context?: string | null;
	contextCabinets?: any;
	cabinetType?: string;
	authUser?: any;
}

const Cabinet = (props: cabinetProps) => {
	const dispatch = useDispatch();
	const [error, setError] = React.useState<boolean>(false);

	React.useEffect(() => {
		setError(props.error || false);
	}, [props.error]);

	const searchCabinetOnTyping = (keyword?: any) => {
		if (keyword) {
			dispatch(
				CabinetActions.cabinetRequestStartAction({
					//offset: 0,
					limit: 100,
					//order: ["cabinet_id"],
					cabinetId: keyword ? `%${keyword}%` : '',
					cabinetState: !props.authUser.record.claims['ALL'] ? '30' : '',
					customerId: props.customerId ? props.customerId : '',
				}),
			);
		}
	};

	useEffect(() => {
		if (props.customerId && props.context) {
			dispatch(CabinetActions.getContextCabinets({ customerId: props.customerId, context: props.context }));
			return;
		}
		dispatch(
			CabinetActions.cabinetRequestStartAction({
				//offset: 0,
				limit: 100,
				//order: ["cabinetId"],
				cabinetState: !props.authUser.record.claims['ALL'] ? '30' : '',
				customerId: props.customerId ? props.customerId : '',
			}),
		);

		if (!props.customerId) {
			props.onChangeCabinet(null);
		}
	}, [props.customerId, dispatch]);

	const onBlur = (value: any) => {
		if (props.required && !value) {
			setError(true);
			return;
		}
		setError(false);
	};

	const changeCabinet = (event?: any, values?: any) => {
		props.onChangeCabinet(values);
		if (!values) {
			dispatch(
				CabinetActions.cabinetRequestStartAction({
					//offset: 0,
					limit: 100,
					//order: ["cabinet_id"],
					cabinetState: !props.authUser.record.claims['ALL'] ? '30' : '',
					customerId: props.customerId ? props.customerId : '',
				}),
			);
		}
	};

	const getSelectedCabinet = (cabinetId: any) => {
		const item: any = props.cabinets.find((option: any) => {
			if (option.cabinetId === cabinetId) {
				return option;
			}
		});
		return item || {};
	};

	const getCabinetOptions = () => {
		if (props.customerId && props.context) {
			return props.contextCabinets;
		}

		if (props.cabinetType) {
			return typeof props.cabinets != 'undefined'
				? props.cabinets.filter((cabinet: any) =>
						cabinet.cabinetId && cabinet.cabinetId !== 'NaN' && cabinet.cabinetType === props.cabinetType
							? true
							: false,
				  )
				: [];
		}

		return typeof props.cabinets != 'undefined'
			? props.cabinets.filter((cabinet: any) => (cabinet.cabinetId && cabinet.cabinetId !== 'NaN' ? true : false))
			: [];
	};

	return (
		<Autocomplete
			style={props.style}
			key={props.componentKey}
			onChange={changeCabinet}
			id="combo-box-cabinet"
			options={getCabinetOptions()}
			getOptionLabel={(option: any) => `${option.cabinetName || ''} (${option.cabinetId || 'No Device Id'})`}
			value={props.selectedCabinetId ? getSelectedCabinet(props.selectedCabinetId) : null}
			renderInput={(params: any) => (
				<TextField
					onChange={({ target }) => searchCabinetOnTyping(target.value)}
					{...params}
					label={props.label || Constants.SEARCH.CABINET}
					onBlur={({ target }) => onBlur(target.value)}
					error={error}
					helperText={props.helperText || ''}
					required={props.required ? true : false}
				/>
			)}
		/>
	);
};
const mapStateToProps = (state: any) => {
	return {
		cabinets: state.cabinet.cabinetCollection ? state.cabinet.cabinetCollection : [],
		contextCabinets: state.cabinet.contextCabinets ? state.cabinet.contextCabinets : [],
		authUser: state.user.authUser,
	};
};

export default connect(mapStateToProps)(Cabinet);
