import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Chip, Modal, Button, FormControl } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import { PracticePageStyles } from '../../../hooks/styles';
import { UsersStyles } from '../../users/users.styles';
import { initUserType, generateDataList, onDownloadUsers } from '../../users/users.helper';
import dataList from '../../../constants/dataList';
import { tablePagination } from '../../../hooks/functions/TablePagination';
import { UserManagementActions } from '../../../redux/actions';
import { getMuiTheme } from '../../../hooks/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { Claims } from '@fffenterprises/authorization/lib/userAuthorization';
import { UserRecord } from '../../../redux/models/user/user';
import { UserDetails } from '../../users/user-details';
import AuthUser from '../../../redux/lib/authUser';
import UserForm from '../../users/user-form.component';

interface AuthorizedUsersProps {
	usersList?: any;
	totalUsers?: number;
	authUser?: AuthUser;
}

const AuthorizedUsers = (props: AuthorizedUsersProps) => {
	const classes = PracticePageStyles();
	const userClasses = UsersStyles();
	const dispatch = useDispatch();
	const muiTheme = getMuiTheme();
	const { customerId } = useParams<any>();
	const isFirstRender = useRef(true);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
	const [userDetails, setUserDetails] = React.useState({} as UserRecord);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPagination] = React.useState({ offset: 0, limit: 10, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const fetchResult = () => {
		isFirstRender.current = false;
		let payload: any = [];
		payload.push({
			key: 'tenantId',
			value: customerId,
		});

		if (sortOrder) {
			payload.push({
				key: 'order',
				value: sortOrder,
			});
		}

		payload.push({
			key: 'limit',
			value: pagination.limit,
		});
		payload.push({
			key: 'offset',
			value: pagination.offset,
		});

		dispatch(UserManagementActions.getUserListStartAction(payload));
	};

	React.useEffect(() => {
		if (props.authUser) {
			fetchResult();
		}
	}, [pagination, sortOrder]);

	useEffect(() => {
		if (isFirstRender.current) {
			fetchResult();
		}
	});

	const renderUserRoles = (value: Claims | null, tableMeta: any, updateValue: any) => {
		if (!value || Object.keys(value).length === 0) {
			return '';
		}
		return (
			<div>
				{Object.keys(value[customerId] ?? {}).map((resource, index) => {
					return <Chip style={{ margin: '3px' }} key={index} label={`${resource}: ${value[customerId][resource]}`} />;
				})}
			</div>
		);
	};

	const handleModalOpen = (rowData: string[], rowMeta: { rowIndex: number; dataIndex: number }) => {
		setUserDetails(props.usersList[rowMeta.dataIndex]);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setUserDetails({} as UserRecord);
		setModalOpen(false);
	};

	const openInviteModal = () => {
		if (
			props.authUser?.authorizationCheck({
				action: 'create',
				resource: 'users',
				tenantId: customerId,
			})
		) {
			setInviteModalOpen(true);
		}
	};

	const closeInviteModal = () => {
		setInviteModalOpen(false);
	};

	const canInviteUsers = () => {
		if (!props.authUser) {
			return false;
		}
		return props.authUser.authorizationCheck({
			action: 'create',
			resource: 'users',
			tenantId: customerId,
		});
	};

	const renderTenants = (value: any | null, tableMeta: any, updateValue: any) => {
		if (!value || Object.keys(value).length === 0) {
			return '';
		}

		let userType = initUserType(value);
		userType = userType === 'admin' ? 'customer' : userType;

		if (value.accessGroups) {
			return (
				<div>
					{value.accessGroups[userType].map((tenant: string, index: number) => {
						return (
							<Chip
								className={userClasses.chip}
								key={index}
								label={`${userType === 'customer' ? '' : userType + ':'}${tenant}`}
							/>
						);
					})}
				</div>
			);
		}

		return (
			<div>
				{Object.keys(value).map((tenant, index) => {
					return <Chip className={userClasses.chip} key={index} label={`${tenant}`} />;
				})}
			</div>
		);
	};

	const tableCols = [
		{
			label: 'Email',
			name: 'email',
			options: {
				display: tableStatePersist ? tableStatePersist.columns[0].display : true,
				sort: true,
				sortOrder: null,
				change: false,
				filter: true,
			},
		},
		{
			label: 'First Name',
			name: 'firstName',
			options: {
				display: tableStatePersist ? tableStatePersist.columns[1].display : true,
				sort: true,
				sortOrder: null,
				filter: true,
				change: false,
			},
		},
		{
			label: 'Last Name',
			name: 'lastName',
			options: {
				display: tableStatePersist ? tableStatePersist.columns[2].display : true,
				sort: true,
				sortOrder: null,
				change: false,
				filter: true,
			},
		},
		{
			label: 'Customers',
			name: 'claims',
			options: {
				display: tableStatePersist ? tableStatePersist.columns[3].display : true,
				customBodyRender: renderTenants,
				download: false,
				change: true,
			},
		},
		{
			label: 'Tenants',
			name: 'tenantsString',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
	];

	return (
		<div>
			<Grid container className={classes.detailPageContainer}>
				<Grid item xs={12}>
					{props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: customerId }) && (
						<FormControl className={classes.authorizedUsersFormControl}>
							<Button
								className={userClasses.viewAllBtn}
								style={{ marginLeft: 0 }}
								variant="contained"
								onClick={openInviteModal}
							>
								Invite User
							</Button>
						</FormControl>
					)}
				</Grid>
				<Grid item xs={12}>
					<MUIDataTable
						data={props.usersList ? generateDataList(props.usersList) : []}
						columns={tableCols}
						options={{
							page: pagination.page,
							rowsPerPage: pagination.limit,
							count: props.totalUsers,
							rowsPerPageOptions: dataList.PageLimitOptions,
							filterType: 'multiselect',
							selectableRows: 'none',
							filter: false,
							search: false,
							serverSide: true,
							onTableChange: (tableAction: any, tableState: any) => {
								console.log(tableAction);
								console.log(tableState);

								if (tableAction == 'changePage' || tableAction == 'changeRowsPerPage') {
									setPagination(tablePagination(tableAction, tableState, pagination));
								} else if (tableAction == 'sort') {
									setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
								} else if (tableAction == 'viewColumnsChange') {
									setTableStatePersist(tableState);
								}
							},
							onDownload: onDownloadUsers,
						}}
					/>
				</Grid>
			</Grid>
			<Modal
				open={modalOpen}
				onClose={handleModalClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<UserDetails thisUser={userDetails} close={handleModalClose} />
				</div>
			</Modal>
			<Modal
				open={inviteModalOpen}
				onClose={closeInviteModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<UserForm
						authUser={props.authUser}
						pagination={pagination}
						sortOrder={sortOrder}
						thisUser={{ claims: { accessGroups: { customer: [customerId] }, roles: {} } } as UserRecord}
						close={closeInviteModal}
					/>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		usersList: state.userManagement.userList?.result,
		totalUsers: state.userManagement.userList?.total_record,
		authUser: state.user.authUser,
	};
};

export default connect(mapStateToProps)(AuthorizedUsers);
