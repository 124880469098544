import { call, put } from 'redux-saga/effects';
import { Constants } from '../../constants/Constants';
import { AuditActions, UIAction, UserActions } from '../actions/index';
import { AuditService } from '../services';

export function* getAuditLogList(action: ReturnType<typeof AuditActions.getAuditLogList>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(AuditService.getInstance().getAuditList, action.payload);
		yield put(UIAction.showLoader(false));
		yield put(AuditActions.setAuditLogList(data));
		if (!data.data || data.data.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(UIAction.showLoader(false));
	}
}
