import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ReportFilter } from '../../../redux/models/reports/Inventory';
import { UIAction } from '../../../redux/actions';
import moment from 'moment';
import GlobalFiltersComponentContainer from '../../../components/globalFiltersComponent';

interface SearchProps {
	onFilterUpdate: (filter: any[]) => void;
	authUser: any;
	tenants: any;
	selectedCustomer: any;
	setSelectedCustomer: (customer: any) => void;
	reportFilters: ReportFilter;
	tabView: string;
	selectedHistoryDate: any;
	setSelectedDateRange: any;
	globalRowsPerPage: any;
	selectedCabinetType: any;
}

const SearchSection = (props: SearchProps) => {
	const todayDate = moment();
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(todayDate.clone().subtract(1, 'days').toDate());
	const [errors, setErrors] = React.useState<any>({});
	const firstRender = React.useRef(true);

	const handleDateChange = (date: Date | null) => {
		setErrors({ ...errors, selectedDate: false });
		setSelectedDate(date);
	};

	const changePractice = (values?: any) => {
		firstRender.current = false;

		if (values !== 'null' && values !== null) {
			props.setSelectedCustomer(values);
			window.history.pushState({}, '', `/inventory-history/${values.customerId}`);
		} else {
			props.setSelectedCustomer(null);
			window.history.pushState({}, '', `/inventory-history`);
		}
	};

	const searchIsValid = () => {
		const searchErrors: any = {};
		if (!selectedDate) {
			searchErrors.selectedDate = true;
		}
		setErrors(searchErrors);
		if (Object.keys(searchErrors).length > 0) {
			return false;
		}
		return true;
	};

	const handleSearchSubmit = () => {
		if (!props.selectedHistoryDate) {
			return;
		}
		let filter = [];
		if (!searchIsValid()) {
			return;
		}
		if (props.selectedCustomer) {
			filter.push({ key: 'customerId', value: props.selectedCustomer.customerId });
		}

		if (props.selectedHistoryDate) {
			filter.push({ key: 'startDate', value: moment(props.selectedHistoryDate).format('YYYY-MM-DD') });
			filter.push({ key: 'endDate', value: moment(props.selectedHistoryDate).format('YYYY-MM-DD') });
		}

		if (props.selectedCabinetType) {
			filter.push({
				key: 'cabinetType',
				value: Array.isArray(props.selectedCabinetType)
					? props.selectedCabinetType.join(',')
					: props.selectedCabinetType,
			});
		}

		filter.push({ key: 'offset', value: 0 });
		filter.push({ key: 'limit', value: props.globalRowsPerPage });
		filter.push({ key: 'order', value: '' });

		props.onFilterUpdate(filter);
	};

	return (
		<div>
			<Grid container>
				<Grid item xs={12}>
					<GlobalFiltersComponentContainer
						pageName="INVENTORY_HISTORY"
						executeSearch={handleSearchSubmit}
						handleTenantSelect={changePractice}
						handleChangeHistoryDate={handleDateChange}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setSelectedDateRange: (historyDate: any) => dispatch(UIAction.setSelectedDateRange(historyDate)),
});

const mapStateToProps = (state: any) => {
	return {
		reportFilters: state.reports.reportFilters ? state.reports.reportFilters : new ReportFilter(),
		selectedCustomer: state.ui.selectedCustomer,
		selectedCabinetType: state.ui.selectedCabinetType,
		authUser: state.user.authUser,
		selectedHistoryDate: state.ui.selectedHistoryDate,
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
		tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
