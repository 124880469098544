import * as React from 'react';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { CommonStyles } from '../../hooks/styles';
import EmptyContent from '../../components/emptyContent';
import { connect } from 'react-redux';
import { OrderActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';
import { useMediaQuery } from 'react-responsive';
import dataList from '../../constants/dataList';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UserTimezoneFormat, GetUserTimezone } from '../../hooks/functions';
import { UserDateFormat } from '../../hooks/functions/UserDateFormat';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';

const renderTrackingLink = (value: any) => {
	if (!value) {
		return;
	}
	return (
		<a target="_BLANK" href={`https://www.fedex.com/fedextrack/?trknbr=${value}`}>
			Track Shipment
		</a>
	);
};

const ShipmentsComponent: React.FC<any> = (props: any) => {
	const styles = CommonStyles();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const firstRender = React.useRef(true);
	const [order, setOrder] = React.useState<string>('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPagination] = React.useState({
		limit: props.globalRowsPerPage,
		page: 0,
		offset: 0,
	});

	const columnData = [
		{
			label: 'Shipment Id',
			name: 'shipmentId',
			display: true,
			change: true,
		},
		{
			label: 'Shipment Date',
			name: 'shipDate',
			display: true,
			change: true,
			customBodyRender: (value: any) => {
				return UserDateFormat(props.properties, value);
			},
		},
		{
			Label: 'Ship Time',
			name: 'shipTime',
			display: false,
			change: false,
			viewColumns: false,
		},
		{
			label: 'Customer Id',
			name: 'customerNumber',
			display: true,
			change: true,
		},
		{
			label: 'Order Number',
			name: 'orderNumber',
			display: true,
			change: true,
		},
		{
			label: 'NDC',
			name: 'itemIdentifier',
			display: true,
			change: true,
		},
		{
			label: 'Product Id',
			name: 'vendorItem',
			odisplay: true,
			change: true,
		},
		{
			label: 'Product Description',
			name: 'factorDescription',
			display: true,
			change: true,
		},
		{
			label: 'Tracking',
			name: 'trackingNumber',
			display: true,
			change: true,
			customBodyRender: renderTrackingLink,
		},
		{
			label: 'Estimated Delivery',
			name: 'estimatedDelivery',
			display: true,
			change: true,
			customBodyRender: (value: any) => {
				return UserDateFormat(props.properties, value);
			},
		},
		{
			label: 'Warehouse',
			name: 'shipFromWarehouse',
			display: true,
			change: true,
		},
		{
			label: 'Transmit Status',
			name: 'transmitStatus',
			display: true,
			change: true,
		},
		{
			label: 'Minibar Item',
			name: 'minibarRxItem',
			display: false,
			change: true,
		},
		{
			label: 'Lot',
			name: 'lotNumber',
			display: false,
			change: true,
		},
		{
			label: 'Expiration',
			name: 'expDate',
			display: false,
			change: true,
			customBodyRender: (value: any) => {
				return value ? UserDateFormat(props.properties, value) : '';
			},
		},
		{
			label: 'Quantity Shipped',
			name: 'numPkgsShipped',
			display: false,
			change: true,
		},
		{
			label: 'Unit',
			name: 'baseUom',
			display: false,
			change: true,
		},
		{
			label: 'Units per Package',
			name: 'numUnitsPerPkg',
			display: false,
			change: true,
		},
		{
			label: 'Total Units',
			name: 'totalNumUnits',
			display: false,
			change: true,
		},
		{
			label: 'Transmit Status Desc',
			name: 'transmitStatusDesc',
			display: false,
			change: true,
		},
		{
			label: 'Transmit Date',
			name: 'transmitDate',
			display: false,
			change: true,
			customBodyRender: (value: any) => {
				return UserTimezoneFormat(props.properties, value, true);
			},
		},
	];

	const initTableColumns = columnData.map((column, index) => {
		return {
			label: column.label,
			name: column.name,
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
				customBodyRender: column.customBodyRender ? column.customBodyRender : undefined,
				change: column.change,
				viewColumns: column.viewColumns ?? undefined,
			},
		};
	});

	const getFilter = () => {
		const filter = [];
		if (props.selectedCustomer) {
			filter.push({ key: 'customerNumber', value: props.selectedCustomer.customerId });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.WAREHOUSE) {
			filter.push({ key: 'shipFromWarehouse', value: props.selectedFilterValues.WAREHOUSE });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.SHIPMENT_STATUS) {
			filter.push({ key: 'transmitStatus', value: props.selectedFilterValues.SHIPMENT_STATUS });
		}

		filter.push({ key: 'limit', value: props.globalRowsPerPage });
		filter.push({ key: 'offset', value: pagination.offset });

		if (order) {
			filter.push({ key: 'order', value: order });
		}

		return filter;
	};

	const onSearchClick = () => {
		props.getShipments(getFilter());
	};

	const handleGetCsv = (cols: any) => {
		const filter: any = getFilter();

		//convert filter from weird array format to more normal
		const reformattedFilter: any = {};

		filter.forEach((fi: any) => {
			reformattedFilter[fi.key] = fi.value;
		});

		reformattedFilter.limit = 9999;

		const params = constructEnqueueParams(
			'RNI_UIEXPORT_ShipmentsReport',
			props.authUser?.record.email,
			reformattedFilter,
			{ columnsRequested: findUserSelectedColumns(cols) },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			onSearchClick();
		} else {
			firstRender.current = false;
		}
	}, [pagination, order]);

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 72; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
			<div className={styles.pageWrap}>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer pageName="SHIPMENTS" executeSearch={onSearchClick} />
					</Grid>
				</Grid>
			</div>
			{props.shipments && props.shipments.result && props.shipments.result.length > 0 ? (
				<div>
					<MUIDataTable
						data={props.shipments?.result || []}
						columns={initTableColumns}
						options={{
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: false,
							download: true,
							print: true,
							selectableRows: 'none',
							serverSide: true,
							count: props.shipments && props.shipments.count ? props.shipments.count.count : 0,
							page: pagination.page,
							rowsPerPage: props.globalRowsPerPage,
							rowsPerPageOptions: dataList.PageLimitOptions,
							fixedHeader: true, // This locks the table headers at the top
							tableBodyHeight: tableHeight + 'px', // Set the height for the table body
							onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
								handleGetCsv(columns);
								return false;
							},
							onChangeRowsPerPage: (numberOfRows: number) => {
								setPagination({ ...pagination, limit: numberOfRows });
								props.setGlobalRowsPerPage(numberOfRows);
							},
							onTableChange: (tableAction: any, tableState: any) => {
								if (tableAction == 'changePage') {
									const newPagination = tablePagination(tableAction, tableState, pagination);
									newPagination && setPagination(newPagination);
								} else if (tableAction == 'viewColumnsChange') {
									setTableStatePersist(tableState);
								}
							},
							onColumnSortChange: (changedColumn: string, direction: string) => {
								setOrder(`${changedColumn} ${direction}`);
							},
						}}
					/>
				</div>
			) : (
				<EmptyContent message="Select the filter criteria above to view suspended transactions." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	shipments: state.orders.shipments,
	selectedFilterValues: state.ui.selectedFilterValues,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getShipments: (filter: any) => dispatch(OrderActions.getShipments(filter)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentsComponent);
