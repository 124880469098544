import { connect } from 'react-redux';
import TagsComponent from './tags.component';
import { InventoryManagementActions, ProductActions, UIAction } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	tags: state.inventoryManagement.tags || {
		result: [],
		total: 0,
	},
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	getTags: (filter: any) => dispatch(InventoryManagementActions.getTags(filter)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	forceVend: (payload: string) => dispatch(InventoryManagementActions.forceVend(payload)),
	revertTag: (payload: any) => dispatch(InventoryManagementActions.revertTag(payload)),
	returnProductTagSerialsRecord: (payload: any) => dispatch(InventoryManagementActions.returnProductTagSerialsRecord(payload)),
	updateProductTagSerials: (payload: any) => dispatch(InventoryManagementActions.updateProductTagSerials(payload)),
	disableSerialNumberFilter: (disabled: boolean) => dispatch(UIAction.disableSerialNumberFilter(disabled)),
	disableOwnershipFilter: (disabled: boolean) => dispatch(UIAction.disableOwnershipFilter(disabled)),
});

export const TagsContainer = connect(mapStateToProps, mapDispatchToProps)(TagsComponent);
