import React from 'react';
import { Container } from '@material-ui/core';
import { InventoryReportsStyles } from '../../../hooks/styles';
import DispensedProducts from './dispensed-products';
import BasePage from '../../common/base';
import { connect } from 'react-redux';

const DispensedProductsPage = (props: any) => {
	const classes = InventoryReportsStyles();

	return (
		<BasePage title={'Dispensed Products'}>
			<div>
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					<DispensedProducts />
				</Container>
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};

export default connect(mapStateToProps)(DispensedProductsPage);
