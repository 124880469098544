const columnOptions = {};

export default {
	TableConfig: {
		restockRequest: {
			id: 'restockRequest',
			displayName: 'Restock Request',
			columns: [
				{
					name: 'customerId',
					label: 'Customer ID',
					options: columnOptions,
				},
				{
					name: 'cabinetId',
					label: 'Device ID',
					options: columnOptions,
				},
				{
					name: 'productId',
					label: 'Product ID',
					options: columnOptions,
				},
				{
					name: 'orderQty',
					label: 'Quantity',
					options: columnOptions,
				},
				{
					name: 'lot',
					label: 'Batch',
					options: columnOptions,
				},
				{
					name: 'warehouse',
					label: 'Batch WHS',
					options: columnOptions,
				},
				{
					name: 'createdAt',
					label: 'Created Date',
					options: columnOptions,
				},
				{
					name: 'abwCode',
					label: 'ABW Code',
					options: columnOptions,
				},
			],
		},
		sapOrders: {
			id: 'sapOrders',
			displayName: 'SAP Orders',
			columns: [
				{
					name: 'orderNumber',
					label: 'Order Number',
					options: columnOptions,
				},
				{
					name: 'orderLine',
					label: 'Order Line',
					options: columnOptions,
				},
				{
					name: 'poNumber',
					label: 'PO Number',
					options: columnOptions,
				},
				{
					name: 'orderStatus',
					label: 'Order Status',
					options: columnOptions,
				},
				{
					name: 'productId',
					label: 'Product ID',
					options: columnOptions,
				},
				{
					name: 'orderQty',
					label: 'Order Qty',
					options: columnOptions,
				},
				{
					name: 'notes',
					label: 'Notes',
					options: columnOptions,
				},
				{
					name: 'createdAt',
					label: 'Created At',
					options: columnOptions,
				},
				{
					name: 'updatedAt',
					label: 'Updated At',
					options: columnOptions,
				},
				{
					name: '',
					label: '',
					options: {
						display: true,
						change: false,
						viewColumns: false,
					},
				},
			],
		},
		sapOrderLots: {
			id: 'sapOrderLots',
			displayName: 'Order Lots',
			columns: [
				{
					name: 'orderNumber',
					label: 'Order Number',
					options: columnOptions,
				},
				{
					name: 'orderLine',
					label: 'Order Line',
					options: columnOptions,
				},
				{
					name: 'productId',
					label: 'Product ID',
					options: columnOptions,
				},
				{
					name: 'lot',
					label: 'Lot',
					options: columnOptions,
				},
				{
					name: 'shippedQty',
					label: 'Shipped Qty',
					options: columnOptions,
				},
				{
					name: 'receivedQty',
					label: 'Received Qty',
					options: columnOptions,
				},
				{
					name: 'createdAt',
					label: 'Created At',
					options: columnOptions,
				},
				{
					name: 'updatedAt',
					label: 'Updated At',
					options: columnOptions,
				},
			],
		},
		warehouseShipment: {
			id: 'warehouseShipment',
			displayName: 'Warehouse Shipment',
			columns: [
				{
					name: 'customerNumber',
					label: 'Customer ID',
					options: columnOptions,
				},
				{
					name: 'itemIdentifier',
					label: 'Item Identifier',
					options: columnOptions,
				},
				{
					name: 'vendorItem',
					label: 'Vendor Item',
					options: columnOptions,
				},
				{
					name: 'factorDescription',
					label: 'Factor Description',
					options: columnOptions,
				},
				{
					name: 'lotNumber',
					label: 'Lot Number',
					options: columnOptions,
				},
				{
					name: 'numPkgsShipped',
					label: 'Packages Shipped',
					options: columnOptions,
				},
				{
					name: 'shipDate',
					label: 'Ship Date',
					options: columnOptions,
				},
				{
					name: 'orderNumber',
					label: 'Order Number',
					options: columnOptions,
				},
				{
					name: 'orderLineNo',
					label: 'Order Line Number',
					options: columnOptions,
				},
				{
					name: 'trackingNumber',
					label: 'Tracking',
					options: columnOptions,
				},
			],
		},
		igShipmentItems: {
			id: 'igShipmentItems',
			displayName: 'Shipment Items',
			columns: [
				{
					name: 'fffItemId',
					label: 'FFF Item ID',
					options: columnOptions,
				},
				{
					name: 'ndc',
					label: 'NDC',
					options: columnOptions,
				},
				{
					name: 'lotNumber',
					label: 'Lot Number',
					options: columnOptions,
				},
				{
					name: 'tagId',
					label: 'Tag ID',
					options: columnOptions,
				},
				{
					name: 'shipmentId',
					label: 'Shipment ID',
					options: columnOptions,
				},
				{
					name: 'expDate',
					label: 'Expiration Date',
					options: columnOptions,
				},
				{
					name: 'serialNumber',
					label: 'Serial No',
					options: columnOptions,
				},
			],
		},
		transactions: {
			id: 'transactions',
			displayName: 'Received',
			columns: [
				{
					name: 'customerId',
					label: 'Customer ID',
					options: columnOptions,
				},
				{
					name: 'cabinetId',
					label: 'Cabinet ID',
					options: columnOptions,
				},
				{
					name: 'fffItemId',
					label: 'FFF Item ID',
					options: columnOptions,
				},
				{
					name: 'type',
					label: 'Type',
					options: columnOptions,
				},
				{
					name: 'lotNumber',
					label: 'Lot Number',
					options: columnOptions,
				},
				{
					name: 'objectId',
					label: 'Tag ID',
					options: columnOptions,
				},
				{
					name: 'sourceTimestamp',
					label: 'Time Received',
					options: columnOptions,
				},
			],
		},
	},
};
