import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { UIAction } from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import { ProductsContainer } from './products.container';
const Products = () => {
	const styles = DashboardStyles();
	const firstRender = useRef(true);
	const dispatch = useDispatch();
	useEffect(() => {
		firstRender.current = false;
	}, [dispatch]);

	return (
		<BasePage title={Constants.PAGE_TITLE.PRODUCTS}>
			<div className={styles.appBarSpacer}></div>
			<Container maxWidth={false} className={styles.container}>
				<ProductsContainer />
			</Container>
		</BasePage>
	);
};

export default Products;
