import * as React from 'react';
import { CommonStyles } from '../../../hooks/styles';
import {
	Button,
	TextField,
	FormControl,
	Grid,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	IconButton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Alert, Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Loading from '../../../components/loading';
import { useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { CabinetActions } from '../../../redux/actions';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { rejectCabinetReceipt } from '../../../redux/sagas/cabinets.saga';
import ConfirmDialog from '../../../components/dialog/confirm';
import { UserTimezoneFormat } from '../../../hooks/functions';

interface ManualReceiptComponentProps {
	flexCabinetProductList: any[] | null;
	sendManualReceipt: (payload: any) => void;
	getCabinetReceipts: (payload: any) => void;
	rejectCabinetReceipt: (payload: any) => void;
	cloudUpdateTime?: string;
	cabinetReceipts?: any[];
	authUser: any;
}

const ManualReceiptComponent: React.FC<any> = (props: ManualReceiptComponentProps) => {
	const classes = CommonStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [receiptAction, setReceiptAction] = React.useState('ADD');
	const [receiptToReject, setReceiptToReject] = React.useState<any>(null);
	const [inputs, setInputs] = React.useState<any>({
		shipmentId: '',
		shipmentDate: '',
		poNumber: '',
		orderNumber: '',
		receiptItems: [{ productId: '', expiryDate: '', totalCount: '', lotNumber: '', lineNumber: '' }],
	});
	const [serializedProductsFlag, setSerializedProductsFlag] = React.useState<string>('NO');
	const [serializedProducts, setSerializedProducts] = React.useState<any[]>([]);
	const [selectedProducts, setSelectedProducts] = React.useState<any[]>([null]);
	const [formErrors, setFormErrors] = React.useState<any>({
		receiptItems: [{ productId: '', expiryDate: '', totalCount: '', lotNumber: '', lineNumber: '' }],
	});

	React.useEffect(() => {
		props.getCabinetReceipts(cabinetId);
	}, []);

	const handleSerializedProductsFlagChange = (value: string) => {
		setSerializedProductsFlag(value);
		if (value === 'NO') {
			setSerializedProducts([]);
			return;
		}
		let rItemsString: string = JSON.stringify(inputs.receiptItems);
		let rItemsCopy: any[] = JSON.parse(rItemsString);
		let serialsArray = rItemsCopy.map((rItem: any) => {
			return rItem.totalCount ? Array(rItem.totalCount).fill('') : [''];
		});
		setSerializedProducts(serialsArray);
	};

	const updateSerializedProductCount = (newCount: number, itemIndex: number) => {
		if (serializedProductsFlag === 'NO') return;
		let serializedProductsCopy = [...serializedProducts];
		let newSerialsAtIndex = Array(newCount || 1).fill('');
		serializedProductsCopy[itemIndex].forEach((serialNo: any, sIndex: number) => {
			if (sIndex > newSerialsAtIndex.length - 1) return;
			newSerialsAtIndex[sIndex] = serialNo;
		});
		serializedProductsCopy[itemIndex] = newSerialsAtIndex;
		setSerializedProducts(serializedProductsCopy);
	};

	const addItem = () => {
		const newItem: any = { productId: '', expiryDate: '', totalCount: '', lotNumber: '', lineNumber: '' };
		if (serializedProductsFlag === 'YES') {
			let serializedProductsCopy = [...serializedProducts];
			serializedProductsCopy.push(['']);
			setSerializedProducts(serializedProductsCopy);
		}
		let newReceiptItems = [...inputs.receiptItems];
		newReceiptItems.push(newItem);
		setInputs({ ...inputs, receiptItems: newReceiptItems });
		setSelectedProducts([...selectedProducts, null]);
		let newFormErrorItems = [...formErrors.receiptItems];
		newFormErrorItems.push(newItem);
		setFormErrors({ ...formErrors, receiptItems: newFormErrorItems });
	};

	const deleteItem = (index: number) => {
		let newReceiptItems = [...inputs.receiptItems];
		newReceiptItems.splice(index, 1);
		setInputs({ ...inputs, receiptItems: newReceiptItems });
		let selectedProductsCopy = [...selectedProducts];
		selectedProductsCopy.splice(index, 1);
		setSelectedProducts(selectedProductsCopy);
		let newFormErrorItems = [...formErrors.receiptItems];
		newFormErrorItems.splice(index, 1);
		setFormErrors({ ...formErrors, receiptItems: newFormErrorItems });
		if (serializedProductsFlag === 'YES') {
			let serializedProductsCopy = [...serializedProducts];
			serializedProductsCopy.splice(index, 1);
			setSerializedProducts(serializedProductsCopy);
		}
	};

	const getProductSerialInputs = (index: number) => {
		return (
			serializedProductsFlag === 'YES' && (
				<>
					<Grid item xs={12}>
						{serializedProducts[index].map((serialNo: string, sIndex: number) => (
							<FormControl style={{ margin: '0 5px 0 8px' }} key={sIndex}>
								<TextField
									label="Serial No"
									variant="outlined"
									style={{ width: 90 }}
									value={serialNo || ''}
									onChange={event => {
										let serializedProductsCopy = [...serializedProducts];
										serializedProductsCopy[index][sIndex] = event.target.value || '';
										setSerializedProducts(serializedProductsCopy);
										let formErrorsCopy = { ...formErrors };
										formErrorsCopy.serialNumbers?.splice(index, 1);
										setFormErrors(formErrorsCopy);
									}}
									required
								/>
							</FormControl>
						))}
					</Grid>
					{formErrors.serialNumbers?.length > 0 && formErrors.serialNumbers[index] && (
						<p className={classes.errorMsg} style={{ margin: '8px 0 0 0' }}>
							All serial numbers are required
						</p>
					)}
				</>
			)
		);
	};

	const submitManualReceipt = () => {
		let hasErrors = false;
		let errors: any = {};
		const inputKeys = Object.keys(inputs);
		inputKeys.forEach((key: string) => {
			if (key === 'receiptItems') {
				errors.serialNumbers = [];
				let receiptItemsErrors = [...formErrors.receiptItems];
				inputs.receiptItems.forEach((item: any, index: number) => {
					const itemKeys = Object.keys(item);
					itemKeys.forEach((itemKey: string) => {
						if (!item[itemKey]) {
							hasErrors = true;
							receiptItemsErrors[index][itemKey] = true;
						}
					});

					if (serializedProductsFlag === 'YES' && serializedProducts[index].includes('')) {
						errors.serialNumbers[index] = true;
						hasErrors = true;
					}
				});
				errors.receiptItems = receiptItemsErrors;
			} else {
				if (!inputs[key]) {
					hasErrors = true;
					errors[key] = true;
				}
			}
		});

		if (hasErrors) {
			setFormErrors(errors);
			return;
		}

		let requestBody = { ...inputs };

		if (serializedProductsFlag === 'YES') {
			requestBody.receiptItems = requestBody.receiptItems.map((rItem: any, index: number) => {
				rItem.serialNumbers = serializedProducts[index];
				return rItem;
			});
		}

		const payload = {
			requestBody: requestBody,
			cabinetId: cabinetId,
			success: () => {
				// clear form
				setInputs({
					shipmentId: '',
					shipmentDate: '',
					poNumber: '',
					receiptItems: [{ productId: '', expiryDate: '', totalCount: '', lotNumber: '', lineNumber: '' }],
				});
				setSerializedProductsFlag('NO');
				setSelectedProducts([null]);
			},
		};

		props.sendManualReceipt(payload);
	};

	return (
		<>
			{!props.flexCabinetProductList ? (
				<Loading message="" />
			) : (
				<Grid container>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<ToggleButtonGroup
								style={{ margin: '5px 0 8px 0' }}
								size="small"
								value={receiptAction}
								exclusive
								onChange={(event: any, value: any) => setReceiptAction(value)}
							>
								<ToggleButton style={{ fontSize: '10px' }} value="ADD" aria-label="Add">
									Add
								</ToggleButton>
								<ToggleButton style={{ fontSize: '10px' }} value="DELETE" aria-label="Reject">
									Reject
								</ToggleButton>
							</ToggleButtonGroup>
						</FormControl>
					</Grid>
					{receiptAction === 'ADD' && (
						<>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<TextField
										label="Shipment Id"
										variant="outlined"
										style={{ width: 200 }}
										value={inputs.shipmentId || ''}
										error={formErrors.shipmentId ? true : false}
										helperText={formErrors.shipmentId ? 'Please enter a shipment id' : ''}
										onChange={event => {
											setInputs({ ...inputs, shipmentId: event.target.value });
											setFormErrors({ ...formErrors, shipmentId: null });
										}}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											autoOk
											format="YYYY-MM-DD"
											variant="inline"
											inputVariant="outlined"
											label="Shipment Date"
											style={{ width: 200 }}
											inputProps={{ readOnly: false }}
											required
											InputAdornmentProps={{ position: 'end' }}
											error={formErrors.shipmentDate ? true : false}
											helperText={formErrors.shipmentDate ? 'Please enter a shipment date' : 'YYYY-MM-DD'}
											value={inputs.shipmentDate || null}
											onChange={(date: any) => {
												setInputs({ ...inputs, shipmentDate: date?.isValid() ? date.format('YYYY-MM-DD') : '' });
												setFormErrors({ ...formErrors, shipmentDate: null });
											}}
										/>
									</MuiPickersUtilsProvider>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<TextField
										label="PO No"
										variant="outlined"
										style={{ width: 200 }}
										value={inputs.poNumber || ''}
										error={formErrors.poNumber ? true : false}
										helperText={formErrors.poNumber ? 'Please enter a PO number' : ''}
										onChange={event => {
											setInputs({ ...inputs, poNumber: event.target.value });
											setFormErrors({ ...formErrors, poNumber: null });
										}}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<TextField
										label="Order No"
										variant="outlined"
										style={{ width: 200 }}
										value={inputs.orderNumber || ''}
										error={formErrors.orderNumber ? true : false}
										helperText={formErrors.orderNumber ? 'Please enter an order number' : ''}
										onChange={event => {
											setInputs({ ...inputs, orderNumber: event.target.value });
											setFormErrors({ ...formErrors, orderNumber: null });
										}}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl component="fieldset" className={classes.formControl}>
									<FormLabel component="legend">Serialized Products</FormLabel>
									<RadioGroup
										className={classes.radioGroup}
										aria-label="serialized-products"
										name="serialized-products"
										value={serializedProductsFlag}
										onChange={(event: any) => handleSerializedProductsFlagChange(event.target.value)}
									>
										<FormControlLabel
											className={classes.radioLabel}
											value={'YES'}
											control={<Radio color="default" />}
											label="Yes"
										/>
										<FormControlLabel value={'NO'} control={<Radio color="default" />} label="No" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Button
									onClick={addItem}
									type="button"
									className={`${classes.searchButton}`}
									variant="contained"
									color="primary"
									style={{ marginBottom: 10 }}
								>
									Add Item
								</Button>
							</Grid>
							{inputs.receiptItems.map((rItem: any, index: number) => (
								<Grid item xs={12} key={index} style={{ marginBottom: 25 }}>
									<Grid container>
										<Grid item xs={2}>
											<FormControl className={classes.formControl}>
												<Autocomplete
													id="product-combo-box"
													options={props.flexCabinetProductList || []}
													getOptionLabel={(option: any) => '(' + option.productId + ') ' + (option.productName || option.product?.productName)}
													style={{ width: '95%' }}
													onChange={(event: any, value: any) => {
														let rItemsCopy = JSON.stringify(inputs.receiptItems);
														let rItemsCopyObj: any[] = JSON.parse(rItemsCopy);
														rItemsCopyObj[index].productId = value?.productId || '';
														setInputs({ ...inputs, receiptItems: rItemsCopyObj });
														let selectedProductsCopy = [...selectedProducts];
														selectedProductsCopy[index] = value || null;
														setSelectedProducts(selectedProductsCopy);
														let receiptItemsErrors = [...formErrors.receiptItems];
														receiptItemsErrors[index].productId = '';
														setFormErrors({ ...formErrors, receiptItems: receiptItemsErrors });
													}}
													value={selectedProducts[index] || null}
													renderInput={(params: any) => (
														<TextField
															{...params}
															variant="outlined"
															label={'Select Product'}
															error={formErrors.receiptItems[index].productId ? true : false}
															helperText={formErrors.receiptItems[index].productId ? 'Please enter a product id' : ''}
															required
														/>
													)}
												/>
											</FormControl>
										</Grid>
										<Grid item xs={2}>
											<FormControl className={classes.formControl}>
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<KeyboardDatePicker
														autoOk
														format="YYYY-MM-DD"
														variant="inline"
														inputVariant="outlined"
														label="Expiry"
														style={{ width: '95%' }}
														inputProps={{ readOnly: false }}
														required
														InputAdornmentProps={{ position: 'end' }}
														error={formErrors.receiptItems[index].expiryDate ? true : false}
														helperText={
															formErrors.receiptItems[index].expiryDate ? 'Please enter an expiry date' : 'YYYY-MM-DD'
														}
														value={rItem.expiryDate || null}
														onChange={(date: any) => {
															let rItemsCopy = JSON.stringify(inputs.receiptItems);
															let rItemsCopyObj: any[] = JSON.parse(rItemsCopy);
															rItemsCopyObj[index].expiryDate = date?.isValid() ? date.format('YYYY-MM-DD') : '';
															setInputs({ ...inputs, receiptItems: rItemsCopyObj });
															let receiptItemsErrors = [...formErrors.receiptItems];
															receiptItemsErrors[index].expiryDate = '';
															setFormErrors({ ...formErrors, receiptItems: receiptItemsErrors });
														}}
													/>
												</MuiPickersUtilsProvider>
											</FormControl>
										</Grid>
										<Grid item xs={1}>
											<FormControl className={classes.formControl}>
												<TextField
													label="Total Count"
													variant="outlined"
													type="number"
													style={{ width: 90 }}
													InputProps={{ inputProps: { min: 0 } }}
													value={rItem.totalCount || ''}
													error={formErrors.receiptItems[index].totalCount ? true : false}
													helperText={formErrors.receiptItems[index].totalCount ? 'Please enter a total count' : ''}
													onChange={event => {
														let rItemsCopy = JSON.stringify(inputs.receiptItems);
														let rItemsCopyObj: any[] = JSON.parse(rItemsCopy);
														rItemsCopyObj[index].totalCount = +event.target.value;
														setInputs({ ...inputs, receiptItems: rItemsCopyObj });
														let receiptItemsErrors = [...formErrors.receiptItems];
														receiptItemsErrors[index].totalCount = '';
														setFormErrors({ ...formErrors, receiptItems: receiptItemsErrors });
														updateSerializedProductCount(+event.target.value, index);
													}}
													required
												/>
											</FormControl>
										</Grid>
										<Grid item xs={2}>
											<FormControl className={classes.formControl}>
												<TextField
													label="Lot"
													variant="outlined"
													style={{ width: '95%' }}
													value={rItem.lotNumber || ''}
													error={formErrors.receiptItems[index].lotNumber ? true : false}
													helperText={formErrors.receiptItems[index].lotNumber ? 'Please enter a lot' : ''}
													onChange={event => {
														let rItemsCopy = JSON.stringify(inputs.receiptItems);
														let rItemsCopyObj: any[] = JSON.parse(rItemsCopy);
														rItemsCopyObj[index].lotNumber = event.target.value;
														setInputs({ ...inputs, receiptItems: rItemsCopyObj });
														let receiptItemsErrors = [...formErrors.receiptItems];
														receiptItemsErrors[index].lotNumber = '';
														setFormErrors({ ...formErrors, receiptItems: receiptItemsErrors });
													}}
													required
												/>
											</FormControl>
										</Grid>
										<Grid item xs={1}>
											<FormControl style={{ margin: '8px 5px 8px 8px' }}>
												<TextField
													label="Line No"
													variant="outlined"
													type="number"
													style={{ width: 90 }}
													value={rItem.lineNumber || ''}
													error={formErrors.receiptItems[index].lineNumber ? true : false}
													helperText={formErrors.receiptItems[index].lineNumber ? 'Please enter a line number' : ''}
													onChange={event => {
														let rItemsCopy = JSON.stringify(inputs.receiptItems);
														let rItemsCopyObj: any[] = JSON.parse(rItemsCopy);
														rItemsCopyObj[index].lineNumber = event.target.value;
														setInputs({ ...inputs, receiptItems: rItemsCopyObj });
														let receiptItemsErrors = [...formErrors.receiptItems];
														receiptItemsErrors[index].lineNumber = '';
														setFormErrors({ ...formErrors, receiptItems: receiptItemsErrors });
													}}
													required
												/>
											</FormControl>
										</Grid>
										{index > 0 && (
											<Grid item xs={1}>
												<CancelIcon
													onClick={() => deleteItem(index)}
													style={{
														marginLeft: 20,
														marginTop: 21,
														cursor: 'pointer',
													}}
												/>
											</Grid>
										)}
										{getProductSerialInputs(index)}
									</Grid>
								</Grid>
							))}
							<Grid item xs={12}>
								<Button
									onClick={submitManualReceipt}
									type="button"
									className={`${classes.searchButton}`}
									variant="contained"
									color="primary"
									style={{ marginTop: 20 }}
								>
									Submit
								</Button>
							</Grid>
						</>
					)}
					{receiptAction === 'DELETE' && (
						<>
							{props.cabinetReceipts ? (
								<div>
									{props.cloudUpdateTime && (
										<Alert variant="outlined" severity="warning">
											Receipt info current as of {UserTimezoneFormat(props.authUser.properties, props.cloudUpdateTime)}
										</Alert>
									)}
									<MUIDataTable
										style={{ width: '100%' }}
										title={<h3>Receipts</h3>}
										data={props.cabinetReceipts || []}
										columns={[
											{ label: 'PO Number', name: 'poNumber' },
											{ label: 'Shipment Id', name: 'shipmentId' },
											{ label: 'Shipped Date', name: 'shippedDate' },
											{
												label: 'Reject',
												name: '',
												options: {
													customBodyRenderLite: (dataIndex: number) => {
														return (
															<IconButton
																onClick={() => {
																	setReceiptToReject(props.cabinetReceipts?.[dataIndex]);
																}}
															>
																<DeleteIcon fontSize="small" />
															</IconButton>
														);
													},
												},
											},
										]}
										options={{
											search: false,
											responsive: 'simple',
											filter: false,
											download: false,
											print: false,
											selectableRows: 'none',
											expandableRowsHeader: false,
											expandableRows: true,
											renderExpandableRow: (rowData: any, rowMeta: any) => {
												const receiptItems = props.cabinetReceipts?.[rowMeta.dataIndex].receiptItems || [];
												return (
													<>
														{receiptItems.map((item: any) => (
															<TableRow>
																<TableCell></TableCell>
																<TableCell>Product Id: {item.productId}</TableCell>
																<TableCell>Lot: {item.lotNumber}</TableCell>
																<TableCell>Count: {item.count}</TableCell>
																<TableCell>Serials: {item.serialNumbers}</TableCell>
															</TableRow>
														))}
													</>
												);
											},
										}}
									/>
								</div>
							) : (
								<Loading message="" />
							)}
							<ConfirmDialog
								open={receiptToReject}
								handleClose={() => setReceiptToReject(null)}
								title={`Reject Receipt for Shipment ID ${receiptToReject?.shipmentId}?`}
								content="This action may not be reflected in realtime."
								handleYes={() => {
									props.rejectCabinetReceipt({
										cabinetId: cabinetId,
										requestBody: {
											shipmentId: receiptToReject.shipmentId,
											rejectReason: 'OTHER',
										},
									});
									setReceiptToReject(null);
								}}
							/>
						</>
					)}
				</Grid>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser.record,
	cabinetReceipts: state.cabinet.cabinetReceipts?.receipts || null,
	cloudUpdateTime: state.cabinet.cabinetReceipts?.cloudUpdateTime || null,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetReceipts: (payload: any) => dispatch(CabinetActions.getCabinetReceipts(payload)),
	rejectCabinetReceipt: (payload: any) => dispatch(CabinetActions.rejectCabinetReceipt(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualReceiptComponent);
