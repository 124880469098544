import * as React from 'react';
import { getModalClasses, getModalStyle } from '../../hooks/styles';
import { Grid, FormControl, Button, Modal, Paper, TextField } from '@material-ui/core';
import { CabinetStyles } from './cabinet.styles';
import { useMediaQuery } from 'react-responsive';
import { CabinetActions } from '../../redux/actions';
import { connect } from 'react-redux';
import Loading from '../../components/loading';
import MUIDataTable from 'mui-datatables';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const CabinetProviders: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();

	const { cabinetId, customerId } = useParams<any>();
	const [addProviderModalOpen, setAddProviderModalOpen] = React.useState(false);
	const [providerId, setProviderId] = React.useState<string>('');
	const [providerIdError, setProviderIdError] = React.useState(false);
	const [providerLookupState, setProviderLookupState] = React.useState<any>({
		loading: false,
		error: false,
	});
	const handleProviderLookup = () => {
		if (!providerId) {
			setProviderIdError(true);
			return;
		}

		setProviderLookupState({ error: false, loading: true });
		props.getProviderById({
			providerId: providerId,
			callback: setProviderLookupState,
		});
	};

	const handleAddProviderModalClose = (modalState: boolean) => {
		setAddProviderModalOpen(modalState);
	};

	const handleAddProvider = () => {
		setProviderLookupState({ error: false, loading: true });

		props.addProvider({
			cabinetId: cabinetId,
			providerId: props.foundProvider?.providerNumber ? String(props.foundProvider.providerNumber) : '',
			success: (newProvider: any) => {
				setAddProviderModalOpen(false);
				setProviderLookupState({ ...providerLookupState, loading: false });

				const selectedCabinetWithNewProvider = cloneDeep(props.selectedCabinet);

				selectedCabinetWithNewProvider.cabinetProviders = selectedCabinetWithNewProvider.cabinetProviders || [];
				const newProviderData = {
					providerId: newProvider.providerId,
					npi: newProvider.npiId,
					firstName: newProvider.firstName,
					lastName: newProvider.lastName,
				};

				selectedCabinetWithNewProvider.cabinetProviders.push(newProviderData);
				props.refreshSelectedCabinet(selectedCabinetWithNewProvider);

				//on successful save to the cabinet_providers table,
				//a flex cabinet also adds the provider to the config json
				if (props.addProviderToFlexCabinet) {
					props.addProviderToFlexCabinet(newProviderData);
				}
				if (props.changeDetected) props.changeDetected(true);
			},
			error: () => {
				setProviderLookupState({
					error: `Failed to add provider with npi number: ${props.foundProvider.providerNumber}`,
					loading: false,
				});
			},
		});
	};

	const onDelete = async (rowsDeleted: any) => {
		let deletedProviderIndexes: any[] = [];
		const providerIdsToDelete = rowsDeleted.data.map((indexMeta: { index: number; dataIndex: number }) => {
			deletedProviderIndexes.push(indexMeta.dataIndex);
			return props.cabinet.cabinetProviders[indexMeta.dataIndex].providerId;
		});

		const selectedCabinetCopy = cloneDeep(props.selectedCabinet);

		props.deleteProviders({
			providers: {
				cabinetId: cabinetId,
				providerIds: providerIdsToDelete,
			},
			success: () => {
				selectedCabinetCopy.cabinetProviders = selectedCabinetCopy.cabinetProviders.filter(
					(provider: any, index: any) => {
						return !deletedProviderIndexes.includes(index);
					},
				);
				props.refreshSelectedCabinet(selectedCabinetCopy);
				if (props.changeDetected) props.changeDetected(true);
			},
			error: () => {
				props.refreshSelectedCabinet(selectedCabinetCopy);
			},
		});
	};

	const providerColumns = [
		{
			name: 'npi',
			label: 'Provider NPI Number',
		},
		{
			name: 'firstName',
			label: 'First Name',
		},
		{
			name: 'lastName',
			label: 'Last Name',
		},
	];

	const classes = CabinetStyles();

	return (
		<div>
			{props.authUser?.authorizationCheck({
				action: 'create',
				resource: 'cabinets',
				tenantId: props.selectedCabinet.customerId,
			}) && (
				<FormControl className={classes.buttonFormControl} style={isDesktopOrLaptop ? { marginTop: 14 } : {}}>
					<Button
						variant="contained"
						color="secondary"
						className={classes.viewAllBtn}
						style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
						onClick={() => {
							props.clearFoundProvider();
							setProviderId('');
							setAddProviderModalOpen(true);
						}}
					>
						Add Provider
					</Button>
				</FormControl>
			)}
			<MUIDataTable
				options={{
					onRowsDelete: onDelete,
				}}
				columns={providerColumns}
				data={props.cabinet.cabinetProviders}
			/>

			<Modal
				open={addProviderModalOpen}
				onClose={() => handleAddProviderModalClose(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{ ...getModalStyle(), padding: '20px', width: isDesktopOrLaptop ? 'auto' : '90%' }}
					className={modalClasses.paper}
				>
					<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
						<div className={classes.inventoryActionHeading}>Add Provider</div>
						<div style={{ width: '420px' }}>
							<Grid container>
								<Grid item xs={6}>
									<FormControl required variant="outlined" className={classes.formControl}>
										<TextField
											disabled={providerLookupState.loading}
											required
											style={{ width: 210 }}
											type="text"
											label="Provider NPI Number"
											placeholder="Enter Provider NPI Number"
											variant="outlined"
											error={providerIdError}
											helperText={providerIdError ? 'Please enter a Provider NPI Number' : ''}
											value={providerId}
											onFocus={() => {
												setProviderIdError(false);
												setProviderLookupState({ ...providerLookupState, error: false });
											}}
											onChange={e => setProviderId(e.target.value)}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl className={classes.formControl}>
										<Button
											disabled={providerLookupState.loading}
											variant="contained"
											color="secondary"
											className={classes.viewAllBtn}
											style={{ margin: 12 }}
											onClick={handleProviderLookup}
										>
											Look Up
										</Button>
									</FormControl>
								</Grid>
								{props.foundProvider && (
									<Grid item xs={12}>
										{props.foundProvider.firstName} {props.foundProvider.lastName}
									</Grid>
								)}
								{!providerLookupState.loading && props.foundProvider && (
									<Grid item xs={12}>
										<Button
											disabled={providerLookupState.loading}
											variant="contained"
											color="secondary"
											className={classes.viewAllBtn}
											style={{ margin: `12px 0` }}
											onClick={handleAddProvider}
										>
											Add Provider
										</Button>
									</Grid>
								)}
								{providerLookupState.loading && (
									<Grid item xs={12}>
										<Loading message="" />
									</Grid>
								)}
								{providerLookupState.error && (
									<Grid item xs={12}>
										<p className={classes.cabinetFormError}>{providerLookupState.error}</p>
									</Grid>
								)}
							</Grid>
						</div>
					</Paper>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCabinet: state.cabinet.selectedCabinet,
	foundProvider: state.cabinet.foundProvider,
	error: state.cabinet.error,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
	getProviderById: (payload: any) => dispatch(CabinetActions.getProviderById(payload)),
	clearFoundProvider: () => dispatch(CabinetActions.setFoundProvider(null)),
	addProvider: (payload: any) => dispatch(CabinetActions.addCabinetProvider(payload)),
	refreshSelectedCabinet: (payload: any) => dispatch(CabinetActions.setCabinet(payload)),
	deleteProviders: (payload: any) => dispatch(CabinetActions.deleteCabinetProviders(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetProviders);
