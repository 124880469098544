import React from "react";
import { getModalClasses, getModalStyle } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from 'highcharts';
import { valueMapper } from "../../../hooks/functions";

const DashboardBarGraphSideRendering: React.FC<any> = props => {

    console.log(`DashboardBarGraphSideRendering called`)
    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());

    const handleClose = () => {
        props.closeChart();
    };

    const options = {
        title: {
            text: props.settings.displayName
        },
        chart: {
          type: "bar"
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
        },
        plotOptions: {
            bar: {
                animation: props?.animation ?? true,
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            },
        },
        xAxis: {
            categories: props.records.categories
                .map((el: string) => valueMapper(el, 'cabinetTypes')),
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 0
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Replens by Cabinet Type',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        credits: {
            enabled: false
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
            shadow: true
        },
        series: props.records.chartData
      };

    return (
        <div style={{ ...modalStyle, maxWidth: 1200 }} className={modalClasses.paper}>
            <div className={modalClasses.closeBtn} onClick={handleClose}>
                <CancelIcon color="disabled"></CancelIcon>
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBarGraphSideRendering);