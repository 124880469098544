import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const CommonStyles = makeStyles(theme => ({
	root: {
		'& > .fa': {
			margin: theme.spacing(2),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	pageWrap: {
		marginBottom: 28,
		padding: 9,
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},

	formControl: {
		margin: `${theme.spacing(1)}px !important`,
		marginLeft: 0,
		marginRight: '5px !important',
		minWidth: '200px !important',
	},
	formControlLabel: {
		fontSize: '10px', 
		color: 'rgba(0, 0, 0, 0.54)',
		margin: 0
	},
	floatRight: {
		float: 'right',
	},
	searchWrap: {
		'margin-bottom': '2em',
		padding: 9,
	},
	searchBox: {
		padding: 9,
		border: '1 solid #f2f2f2',
		marginBottom: '10px!important',
		background: '#fff',
	},
	buttonFormControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '16px!important',
		minWidth: '60px!important',
		verticalAlign: 'bottom',
	},
	searchButton: {
		margin: '12px 0 0 10px',
		fontSize: '10px!important',
	},
	tableButton: {
		margin: '10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px!important',
	},
	tableTimePicker: {
		margin: '0',
		width: '150px',
	},
	tableFormControl: {
		margin: '0 0 0 10px',
	},
	viewAllBtn: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px !important',
	},
	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	cancelBtn: {
		margin: '0 5px 0 15px',
		padding: '6px 5px',
		color: 'white',
		fontSize: '10px!important',
		backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
	},
	groupByWrapper: {
		position: 'absolute',
		top: -5,
		left: 20,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		zIndex: 10,
		[theme.breakpoints.down('lg')]: {
			top: -50,
			left: 0,
			right: 'unset',
		},
	},
	groupByWrapperSmall: {
		position: 'absolute',
		top: 55,
		left: 0,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		zIndex: 10,
	},
	groupByHeading: {
		marginRight: '6px',
	},
	groupByLinks: {
		margin: '0 20px 0 0',
	},
	groupByButtons: {
		// color: 'rgba(0, 0, 0, 0.87)'
	},
	expansionHeading: {
		fontWeight: 'bold',
		flexBasis: '33.33%',
		flexShrink: 0,
		fontSize: 14,
	},
	expansionSecondary: {
		fontSize: 14,
	},
	rightAlignFormControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		marginRight: '0px!important',
	},
	defaultButton: {
		backgroundColor: '#558db1 !important',
	},
	chipStyle: {
		marginRight: 5,
	},
	centerButton: {
		margin: '0 auto',
	},
	topBackButton: {
		textAlign: 'right',
		marginBottom: 20,
	},
	errorMsg: {
		color: '#f44336',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	checkbox: {
		'&.MuiCheckbox-colorSecondary.Mui-checked': {
			color: globalColors.FFF_BLUE,
		},
	},
	menuItem: {
		cursor: 'pointer',
	},
	menuItemActive: {
		width: 'auto',
		background: theme.palette.type === 'dark' ? globalColors.FFF_DARK_BLUE : globalColors.FFF_BLUE,
	},
	radioRoot: {
		flexDirection: 'row',
		display: 'flex',
		marginBottom: '1em',
	},
	radioGroup: {
		flexDirection: 'row',
	},
	radioLabel: {
		marginRight: '2.5em',
	},
	buttonGroupHeading: {
		width: 'fit-content',
		margin: '-28px 0 15px 0',
		padding: '0 5px',
		fontSize: '1.2em',
		backgroundColor: theme.palette.background.paper,
	},
	buttonGroupActive: {
		backgroundColor: globalColors.FFF_BLUE,
		color: 'white',
		'&:hover': {
			background: globalColors.FFF_BLUE,
		},
	},
	parToolTip: {
		'&:hover': {
			backgroundColor: 'red',
		}
	},
	link: {
		'text-decoration': 'inherit',
		color: 'inherit',
		'&:focus, &:hover, &:visited, &:link, &:active': {
			'text-decoration': 'inherit',
			color: 'inherit',
		},
	}
}));
export default CommonStyles;
