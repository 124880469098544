import * as React from 'react';
import { Button, FormControl } from '@material-ui/core';
import { UserManagementActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { userModalStyles } from './users.styles';

function getImportModalStyle() {
	const modalTop = 50;
	const modalLeft = 50;

	return {
		top: `${modalTop}%`,
		left: `${modalLeft}%`,
		transform: `translate(-${modalTop}%, -${modalLeft}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

const ResendInvite: React.FC<any> = props => {
	const importClasses = useStyles();

	return (
		<div style={getImportModalStyle()} className={importClasses.paper}>
			<h2>Resend Invite Email</h2>
			<p>Are you sure you want to resend an invite email to {props.thisUser.email}?</p>
			<>
				<FormControl className={importClasses.input}>
					<Button
						className={importClasses.addPermBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							props.resendInvite(props.thisUser.email);
							props.close();
						}}
					>
						Yes
					</Button>
				</FormControl>
				<FormControl className={importClasses.input}>
					<Button className={importClasses.cancelBtn} variant="contained" color="default" onClick={props.close}>
						No
					</Button>
				</FormControl>
			</>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	resendInvite: (payload: string) => dispatch(UserManagementActions.resendInviteEmail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResendInvite);
