import { Button, FormControl } from '@material-ui/core';
import React from 'react';

const IncidentButton = (props: any) => {
	return (
		<FormControl>
			<Button type="button" className={props.styles} variant="contained" color="primary" onClick={props.onButtonClick}>
				{props.text}
			</Button>
		</FormControl>
	);
};

export default IncidentButton;
