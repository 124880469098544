import * as React from 'react';
import {
	TextField,
	Button,
	FormControl,
	Grid,
	Paper,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Modal,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import { getModalStyle, getModalClasses } from '../../hooks/styles';
import { CabinetActions } from '../../redux/actions';
import { CabinetStyles } from './cabinet.styles';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { uniqBy } from 'lodash';
import Loading from '../../components/loading';

const ReportCabinetInventory: React.FC<any> = props => {
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();

	const [productList, setProductList] = React.useState<any>(null);
	const [inventoryStructure, setInventoryStructure] = React.useState<any>(null);
	const [expandedInventory, setExpandedInventory] = React.useState<any>(null);
	const [confirmedInventory, setConfirmedInventory] = React.useState<any>(null);
	const [currentIndexes, setCurrentIndexes] = React.useState<any>({ productIndex: 0, lotIndex: 0 });
	const [inputErrors, setInputErrors] = React.useState<any>({});
	const [useInput, setUseInput] = React.useState(false);
	const [input, setInput] = React.useState<any>(null);
	const [adjustmentMsg, setAdjustmentMsg] = React.useState<any>(null);
	const [suspended, setSuspended] = React.useState<boolean>(false);
	const [done, setDone] = React.useState(false);
	const [canCloseModal, setCanCloseModal] = React.useState(true);

	const getInventoryStructure = (productList: any) => {
		let array: any = [];
		productList.forEach((product: any) => {
			const productLotCount = props.cycleCountInventory.filter(
				(lot: any) => lot.productItemId === product.value,
			).length;
			array.push(Array(productLotCount).fill(false));
		});

		return array;
	};

	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	const setupCycleCount = () => {
		const cycleCountInventory = uniqBy(
			props.cycleCountInventory.map((product: any) => {
				return { label: product.productLabelName, value: product.productItemId };
			}),
			'label',
		);

		const initInventoryStructure = getInventoryStructure(cycleCountInventory);
		let initExpandedInventory = initInventoryStructure.map((arr: any) => arr.slice());
		initExpandedInventory[0][0] = true;
		let initConfirmedInventory = initInventoryStructure.map((arr: any) => arr.slice());

		setProductList(cycleCountInventory);
		setInventoryStructure(initInventoryStructure);
		setExpandedInventory(initExpandedInventory);
		setConfirmedInventory(initConfirmedInventory);
		setInput({
			customerId: props.cycleCountInventory[0].customerId,
			cabinetId: props.cycleCountInventory[0].cabinetId,
			productItemId: props.cycleCountInventory[0].productItemId,
			lot: props.cycleCountInventory[0].lot,
			reportedQty: props.cycleCountInventory[0].salesUoUQty,
		});
	};

	React.useEffect(() => {
		if (!props.cycleCountInventory) {
			props.getCycleCountInventory({
				customerId: props.inventory[0].customerId,
				cabinetId: props.inventory[0].cabinetId,
				limit: props.inventoryRecordCount,
			});
		}
	}, []);

	React.useEffect(() => {
		if (props.cycleCountInventory) {
			setupCycleCount();
		}
	}, [props.cycleCountInventory]);

	const qtyIsValid = (qty: any) => {
		if (qty !== 0 && !qty) {
			setInputErrors({ ...inputErrors, reportedQty: 'Reported quantity is required.' });
			return false;
		}

		if (parseInt(qty) < 0) {
			setInputErrors({ ...inputErrors, reportedQty: 'Reported quantity cannot be less than 1.' });
			return false;
		}

		return true;
	};

	const handleProgress = (stateIndexes: { productIndex: number; lotIndex: number }, isSuspended: boolean) => {
		setInput({ ...input, reportedQty: '' });
		setUseInput(false);

		let confirmed = confirmedInventory.map((arr: any) => arr.slice());
		confirmed[stateIndexes.productIndex][stateIndexes.lotIndex] = isSuspended ? 'suspended' : 'confirmed';
		setConfirmedInventory(confirmed);

		let expanded = inventoryStructure.map((arr: any) => arr.slice());
		const lastProductIndex = inventoryStructure.length - 1;
		const lastLotIndex = inventoryStructure[lastProductIndex].length - 1;
		if (stateIndexes.productIndex === lastProductIndex && stateIndexes.lotIndex === lastLotIndex) {
			setDone(true);
			setCanCloseModal(true);
			setExpandedInventory(expanded);
			return;
		}

		if (expanded[stateIndexes.productIndex].length - 1 === stateIndexes.lotIndex) {
			expanded[stateIndexes.productIndex + 1][0] = true;
			setCurrentIndexes({ productIndex: stateIndexes.productIndex + 1, lotIndex: 0 });
			setExpandedInventory(expanded);
			return;
		}

		expanded[stateIndexes.productIndex][stateIndexes.lotIndex + 1] = true;
		setCurrentIndexes({ productIndex: stateIndexes.productIndex, lotIndex: stateIndexes.lotIndex + 1 });
		setExpandedInventory(expanded);
		return;
	};

	const checkQtyAdjustment = (inventory: any, stateIndexes: { productIndex: number; lotIndex: number }) => {
		if (adjustmentMsg) {
			setAdjustmentMsg(null);
			submit(inventory, stateIndexes);
			return;
		}

		if (parseInt(inventory.salesUoUQty) > parseInt(input.reportedQty)) {
			setAdjustmentMsg(
				`This will make an adjustment by removing ${
					parseInt(inventory.salesUoUQty) - parseInt(input.reportedQty)
				} from inventory. Are you sure?`,
			);
			return;
		}

		if (parseInt(inventory.salesUoUQty) < parseInt(input.reportedQty)) {
			setAdjustmentMsg(
				`This will make an adjustment by inserting ${
					parseInt(input.reportedQty) - parseInt(inventory.salesUoUQty)
				} into inventory. Are you sure?`,
			);
			return;
		}

		submit(inventory, stateIndexes);
	};

	const submit = (inventoryRecord: any, stateIndexes: { productIndex: number; lotIndex: number }) => {
		const qty = useInput ? input.reportedQty : '';
		if (!qtyIsValid(qty)) {
			return;
		}

		const payload = {
			callback: (isSuspended: boolean = false) => {
				setSuspended(isSuspended);
				setAdjustmentMsg(null);
				handleProgress(stateIndexes, isSuspended);
				props.fetchInventory();
			},
			data: {
				customerId: inventoryRecord.customerId,
				cabinetId: inventoryRecord.cabinetId,
				productItemId: inventoryRecord.productItemId,
				lot: inventoryRecord.lot,
				memo: parseInt(qty) !== inventoryRecord.salesUoUQty ? 'Quantity adjusted during cycle count.' : '',
				reportedQty: parseInt(useInput ? input.reportedQty : ''),
				customerNote: input.customerNote,
			},
		};

		if (!done) {
			setCanCloseModal(false);
		}

		props.reportCabinetInventory(payload);
	};

	return (
		<div
			style={{
				...getModalStyle(),
				padding: '20px',
				width: isDesktopOrLaptop ? 'auto' : '90%',
			}}
			className={modalClasses.paper}
		>
			<h3>
				Cycle Count {props.cabinet.cabinetName} ({props.cabinet.cabinetId})
			</h3>
			{productList ? (
				<Grid container style={{ maxHeight: '500px', overflow: 'scroll' }}>
					<Grid item xs={12}>
						{productList.map((product: any, productMapIndex: number) => (
							<div key={productMapIndex}>
								<div className={classes.reportedInvProductHeading}>{product.label}</div>
								{props.cycleCountInventory
									.filter((lot: any) => lot.productItemId === product.value)
									.map((inventory: any, lotMapIndex: number) => (
										<Accordion
											style={{ margin: '0 0 10px 0' }}
											key={lotMapIndex}
											expanded={expandedInventory[productMapIndex][lotMapIndex]}
											onChange={() => {
												if (
													!confirmedInventory[productMapIndex][lotMapIndex] &&
													(currentIndexes.productIndex !== productMapIndex || currentIndexes.lotIndex !== lotMapIndex)
												) {
													return;
												}
												if (confirmedInventory[productMapIndex][lotMapIndex] === 'suspended') {
													return;
												}
												setUseInput(true);
												setInput({
													...input,
													reportedQty: inventory.salesUoUQty,
												});
												setAdjustmentMsg(null);
												let structure = inventoryStructure.map((arr: any) => arr.slice());
												structure[productMapIndex][lotMapIndex] = !expandedInventory[productMapIndex][lotMapIndex];
												setExpandedInventory(structure);
											}}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<div style={{ verticalAlign: 'middle' }}>
													{confirmedInventory[productMapIndex][lotMapIndex] && (
														<>
															{confirmedInventory[productMapIndex][lotMapIndex] === 'confirmed' && (
																<CheckCircleIcon className={classes.confirmationCheck} />
															)}
															{confirmedInventory[productMapIndex][lotMapIndex] === 'suspended' && (
																<BlockIcon className={classes.suspendedIcon} />
															)}
														</>
													)}
													Lot: {inventory.lot}
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<Paper variant="outlined" style={{ width: '100%', padding: 15, marginTop: 0 }}>
													<div>
														<FormControl
															required
															variant="outlined"
															className={classes.formControlFreeMargin}
															style={{ marginBottom: 0 }}
														>
															<TextField
																required
																type="number"
																size="small"
																InputProps={{
																	inputProps: {
																		min: 1,
																		step: 1,
																		onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57,
																	},
																}}
																label="Quantity"
																variant="outlined"
																style={{ width: '90px' }}
																error={inputErrors.reportedQty ? true : false}
																helperText={inputErrors.reportedQty || ''}
																value={useInput ? input.reportedQty : ''}
																FormHelperTextProps={{
																	style: { width: '230px', marginLeft: 0 },
																}}
																onChange={e => {
																	setUseInput(true);
																	setAdjustmentMsg(null);
																	setInput({
																		...input,
																		reportedQty: e.target.value,
																	});
																	setInputErrors({
																		...inputErrors,
																		reportedQty: null,
																	});
																}}
															/>
														</FormControl>
													</div>
													<div
														style={{
															width: '100%',
															paddingTop: 15,
															marginTop: 0,
														}}
													>
														<FormControl required variant="outlined" className={classes.formControlFreeMargin}>
															<TextField
																style={{ width: 210 }}
																size="small"
																type="text"
																label="Memo (optional)"
																placeholder="Memo (optional)"
																variant="outlined"
																error={inputErrors.customerNote ? true : false}
																helperText={inputErrors.customerNote || ''}
																value={input.customerNote || ''}
																onChange={e =>
																	setInput({
																		...input,
																		customerNote: e.target.value,
																	})
																}
															/>
														</FormControl>
													</div>
													<div>
														<FormControl
															className={[
																classes.formControlFreeMargin,
																!isDesktopOrLaptop ? classes.formControlMobileFreeMargin : '',
															].join(' ')}
															style={{ marginBottom: 0 }}
														>
															<Button
																className={classes.viewAllBtn}
																style={{
																	margin: isDesktopOrLaptop ? '3px 0 0 10px' : '10px 0 0 0',
																}}
																variant="contained"
																color="secondary"
																onClick={() => {
																	checkQtyAdjustment(inventory, {
																		productIndex: productMapIndex,
																		lotIndex: lotMapIndex,
																	});
																}}
															>
																Confirm &amp; Continue
															</Button>
														</FormControl>
													</div>
													{adjustmentMsg && (
														<div
															style={{
																fontWeight: 'bold',
																marginTop: '8px',
																fontSize: '12px',
															}}
														>
															{adjustmentMsg}
														</div>
													)}
													{props.error && <p className={classes.cabinetFormError}>Failed to confirm inventory.</p>}
												</Paper>
											</AccordionDetails>
										</Accordion>
									))}
							</div>
						))}
					</Grid>
				</Grid>
			) : (
				<>
					{props.cycleCountInventoryError ? (
						<p className={classes.cabinetFormError}>Failed to load inventory for cycle count.</p>
					) : (
						<Loading message="" />
					)}
				</>
			)}
			{canCloseModal && (
				<div style={{ textAlign: 'center', margin: '10px 0 0 0' }}>
					<FormControl>
						<Button
							className={done ? classes.viewAllBtn : classes.cancelBtn}
							variant="contained"
							color={done ? 'secondary' : 'default'}
							style={{ marginTop: 0 }}
							onClick={() => {
								props.closeModal();
							}}
						>
							{done ? 'Done' : 'Cancel'}
						</Button>
					</FormControl>
				</div>
			)}
			<Modal
				disableBackdropClick={true}
				open={suspended}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{
						...getModalStyle(),
						textAlign: 'center',
						padding: '20px',
						width: isDesktopOrLaptop ? 'auto' : '90%',
					}}
					className={modalClasses.paper}
				>
					<b>This adjustment is in review.</b>
					<br />
					Current on hand inventory will not reflect this adjustment until is it resolved.
					<Grid>
						<Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
							<FormControl className={classes.formControl}>
								<Button
									className={classes.cancelBtn}
									variant="contained"
									color="default"
									style={{ marginTop: 0 }}
									onClick={() => {
										setSuspended(false);
									}}
								>
									CLOSE
								</Button>
							</FormControl>
						</Grid>
					</Grid>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	error: state.cabinet.error,
	cycleCountInventoryError: state.cabinet.cycleCountInventoryError,
	cycleCountInventory: state.cabinet.cycleCountInventory?.result || null,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	getCycleCountInventory: (payload: any) => dispatch(CabinetActions.getCycleCountInventory(payload)),
	reportCabinetInventory: (payload: any) => dispatch(CabinetActions.reportCabinetInventory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportCabinetInventory);
