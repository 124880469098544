import * as React from 'react';
import { connect } from 'react-redux';
import { FormControl, Grid, Paper, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses } from '../../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import { UserActions } from '../../../redux/actions';
import moment from 'moment';

const QueueModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	const [showPreviouslyDownloaded, setShowPreviouslyDownloaded] = React.useState<boolean>(false);

	const downloadQueueItem = (qi: any) => {
		window.open(qi.reportLink, '_blank');

		const params = {
			paramsToUpdate: {
				hasBeenDownloaded: true,
			},
			urlToUpdate: qi.reportLink,
			originApp: 'RNI',
		};

		props.updateUserQueueItem(params); //update on backend
		qi.hasBeenDownloaded = true; //update list in this modal

		//update count for frontend
		props.markQueueItemReadLocally();
	};

	const sortFn = (a: any, b: any) => {
		if (!a.createdDate || !b.createdDate) return 0;

		if (moment(a.createdDate).valueOf() > moment(b.createdDate).valueOf()) {
			return -1;
		}

		if (moment(a.createdDate).valueOf() < moment(b.createdDate).valueOf()) {
			return 1;
		}

		return 0;
	};

	const renderUserQueue = () => {
		const undownloadedItems = props.userQueue.filter((qi: any) => {
			return !qi.hasBeenDownloaded && !qi.isFailedExport;
		});

		const downloadedItems = props.userQueue.filter((qi: any) => {
			return qi.hasBeenDownloaded && !qi.isFailedExport;
		});

		const errorItems = props.userQueue.filter((qi: any) => {
			return qi.isFailedExport;
		});

		if (undownloadedItems?.length) {
			undownloadedItems.sort(sortFn);
		}

		if (downloadedItems?.length) {
			downloadedItems.sort(sortFn);
		}

		if (errorItems?.length) {
			errorItems.sort(sortFn);
		}

		return (
			<div>
				<div>
					{Boolean(errorItems && errorItems.length) && (
						<>
							<h4
								style={{
									color: 'red',
								}}
							>
								Errors
							</h4>
							<div>
								{errorItems.map((ei: any) => {
									return (
										<div>
											<a
												title={ei.errorMessage}
												style={{
													color: 'red !important',
												}}
											>
												{ei.reportTitle}
											</a>
										</div>
									);
								})}
							</div>
						</>
					)}
					<h4>New reports</h4>
					<div>
						{Boolean(undownloadedItems?.length) ? (
							undownloadedItems.map((ui: any) => {
								return (
									<div>
										<a
											onClick={() => downloadQueueItem(ui)}
											style={{
												textDecoration: 'underline',
												cursor: 'pointer',
											}}
										>
											{ui.reportTitle}
										</a>
									</div>
								);
							})
						) : (
							<span>No items.</span>
						)}
					</div>
				</div>
				<div>
					<h4>
						<a
							onClick={() => setShowPreviouslyDownloaded(!showPreviouslyDownloaded)}
							style={{
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
						>
							{showPreviouslyDownloaded ? 'Hide' : 'Show'} Previously downloaded reports ({downloadedItems?.length || 0}
							)
						</a>
					</h4>
					{showPreviouslyDownloaded && (
						<div>
							{Boolean(downloadedItems?.length) ? (
								downloadedItems.map((di: any) => {
									return (
										<div>
											<a href={di.reportLink} target="_blank">
												{di.reportTitle}
											</a>
										</div>
									);
								})
							) : (
								<span>No items.</span>
							)}
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div
			style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%', maxHeight: 600, overflow: 'auto' }}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={props.close}
			>
				<CancelIcon />
			</div>

			{props.userQueue.length ? renderUserQueue() : <div>No current queue items.</div>}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	userQueue: state.user?.authUser?.record?.reportQueue || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	updateUserQueueItem: (params: any) => dispatch(UserActions.updateUserQueueItem(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueModal);
