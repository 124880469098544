import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getMuiTheme } from '../../../../hooks/styles';
import TableColumns from './tableColumns.json';
import { ReportActions, UserActions } from '../../../../redux/actions';
import { FixViewColumns, GetUserTimezone } from '../../../../hooks/functions';
import dataList from '../../../../constants/dataList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

interface TransactionGroupTableProps {
	data?: any;
	totalRecords?: any;
	filter?: any;
	groupName?: any;
	groupBy?: any;
	groupKey?: any;
	searchClicked?: any;
	handleOpen?: any;
	transactionGroupData: any;
	filterChanged: any;
	authUser: any;
	sendOneTimeReportRequest: Function;
	properties: any;
}

const TransactionGroupTable = (props: TransactionGroupTableProps) => {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = React.useState(props.data.length > 0 ? true : false);
	const [tableColumns, setColumns] = React.useState(TableColumns);
	const [columnsOrder, setColumnsOrder] = React.useState(null as any);
	const [pagination, setPaginationData] = React.useState({
		limit: 10,
		page: 0,
	});
	const groupKeyMap: any = {
		cabinet: 'cabinetId',
		product: 'productId',
		lot: 'lot',
		type: 'type',
		serial_no: 'serial_no',
	};

	/*
	 * sends server call to load records on pagination change,
	 * and loads page one when accordion is opened for the first time
	 */
	const changePage = (paging: any) => {
		const groupFilter = getGroupFilter(paging);
		if (groupFilter.date) {
			dispatch(ReportActions.getTransactionGroupLog({ filter: groupFilter, groupKey: props.groupKey }));
		}
	};

	const getGroupFilter = (paging: any) => {
		const groupFilter = Object.assign({}, props.filter);
		groupFilter.order = columnsOrder ? columnsOrder : 'date desc';
		groupFilter.paging = paging;
		groupFilter[groupKeyMap[props.groupBy]] = props.groupKey;
		groupFilter.groupBy = null;
		return groupFilter;
	};

	const onTableChange = (action: any, tableState: any) => {
		switch (action) {
			case 'changePage':
				changePage({ limit: pagination.limit, page: tableState.page + 1 });
				setPaginationData({ limit: pagination.limit, page: tableState.page });
				break;
			case 'changeRowsPerPage':
				changePage({ limit: tableState.rowsPerPage, page: tableState.page + 1 });
				setPaginationData({ limit: tableState.rowsPerPage, page: tableState.page });
				break;
		}
	};

	const formatLog = (log: any[]) => {
		const output = log.map((el: any) => {
			const datetime = `${el.date.split('T')[0]} ${el.time}`;
			const timestamp = moment.utc(datetime, 'YYYY/MM/DD HH:mm:ss');
			el.timestamp = timestamp.local().toISOString(true).replace('.000', '');
			return el;
		});
		return output;
	};

	const getGroupTitle = () => {
		let groupType = props.groupBy;

		if (props.groupBy === 'type') {
			groupType = 'action';
		} else if (props.groupBy === 'serial_no') {
			groupType = 'serial number';
		}

		if (['product', 'cabinet'].includes(props.groupBy)) {
			return `${groupType}: ${props.groupName || ''} (${props.groupKey})`;
		}

		return `${groupType}: ${props.groupKey}`;
	};

	React.useEffect(() => {
		setExpanded(false);
	}, [props.groupBy]);

	React.useEffect(() => {
		setExpanded(false);
	}, [props.filterChanged]);

	return (
		<Accordion
			square={true}
			expanded={expanded}
			onChange={() => {
				setExpanded(!expanded);

				if (!expanded) {
					changePage({
						limit: 10,
						page: 1,
					});
				}
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`panel-${props.groupKey}`}
				id={`panel-${props.groupKey}`}
			>
				<h4>View transactions for {getGroupTitle()}</h4>
			</AccordionSummary>
			<AccordionDetails>
				{expanded && (
					<MUIDataTable
						data={formatLog(props.data)}
						columns={tableColumns}
						options={{
							onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
								const filter = getGroupFilter({
									limit: 9999,
									page: 0,
								});

								const columnsRequested: string[] = [];

								cols.forEach((tc: any) => {
									if (tc.display === 'true' || tc.display === true) {
										columnsRequested.push(tc.name);
									}
								});

								const params = {
									filter_object_from_ui: filter,
									report_type: 'RNI_UIEXPORT_TransactionLogReport',
									report_frequency: 'OneTime',
									delivery_method: 'UserQueue',
									file_type: 'csv',
									user_name: props.authUser?.record.email,
									recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
									customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
									customers_reported_on_name: undefined,
									timezone: GetUserTimezone(props.properties),
									additional_filters: undefined,
									custom_start_date: undefined,
									custom_end_date: undefined,
									origin_application: 'RNI',
									report_additional_metadata: {
										columnsRequested: columnsRequested,
									},
								};

								props.sendOneTimeReportRequest(params);
								return false;
							},
							onColumnSortChange: (changedColumn: string, direction: string) => {
								if (changedColumn === 'timestamp') {
									changedColumn = 'date';
								}
								const newColumnsOrder = `${changedColumn} ${direction}`;
								setColumnsOrder(newColumnsOrder);
								changePage(pagination);
							},
							onRowClick: props.handleOpen,
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							download: true,
							print: true,
							selectableRows: 'none',
							serverSide: true,
							count: props.totalRecords ? props.totalRecords : 0,
							search: false,
							page: pagination.page,
							onColumnViewChange: (changedColumn: string, direction: string) => {
								setColumns(FixViewColumns(changedColumn, direction, tableColumns));
							},
							rowsPerPage: pagination.limit,
							rowsPerPageOptions: dataList.PageLimitOptions,
							textLabels: {
								body: {
									noMatch: props.totalRecords === 0 ? 'Sorry, no matching records found' : 'Loading records...',
								},
							},
							onTableChange: (action: any, tableState: any) => onTableChange(action, tableState),
						}}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

const mapStateToProps = (state: any) => ({
	transactionGroupData: state.reports.transactionGroups,
	authUser: state.user.authUser,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});
const mapDispatchToProps = (dispatch: any) => ({
	getTransactionGroupLog: (payload: any) => dispatch(ReportActions.getTransactionGroupLog(payload)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionGroupTable);
