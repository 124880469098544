import { startCase } from 'lodash';
import DataList from '../../constants/dataList';

export const formatCabinetPropertyKey = (key: string) => {
	if (key === 'mbrxPracticeId') return 'MinibarRx Practice Id';
	return startCase(key);
};

export const formatCabinetPropertyValue = (key: string, value: string) => {
	value = value.toString();
	if (['consignment', 'locationTracking'].includes(key)) {
		return value === '1' ? 'True' : 'False';
	}
	if (key === 'connectivityStatus') {
		const statusObj: any = DataList.ConnectivityStatus.find((conStatus: any) => {
			return conStatus.value === value;
		});
		return statusObj?.label || value;
	}

	return value;
};
