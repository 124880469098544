import Api from '../lib/api';
import { REPORTS } from '../constants/api.url';

type DashboardTypes = 'OPERATIONS' | 'SERVICES' | 'CUSTOMER'; 

export class DashboardService {
	private static instance: DashboardService;
	private constructor() {}

	public static getInstance(): DashboardService {
		if (!DashboardService.instance) {
			DashboardService.instance = new DashboardService();
		}
		return DashboardService.instance;
	}

	public getDashboardValues = async (dashboardType: DashboardTypes): Promise<any> => {

		let url = '';
		if(dashboardType === 'OPERATIONS') url = REPORTS.DASHBOARD.OPERATIONS;
		if(dashboardType === 'SERVICES') url = REPORTS.DASHBOARD.SERVICES;
		if(dashboardType === 'CUSTOMER') url = REPORTS.DASHBOARD.CUSTOMER;

		return await Api.get(url)
			.catch(e => {return e});
	};

}
