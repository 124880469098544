import moment from 'moment';
import React from 'react';

export const CartridgeInventoryRenderStatus = (value: any, tableMeta: any) => {
	const rowData = {
		thawComplete: tableMeta.rowData[9],
		shelfLifeExp: tableMeta.rowData[10],
		expiration: tableMeta.rowData[11],
	};

	if (!rowData.thawComplete && moment().diff(moment(rowData.expiration), 'days') > 0) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: 'red',
					borderRadius: '4px',
				}}
			>
				Lot Expired
			</div>
		);
	}

	if (!rowData.thawComplete && moment().diff(moment(rowData.expiration), 'days') <= 0) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: '#62ca62',
					borderRadius: '4px',
				}}
			>
				OK
			</div>
		);
	}

	const thawTimestamp = rowData.thawComplete.replace('T', ' ').replace('Z', '');
	const thawDateTime = moment.utc(thawTimestamp, 'YYYY-MM-DD HH:mm:ss');
	const minutesToThaw =
		moment.utc().diff(thawDateTime, 'minutes') < 0 ? Math.abs(moment.utc().diff(thawDateTime, 'minutes')) : 0;
	const selfLifTimestamp = rowData.shelfLifeExp?.replace('T', ' ').replace('Z', '');
	const shelfLife = moment.utc(selfLifTimestamp, 'YYYY-MM-DD HH:mm:ss');
	const shelfLifeMinutes =
		moment.utc().diff(shelfLife, 'minutes') < 0 ? Math.abs(moment.utc().diff(shelfLife, 'minutes')) : 0;
	const shelfLifeHours = Math.floor(shelfLifeMinutes / 60);

	if (shelfLifeMinutes === 0) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: 'red',
					borderRadius: '4px',
				}}
			>
				Shelf Life Expired
			</div>
		);
	}

	if (minutesToThaw === 0 && shelfLifeHours <= 36) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: '#dede00',
					borderRadius: '4px',
				}}
			>
				{' '}
				Expiring Soon: {shelfLife.fromNow()}
			</div>
		);
	}

	if (minutesToThaw === 0) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: '#62ca62',
					borderRadius: '4px',
				}}
			>
				Thawed
			</div>
		);
	}

	if (minutesToThaw > 0) {
		return (
			<div
				style={{
					fontWeight: 'bold',
					width: 'fit-content',
					padding: '4px 5px',
					backgroundColor: '#16bfd0',
					borderRadius: '4px',
				}}
			>
				Frozen
			</div>
		);
	}
};
