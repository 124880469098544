import * as React from 'react';
import Loading from '../../components/loading';
import { Grid, Paper, Modal } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import DashboardTableChart from './dashboard-charts/dashboard-table.chart';
import modalSettingsJson from './dashboard-charts/chart.settings.json';
import DashboardPieChart from './dashboard-charts/dashboard-pie-chart.chart';
import DashboardBarGraphSideChart from './dashboard-charts/dashboard-bar-graph-side.chart';
import DashboardBarGraphChart from './dashboard-charts/dashboard-bar-graph.chart';
import { DashboardStyles, globalColors } from '../../hooks/styles';
import CancelIconSharp from '@material-ui/icons/CancelSharp';
import CheckCircleIconSharp from '@mui/icons-material/CheckCircleSharp';

interface ChartSettings {
    summaryName: string,
    displayName: string,
    chartType?: ChartTypes | null, // Type of chart to display
    columns?: ColumnOptions[], // Column options for display on tables
    placement?: PlacementOptions, // where on the screen in the UI should chart be displayed.
    modalPopUp: boolean // Whether a modal is to be applied or not
}

type ChartTypes = 'table' | 'pieChart' | 'barGraph' | 'barGraphSide';
type PlacementOptions = 'top' | 'center' | 'bottom';

interface ColumnOptions {
    label: string
    name: string
    change: boolean
    viewColumns: boolean
    display: boolean
}

interface DashboardResults {
    rejected: Rejected
    fulfilled: Fulfilled
}

interface Rejected {
    total: number
    results: any[]
}

interface Fulfilled {
    total: number
    results: FulfilledResult[]
}

interface FulfilledResult {
    summaryName: string
    summaryTotals?: {
        currentDayRecords?: number,
        totalRecords: number
        jobTotals?: {
            inProgressJobs: number
            failedJobs: number
            successfulJobs: number
        }
    }
    connection?: string,
    records?: { [key: string]: any }[],
    dateOfData: string
}

const DashboardComponent: React.FC<any> = (props: any) => {

    const [chartType, setChartType] = React.useState<string | null | undefined>(null);
    const [modalRecords, setChartRecords] = React.useState<{ [key: string]: any }[] | null>(null);
    const [modalSettings, setChartSettings] = React.useState<{ [key: string]: any } | null>(null);
    const dashboardStyling = DashboardStyles();
    const [dashboardRole, setDashboardRole] = React.useState<string | undefined>(undefined);
    const [dashboardType, setDashboardType] = React.useState<'OPERATIONS' | 'SERVICES' | 'CUSTOMER' | undefined>();
    const [open, setChartOpen] = React.useState(false);

    React.useEffect(() => {
        let dType: 'OPERATIONS' | 'SERVICES' | 'CUSTOMER' | undefined;
        if(dashboardRole === 'dashboard_operations') dType = 'OPERATIONS';
        if(dashboardRole === 'dashboard_services') dType = 'SERVICES';
        if(dashboardRole === 'dashboard_customer') dType = 'CUSTOMER';

        if(dType) setDashboardType(dType);
    }, [dashboardRole])

    React.useEffect(() => {
        const currentUser = props.authUser.record;
        const roles = currentUser && currentUser.claims ? currentUser.claims[Object.keys(currentUser.claims)[0]] : null;

        const dashboardRoles = ['dashboard_operations', 'dashboard_services', 'dashboard_customer']
        const role = dashboardRoles.find(el => Object.keys(roles).includes(el));

        if (role) setDashboardRole(role);
    }, [props.authUser.record]);

    const handleOpen = () => {
        if (!open) setChartOpen(true);
    };

    const handleChartClose = () => {
        setChartOpen(false);
    };

    const showLoadingMsg = () => !props.authUser && dashboardValues.fulfilled.results.length === 0;

    const Card = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center'
    }));

    // Settings maintained here: src/pages/dashboard/dashboard-modals/modal.settings.json
    const itemSettings = modalSettingsJson as ChartSettings[];
    const dashboardValues = props.dashboardValues as DashboardResults;

    function handleCenterCardDisplay(itemSetting: ChartSettings, record: FulfilledResult) {

        const cardTotals = getCardStatusTotals(record);

        const cardSettings: { [key: string]: any } = {};
        if (cardTotals.status === 'Warning') cardSettings.backgroundColor = globalColors.WARNING_YELLOW;
        if (cardTotals.status === 'Fail') cardSettings.backgroundColor = globalColors.TABLE_WARNING;

        if (itemSetting.modalPopUp) {
            cardSettings.cursor = 'pointer';
            return (
                // Chart associated with summary
                <Card sx={cardSettings} onClick={() => {
                    handleOpen();
                    setChartRecords(record.records || []);
                    setChartType(itemSetting.chartType);
                    setChartSettings(itemSetting);
                }
                } elevation={4}>
                    {itemSetting.displayName}
                    <div className={dashboardStyling.cardItem}>
                        {cardTotals.display}
                    </div>
                </Card>
            )
        }

        // Default card item
        return (
            <Card sx={cardSettings} elevation={4}>
                {itemSetting.displayName}
                <div className={dashboardStyling.cardItem}>
                    {cardTotals.display}
                    {itemSetting.chartType === 'pieChart' && < DashboardPieChart records={record.records} settings={itemSetting} />}
                </div>
            </Card>
        )

    }

    // Define custom display of card and/or the status to color the card if desired
    function getCardStatusTotals(record: FulfilledResult) {

        // Default totals to return if no custom formatting needed
        const totals: { display: string | number, status: 'Pass' | 'Warning' | 'Fail' } = {
            display: Intl.NumberFormat("en-US")
                .format(record.summaryTotals?.currentDayRecords ?? record.summaryTotals?.totalRecords ?? 0),
            status: 'Pass'
        }

        if (record.summaryName === 'jobStatuses') {
            const jobTotals = record.summaryTotals?.jobTotals;
            const totalJobs = record.summaryTotals?.totalRecords;

            if (jobTotals && totalJobs) {

                if (jobTotals.inProgressJobs) totals.status = 'Warning';
                if (jobTotals.failedJobs > 0) totals.status = 'Fail';

                const incompleteJobs = jobTotals.failedJobs + jobTotals.inProgressJobs;
                totals.display = `${totalJobs - incompleteJobs}/${totalJobs}`
            }
        }

        return totals;
    }

    function getTitle(roleName?: string) {
        const roleTitles = [
            { role: 'dashboard_operations', title: 'RNI Operations' },
            { role: 'dashboard_services', title: 'RNI Services' },
            { role: 'dashboard_customer', title: 'Customer' }
        ];

        return roleTitles.find(el => el.role === roleName)?.title ?? '';
    }

    return (
        <div>
            <div className={dashboardStyling.dashboardTitle}>{getTitle(dashboardRole)}</div>
            {showLoadingMsg() ? (
                <div>
                    <Loading message="" />
                </div>
            ) :
                <div>
                    {/* Top Level Summaries */}
                    <Grid container spacing={2}>
                        {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                            const itemSetting = itemSettings.find(setting =>
                                setting.summaryName === el.summaryName
                                && setting.placement === 'top'
                            );

                            if (itemSetting) {
                                return (
                                    <Grid item key={el.summaryName} xs={1}>
                                        <Card elevation={4}>
                                            <div className={dashboardStyling.cardItemWithIcon}>
                                                {el.connection === 'Success' ?
                                                    <CheckCircleIconSharp style={{ color: 'green' }} fontSize='small'></CheckCircleIconSharp> :
                                                    <CancelIconSharp style={{ color: 'red' }} fontSize='small'></CancelIconSharp>
                                                }
                                                {itemSetting.displayName}
                                            </div>
                                        </Card>
                                    </Grid>
                                )
                            }

                        })
                        }

                    </Grid>
                    <br></br>
                    {/* Center level summaries */}
                    <Grid container spacing={2} >
                        {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                            const itemSetting = itemSettings.find(setting =>
                                setting.summaryName === el.summaryName
                                && (!setting.placement || setting.placement === 'center')
                            );

                            if (itemSetting) {
                                return (
                                    <Grid item key={el.summaryName} xs={12} sm={6} md={3} lg={3} xl={2}>
                                        {handleCenterCardDisplay(itemSetting, el)}
                                    </Grid>
                                )
                            }

                        },
                        )}
                    </Grid>
                    <br></br>
                    {/* Bottom Level Summaries */}
                    <Grid container spacing={3}>
                        {dashboardValues.fulfilled.results && dashboardValues.fulfilled.results.map((el) => {

                            const itemSetting = itemSettings.find(setting =>
                                setting.summaryName === el.summaryName
                                && setting.placement === 'bottom'
                            );

                            if (itemSetting) {
                                return (
                                    <Grid item key={el.summaryName} xs={3}>
                                        <Card elevation={4}>
                                            <div className={dashboardStyling.cardItem}>
                                                {itemSetting.chartType === 'pieChart' && < DashboardPieChart records={el.records} settings={itemSetting} animation={false} />}
                                            </div>
                                        </Card>
                                    </Grid>
                                )
                            }

                        })
                        }

                    </Grid>
                </div>
            }
            <Modal
                open={open}
                onClose={() => handleChartClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div>
                    {chartType === 'table' && < DashboardTableChart records={modalRecords} settings={modalSettings} properties={props.properties} closeChart={handleChartClose} />}
                    {chartType === 'pieChart' && < DashboardPieChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                    {chartType === 'barGraphSide' && < DashboardBarGraphSideChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                    {chartType === 'barGraph' && < DashboardBarGraphChart records={modalRecords} settings={modalSettings} closeChart={handleChartClose} />}
                </div>
            </Modal>
        </div>
    );
}

export default DashboardComponent;
