import { getCabinetTypeName } from './GetCabinetTypeName';

export const formatTitle = (data: any) => {
	if (data.Customer_ID) {
		return `${data.Customer_ID} - ${data.Customer_Name} - ${data.Cabinet_ID}`;
	} else if (data.Cabinet_Type) {
		const displayName = getCabinetTypeName(data.Cabinet_Type);
		return `Device Type: ${displayName}`;
	} else if (data.Lot) {
		return `${data.Lot} - ${data.Product_Item_ID} - ${data.product_name}`;
	} else if (data.Product_Item_ID) {
		return `${data.Product_Item_ID} - ${data.product_name}`;
	}
};
