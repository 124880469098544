import React from 'react';
import { connect } from 'react-redux';
import { Container, Button, Tab, Tabs, Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import BasicInformation from './BasicInformation';
import AuthorizedUsers from './AuthorizedUsers';
import AdditionalDetails from './AdditionalDetails';
import NotificationSettings from './NotificationSettings';
import NotificationActivity from './NotificationActivity';
import CustomerProductListComponent from './customer-products';
import BasePage from '../../common/base';
import { PracticePageStyles } from '../../../hooks/styles';
import { AlertActions } from '../../../redux/actions';
import { getCustomerDetailsPageTitle } from '../../../hooks/functions';
import ProductSettings from './product-settings';

interface CustomerDetailsProps {
	authUser: any;
	resetChannelGroups: any;
}

enum DetailType {
	BasicDetails = 'BasicDetails',
	AdditionalDetails = 'AdditionalDetails',
	SelfManagedProducts = 'SelfManagedProducts',
	ProductSettings = 'ProductSettings',
	NotificationSettings = 'NotificationSettings',
	NotificationActivity = 'NotificationActivity',
	Users = 'Users',
}

const CustomerDetailsPage = (props: CustomerDetailsProps) => {
	const classes = PracticePageStyles();
	const history = useHistory();
	const [detailType, setDetailType] = React.useState<DetailType>(DetailType.BasicDetails);
	const { customerId } = useParams() as any;

	const handleTabChange = (event: any, newValue: string) => {
		if (newValue === detailType) {
			return;
		}
		if (newValue === 'NotificationSettings') {
			props.resetChannelGroups();
		}
		setDetailType(newValue as DetailType);
	};

	const canViewAuthorizedUsers = () => {
		if (!props.authUser) {
			return false;
		}
		return props.authUser.authorizationCheck({
			action: 'read',
			resource: 'users',
			tenantId: customerId,
		});
	};

	const canViewProductSettings = () => {
		if (!props.authUser) {
			return false;
		}
		return props.authUser.authorizationCheck({
			action: 'read',
			resource: 'products',
			tenantId: 'ALL',
		});
	};

	const canViewNotifications = () => {
		if (!props.authUser) {
			return false;
		}
		return props.authUser.authorizationCheck({
			action: 'read',
			resource: 'alerts',
			tenantId: customerId,
		});
	};

	const canChangeNotifications = () => {
		if (!props.authUser) {
			return false;
		}
		return props.authUser.authorizationCheck({
			action: 'create',
			resource: 'alerts',
			tenantId: customerId,
		});
	};

	const goBack = () => {
		if (props.authUser.record.claims['ALL']) {
			history.push('/customer-list');
			return;
		}
		history.push('/accounts');
	};

	return (
		<BasePage title={getCustomerDetailsPageTitle(props.authUser)}>
			<div>
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					{(props.authUser.record.claims['ALL'] || Object.keys(props.authUser.record.claims).length > 1) && (
						<div className={classes.appBarSpacer}>
							<Button className="backButton" onClick={goBack} variant="contained" color="primary">
								Back
							</Button>
						</div>
					)}
					{props.authUser ? (
						<div>
							<Paper>
								<Tabs
									value={detailType}
									textColor="primary"
									onChange={handleTabChange}
									variant="scrollable"
									scrollButtons="on"
									aria-label="scrollable tabs"
								>
									<Tab className={classes.customerDetailsTab} value="BasicDetails" label="Basic Information" />
									<Tab className={classes.customerDetailsTab} value="AdditionalDetails" label="Billing Information" />
									{canViewProductSettings() && (
										<Tab className={classes.customerDetailsTab} value="ProductSettings" label="Par Settings" />
									)}
									{canViewNotifications() && (
										<Tab
											className={classes.customerDetailsTab}
											value="NotificationActivity"
											label="Notification Activity"
										/>
									)}
									{canChangeNotifications() && (
										<Tab
											className={classes.customerDetailsTab}
											value="NotificationSettings"
											label="Notification Settings"
										/>
									)}
									{canViewAuthorizedUsers() && (
										<Tab className={classes.customerDetailsTab} value="Users" label="Users" />
									)}
								</Tabs>
								{detailType === DetailType.BasicDetails && <BasicInformation />}
								{detailType === DetailType.AdditionalDetails && <AdditionalDetails />}
								{detailType === DetailType.ProductSettings && <ProductSettings />}
								{detailType === DetailType.NotificationActivity && <NotificationActivity />}
								{detailType === DetailType.NotificationSettings && <NotificationSettings />}
								{canViewAuthorizedUsers() && detailType === DetailType.Users && <AuthorizedUsers />}
							</Paper>
							<div className={classes.detailPageSpacer} />
						</div>
					) : (
						''
					)}
				</Container>
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {
		authUser: state.user.authUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetChannelGroups: () => dispatch(AlertActions.setNotificationChannelGroupsAction(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage);
