import * as React from 'react';
import { FormControl, Grid, Paper, TextField } from '@material-ui/core';
import { CommonStyles, getModalStyle, getModalClasses } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import CancelIcon from '@material-ui/icons/Cancel';
import { IncidentButton, IncidentIgnoreTimePicker } from '../../components/incidents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addMinutes } from 'date-fns';
import { UserTimezoneFormat } from '../../hooks/functions';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const IncidentModal: React.FC<any> = (props: any) => {
	const commonStyles = CommonStyles();
	const modalClasses = getModalClasses();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [comment, setComment] = React.useState<any>('');
	const [ignoreExpiration, setIgnoreExpiration] = React.useState<MaterialUiPickersDate>(
		addMinutes(new Date(), 30) as any,
	);
	const [showAlertDetails, setShowAlertDetails] = React.useState(false);

	const getButtons = () => {
		if (props.incident.incidentState === 'Closed') {
			return '';
		}
		let statusButtons: any[] = [];
		if (props.incident.incidentState === 'Open') {
			statusButtons = [
				{ label: 'Acknowledge', value: 'Acknowledged' },
				{ label: 'Close', value: 'Closed' },
				{ label: 'Ignore', value: 'Ignored' },
			];
		}
		if (props.incident.incidentState === 'Ignored') {
			statusButtons = [
				{ label: 'Acknowledge', value: 'Acknowledged' },
				{ label: 'Close', value: 'Closed' },
			];
		}
		if (props.incident.incidentState === 'Acknowledged') {
			statusButtons = [{ label: 'Close', value: 'Closed' }];
		}
		return (
			<div style={{ display: 'flex' }}>
				{statusButtons.map((status: any, index: number) => (
					<IncidentButton
						key={index}
						text={`${status.label}`}
						styles={commonStyles.searchButton}
						onButtonClick={() => {
							props.updateIncident(
								{
									incidentId: props.incident.id,
									body: {
										state: status.value,
										comments: comment || `Incident ${status.value}.`,
										ignoreExpiration: status.value === 'Ignored' ? ignoreExpiration : undefined,
									},
								},
								'status',
							);
						}}
					/>
				))}
				{statusButtons.some(button => button.label === 'Ignore') && (
					<IncidentIgnoreTimePicker ignoreExpiration={ignoreExpiration} setIgnoreExpiration={setIgnoreExpiration} />
				)}
			</div>
		);
	};

	const formatHistoryEntryComment = (entry: any) => {
		const comment = entry.comments.split(' ');
		if (entry.incidentState === 'Ignored' && comment.length > 2) {
			const timestamp = comment.pop();
			return `${comment.join(' ')} ${UserTimezoneFormat(props.properties, timestamp)}`;
		}
		return entry.comments;
	};

	React.useEffect(() => {
		setComment(null);
	}, [props.history]);

	return (
		<div
			style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%', maxHeight: 600, overflow: 'scroll' }}
			className={modalClasses.paper}
		>
			<div
				style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
				onClick={props.close}
			>
				<CancelIcon />
			</div>
			<h2>
				{props.incident.alertType} - Severity: {props.incident.severity}
			</h2>
			<p>
				[{props.getLocalTimestamp(props.incident.createdAt)}]: {props.incident.sendMessage}
			</p>
			<div>
				<div>
					Current Status: <b>{props.incident.incidentState}</b>
				</div>
				{props.incident.alertDetails && props.incident.alertDetails.length > 0 && (
					<div>
						<Grid
							style={{ cursor: 'pointer' }}
							onClick={() => setShowAlertDetails(!showAlertDetails)}
							container
							direction="row"
							alignItems="center"
						>
							<span>Alert Details</span> {showAlertDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</Grid>
						{showAlertDetails && <pre>{JSON.stringify(props.incident.alertDetails, null, 4)}</pre>}
					</div>
				)}
			</div>
			<div style={{ margin: '20px 0 0 0' }}>
				<FormControl style={{ width: '100%' }}>
					<TextField
						label="Comment"
						placeholder="Leave a comment (optional)"
						variant="outlined"
						multiline
						rows={2}
						rowsMax={2}
						onChange={e => {
							setComment(e.target.value);
						}}
					/>
				</FormControl>
			</div>
			<div style={{ margin: '10px 0 0' }}>{getButtons()}</div>
			<div style={{ margin: '10px 0 0' }}>
				<IncidentButton
					text={`${props.incident.incidentState === 'Closed' ? 'Add Comment' : 'Comment Only'}`}
					styles={commonStyles.searchButton}
					onButtonClick={() => {
						if (!comment) {
							return;
						}
						props.updateIncident(
							{
								incidentId: props.incident.id,
								body: {
									state: props.incident.incidentState,
									comments: comment,
								},
							},
							'comment',
						);
					}}
				/>
			</div>
			{props.history && props.history.length > 0 && (
				<div>
					<h3 style={{ marginBottom: 5 }}>History:</h3>
					<Grid container>
						<Grid item xs={12}>
							{props.history.map((historyEntry: any, index: number) => (
								<Paper key={index} elevation={0} style={{ padding: 5, marginBottom: 10 }}>
									<b>{historyEntry.incidentState}</b> - {props.getLocalTimestamp(historyEntry.createdAt)}
									<br />
									{formatHistoryEntryComment(historyEntry)} <br />
									{historyEntry.createdBy} <br />
								</Paper>
							))}
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

export default connect(mapStateToProps)(IncidentModal);
