import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import { FixViewColumns, formatTitle, SyncCartridgeInventoryColumns, OnDownloadInventory } from '../../hooks/functions';
import moment from 'moment';
import { InventoryTableTheme } from './inventory-management.styles';
import { globalColors } from '../../hooks/styles/muiTheme';

const CartridgeInventoryGroupTable: React.FC<any> = props => {
	const [tableColumns, setTableColumns] = React.useState(props.columns);

	React.useEffect(() => {
		SyncCartridgeInventoryColumns(props, setTableColumns);
	}, [props.columns, props.tablePersist]);

	return (
		<div>
			<MUIDataTable
				title={<h3>{props.data ? formatTitle(props.data) : ''}</h3>}
				columns={tableColumns}
				data={props.inventory}
				options={{
					rowsPerPageOptions: dataList.PageLimitOptions,
					onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
						if (!props.inventory?.length) return false;

						const groupByName = props.groupBy;
						let groupByVal = '';

						if (groupByName) {
							groupByVal = props.inventory[0][groupByName]; //grab this group's identifier from the first item (it should be the same for them all)
						}

						props.onGetCsv(cols, groupByName, groupByVal);
						return false;
					},
					textLabels: {
						body: {
							noMatch: props.tableMessage,
						},
					},
					selectableRows: 'none',
					filterType: 'multiselect',
					filter: false,
					search: false,
					setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
						if (!props.inventory[dataIndex].expiration) {
							return;
						}
						const expiration = moment(props.inventory[dataIndex].expiration, 'YYYY-MM-DD');
						if (moment().diff(expiration, 'day') >= 0) {
							return { style: { backgroundColor: globalColors.TABLE_WARNING } };
						}
					},
					onRowClick: props.onRowClick,
					onColumnViewChange: (changedColumn: string, direction: string) => {
						setTableColumns(FixViewColumns(changedColumn, direction, tableColumns));
					},
				}}
			/>
		</div>
	);
};

export default CartridgeInventoryGroupTable;
