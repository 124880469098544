import { call, put, delay } from 'redux-saga/effects';
import { OrderActions, UIAction, UserActions } from '../actions/index';
import { OrdersService, TenantsService } from '../services';
import { Constants } from '../../constants/Constants';

export function* getOrdersList(action: ReturnType<typeof OrderActions.getOrderList>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(OrdersService.getInstance().getOrders, action.payload);
		yield put(UIAction.showLoader(false));
		if (action.payload.options?.type == 'csv') {
			var blob = new Blob([data]);
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'restock_orders_report.csv';
			link.click();
			return;
		}
		yield put(OrderActions.setOrderList(data));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(OrderActions.setOrderList(null));
		console.log('order saga error', err);
		yield handleOrdersError(err);
	}
}

export function* getGroupedOrdersList(action: ReturnType<typeof OrderActions.getGroupedOrderList>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(OrdersService.getInstance().getGroupedOrders, action.payload);
		yield put(UIAction.showLoader(false));
		if (action.payload.options?.type == 'csv') {
			var blob = new Blob([data]);
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'grouped_restock_orders_report.csv';
			link.click();
			return;
		}
		yield put(OrderActions.setGroupedOrderList(data));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(OrderActions.setOrderList(null));
		console.log('order saga error', err);
		yield handleOrdersError(err);
	}
}

export function* getShipments(action: ReturnType<typeof OrderActions.getShipments>) {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		const { data } = yield call(OrdersService.getInstance().getShipments, action.payload);
		yield put(OrderActions.setShipments(data));
		yield put(UIAction.showLoader(false));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield put(OrderActions.setShipments(null));
		console.log('Shipment saga error', err);
		yield handleOrdersError(err);
	}
}

function* handleOrdersError(err: any) {
	yield put(UIAction.showLoader(false));
	const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

	yield put(
		UserActions.setSnackbarMessage({
			message: `An error occurred: ${errorMessage} in Orders saga`,
			type: 'error',
		}),
	);
}

export function* updateRestockRequestStatus(action: ReturnType<typeof OrderActions.updateRestockOrderStatus>) {
	try {
		yield call(OrdersService.getInstance().updateOrderStatus, action.payload);
		action.payload.success();
	} catch (err) {
		action.payload.error();
		console.log('Update Restock Request Status saga error', err);
	}
}

export function* getRecommendations(action: ReturnType<typeof OrderActions.getRecommendations>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const recommendationType = action.payload.recommendationType ? action.payload.recommendationType : 'recommendations';
		delete action.payload.recommendationType;
		let results: any = null;
		let inventoryExecptionsTotal: any = null
		if (recommendationType === 'recommendations') {
			const { data } = yield call(OrdersService.getInstance().getRecommendations, action.payload);
			results = data;
			yield put(OrderActions.setRecommendationsTotal(data.totalRecords));
		} else {
			const { data } = yield call(OrdersService.getInstance().getInventoryExceptions, action.payload);
			results = data;
			yield put(OrderActions.setInventoryExceptionsTotal(data.totalRecords));
		}
		yield put(OrderActions.setRecommendations(results));

		const exclusionsParams: any[] = action.payload.customerId ? [{ key: 'customerId', value: action.payload.customerId}] : []
		const exclusionsResponse: {data: any[]} = yield call(OrdersService.getInstance().getOrderExclusions, exclusionsParams);
		yield put(OrderActions.setOrderExclusions(exclusionsResponse.data || []));

		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		yield put(OrderActions.setRecommendations([]));
		console.log('Get restock recommendations saga error', err);

		let errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		errorMessage = `An error occurred: ${errorMessage} in getRecommendations`
		if (err?.response?.status === 504) {
			errorMessage = `Getting recommendations took longer than expected.  Please click search to try again.`
		}
		yield put(
			UserActions.setSnackbarMessage({
				message: errorMessage,
				type: 'error',
			}),
		);
	}
}

export function* releaseOrders(action: ReturnType<typeof OrderActions.releaseOrders>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(OrdersService.getInstance().releaseOrders, action.payload.requestBody);
		yield put(
			UserActions.setSnackbarMessage({
				message: `The order release process has been initiated. You will receive an email with a summary of results.`,
				type: 'info',
			}),
		);
	} catch (err: any) {
		console.log('Release Orders saga error', err);
		let errorMessage = `An error occurred:${err.message}`
		if (err?.response?.status === 504) {
			errorMessage = `Orders were released but refreshing recommendations took longer than expected.  Please click search to try again.`
		}
		yield put(
			UserActions.setSnackbarMessage({
				message: errorMessage,
				type: 'error',
			}),
		)
	}
	action.payload.onComplete();
	yield put(UIAction.showLoader(false));
}

export function* getCustomerProductsForOrder(action: ReturnType<typeof OrderActions.getCustomerProductsForOrder>) {
	try {
		const { data } = yield call(OrdersService.getInstance().getCustomerProductsForOrder, action.payload.customerId, action.payload.params);
		yield put(OrderActions.setCustomerProductsForOrder(data));
		if (data.restockRecommendations.length === 0) {
			throw new Error('This customer does not have any products to restock.');
		}
		action.payload.onSuccess(data)
	} catch (err: any) {
		console.log(JSON.stringify(err))
		yield put(UIAction.showLoader(false));
		yield put(OrderActions.setCustomerProductsForOrder([]));
		console.log('Get customer products for order saga error', err);
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : err.message;
		action.payload.onError(errorMessage)
	}
}

export function* syncOrderExclusions(action: ReturnType<typeof OrderActions.syncOrderExclusions>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		yield call(OrdersService.getInstance().syncOrderExclusions);
		yield delay(8000);
		const exclusionsParams: any[] = action.payload.customerId ? [{ key: 'customerId', value: action.payload.customerId}] : []
		const exclusionsResponse: {data: any[]} = yield call(OrdersService.getInstance().getOrderExclusions, exclusionsParams);
		yield put(OrderActions.setOrderExclusions(exclusionsResponse.data || []));

		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		console.log('Sync and Get restock recommendations saga error', err);

		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in syncOrderExclusions`,
				type: 'error',
			}),
		);
	}
}

export function* getParRecord(action: ReturnType<typeof OrderActions.getParRecord>) {
	try {
		yield put(UIAction.showLoader(true));
		const { data } = yield call(TenantsService.getInstance().getTenantProducts, action.payload.filter);
		const parRecord = data?.results && data?.results.length > 0 ?  data.results[0] : null;
		if (!parRecord) {
			throw new Error('There is no par record for this customer/product.');
		}
		yield put(UIAction.showLoader(false));
		action.payload.onSuccess(parRecord);
	} catch (err: any) {
		console.log(JSON.stringify(err))
		yield put(UIAction.showLoader(false));
		console.log('Get par record for order saga error', err);
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : err.message;
		yield put(
			UserActions.setSnackbarMessage({
				message: errorMessage,
				type: 'error',
			}),
		);
	}
}

export function* updateOrder(action: ReturnType<typeof OrderActions.updateOrder>) {
	try {
		yield call(OrdersService.getInstance().updateOrder, action.payload);
		action.payload.success();
	} catch (err) {
		action.payload.error();
		console.log('Update Order saga error', err);
	}
}

export function* checkOrderJobStatus(action: ReturnType<typeof OrderActions.checkOrderJobStatus>) {
	try {
		yield put(UIAction.showLoader(false));
		const { data } = yield call(OrdersService.getInstance().checkJobStatus);
		action.payload.onSuccess(data)
	} catch (err: any) {
		console.log(JSON.stringify(err))
		yield put(UIAction.showLoader(false));
		console.log('Get customer products for order saga error', err);
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : err.message;
		action.payload.onError()
		yield put(
			UserActions.setSnackbarMessage({
				message: errorMessage,
				type: 'error',
			}),
		);
	}
}
