import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Typography, TextField, FormControl, Paper, Grid, InputLabel, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SupportStyles } from './support.styles';
import { SelectBox } from '../../components/selectBox';
import { Constants } from '../../constants/Constants';

interface SupportProps {
	sendSupportRequest: Function;
}

export const SupportComponent: React.FC<SupportProps> = props => {
	const history = useHistory();
	const supportClasses = SupportStyles();

	const [submitterName, setSubmitterName] = React.useState<string>('');
	const [submitterEmail, setSubmitterEmail] = React.useState<string>('');
	const [comments, setComments] = React.useState<string>('');
	const [supportTopic, setSupportTopic] = React.useState<string>('');

	const supportTopicOptions = [
		{ label: 'Restock Request', value: 'restock' },
		{ label: 'New Product Request', value: 'newProduct' },
		{ label: 'Web Portal Issue', value: 'portalBug' },
		{ label: 'Other', value: 'other' },
	];

	const submitSupportRequest = () => {
		props.sendSupportRequest({
			messageBody: `${supportTopic}\n\n From: ${submitterName} ${submitterEmail} \n${comments}`,
		});
	};

	// const openTicketModal = () => {
	//   //history.push('support?report=true');
	//   window.location.href = '/support?report=true';
	// }

	return (
		<Paper
			style={{
				padding: 20,
			}}
		>
			<h2>Contact Support</h2>
			<div>Phone: 800-843-7477</div>
			<div>
				Email:{' '}
				<a href="mailto:support@fffenterprises.com" className={supportClasses.emailLink}>
					support@fffenterprises.com
				</a>
			</div>

			<h2>File A Support Ticket</h2>
			<div>
				<div className={supportClasses.formRowContainer}>
					<FormControl>
						<SelectBox
							inputLabel="Issue Description"
							emptyItemLabel={'Choose'}
							listItems={supportTopicOptions}
							style={{ width: '300px' }}
							onChangeItem={topic => setSupportTopic(topic)}
							selected={supportTopic}
							className={supportClasses.formField}
						/>
					</FormControl>
				</div>

				<div className={supportClasses.formRowContainer}>
					<FormControl>
						<TextField
							InputProps={{
								value: submitterName,
							}}
							label="Your name"
							onChange={({ target }) => {
								setSubmitterName(target.value);
							}}
							variant="outlined"
							className={supportClasses.formField}
						/>
					</FormControl>
				</div>

				<div className={supportClasses.formRowContainer}>
					<FormControl>
						<TextField
							InputProps={{
								value: submitterEmail,
							}}
							label="Your email"
							onChange={({ target }) => {
								setSubmitterEmail(target.value);
							}}
							variant="outlined"
							className={supportClasses.formField}
						/>
					</FormControl>
				</div>

				<div className={supportClasses.formRowContainer}>
					<FormControl>
						<TextField
							label="Comments"
							multiline={true}
							rows={10}
							className={supportClasses.formField}
							InputProps={{
								value: comments,
							}}
							onChange={({ target }) => {
								setComments(target.value);
							}}
							variant="outlined"
						/>
					</FormControl>
				</div>
				<div className={supportClasses.formRowContainer}>
					<FormControl>
						<Button onClick={submitSupportRequest} variant="outlined">
							Submit
						</Button>
					</FormControl>
				</div>
			</div>
			{/*

      <h2>
        Your Open Tickets
      </h2> */}
		</Paper>
	);
};
