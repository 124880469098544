// import { fbAnalytics } from '../../auth/firebase';
// import { logEvent } from "firebase/analytics";

export enum Severities {
	Log = 'log',
	Warn = 'warn',
	Error = 'error'
}

export const SendLog = (
	message: string, 
	severity: Severities = Severities.Log, 
	analyticsEventName?: string,
	otherAnalyticsParams: object = {}
) => {
	console[severity](message);

	//CAUSNG BUILD PROBLEM SO REMOVING FOR NOW~~
	//optional logging to firebase as well as local console
	// if (analyticsEventName) {
	// 	logEvent(fbAnalytics, analyticsEventName, {
	// 		value: message,
	// 		...otherAnalyticsParams
	// 	});
	// }
};