import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { TextField, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TenantsActions } from '../../../redux/actions';
import { Constants } from '../../../constants/Constants';

interface CustomerProps {
	customerList?: any;
	onChangePractice: (practice: any) => void;
	componentKey?: any;
	style?: any;
	value?: any;
	error?: boolean;
	helperText?: string;
	hideLabel?: boolean;
}

const Customer = (props: CustomerProps) => {
	const dispatch = useDispatch();

	const searchPracticeOnTyping = (keyword?: any) => {
		let whereConditions: any = {};
		if (keyword.length > 0) {
			whereConditions = {
				or: [
					{
						name: { like: '%' + keyword + '%' },
					},
					{
						customerId: { like: '%' + keyword + '%' },
					},
				],
			};
		}
		dispatch(
			TenantsActions.getTenantList({
				offset: 0,
				limit: 100,
				skip: 0,
				order: ['id'],
				where: whereConditions,
				fields: {
					id: true,
					customerId: true,
					name: true,
				},
			}),
		);
	};

	const changePracticeName = (event?: any, values?: any) => {
		props.onChangePractice(values);
	};

	const PopperMy = function (props: any) {
		return <Popper {...props} style={{ width: 250, fontSize: '10px!important' }} placement="bottom-start" />;
	};

	return (
		<Autocomplete
			style={props.style}
			PopperComponent={PopperMy}
			key={props.componentKey}
			onChange={changePracticeName}
			id={`combo-box-${props.componentKey}`}
			options={typeof props.customerList != 'undefined' ? props.customerList : []}
			value={props.value || null}
			getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
			renderInput={(params: any) => (
				<TextField
					error={props.error}
					helperText={props.error && (props.helperText ? props.helperText : 'Please choose a customer')}
					onChange={({ target }) => searchPracticeOnTyping(target.value)}
					{...params}
					label={props.hideLabel ? '' : Constants.SEARCH.CUSTOMER_NAME}
				/>
			)}
		/>
	);
};
const mapStateToProps = (state: any) => {
	return {
		customerList: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	};
};

export default connect(mapStateToProps)(Customer);
