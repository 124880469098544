import * as React from 'react';
import {
	makeStyles,
	createStyles,
	Theme,
	Grid,
	FormControl,
	TextField,
	Button,
	MuiThemeProvider,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Link,
	ButtonGroup,
	FormHelperText,
} from '@material-ui/core';
import { CabinetStyles } from './cabinet.styles';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';
import { Autocomplete } from '@material-ui/lab';
import { emailRegex } from '../users/users.helper';

function getModalStyle() {
	return {
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-50%, -50%)`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(2, 4, 3),
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			position: 'absolute',
			boxShadow: theme.shadows[5],
			borderRadius: '3px',
		},
		modalActionError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
	}),
);

const AddCabinetUserModal: React.FC<any> = (props: any) => {
	const modalClasses = useStyles();
	const classes = CabinetStyles();
	const [modalStyle] = React.useState(getModalStyle);
	const [formType, setFormType] = React.useState<string>('new');
	const [newUserErrors, setNewUserErrors] = React.useState<any>({});
	const [selectedUser, setSelectedUser] = React.useState(null as any);
	const [selectedRole, setSelectedRole] = React.useState(null as any);

	const handleNewUserFieldChange = (field: string, value: string) => {
		setNewUserErrors({ ...newUserErrors, [field]: false });
		const newUser = selectedUser ? selectedUser : {};
		newUser[field] = value ? value : '';
		setSelectedUser(newUser);
	};

	const isValid = () => {
		const errors: any = {};

		if (!selectedUser?.firstName) {
			errors.firstName = 'Please enter a first name.';
		}
		if (!selectedUser?.lastName) {
			errors.lastName = 'Please enter a last name.';
		}
		if (!selectedUser?.email || !emailRegex.test(selectedUser.email)) {
			errors.email = 'Please enter valid email.';
		}
		if (!selectedRole) {
			errors.role = 'Please select a cabinet role.';
		}

		setNewUserErrors(errors);

		if (Object.keys(errors).length > 0) {
			return false;
		}

		return true;
	};

	const submit = () => {
		if (!isValid()) {
			return;
		}

		const payload = {
			close: () => {
				props.closeModal();
				props.refreshCabinetUsers();
			},
			cabinetId: props.cabinet.cabinetId,
			role: selectedRole,
			email: selectedUser.email,
			firstName: selectedUser.firstName,
			lastName: selectedUser.lastName,
		};

		props.addCabinetUser(payload);
	};

	const handleCancel = () => {
		props.closeModal();
	};

	const handleFormTypeChange = (value: string) => {
		if (formType !== value) {
			setSelectedUser(null);
			setFormType(value);
			setSelectedRole(null);
			setNewUserErrors({});
		}
	};

	const handleRoleSelect = (value: any) => {
		setSelectedRole(value);
		setNewUserErrors({ ...newUserErrors, role: false });
	};

	const roleSelect = (
		<FormControl error={newUserErrors.role ? true : false} component="fieldset" className={classes.formControl}>
			<FormLabel component="legend">Select Cabinet Role</FormLabel>
			<FormGroup row>
				<>
					<FormControlLabel
						control={
							<Checkbox
								className={classes.checkbox}
								checked={selectedRole === 30}
								onChange={() => handleRoleSelect(30)}
								name="refiller"
							/>
						}
						label="Refiller"
					/>
					<FormControlLabel
						control={
							<Checkbox
								className={classes.checkbox}
								checked={selectedRole === 10}
								onChange={() => handleRoleSelect(10)}
								name="vendee"
							/>
						}
						label="Vendee"
					/>
					{props.isFlexCabinet && (
						<FormControlLabel
							control={
								<Checkbox
									className={classes.checkbox}
									checked={selectedRole === 50}
									onChange={() => handleRoleSelect(50)}
									name="engineer"
								/>
							}
							label="Engineer"
						/>
					)}
				</>
			</FormGroup>
			<FormHelperText>{newUserErrors.role || ''}</FormHelperText>
		</FormControl>
	);

	return (
		<div style={modalStyle} className={modalClasses.paper}>
			<div>
				<h3>
					Add Cabinet User
					<ButtonGroup size="small" aria-label="small outlined button group" style={{ margin: '5px 15px' }}>
						<Button
							style={{ fontSize: '10px' }}
							className={formType === 'new' ? classes.selectedLotState : ''}
							onClick={() => {
								handleFormTypeChange('new');
							}}
						>
							Create New
						</Button>
						<Button
							style={{ fontSize: '10px' }}
							className={formType === 'existing' ? classes.selectedLotState : ''}
							onClick={() => {
								handleFormTypeChange('existing');
							}}
						>
							Select Existing
						</Button>
					</ButtonGroup>
				</h3>
				{!selectedUser && formType === 'existing' && (
					<FormControl className={classes.formControl} error={newUserErrors.firstName ? true : false}>
						<Autocomplete
							id="combo-box-demo"
							options={props.availableUsers?.result || []}
							getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} (${option.email})`}
							onChange={(event: any, newValue: string | null) => {
								setSelectedUser(newValue);
							}}
							style={{ width: 300 }}
							renderInput={params => <TextField {...params} label="Available Users" />}
						/>
						<FormHelperText>{newUserErrors.firstName ? 'Please select an existing user.' : ''}</FormHelperText>
					</FormControl>
				)}
			</div>
			{formType === 'existing' && selectedUser && (
				<div>
					<FormControl className={classes.formControl}>
						<p>
							Adding {selectedUser.firstName} {selectedUser.lastName} ({selectedUser.email}) to Cabinet ID{' '}
							{props.cabinet.cabinetId}
							<br></br>
							<Link href="#" onClick={() => setSelectedUser(null)}>
								Change User
							</Link>
						</p>
					</FormControl>
				</div>
			)}
			{formType === 'existing' && selectedUser && <div>{roleSelect}</div>}
			{formType === 'new' && (
				<div>
					<div>
						<FormControl required variant="outlined" className={classes.formControl}>
							<TextField
								required
								style={{ width: 210 }}
								type="text"
								label="First Name"
								placeholder="Enter First Name"
								variant="outlined"
								error={newUserErrors.firstName ? true : false}
								helperText={newUserErrors.firstName || ''}
								value={selectedUser?.firstName || ''}
								onChange={e => handleNewUserFieldChange('firstName', e.target.value)}
							/>
						</FormControl>
						<FormControl required variant="outlined" className={classes.formControl}>
							<TextField
								required
								style={{ width: 210 }}
								type="text"
								label="Last Name"
								placeholder="Enter Last Name"
								variant="outlined"
								error={newUserErrors.lastName ? true : false}
								helperText={newUserErrors.lastName || ''}
								value={selectedUser?.lastName || ''}
								onChange={e => handleNewUserFieldChange('lastName', e.target.value)}
							/>
						</FormControl>
					</div>
					<div>
						<FormControl required variant="outlined" className={classes.formControl}>
							<TextField
								required
								style={{ width: 210 }}
								type="text"
								label="Email"
								placeholder="Enter Email"
								variant="outlined"
								error={newUserErrors.email ? true : false}
								helperText={newUserErrors.email || ''}
								value={selectedUser?.email || ''}
								onChange={e => handleNewUserFieldChange('email', e.target.value)}
							/>
						</FormControl>
						{roleSelect}
					</div>
				</div>
			)}
			<div>
				{props.apiError && <p style={{ color: '#f44336', fontWeight: 'bold' }}>Failed to add cabinet user.</p>}
				{
					<FormControl className={classes.formControl}>
						<Button
							onClick={submit}
							style={{ margin: '12px 0 0 10px' }}
							type="button"
							className={classes.searchButton}
							variant="contained"
							color="primary"
						>
							Submit
						</Button>
					</FormControl>
				}
				<FormControl className={classes.formControl}>
					<Button
						onClick={handleCancel}
						type="button"
						className={classes.cancelBtn}
						variant="contained"
						color="default"
					>
						Cancel
					</Button>
				</FormControl>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	isLoading: state.ui.isLoading,
	apiError: state.ui.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
	addCabinetUser: (payload: any) => dispatch(CabinetActions.addCabinetUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCabinetUserModal);
