import { CartridgeInventoryRenderStatus, InventoryCustomBodyRenderFunction, UserTimezoneFormat } from '.';
import { InventoryIcons } from './Renderers';

export const GetCartridgeInventoryColumns = (properties: any, suspendedTransactions?: any) => {
	const columnNames = [
		'customerName',
		'customerId',
		'cabinetId',
		'cabinetType',
		'productLabelName',
		'holderOffset',
		'dispenserOffset',
		'lot',
		'salesUoUQty',
		'thawComplete',
		'shelfLifeExp',
		'expiration',
		'ndc542',
		'lotSize',
		'lotAssay',
		'lotUnitOfMeasure',
		''
	];
	const columnLabels = [
		'Customer',
		'Customer Id',
		'Device',
		'Device Type',
		'Product Name',
		'Holder',
		'Dispenser',
		'Lot',
		'On Hand',
		'Thawed At',
		'Shelf Life Exp',
		'Status',
		'NDC',
		'Lot Size',
		'Lot Assay',
		'Lot Unit of Measure',
		''
	];
	return columnNames.map((name, index) => {
		const options: any = {
			//customBodyRender: InventoryCustomBodyRenderFunction,
			sort: true,
			filter: true,
			filterList: null,
			change: true,
			sortOrder: null,
			display: true,
			viewColumns: true,
		};

		if (name === 'cabinetType') {
			options.customBodyRender = InventoryCustomBodyRenderFunction;
		}
		if (name === 'expiration') {
			options.customBodyRender = CartridgeInventoryRenderStatus;
		}

		if (['thawComplete', 'shelfLifeExp'].includes(name)) {
			// set Sort Compare
			return {
				name: name,
				label: columnLabels[index],
				options: {
					filter: false,
					filterList: null,
					change: true,
					display: false,
					viewColumns: true,
					customBodyRender: (value: any) => {
						return value ? UserTimezoneFormat(properties, value) : '';
					},
				},
			};
		}

		if (['lotSize', 'lotAssay', 'lotUnitOfMeasure'].includes(name)) {
			options.display = false;
		}

		if (name === '') {
			options.viewColumns = false
			options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
				if(suspendedTransactions && suspendedTransactions.result){
					const suspendedLots = suspendedTransactions.result.map((transaction: any) => transaction.lot)
					const rowLot = tableMeta.rowData[7]
					return suspendedLots.includes(rowLot) ? InventoryIcons() : ""
				} 
				return ""
			};
		}

		return {
			name: name,
			label: columnLabels[index],
			options: options,
		};
	});
};

export const SyncCartridgeInventoryColumns = (props: any, setTableColumns: any) => {
	let columns = props.columns;
	const tablePersist = props.tablePersist ? props.tablePersist : {};
	if (Object.keys(tablePersist).length > 0) {
		columns.map((column: any, index: any) => {
			const tablePersistCol = tablePersist.columns.find((tableStateCol: any) => {
				return column.name === tableStateCol.name;
			});
			if (tablePersistCol) {
				column.options.display = tablePersistCol.display;
			}
		});
	}
	setTableColumns(columns);
};
