import moment from 'moment';
import { UserProperty } from '../../redux/models/user/user';

export const GetUserTimezone = (properties: UserProperty[]) => {
	const defaultTimezone = moment.tz.guess();

	const timezone_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'TIMEZONE',
	);
	return timezone_key_index > -1 && properties[timezone_key_index].propertyValue != 'LOCAL'
		? properties[timezone_key_index].propertyValue
		: defaultTimezone;
};
