import { connect } from 'react-redux';
import { DashboardActions } from '../../redux/actions';
import DashboardComponent from './dashboard.component';

const mapStateToProps = (state: any) => ({
	dashboardValues: state.dashboard.dashboardValues ? state.dashboard.dashboardValues : [],
	authUser: state.user.authUser,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: []
});

const mapDispatchToProps = (dispatch: any) => ({
	setDashboardValues: (dashboardValues: any) => dispatch(DashboardActions.setAllDashboardValues(dashboardValues.data)),
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
