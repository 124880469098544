import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Button, Grid, Modal } from '@material-ui/core';
import { PracticePageStyles } from '../../../hooks/styles';
import AddCustomer from './add-customer';
import { CustomerListFilter } from '../../../redux/models/tenants/tenants';
import { UIAction } from '../../../redux/actions';
import 'react-daterange-picker/dist/css/react-calendar.css';
import GlobalFiltersComponentContainer from '../../../components/globalFiltersComponent';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

// Current latest release has wrong type definition,
// so I put any casting to avoid errors.
const moment = extendMoment(Moment as any);

interface SearchProps {
	onSearch: () => void;
	filter: CustomerListFilter;
	tenantsList: any;
	authUser: any;
	selectedCustomer: any;
	setSelectedCustomer: (customer: any) => void;
	selectedFilterValues: any;
	selectedDateRange: any;
}

const SearchSection = (props: SearchProps) => {
	const classes = PracticePageStyles();
	const [modalOpen, setModalOpen] = React.useState(false);

	const handleModalClose = (goToDetails: boolean = false) => {
		setModalOpen(false);
		if (goToDetails) {
			// redirect to customer details
		}
	};

	return (
		<div>
			<Grid container>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer pageName="ADMIN_CUSTOMERS" executeSearch={props.onSearch} />

						{props.authUser?.authorizationCheck({ action: 'create', resource: 'tenants', tenantId: 'ALL' }) && (
							<FormControl className={classes.buttonFormControl}>
								<Button
									className={classes.searchButton}
									variant="contained"
									color="secondary"
									onClick={() => {
										setModalOpen(true);
									}}
								>
									Add Customer
								</Button>
							</FormControl>
						)}
					</Grid>
				</Grid>
			</Grid>

			<div>
				<Modal
					disableBackdropClick={false}
					open={modalOpen}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div>
						<AddCustomer closeModal={handleModalClose} />
					</div>
				</Modal>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
});

const mapStateToProps = (state: any) => {
	return {
		selectedCustomer: state.ui.selectedCustomer,
		selectedFilterValues: state.ui.selectedFilterValues,
		selectedDateRange: state.ui.selectedDateRange,
		authUser: state.user.authUser,
		tenantsList: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
