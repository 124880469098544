import { connect } from 'react-redux';
import TelemetryComponent from './telemetry.component';
import { AlertActions, TenantsActions, UIAction, CabinetActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	alertsList: state.alerts.alertsList ? state.alerts.alertsList.data.result : [],
	totalAlerts: state.alerts.alertsList ? state.alerts.alertsList.data.total_record : 0,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	getAlerts: (filter: any) => dispatch(AlertActions.alertsRequestStartAction({ filter, showError: true })),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export const TelemetryContainer = connect(mapStateToProps, mapDispatchToProps)(TelemetryComponent);
