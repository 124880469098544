import * as React from 'react';
import {
	TextField,
	Button,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Paper,
	Chip,
	Zoom,
	FormHelperText,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { UserRecord, Role } from '../../redux/models/user/user';
import AuthUser from '../../redux/lib/authUser';
import { TenantsActions, UserManagementActions } from '../../redux/actions';
import { connect } from 'react-redux';
import {
	nameRegex,
	emailRegex,
	getUserResources,
	getUserRoles,
	initUserType,
	initAccessGroupId,
	initCustomers,
	initRoles,
	getEditedUserPersonas,
	combineRolesAndPersonas,
	getEditedUserHelpViewerPermissions,
	getRoleLabel,
} from './users.helper';
import { userModalStyles } from './users.styles';
import { AlphaSortObject } from '../../hooks/functions/AlphaSortObject';
import dataList from '../../constants/dataList';
import { CommonStyles } from '../../hooks/styles';

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

interface UserFormProps {
	thisUser: UserRecord;
	userExistsCheckResult?: UserRecord | string;
	authUser?: AuthUser;
	tenants: any[];
	isLoading: boolean;
	error: any;
	createUserErrors: string[];
	allAvailablePersonas: any;
	selectedCustomer: any;
	useFilters?: boolean;
	selectedFilterValues: any;
	globalRowsPerPage: any;
	pagination: any;
	sortOrder: any;
	userScheduledReportSubscriptions: any;
	searchTenants: (filter: any) => void;
	createUser: (user: UserRecord) => void;
	updateUser: (user: UserRecord) => void;
	userExistsCheck: (email: string) => void;
	clearExistingUser: () => void;
	close: () => void;
	openScheduledReportsForUserModal?: (user: any) => void;
}

/*
 * Edit user component
 */
const UserForm: React.FC<UserFormProps> = props => {
	const classes = useStyles();
	const commonClasses = CommonStyles();
	const [loadingUserCheck, setLoadingUserCheck] = React.useState(false);
	const [firstName, setFirstName] = React.useState(props.thisUser.firstName ?? ('' as string));
	const [lastName, setLastName] = React.useState(props.thisUser.lastName ?? ('' as string));
	const [email, setEmail] = React.useState(props.thisUser.email ?? ('' as string));
	const [userType, setUserType] = React.useState(initUserType(props.thisUser.claims) as string);
	const [newClaimTenant, setNewClaimTenant] = React.useState('' as string);
	const [selectedCustomer, setSelectedCustomer] = React.useState(null as any);
	const [initCustomerIds] = React.useState(initCustomers(props.thisUser.claims) as string[]);
	const [accessGroupCustomersFetched, setAccessGroupCustomersFetched] = React.useState(false);
	const [customers, setCustomers] = React.useState([] as any[]);
	const [accessGroupId, setAccessGroupId] = React.useState(initAccessGroupId(props.thisUser.claims) as string);
	const [selectedResource, setSelectedResource] = React.useState('' as string);
	const [selectedRole, setSelectedRole] = React.useState('' as any);
	const [selectedPersonaToAdd, setSelectedPersonaToAdd] = React.useState('' as string);
	const [allowedRoles, setAllowedRoles] = React.useState([] as any);
	const [roles, setRoles] = React.useState(initRoles(props.thisUser.claims) as any); //roles of the user being edited
	const [missingRoles, setMissingRoles] = React.useState<any>({});
	const [canEditPermissionsFlag, setCanEditPermissionsFlag] = React.useState(true);
	const [editedUserPersonaNames, setEditedUserPersonaNames] = React.useState(props.thisUser.claims?.personas || []); //persona names currently assigned to the user being edited
	const [editedUserPersonas, setEditedUserPersonas] = React.useState(
		getEditedUserPersonas(props.thisUser.claims, props.allAvailablePersonas) as any,
	); //persona objects currently assigned to the user being edited
	const [editedUserHelpViewerPermissions, setEditedUserHelpViewerPermissions] = React.useState(
		getEditedUserHelpViewerPermissions(props.thisUser.claims),
	);
	const [editedUserReportSubscriptions, setEditedUserReportSubscriptions] = React.useState(
		props.thisUser.reportSubscriptions || {},
	);

	const [resources] = React.useState(getUserResources(props.authUser?.record.claims) as string[]); //all resources of the logged-in user, corresponding to menu choices bc you can only give other roles you yourself have
	const [submitted, setSubmitted] = React.useState(false as boolean);
	const [formErrors, setFormErrors] = React.useState({} as any);
	const userTypeLabels: any = { gpo: 'GPO', idn: 'IDN', subGpo: 'Sub GPO', subIdn: 'Sub IDN' };

	const [userDerivedRoles, setUserDerivedRoles] = React.useState(
		AlphaSortObject(
			combineRolesAndPersonas(
				props.thisUser.claims && props.thisUser.claims.roles ? { ...props.thisUser.claims.roles } : {},
				props.thisUser.claims && props.thisUser.claims.personas ? [...props.thisUser.claims.personas] : [],
				props.allAvailablePersonas,
			),
		) as any,
	);

	const [showEditRolesSection, setShowEditRolesSection] = React.useState(false);
	const [showEditPersonasSection, setShowEditPersonasSection] = React.useState(true);
	const [userHelpTopics] = React.useState((props.authUser?.record.helpTopics as object) || {});
	const isHelpAdmin =
		props.authUser?.record.claims['ALL'] && props.authUser?.record.claims['ALL']['help_admin'] ? true : false;

	React.useEffect(() => {
		if (props.isLoading) {
			setSubmitted(true);
		}
		if (submitted && !props.isLoading && !props.error) {
			props.close();
		}
		if (loadingUserCheck) {
			if (props.userExistsCheckResult) {
				if (props.userExistsCheckResult === 'User does not exist') {
					if (formErrors.email && formErrors.email === 'A user with this email already exists') {
						const errors = Object.assign({}, formErrors);
						delete errors.email;
						setFormErrors(errors);
					}
				}
				if (props.userExistsCheckResult === 'User already exists') {
					setFormErrors({ ...formErrors, email: 'A user with this email already exists' });
				}
				props.clearExistingUser();
				setLoadingUserCheck(false);
			}
		}
		if (
			!accessGroupCustomersFetched &&
			(props.thisUser.id || props.thisUser.claims) &&
			userType === 'customer' &&
			customers.length === 0 &&
			initCustomerIds.length > 0
		) {
			setAccessGroupCustomersFetched(true);
			const preSelectedCustomers = props.tenants.filter((customer: any) => {
				return initCustomerIds.includes(customer.customerId);
			});
			console.log('PRESELECTED CUSTOMERS', preSelectedCustomers);
			setCustomers(preSelectedCustomers);
		}
		if (
			userType !== 'customer' &&
			!props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: 'ALL' })
		) {
			setUserType('customer');
		}
	}, [props, submitted, formErrors, loadingUserCheck]);

	const handleTextFieldChange = (event: any, field: string) => {
		if (field === 'firstName') {
			setFirstName(event.target.value);
			setFormErrors({ ...formErrors, firstName: false });
		}
		if (field === 'lastName') {
			setLastName(event.target.value);
			setFormErrors({ ...formErrors, lastName: false });
		}
		if (field === 'email') {
			setEmail(event.target.value);
			if (userType === 'admin' && !validAdminEmail('admin', event.target.value)) {
				setFormErrors({
					...formErrors,
					email:
						'Admin users must have @fffenterprises.com, @rightnowinventory.com, @minibarrx.com, or @nufactor.com as their email domain',
				});
				return;
			}
			setFormErrors({ ...formErrors, email: false });
		}
		if (field === 'accessGroup') {
			setAccessGroupId(event.target.value);
			setFormErrors({ ...formErrors, accessGroup: false });
		}
		setSubmitted(false);
	};

	const handleTenantSelect = (event: any, value: any) => {
		if (!value) {
			setSelectedCustomer(null);
			return;
		}
		setSelectedCustomer(value);
		setFormErrors({ ...formErrors, customers: false });
	};

	const handlePersonaToAddSelect = (event: any, value: any) => {
		setSelectedPersonaToAdd(event.target.value);
	};

	const addCustomer = () => {
		if (!selectedCustomer) {
			return;
		}
		setFormErrors({ ...formErrors, customers: false });
		setCustomers([...customers, selectedCustomer]);
		setSelectedCustomer(null);
	};

	const handleResourceSelect = (event: any) => {
		const value = event.target.value ?? '';
		if (!value) {
			setSelectedResource('');
			setSelectedRole('');
			return;
		}
		setSelectedResource(value);
		setAllowedRoles(getUserRoles(props.authUser?.record.claims, value));
	};

	const handleAddPersona = (e: any, getEditedUserPersonas: Function) => {
		if (!selectedPersonaToAdd) {
			return;
		}

		let newPersonasList = [...editedUserPersonaNames];
		newPersonasList.push(selectedPersonaToAdd);

		//update list of names and complete persona records
		setEditedUserPersonaNames(newPersonasList);
		setEditedUserPersonas(getEditedUserPersonas({ personas: newPersonasList }, props.allAvailablePersonas));
		setSelectedPersonaToAdd('');
		setUserDerivedRoles(
			AlphaSortObject(
				combineRolesAndPersonas(
					props.thisUser.claims ? { ...props.thisUser.claims.roles } : {},
					[...newPersonasList],
					props.allAvailablePersonas,
				),
			) as any,
		);

		setFormErrors({ ...formErrors, claims: false });
		setSubmitted(false);
	};

	const handleRemovePersonaFromUser = (persona: string, getEditedUserPersonas: Function) => {
		if (!persona || editedUserPersonaNames.indexOf(persona) === -1) {
			return;
		}

		let newPersonasList = [...editedUserPersonaNames];
		newPersonasList.splice(editedUserPersonaNames.indexOf(persona), 1);

		//update list of names and complete persona records
		setEditedUserPersonaNames(newPersonasList);
		setEditedUserPersonas(getEditedUserPersonas({ personas: newPersonasList }, props.allAvailablePersonas));

		//update derived roles display
		setUserDerivedRoles(
			AlphaSortObject(combineRolesAndPersonas({ ...roles }, [...newPersonasList], props.allAvailablePersonas)),
		);

		setFormErrors({ ...formErrors, claims: false });
		setSubmitted(false);
	};

	const handleUserExistsCheck = () => {
		if (props.thisUser.email && props.thisUser.email === email) {
			return;
		}
		props.userExistsCheck(email);
		setLoadingUserCheck(true);
	};

	const handleRoleSelect = (event: any) => {
		const value = event.target.value ?? '';
		if (!value) {
			setSelectedRole('');
			return;
		}
		setSelectedRole(value);
	};

	const handleAddRole = () => {
		if (!selectedResource || !selectedRole) {
			return;
		}

		let newRoles = { ...roles };

		newRoles[selectedResource] = selectedRole as Role;

		setRoles(newRoles);
		setUserDerivedRoles(
			AlphaSortObject(
				combineRolesAndPersonas({ ...newRoles }, editedUserPersonaNames, props.allAvailablePersonas),
			) as any,
		);

		setFormErrors({ ...formErrors, claims: false });
		setSubmitted(false);
	};

	const handleDeleteRole = (resource: string) => {
		let newRoles = { ...roles };
		delete newRoles[resource];
		setRoles(newRoles);
		//update derived roles display
		setUserDerivedRoles(
			AlphaSortObject(
				combineRolesAndPersonas({ ...newRoles }, [...editedUserPersonaNames], props.allAvailablePersonas),
			),
		);
	};

	const submitUserForm = () => {
		if (!formIsValid()) {
			return;
		}

		const accessGroupIdValue = ['admin', 'customer'].includes(userType)
			? customers.map((customer: any) => {
					return customer.customerId;
			  })
			: [accessGroupId];

		const accessGroups = {
			[userType === 'admin' ? 'customer' : userType]: userType === 'admin' ? ['ALL'] : accessGroupIdValue,
		};

		let newRoles = { ...roles };
		delete newRoles.reports;

		let claims = {
			accessGroups: accessGroups,
			roles: newRoles,
			personas: editedUserPersonaNames || [],
		};

		const userData: any = { ...{}, firstName: firstName, lastName: lastName, email: email, claims: claims }; //rmm

		const refreshFilter = getRefreshFilter();

		if (props.thisUser.id) {
			userData.id = props.thisUser.id;
			if (!canEditPermissionsFlag) delete userData.claims;
			props.updateUser({ ...userData, refreshFilter: refreshFilter });
			return;
		}
		props.createUser({ ...userData, refreshFilter: refreshFilter });
	};

	const validAdminEmail = (userType: string, emailToCheck?: string) => {
		emailToCheck = emailToCheck ? emailToCheck : email;
		if (userType !== 'admin') {
			return true;
		}

		if (
			!emailToCheck ||
			!emailToCheck.includes('@') ||
			!['fffenterprises.com', 'rightnowinventory.com', 'minibarrx.com', 'nufactor.com'].includes(
				emailToCheck.split('@')[1].trim(),
			)
		) {
			setFormErrors({
				...formErrors,
				email: `Admin users must have @fffenterprises.com, @rightnowinventory.com, @minibarrx.com, or @nufactor.com as their email domain`,
			});
			return false;
		}
		return true;
	};

	const getRefreshFilter = () => {
		const refreshFilter: any[] = [];

		props.selectedCustomer && refreshFilter.push({ key: 'tenantId', value: props.selectedCustomer.customerId });

		if (props.useFilters && props.selectedFilterValues) {
			props.selectedFilterValues['USER_FIRST_NAME'] &&
				refreshFilter.push({ key: 'firstName', value: props.selectedFilterValues['USER_FIRST_NAME'] });
			props.selectedFilterValues['USER_LAST_NAME'] &&
				refreshFilter.push({ key: 'lastName', value: props.selectedFilterValues['USER_LAST_NAME'] });
			props.selectedFilterValues['USER_EMAIL'] &&
				refreshFilter.push({ key: 'email', value: props.selectedFilterValues['USER_EMAIL'] });
		}

		props.globalRowsPerPage && refreshFilter.push({ key: 'limit', value: props.globalRowsPerPage });

		props.pagination.offset && refreshFilter.push({ key: 'offset', value: props.pagination.offset });

		props.sortOrder && refreshFilter.push({ key: 'order', value: props.sortOrder });

		return refreshFilter;
	};

	/*
	 * on check or uncheck, add or remove specific help folder view permission from array
	 */
	const toggleHelpViewerPermission = (topic: string, value: boolean) => {
		const newPermissions = [...editedUserHelpViewerPermissions];

		if (!value && newPermissions.indexOf(topic) > -1) {
			newPermissions.splice(newPermissions.indexOf(topic), 1);
		} else if (value && newPermissions.indexOf(topic) === -1) {
			newPermissions.push(topic);
		}

		setEditedUserHelpViewerPermissions(newPermissions);
		setRoles({ ...roles, help_viewer: newPermissions });
		setUserDerivedRoles(
			AlphaSortObject(
				combineRolesAndPersonas(
					{ ...roles, help_viewer: newPermissions },
					editedUserPersonaNames,
					props.allAvailablePersonas,
				),
			) as any,
		);
	};

	const formIsValid = () => {
		let errors: any = {};

		if (!firstName || !nameRegex.test(firstName)) {
			errors.firstName = 'A valid first name is required';
		}
		if (!lastName || !nameRegex.test(lastName)) {
			errors.lastName = 'A valid last name is required';
		}
		if (formErrors.email) {
			errors.email = formErrors.email;
		}
		if (!email || !emailRegex.test(email)) {
			errors.email = 'A valid email is required';
		}
		if (!userType) {
			errors.userType = 'A user type is required';
		}
		if (userType && !['customer', 'admin'].includes(userType) && !accessGroupId) {
			errors.accessGroup = `A ${userTypeLabels[userType]} ID is required`;
		}
		if (canEditPermissionsFlag && userType === 'customer' && customers.length === 0) {
			errors.customers = 'At least one customer must be selected';
		}

		if (Object.keys(roles).length === 0 && !editedUserPersonaNames?.length) {
			errors.claims = 'User permissions must be set';
			setShowEditRolesSection(true);
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const canEditPermissionsCheck = () => {
		let canEdit = true;
		const roleValueMap: any = { OWNER: 2, VIEWER: 1 };
		let authUserClaims: any = {};
		let authUserRoles: any = {};
		let targetUserRoles = props.thisUser?.claims?.roles || {};
		if (props.authUser?.record.claims) {
			authUserClaims = props.authUser?.record.claims;
			authUserRoles = authUserClaims[Object.keys(authUserClaims)[0]];
		}

		let rolesNeeded: any = {};
		Object.keys(targetUserRoles).forEach((resource: string) => {
			if (['reports', 'help_viewer', 'help_admin'].includes(resource)) return;
			if (!authUserRoles[resource]) {
				rolesNeeded[resource] = targetUserRoles[resource];
				canEdit = false;
				return;
			}
			if (roleValueMap[targetUserRoles[resource]] > roleValueMap[authUserRoles[resource]]) {
				rolesNeeded[resource] = targetUserRoles[resource];
				canEdit = false;
				return;
			}
		});

		setMissingRoles(rolesNeeded);
		setCanEditPermissionsFlag(canEdit);
	};

	React.useEffect(() => {
		if (props.authUser) canEditPermissionsCheck();
	}, [props.authUser]);

	return (
		<div style={getModalStyle()} className={classes.paper}>
			<div>
				<h2>{props.thisUser.id ? 'Update' : 'Create New'} User</h2>
				<form noValidate autoComplete="off">
					<FormControl className={classes.input} style={{ maxWidth: 153 }}>
						<TextField
							label="Email"
							variant="outlined"
							value={email}
							error={formErrors.email ? true : false}
							helperText={formErrors.email ? formErrors.email : ''}
							onChange={(event: any) => {
								handleTextFieldChange(event, 'email');
							}}
							onBlur={handleUserExistsCheck}
							disabled={props.thisUser.id ? true : false}
							required
						/>
					</FormControl>
					<FormControl className={classes.input}>
						<TextField
							label="First Name"
							variant="outlined"
							value={firstName}
							error={formErrors.firstName ? true : false}
							helperText={formErrors.firstName ? formErrors.firstName : ''}
							onChange={(event: any) => {
								handleTextFieldChange(event, 'firstName');
							}}
							required
						/>
					</FormControl>
					<FormControl className={classes.input}>
						<TextField
							label="Last Name"
							variant="outlined"
							value={lastName}
							error={formErrors.lastName ? true : false}
							helperText={formErrors.lastName ? formErrors.lastName : ''}
							onChange={(event: any) => {
								handleTextFieldChange(event, 'lastName');
							}}
							required
						/>
					</FormControl>
					<div>
						{canEditPermissionsFlag &&
							props.authUser?.authorizationCheck({ action: 'create', resource: 'users', tenantId: 'ALL' }) && (
								<FormControl variant="filled" className={classes.input}>
									<InputLabel id="user-type-select">User Type</InputLabel>
									<Select
										labelId="user-type-select"
										value={userType}
										onChange={(event: any) => {
											setUserType(event.target.value as string);
											setCustomers([]);
											if (!validAdminEmail(event.target.value as string)) {
												return;
											}
											setFormErrors({ ...formErrors, userType: false, email: false });
										}}
										error={formErrors.userType ? true : false}
										inputProps={{ 'aria-label': 'Without label' }}
									>
										<MenuItem value={''}>
											<em>None</em>
										</MenuItem>
										<MenuItem value={'admin'}>
											<em>Admin</em>
										</MenuItem>
										<MenuItem value={'customer'}>
											<em>Customer</em>
										</MenuItem>
										<MenuItem value={'gpo'}>
											<em>GPO</em>
										</MenuItem>
										<MenuItem value={'idn'}>
											<em>IDN</em>
										</MenuItem>
										<MenuItem value={'subGpo'}>
											<em>Sub GPO</em>
										</MenuItem>
										<MenuItem value={'subIdn'}>
											<em>Sub IDN</em>
										</MenuItem>
									</Select>
									{formErrors.userType && (
										<FormHelperText error={formErrors.userType ? true : false}>{formErrors.userType}</FormHelperText>
									)}
								</FormControl>
							)}
						{userType && !['admin', 'customer'].includes(userType) && (
							<FormControl className={classes.input}>
								<TextField
									label={`${userTypeLabels[userType]} ID`}
									variant="outlined"
									value={accessGroupId}
									error={formErrors.accessGroup ? true : false}
									helperText={formErrors.accessGroup ? formErrors.accessGroup : ''}
									onChange={(event: any) => {
										handleTextFieldChange(event, 'accessGroup');
									}}
									required
								/>
							</FormControl>
						)}
					</div>

					{canEditPermissionsFlag ? (
						<>
							<h2>User Permissions</h2>
							{userType === 'customer' && (
								<div>
									<FormControl className={classes.input}>
										<Autocomplete
											id="customer-combo-box"
											options={props.tenants}
											getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
											style={{ width: 280 }}
											onChange={handleTenantSelect}
											renderInput={(params: any) => (
												<TextField
													value={newClaimTenant}
													{...params}
													label="Search Customers"
													variant="filled"
													error={formErrors.customers ? true : false}
													helperText={customers.length === 0 && 'Please choose a customer to grant permissions.'}
												/>
											)}
										/>
									</FormControl>
									<FormControl className={classes.input}>
										<Button className={classes.addPermBtn} variant="contained" color="secondary" onClick={addCustomer}>
											ADD
										</Button>
									</FormControl>
								</div>
							)}
							{userType === 'customer' && (
								<div style={{ marginBottom: '15px' }}>
									<Zoom in={customers.length > 0 ? true : false} timeout={500}>
										<div>
											{customers.map((customer: any, index: number) => (
												<FormControl key={index} className={classes.input}>
													<Chip
														label={customer.name}
														onDelete={() => {
															let customerArr = [...customers];
															customerArr.splice(index, 1);
															setCustomers(customerArr);
														}}
													/>
												</FormControl>
											))}
										</div>
									</Zoom>
								</div>
							)}

							<div>
								<h3>Personas</h3>
								{showEditPersonasSection && (
									<>
										<div>
											<FormControl variant="filled" className={classes.input}>
												<InputLabel id="add-persona-select">Persona</InputLabel>
												<Select
													labelId="add-persona-select"
													inputProps={{ 'aria-label': 'Without label' }}
													value={selectedPersonaToAdd}
													onChange={handlePersonaToAddSelect}
													disabled={userType ? false : true}
												>
													{props.allAvailablePersonas.map((persona: any, index: number) => {
														if (
															editedUserPersonaNames &&
															editedUserPersonaNames.indexOf(persona.identifier) === -1 &&
															!persona.hidden
														) {
															return (
																<MenuItem key={index} value={persona.identifier}>
																	{persona.identifier}
																</MenuItem>
															);
														}

														return null;
													})}
												</Select>
											</FormControl>

											<FormControl className={classes.input}>
												<Button
													className={classes.addPermBtn}
													variant="contained"
													color="secondary"
													disabled={userType ? false : true}
													onClick={(e: any) => handleAddPersona(e, getEditedUserPersonas)}
												>
													ADD
												</Button>
											</FormControl>
										</div>
										<div>
											{/* display this user's personas */}
											{editedUserPersonas.map((personaObj: any, index: number) => {
												let thisLabel = `${personaObj.identifier} (`;

												for (var roleName in personaObj.roles) {
													thisLabel += `${roleName}: ${personaObj.roles[roleName]} `;
												}

												thisLabel += ')';

												return (
													<FormControl key={index} className={classes.input}>
														<Chip
															label={thisLabel}
															onDelete={() => {
																handleRemovePersonaFromUser(personaObj.identifier, getEditedUserPersonas);
															}}
														/>
													</FormControl>
												);
											})}
										</div>
									</>
								)}
							</div>

							<div>
								{/* <h3>
                Edit Roles
                <div style={{ margin: '4px 0 0 6px', display: 'inline-block' }}>
                  {!showEditRolesSection && <EditIcon onClick={() => setShowEditRolesSection(true)} />}
                  {showEditRolesSection && <CloseIcon onClick={() => setShowEditRolesSection(false)} />}
                </div>
              </h3> */}
								{showEditRolesSection && (
									<>
										<FormControl variant="filled" className={classes.input}>
											<InputLabel id="resource-select">Resource</InputLabel>

											{/* resource select */}
											<Select
												labelId="resource-select"
												value={selectedResource}
												onChange={handleResourceSelect}
												inputProps={{ 'aria-label': 'Without label' }}
												disabled={userType ? false : true}
											>
												<MenuItem value={''}>
													<em>None</em>
												</MenuItem>
												{resources
													.filter(resource => !['help_viewer', 'help_admin'].includes(resource))
													.sort()
													.map((resource, index) => {
														return (
															<MenuItem key={index} value={resource}>
																{resource.charAt(0).toUpperCase() + resource.slice(1)}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>

										{/* role select */}
										<FormControl variant="filled" className={classes.input}>
											<InputLabel id="role-select">Role</InputLabel>
											<Select
												labelId="role-select"
												value={selectedRole || roles[selectedResource] || ''}
												onChange={handleRoleSelect}
												inputProps={{ 'aria-label': 'Without label' }}
												disabled={userType ? false : true}
											>
												<MenuItem value={''}>
													<em>None</em>
												</MenuItem>
												{allowedRoles.map((role: Role, index: number) => {
													return (
														<MenuItem key={index} value={role}>
															{role}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
										<FormControl className={classes.input}>
											<Button
												className={classes.addPermBtn}
												variant="contained"
												color="secondary"
												disabled={userType ? false : true}
												onClick={handleAddRole}
											>
												ADD/UPDATE
											</Button>
										</FormControl>
										{formErrors.claims && <div className={classes.permissionsError}>{formErrors.claims}</div>}
										<Zoom in={Object.keys(roles).length > 0 ? true : false} timeout={500}>
											<div>
												{/* display this user's current roles */}
												{Object.keys(roles)
													.filter(resource => !['help_viewer', 'help_admin'].includes(resource))
													.map((resource, index) => {
														return (
															<FormControl key={index} className={classes.input}>
																<Chip
																	label={getRoleLabel(resource, roles[resource])}
																	onDelete={() => {
																		handleDeleteRole(resource);
																	}}
																/>
															</FormControl>
														);
													})}
											</div>
										</Zoom>
									</>
								)}
							</div>

							{isHelpAdmin && (
								<div>
									<h3>Help Viewer Permissions</h3>
									{/* special handling for help_viewer: checkboxes to choose which topics to allow */}
									{userType === 'admin' && (
										<FormControl variant="filled" className={classes.input}>
											<FormControlLabel
												control={
													<Checkbox
														name={'helpAdminRole'}
														checked={roles['help_admin']}
														onChange={(e: any, value: any) => {
															if (value) {
																const newRoles = { ...roles, help_admin: 'OWNER' };
																if (!roles.help_viewer) newRoles['help_viewer'] = [];
																setRoles(newRoles);
															} else {
																let rolesCopy = { ...roles };
																delete rolesCopy.help_admin;
																if (rolesCopy.help_viewer?.length === 0) delete rolesCopy.help_viewer;
																setRoles(rolesCopy);
															}
														}}
													/>
												}
												label={'Is Help Admin'}
											/>
										</FormControl>
									)}
									<FormControl variant="filled" className={classes.input}>
										<div
											className="roleCheckboxWrapper"
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
										>
											{Object.keys(userHelpTopics).map((topic: string, index: number) => {
												const topicMap = userHelpTopics as any;
												const thisObj = topicMap[topic];

												return (
													<FormControlLabel
														control={
															<Checkbox
																name={topic}
																checked={editedUserHelpViewerPermissions.includes(topic)}
																onChange={(e: any, value: any) => toggleHelpViewerPermission(topic, value)}
															/>
														}
														label={thisObj.displayName}
														key={index}
													/>
												);
											})}
										</div>
									</FormControl>
								</div>
							)}
						</>
					) : (
						<p className={commonClasses.errorMsg}>
							You cannot edit this user's permissions because they have the following permissions which are greater than
							your own:
							<br />
							{Object.keys(missingRoles).map((resource: string) => (
								<>
									{resource}:{missingRoles[resource]}
									<br />
								</>
							))}
						</p>
					)}

					<div>
						<h3>User's Derived Roles {/* show what roles user ends up with from combining personas and roles */}</h3>
						<div>
							{Object.keys(userDerivedRoles).map((resource, index) => {
								return (
									<FormControl key={index} className={classes.input}>
										<Chip label={getRoleLabel(resource, userDerivedRoles[resource])} />
									</FormControl>
								);
							})}
						</div>
					</div>

					<div>
						<h3>User's Scheduled Report Subscriptions</h3>
						<div>
							{Object.keys(editedUserReportSubscriptions).length ? (
								<div>
									{Object.keys(editedUserReportSubscriptions).map((reportSubKey, index) => {
										const thisReportSub = props.userScheduledReportSubscriptions[reportSubKey];

										return (
											<Chip
												label={reportSubKey}
												style={{
													marginRight: 6,
												}}
											/>
										);
									})}
								</div>
							) : (
								<h4>User has no scheduled reports.</h4>
							)}
							<div
								style={{
									marginTop: 12,
								}}
							>
								<Button
									onClick={() => {
										if (props.openScheduledReportsForUserModal) props.openScheduledReportsForUserModal(props.thisUser);
									}}
								>
									Create report for user
								</Button>
							</div>
						</div>
					</div>

					{submitted && props.error && (
						<>
							<p className={classes.createUserError}>
								{props.error.length // error is string and not boolean
									? props.error
									: `Failed to ${props.thisUser.id ? 'update' : 'create'} user.`}
							</p>
							{props.createUserErrors.map((createUserError: string) => (
								<p className={classes.createUserError}>{createUserError}</p>
							))}
						</>
					)}

					<FormControl className={classes.input}>
						<Button
							className={classes.addPermBtn}
							variant="contained"
							color="secondary"
							disabled={userType ? false : true}
							onClick={submitUserForm}
						>
							SUBMIT
						</Button>
					</FormControl>
					<FormControl className={classes.input}>
						<Button className={classes.cancelBtn} variant="contained" color="default" onClick={props.close}>
							CANCEL
						</Button>
					</FormControl>
				</form>
			</div>
			{loadingUserCheck && (
				<div
					style={{
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.2)',
						position: 'absolute',
						top: 0,
						left: 0,
					}}
				></div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	isLoading: state.ui.isLoading,
	error: state.userManagement.error,
	createUserErrors: state.user.createUserErrors || [],
	allAvailablePersonas: state.user.allAvailablePersonas,
	userExistsCheckResult: state.userManagement.existingUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	userScheduledReportSubscriptions: state.user.reportSubscriptions || {},
	tenants: state.tenants?.tenantsList?.result || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	createUser: (userData: UserRecord) => dispatch(UserManagementActions.createUserAction(userData)),
	updateUser: (userData: UserRecord) => dispatch(UserManagementActions.updateUserAction(userData)),
	userExistsCheck: (email: string) => dispatch(UserManagementActions.userExistsCheckAction(email)),
	clearExistingUser: () => dispatch(UserManagementActions.clearUserCheckAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
