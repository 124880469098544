import moment from 'moment';
import { InventoryIcons, QtyRow } from './Renderers';
import { isObject } from 'lodash';
import { InventoryCustomBodyRenderFunction, UserDateFormat } from '.';

export const GetInventoryColumns = (tablePersist: any, properties: any, suspendedTransactions?: any) => {
	const columnNames = [
		'customerName',
		'customerId',
		'cabinetId',
		'productLabelName',
		'cabinetType',
		'lot',
		'salesUoUQty',
		'expiration',
		'ndc542',
		'lotSize',
		'lotAssay',
		'lotUnitOfMeasure',
		''
	];
	const columnLabels = [
		'Customer',
		'Customer Id',
		'Device',
		'Product Name',
		'Device Type',
		'Lot',
		'On Hand',
		'Expiry',
		'NDC',
		'Lot Size',
		'Lot Assay',
		'Lot Unit of Measure',
		''
	];

	let columns = columnNames.map((name, index) => {
		const options: any = {
			filter: true,
			sort: true,
			filterList: null,
			display: true,
			sortOrder: null,
			viewColumns: true,
			change: true,
			customBodyRender: InventoryCustomBodyRenderFunction,
		};

		if (name === 'expiration') {
			options.sortCompare = (order: string) => {
				sortCompare(order);
			};
			options.customBodyRender = (value: any) => {
				return UserDateFormat(properties, value);
			};
		}

		if (name === 'salesUoUQty') {
			//if user loaded latest scan, we have an object of two values
			options.customBodyRender = (value: any) => {
				if (isObject(value)) {
					return QtyRow(value.dbValue, value.scanValue);
				} else {
					return QtyRow(value);
				}
			};
		}

		if (name === '') {
			options.viewColumns = false
			options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
				if(suspendedTransactions && suspendedTransactions.result){
					const suspendedLots = suspendedTransactions.result.map((transaction: any) => transaction.lot)
					const rowLot = tableMeta.rowData[5]
					return suspendedLots.includes(rowLot) ? InventoryIcons() : ""
				} 
				return ""
			};
		}

		if (['lotSize', 'lotAssay', 'lotUnitOfMeasure'].includes(name)) {
			options.display = false;
		}

		return {
			name: name,
			label: columnLabels[index],
			options: options,
		};
	});

	if (!tablePersist.columns) {
		// make these column unclickable in view column section
		columns[0].options.change = false;
		columns[1].options.change = false;
		columns[2].options.change = false;
		columns[3].options.change = false;
		return columns;
	}

	columns = updateColumnOptions(columns, tablePersist);

	return columns;
};

const sortCompare = (order: string) => {
	return (obj1: any, obj2: any) => {
		const val1 = obj1.data ? moment(obj1.data, 'YYYY-MM-DD').unix() : moment().unix();
		const val2 = obj2.data ? moment(obj2.data, 'YYYY-MM-DD').unix() : moment().unix();
		return (val1 - val2) * (order === 'asc' ? 1 : -1);
	};
};

const expirationBodyRender = (value: any) => {
	if (!value) {
		return '';
	}
	return moment(value).format('YYYY-MM-DD');
};

const updateColumnOptions = (columns: any, tablePersist: any) => {
	for (let i = 0; i < columns.length; i++) {
		columns[i].options.filterList = tablePersist.filterList[i];
		if (tablePersist.columns[i] !== undefined) {
			if (tablePersist.columns[i].hasOwnProperty('display'))
				columns[i].options.display = tablePersist.columns[i].display;
			if (tablePersist.columns[i].hasOwnProperty('sortOrder')) {
				if (tablePersist.columns[i].sortOrder !== 'none')
					columns[i].options.sortOrder = tablePersist.columns[i].sortOrder;
			}
		}
	}
	return columns;
};
