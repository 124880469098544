import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { CabinetActions } from '../../../redux/actions';
import { Styles } from '../styles';
interface cabinetProps {
	cabinets?: any;
	onChangeCabinet: (practice: any) => void;
	customerId?: any;
}

const Cabinet = (props: cabinetProps) => {
	const dispatch = useDispatch();
	const classes = Styles();
	const [cabinetId, setCabinetId] = React.useState('');

	useEffect(() => {
		if (props.customerId) {
			const searchCabinets = () => {
				dispatch(
					CabinetActions.cabinetRequestStartAction({
						offset: 0,
						limit: 100,
						order: ['cabinet_id'],
						customerId: props.customerId,
					}),
				);
			};
			searchCabinets();
		}
	});

	const changeCabinet = (event?: any) => {
		setCabinetId(event.target.value !== null && event.target.value !== 'null' ? event.target.value : '');
		props.onChangeCabinet(event.target.value !== null && event.target.value !== 'null' ? event.target.value : '');
	};

	return (
		<span>
			<InputLabel id="demo-simple-select-outlined-label">Select Device</InputLabel>
			<Select
				className={classes.formControlSelect}
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				label="Select Cabinet"
				onChange={changeCabinet}
				value={cabinetId}
				//error={validationRequired && !filter.cabinet_id}
			>
				<MenuItem value="">Select Device</MenuItem>
				{props.cabinets.map((item: any, key: number) => {
					return [<MenuItem value={item.cabinetId}>{item.cabinetId}</MenuItem>];
				})}
			</Select>
		</span>
	);
};
const mapStateToProps = (state: any) => {
	return {
		cabinets: state.cabinet.cabinetCollection ? state.cabinet.cabinetCollection : [],
	};
};

export default connect(mapStateToProps)(Cabinet);
