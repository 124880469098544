export const tablePagination = (tableAction: any, tableState: any, pagination: any) => {
	switch (tableAction) {
		case 'changeRowsPerPage':
			return { offset: 0, limit: tableState.rowsPerPage, page: 0 };
		case 'changePage':
			if (tableState.page > pagination.page) {
				return { offset: pagination.offset + pagination.limit, limit: pagination.limit, page: tableState.page };
			} else if (tableState.page < pagination.page) {
				return {
					offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit,
					limit: pagination.limit,
					page: tableState.page,
				};
			}
			break;
	}
};
