import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container, Grid, IconButton, Menu, MenuItem, Modal } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import KitchenIcon from '@material-ui/icons/Kitchen';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import StorageIcon from '@material-ui/icons/Storage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { PracticePageStyles, getMuiTheme, CommonStyles } from '../../../hooks/styles';
import SearchActions from './search';
import { CustomerListFilter } from '../../../redux/models/tenants/tenants';
import { TenantsActions, UIAction } from '../../../redux/actions';
import LinkableActions from '../../../components/linkableActions';
import Loading from '../../../components/loading';
import PeopleIcon from '@material-ui/icons/People';
import BasePage from '../../common/base';
import dataList from '../../../constants/dataList';
import { Constants } from '../../../constants/Constants';
import SyncIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import { orderBy } from 'lodash';
import CustomerFormModal from './customer-form-modal.component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ca } from 'date-fns/locale';

const CustomerList = (props: any) => {
	const searchStyles = CommonStyles();
	const muiThemeStyle = getMuiTheme();
	const dispatch = useDispatch();
	const [customerFilter] = React.useState(new CustomerListFilter());
	const styles = PracticePageStyles();
	const isFirstRender = useRef(true);
	const [isActionDialog, setActionDialog] = React.useState(false);
	let [links, setLinks] = React.useState(null as any);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	});
	const [customerToEdit, setCustomerToEdit] = React.useState(null as any);
	const [modalOpen, setModalOpen] = React.useState(false);

	const columnData = [
		{
			label: 'Customer ID',
			name: 'customerId',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[0].display : true,
				change: false,
			},
		},
		{
			label: 'Customer Name',
			name: 'name',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[1].display : true,
				change: false,
			},
		},

		{
			label: 'Address',
			name: 'address1',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[2].display : true,
				change: true,
			},
		},
		{
			label: 'Email',
			name: 'userEmail',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[3].display : true,
				change: true,
				sort: false,
			},
		},
		{
			label: 'Phone',
			name: 'userPhone',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[4].display : true,
				change: true,
				sort: false,
			},
		},
		{
			label: 'Action',
			name: 'action',
			options: {
				filter: false,
				sort: false,
				empty: true,
				display: tableStatePersist ? tableStatePersist.columns[5].display : false,
				viewColumns: false,
				change: true,
			},
		},
		{
			label: 'Zip Code',
			name: 'zip',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[6].display : true,
				change: true,
			},
		},
		{
			label: 'Class of Trade',
			name: 'classOfTradeDescription',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[7].display : true,
				change: true,
			},
		},
		{
			label: 'FacilityType',
			name: 'facilityTypeDescription',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[8].display : true,
				change: true,
			},
		},
		{
			label: 'Manual Split Bill',
			name: 'manualSplitBill',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[9].display : true,
				change: true,
			},
		},
		{
			label: 'Auto Split Bill',
			name: 'autoSplitBill',
			options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[10].display : true,
				change: true,
			},
		},
		{
			label: 'ID',
			name: 'id',
			options: {
				filter: true,
				display: false,
				change: false,
				viewColumns: false,
			},
		},
		{
			label: 'IG Org ID',
			name: 'igOrgId',
			options: {
				filter: true,
				display: false,
				change: false,
				viewColumns: false,
			},
		},
		{
			label: '',
			name: '',
			options: {
				filter: false,
				display: true,
				change: false,
				viewColumns: false,
				customBodyRender: (value: any, tableMeta: any) => {
					const rowData = tableMeta.rowData;
					return <ActionsMenu rowData={rowData} />;
				},
			},
		},
	];

	const ActionsMenu: React.FC<any> = (actionProps: any) => {
		const [menuOpen, setMenuOpen] = React.useState(false);
		const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
		if (!props.authUser?.authorizationCheck({ action: 'create', resource: 'tenants', tenantId: 'ALL' })) {
			return <></>;
		}
		return (
			<div>
				<IconButton
					aria-haspopup="true"
					aria-controls="long-menu"
					aria-label="more"
					style={{ padding: 0 }}
					onClick={e => {
						setMenuAnchor(e.currentTarget);
						setMenuOpen(!menuOpen);
						handleOpen(actionProps.rowData);
					}}
				>
					<MoreVertIcon />
				</IconButton>
			</div>
		);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setCustomerToEdit(null);
	};

	const handleClose = () => {
		setActionDialog(false);
	};

	const handleOpen = (rowData: any) => {
		setActionDialog(true);
		links = [
			{
				title: Constants.PAGE_TITLE.CUSTOMER_DETAILS,
				url: `/customer-details/${rowData[0]}`,
				icon: <PeopleIcon />,
			},
			...(props.authUser?.authorizationCheck({
				action: 'read',
				resource: 'tenants',
				tenantId: 'ALL',
			})
				? [
						{
							title: Constants.PAGE_TITLE.SYNC_CUSTOMER,
							url: ``,
							callback: { action: 'sync', customerId: rowData[0], igOrgId: rowData[12]},
							icon: <SyncIcon />,
						},
				  ]
				: []),
			{
				title: Constants.PAGE_TITLE.INVENTORY,
				url: `/inventory-management/${rowData[0]}`,
				icon: <StorageIcon />,
			},
			{
				title: Constants.PAGE_TITLE.INVOICES,
				url: `/admin-invoices/${rowData[0]}`,
				icon: <ReceiptIcon />,
			},
			{
				title: Constants.PAGE_TITLE.CABINETS,
				url: `/cabinet-list/${rowData[0]}`,
				icon: <KitchenIcon />,
			},
			{
				title: Constants.PAGE_TITLE.TRANSACTION_LOG,
				url: `/transaction-log/${rowData[0]}`,
				icon: <IconLibraryBooks />,
			},
		];
		setLinks(orderBy(links, [(link: any) => link.title.toLowerCase()], ['asc']));
	};

	const handleActionCallback = (callbackObj: any) => {
		if (callbackObj.action === 'sync') {
			props.syncCustomer({ customerId: callbackObj.customerId, igCustomer: callbackObj.igOrgId ? true : false, callback: setActionDialog });
		} else if (callbackObj.action === 'edit') {
			setModalOpen(true);
			setCustomerToEdit(callbackObj.customerData);
			setActionDialog(false);
		}
	};

	const [apiCalled, setApiCalled] = React.useState(false);

	const fetchResult = (pageReset?: boolean) => {
		customerFilter.primaryContact = true;

		const filterValues = props.selectedFilterValues || {}; //from state
		customerFilter.customerId = props.selectedCustomer?.customerId || undefined;
		customerFilter.state = filterValues?.CUSTOMER_STATE || undefined;
		customerFilter.zip = filterValues?.CUSTOMER_ZIP_CODE || undefined;
		customerFilter.manualSplitBill = filterValues?.MANUAL_SPLIT_BILL || undefined;
		customerFilter.autoSplitBill = filterValues?.AUTO_SPLIT_BILL || undefined;
		customerFilter.classOfTradeDescription = filterValues?.CLASS_OF_TRADE || undefined;
		customerFilter.facilityTypeDescription = filterValues?.FACILITY_TYPE || undefined;

		setApiCalled(true);
		dispatch(
			TenantsActions.getTenantsDetails({
				skip: 0,
				order: sortOrder ? [sortOrder] : ['customerId asc'],
				where: customerFilter,
				fields: {
					id: true,
					customerId: true,
					name: true,
					type: true,
					address1: true,
					address2: true,
					zip: true,
					manualSplitBill: true,
					autoSplitBill: true,
					classOfTradeDescription: true,
					facilityTypeDescription: true,
					igOrgId: true,
				},
			}),
		);
	};

	const onSearch = () => {
		setPageState({
			offset: 0,
			limit: props.globalRowsPerPage,
			page: 0,
		});
		isFirstRender.current = true;
		fetchResult(true);
	};

	useEffect(() => {
		if (!isFirstRender.current) {
			fetchResult();
		} else {
			isFirstRender.current = false;
		}
	}, [sortOrder]);

	const generateDataList = (data: any[], profile: any[]) => {
		const dataList: any[] = [];
		data.forEach((item: any, key: number) => {
			if (profile && profile[item.customerId]) {
				const customerProfile = profile[item.customerId];
				item.userEmail = customerProfile.userEmail;
				item.userPhone = customerProfile.userPhone;
			} else {
				item.userEmail = '';
				item.userPhone = '';
			}
			dataList.push(item);
		});
		return dataList;
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 60; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<BasePage title={Constants.PAGE_TITLE.CUSTOMERS}>
			<div>
				{!props.authUser && props.tenantsList?.result ? (
					<div>
						<Loading message="" />
					</div>
				) : (
					<div>
						<div className={styles.appBarSpacer} />
						<Container maxWidth={false} className={styles.container}>
							<Grid item xs={12} className={searchStyles.searchWrap}>
								<SearchActions filter={customerFilter} onSearch={onSearch} />
							</Grid>
							{props.tenantsList && props.tenantsList.result.length > 0 && (
								<Grid item xs={12}>
									<MUIDataTable
										data={generateDataList(
											props.tenantsList && props.tenantsList.result.length > 0 ? props.tenantsList.result : [],
											props.tenantsList ? props.tenantsList.profile : [],
										)}
										columns={columnData}
										options={{
											rowsPerPage: props.globalRowsPerPage,
											rowsPerPageOptions: dataList.PageLimitOptions,
											onChangeRowsPerPage: (numberOfRows: number) => {
												setPageState({ ...pagination, limit: numberOfRows });
												props.setGlobalRowsPerPage(numberOfRows);
											},
											filterType: 'dropdown',
											responsive: 'simple',
											filter: false,
											search: false,
											download: true,
											print: true,
											selectableRows: 'none',
											serverSide: false,
											page: pagination.page,
											count: props.tenantsList && props.tenantsList.total_record ? props.tenantsList.total_record : 0,
											textLabels: {
												body: {
													noMatch: apiCalled ? 'Sorry, no matching records found' : '',
												},
											},
											onColumnSortChange: (changedColumn: string, direction: string) => {
												setSortOrder(`${changedColumn} ${direction}`);
											},

											onTableChange: (tableAction: any, tableState: any) => {
												switch (tableAction) {
													case 'changePage':
														if (tableState.page > pagination.page) {
															setPageState({
																offset: pagination.offset + props.globalRowsPerPage,
																limit: props.globalRowsPerPage,
																page: tableState.page,
															});
														} else if (tableState.page < pagination.page) {
															setPageState({
																offset: pagination.offset - props.globalRowsPerPage,
																limit: props.globalRowsPerPage,
																page: tableState.page,
															});
														}
														break;
													case 'viewColumnsChange':
														setTableStatePersist(tableState);
														break;
												}
											},
											fixedHeader: true, // This locks the table headers at the top
											tableBodyHeight: tableHeight + 'px', // Set the height for the table body
										}}
									/>
								</Grid>
							)}
						</Container>
						<LinkableActions
							onCallBack={handleActionCallback}
							open={isActionDialog}
							onClose={handleClose}
							links={links}
						/>
						<div>
							<Modal
								disableBackdropClick={true}
								open={modalOpen}
								onClose={handleModalClose}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
							>
								<div>
									<CustomerFormModal
										getCustomers={onSearch}
										customerData={customerToEdit}
										closeModal={handleModalClose}
									/>
								</div>
							</Modal>
						</div>
					</div>
				)}
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {
		tenantsList: state.tenants.details,
		selectedFilterValues: state.ui.selectedFilterValues,
		selectedCustomer: state.ui.selectedCustomer,
		selectedDateRange: state.ui.selectedDateRange,
		authUser: state.user.authUser,
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	syncCustomer: (payload: any) => dispatch(TenantsActions.syncCustomer(payload)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
