import * as React from 'react';
import { CabinetStyles } from './cabinet.styles';
import MUIDataTable from 'mui-datatables';
import { Grid, FormControl, MuiThemeProvider, TextField, Button, Modal } from '@material-ui/core';
import { CommonStyles, getMuiTheme, getModalClasses, getModalStyle } from '../../hooks/styles';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMediaQuery } from 'react-responsive';
import AddCabinetUserModal from './add-cabinet-user.modal';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BlockIcon from '@material-ui/icons/Block';
import ReplayIcon from '@material-ui/icons/Replay';
import LinkableActions from '../../components/linkableActions';
import { GridContainer } from '../../hooks/functions';
import dataList from '../../constants/dataList';
import { orderBy } from 'lodash';

const CabinetsUsersComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();
	const searchStyles = CommonStyles();
	const tableTheme = getMuiTheme();
	const [cabinetUsers, setCabinetUsers] = React.useState(null as any);
	const [cabinetUsersLoaded, setCabinetUsersLoaded] = React.useState(false);
	const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
	const [isActionDialog, setActionDialog] = React.useState(false);
	let [links, setLinks] = React.useState(null as any);
	const [pinModalOpen, setPinModalOpen] = React.useState(false);
	const [currentCabinetUser, setCurrentCabinetUser] = React.useState(null as any);
	const roleMap: any = {
		10: 'Vendee',
		20: 'SuperVendee',
		30: 'Refiller',
		40: 'Auditor',
		50: 'Engineer',
		60: 'Factory Worker',
		70: 'Flex User',
		80: 'Flex Admin',
	};

	const handleAddUserModalClose = () => {
		setAddUserModalOpen(false);
	};

	const defaultColumns = [
		{
			label: 'Name',
			name: 'displayName',
		},
		{
			label: 'Role',
			name: 'userRole',
			options: {
				customBodyRender: (value: any) => {
					return roleMap[value];
				},
			},
		},
		{
			label: 'Enabled',
			name: 'enabled',
			options: {
				customBodyRender: (value: any) => {
					return value ? 'Yes' : 'No';
				},
			},
		},
	];

	const flexCabinetColumns = [
		{
			label: 'Name',
			name: 'displayName',
		},
		{
			label: 'Role',
			name: 'userRole',
			options: {
				customBodyRender: (value: any) => {
					return roleMap[value];
				},
			},
		},
		{
			label: 'Enabled',
			name: 'enabled',
			options: {
				customBodyRender: (value: any) => {
					return value ? 'Yes' : 'No';
				},
			},
		},
	];

	React.useEffect(() => {
		if (!cabinetUsersLoaded && !props.isLoading) {
			props.getCabinetUsers(props.cabinet.cabinetId);
			props.getAvailableUsers({ customerId: props.cabinet.customerId });
			setCabinetUsers(null);
			setCabinetUsersLoaded(true);
			return;
		}
		if (props.cabinetUsers && cabinetUsersLoaded && !cabinetUsers) {
			setCabinetUsers(props.cabinetUsers);
		}
	}, [props.cabinetUsers, cabinetUsers, cabinetUsersLoaded]);

	const filterByName = (e: any) => {
		const filter = e.target.value;
		if (!filter) {
			setCabinetUsers(props.cabinetUsers);
			return;
		}
		setCabinetUsers(
			props.cabinetUsers.filter((user: any) => {
				if (user.displayName.toLowerCase().includes(filter.toLowerCase())) {
					return user;
				}
			}),
		);
	};

	const handleOpenActionDialog = (rowData: any, tableMeta: any) => {
		setActionDialog(true);
		const currentUser = getCurrentUser(rowData);
		links = [
			{
				title: 'View Pin',
				suffix: `For ${currentUser.displayName}`,
				url: '',
				callback: { data: currentUser, action: 'view_pin' },
				icon: <FiberPinIcon />,
			},
			{
				title: 'Regenerate Pin',
				suffix: `For ${currentUser.displayName}`,
				url: '',
				callback: { data: currentUser, action: 'new_pin' },
				icon: <AutorenewIcon />,
			},
			{
				title: currentUser.enabled ? 'Disable User' : 'Enable User',
				suffix: `${currentUser.displayName}`,
				url: '',
				callback: { data: currentUser, action: currentUser.enabled ? 'disable_user' : 'enable_user' },
				icon: currentUser.enabled ? <BlockIcon /> : <ReplayIcon />,
			},
		];
		setLinks(orderBy(links, [(link: any) => link.title.toLowerCase()], ['asc']));
	};

	const getCurrentUser = (data: any) => {
		return props.cabinetUsers.find((user: any) => {
			return user.displayName === data[0] && roleMap[user.userRole] === data[1];
		});
	};

	const handleClose = () => {
		setActionDialog(false);
	};

	const actionModalCallback = (callbackObj: any) => {
		if (callbackObj.action === 'view_pin') {
			props.getSingleCabinetUser({
				callback: () => {
					setActionDialog(false);
					setPinModalOpen(true);
				},
				cabinetId: callbackObj.data.cabinetId,
				cabinetUserId: callbackObj.data.userId.toString(),
			});
			return;
		}
		if (callbackObj.action === 'new_pin') {
			updateUser({
				cabinetId: callbackObj.data.cabinetId,
				email: callbackObj.data.email,
				role: callbackObj.data.userRole,
				enabled: callbackObj.data.enabled,
				generateNewPin: true,
				close: setActionDialog,
			});
		}
		if (callbackObj.action === 'enable_user') {
			updateUser({
				cabinetId: callbackObj.data.cabinetId,
				email: callbackObj.data.email,
				role: callbackObj.data.userRole,
				enabled: 1,
				close: setActionDialog,
			});
		}
		if (callbackObj.action === 'disable_user') {
			updateUser({
				cabinetId: callbackObj.data.cabinetId,
				email: callbackObj.data.email,
				role: callbackObj.data.userRole,
				enabled: 0,
				close: setActionDialog,
			});
		}
		setCurrentCabinetUser(callbackObj.data);
		if (props.isFlexCabinet) props.changeDetected(true);
	};

	const updateUser = (payload: any) => {
		props.updateCabinetUser(payload);
	};

	return (
		<div>
			{(props.cabinetUsers || props.isFlexCabinet) && (
				<div>
					<div style={{ margin: '25px 0 10px 0' }} className={searchStyles.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								{!props.isFlexCabinet && (
									<FormControl className={classes.formControl}>
										<TextField
											style={{ width: isDesktopOrLaptop ? 300 : 200 }}
											label="Search User Names"
											onChange={filterByName}
										/>
									</FormControl>
								)}
								{props.authUser?.authorizationCheck({
									action: 'create',
									resource: 'users',
									tenantId: props.cabinet.customerId,
								}) && (
									<FormControl
										className={classes.formControl}
										style={{ float: props.isFlexCabinet ? 'left' : 'right' }}
									>
										<Button
											style={{ marginTop: 10 }}
											className={classes.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={() => setAddUserModalOpen(true)}
										>
											Add Cabinet User
										</Button>
									</FormControl>
								)}
							</Grid>
						</Grid>
					</div>

					<MUIDataTable
						data={cabinetUsers || []}
						columns={props.isFlexCabinet ? flexCabinetColumns : defaultColumns}
						options={{
							rowsPerPageOptions: dataList.PageLimitOptions,
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: false,
							download: true,
							print: true,
							selectableRows: 'none',
							onRowClick: handleOpenActionDialog,
						}}
					/>
				</div>
			)}
			<Modal
				disableBackdropClick={false}
				open={addUserModalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<AddCabinetUserModal
						availableUsers={props.availableUsers}
						cabinet={props.cabinet}
						cabinetUsers={props.cabinetUsers}
						closeModal={handleAddUserModalClose}
						isFlexCabinet={props.isFlexCabinet}
						refreshCabinetUsers={() => {
							if (props.isFlexCabinet) props.changeDetected(true);
							setCabinetUsersLoaded(false);
						}}
					/>
				</div>
			</Modal>
			<Modal open={pinModalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<div>
					{props.singleCabinetUser && (
						<div style={getModalStyle()} className={modalClasses.paper}>
							<div className={modalClasses.closeBtn} onClick={() => setPinModalOpen(false)}>
								<CancelIcon color="disabled"></CancelIcon>
							</div>
							{GridContainer([
								{ label: 'Cabinet', value: props.cabinet.cabinetId },
								{ label: 'User', value: `${props.singleCabinetUser.displayName} (${props.singleCabinetUser.email})` },
							])}
							{GridContainer([
								{ label: `${roleMap[props.singleCabinetUser.userRole]} Pin`, value: props.singleCabinetUser.pin },
							])}
						</div>
					)}
				</div>
			</Modal>
			<LinkableActions onCallBack={actionModalCallback} open={isActionDialog} onClose={handleClose} links={links} />
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	cabinetUsers: state.cabinet.users,
	singleCabinetUser: state.cabinet.singleCabinetUser,
	availableUsers: state.cabinet.availableUsers,
	error: state.cabinet.detailsError,
	isLoading: state.ui.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetUsers: (cabinetId: string) => dispatch(CabinetActions.getCabinetUsers(cabinetId)),
	getSingleCabinetUser: (payload: any) => dispatch(CabinetActions.getSingleCabinetUser(payload)),
	updateCabinetUser: (cabinetUser: any) => dispatch(CabinetActions.updateCabinetUser(cabinetUser)),
	getAvailableUsers: (filters: any) => dispatch(CabinetActions.getAvailableUsers(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetsUsersComponent);
