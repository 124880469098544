import * as React from 'react';
import { Grid, Modal } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import EmptyContent from '../../components/emptyContent';
import { connect } from 'react-redux';
import { AlertActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';
import { CommonStyles } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import IncidentModal from './incident.modal';
import dataList from '../../constants/dataList';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { IncidentButton, IncidentIgnoreTimePicker } from '../../components/incidents';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addMinutes } from 'date-fns';
import { GetUserDatetimeFormat, GetUserTimezone, UserTimezoneFormat } from '../../hooks/functions';
import StyledChip from '../../components/styledChip';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';

const IncidentsComponent: React.FC<any> = (props: any) => {
	const styles = CommonStyles();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	const firstRender = React.useRef(true);
	const [incidentModalOpen, setIncidentModalOpen] = React.useState(false);
	const [currentIncident, setCurrentIncident] = React.useState<any>(null);
	const [order, setOrder] = React.useState<string>('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [ignoreExpiration, setIgnoreExpiration] = React.useState<MaterialUiPickersDate>(
		addMinutes(new Date(), 30) as any,
	);
	const severityColorMap: any = { low: '#008000', high: '#e30926' };
	const statusColorMap: any = { Open: '#e30926', Closed: '#0a8df2', Acknowledged: '#edcb07', Ignored: '#f2820a' };
	const [pagination, setPagination] = React.useState({
		limit: props.globalRowsPerPage,
		page: 1,
	});

	const getColumns = () => {
		const columns = [
			{
				label: 'Customer Id',
				name: 'customerId',
			},
			{
				label: 'Device Id',
				name: 'cabinetId',
			},
			{
				label: 'Alert Type',
				name: 'alertType',
			},
			{
				label: 'Severity',
				name: 'severity',
				customRender: (value: string) => {
					return <StyledChip color={severityColorMap[value]} label={value} />;
				},
			},
			{
				label: 'Status',
				name: 'incidentState',
				customRender: (value: string) => {
					if (statusColorMap[value]) {
						return <StyledChip color={statusColorMap[value]} label={value} />;
					}
					return <div>{value}</div>;
				},
			},
			{
				label: 'Last Alert Timestamp',
				name: 'lastAlertTimestamp',
				customRender: (value: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
			{
				label: 'Created',
				name: 'createdAt',
				customRender: (value: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
			{
				label: 'State Updated',
				name: 'stateUpdatedAt',
				customRender: (value: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
			{
				label: 'Ignore Expiration',
				name: 'ignoreExpiration',
				display: false,
				customRender: (value: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
		];
		return columns.map((column, index) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					customBodyRender: column.customRender ? column.customRender : null,
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display ?? true,
					change: false,
				},
			};
		});
	};

	const onChangeCustomer = (customer: any) => {
		if (customer) {
			props.setSelectedCustomer(customer);
			window.history.pushState({}, '', `/incidents/${customer.customerId}`);
		} else {
			props.setSelectedCustomer(null);
		}
	};

	const getFilter = () => {
		const filter = [];
		if (props.selectedCustomer) {
			filter.push({ key: 'customerId', value: props.selectedCustomer.customerId });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.INCIDENT_SEVERITY) {
			filter.push({ key: 'severity', value: props.selectedFilterValues.INCIDENT_SEVERITY });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.INCIDENT_STATUS) {
			filter.push({ key: 'incidentState', value: props.selectedFilterValues.INCIDENT_STATUS });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.CABINET_ID) {
			filter.push({ key: 'cabinetId', value: props.selectedFilterValues.CABINET_ID });
		}

		if (props.selectedFilterValues && props.selectedFilterValues.ALERT_TYPE) {
			filter.push({ key: 'alertType', value: props.selectedFilterValues.ALERT_TYPE });
		}

		filter.push({ key: 'limit', value: props.globalRowsPerPage });
		filter.push({ key: 'page', value: pagination.page });
		order && filter.push({ key: 'order', value: order });

		return filter;
	};

	const onSearchClick = () => {
		props.getIncidents(getFilter());
	};

	const handleCloseIncidentModal = () => {
		setIncidentModalOpen(false);
	};

	const handleIncidentModal = (rowData: any, tableMeta: any) => {
		const incident = props.incidents.result[tableMeta.dataIndex];
		props.getIncidentHistory({
			incidentId: incident.id,
			callback: () => {
				setCurrentIncident(incident);
				setIncidentModalOpen(true);
			},
		});
	};

	const updateIncident = (payload: any, callback: string) => {
		if (callback === 'status') {
			payload.callback = () => {
				onSearchClick();
				setIncidentModalOpen(false);
			};
		}
		if (callback === 'comment') {
			payload.callback = () => {
				props.getIncidentHistory({ incidentId: currentIncident.id });
			};
		}
		props.updateIncident(payload);
	};

	const selectionToolbar = (selectedRows: any, displayData: any, setNewSelectedRows: any) => {
		let incidents: any = [];
		selectedRows.data.map((row: any) => {
			incidents.push(props.incidents.result[row.index]);
		});

		const statusButtons: any[] = [
			{ label: 'Acknowledge', value: 'Acknowledged' },
			{ label: 'Close', value: 'Closed' },
			{ label: 'Ignore', value: 'Ignored' },
		];
		return (
			<div style={{ display: 'flex' }}>
				{statusButtons.map((status: any, index: number) => (
					<IncidentButton
						key={index}
						text={`Set ${status.label}`}
						styles={styles.tableButton}
						onButtonClick={() => {
							incidents.map((incident: any) => {
								switch (incident.incidentState) {
									case 'Closed':
										return;
									case 'Open':
										break;
									case 'Ignored':
										if (status.value === 'Ignored') {
											return;
										}
										break;
									case 'Acknowledged':
										if (status.value != 'Closed') {
											return;
										}
										break;
								}
								updateIncident(
									{
										incidentId: incident.id,
										body: {
											state: status.value,
											comments: `Incident ${status.value}.`,
											ignoreExpiration: status.value === 'Ignored' ? ignoreExpiration : undefined,
										},
									},
									'status',
								);
							});

							//deselect after action performed
							setNewSelectedRows([]);
						}}
					/>
				))}
				<IncidentIgnoreTimePicker ignoreExpiration={ignoreExpiration} setIgnoreExpiration={setIgnoreExpiration} />
			</div>
		);
	};

	const handleGetCsv = (tableColumns: any) => {
		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);

		const filterPayloadAsArray = [...getFilter()];
		const filterPayloadAsObj: any = {};

		//need to convert filters to a different format to agree with backend datasource query object
		filterPayloadAsArray.forEach(fo => {
			filterPayloadAsObj[fo.key] = fo.value;
		});

		const filter: any = {
			...filterPayloadAsObj,
			limit: 9999,
		};

		const params = constructEnqueueParams(
			'RNI_UIEXPORT_IncidentsReport',
			props.authUser?.record.email,
			filter,
			{ columnsRequested: columnsRequested },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (firstRender.current && props.authUser) {
			firstRender.current = false;
		} else {
			onSearchClick();
		}
	}, [pagination, order]);

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 72; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<>
			<div style={{ marginBottom: 28, padding: 9 }}>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer
							pageName="INCIDENTS"
							executeSearch={onSearchClick}
							handleTenantSelect={onChangeCustomer}
						/>
					</Grid>
				</Grid>
			</div>
			{props.incidents && props.incidents.result && props.incidents.result.length > 0 ? (
				<div>
					<MUIDataTable
						data={props.incidents?.result || []}
						columns={getColumns()}
						options={{
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: false,
							download: true,
							print: true,
							selectableRows: 'multiple',
							serverSide: true,
							count: props.incidents && props.incidents.total_record ? props.incidents.total_record.count : 0,
							page: pagination.page - 1,
							rowsPerPage: props.globalRowsPerPage,
							rowsPerPageOptions: dataList.PageLimitOptions,
							fixedHeader: true, // This locks the table headers at the top
							tableBodyHeight: tableHeight + 'px', // Set the height for the table body
							onChangeRowsPerPage: (numberOfRows: number) => {
								setPagination({ ...pagination, limit: numberOfRows });
								props.setGlobalRowsPerPage(numberOfRows);
							},
							customToolbarSelect: selectionToolbar,
							onRowClick: handleIncidentModal,
							onTableChange: (action: any, tableState: any) => {
								switch (action) {
									case 'changePage':
										setPagination({ limit: props.globalRowsPerPage, page: tableState.page + 1 });
										break;
									case 'viewColumnsChange':
										setTableStatePersist(tableState);
										break;
								}
							},
							onColumnSortChange: (changedColumn: string, direction: string) => {
								setOrder(`${changedColumn} ${direction}`);
							},
							onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
								handleGetCsv(cols);
								return false;
							},
						}}
					/>
				</div>
			) : (
				<EmptyContent message="Select the filter criteria above to view incident information." />
			)}
			<Modal
				open={incidentModalOpen}
				onClose={handleCloseIncidentModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<IncidentModal
						incident={currentIncident}
						history={props.incidentHistory}
						close={handleCloseIncidentModal}
						getLocalTimestamp={(value: any) => {
							return value ? UserTimezoneFormat(props.properties, value) : '';
						}}
						updateIncident={updateIncident}
					/>
				</div>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	incidents: state.alerts.incidents,
	incidentHistory: state.alerts.incident,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	properties: Array.isArray(state.user.authUser?.record?.properties) ? state.user.authUser.record.properties : [],
	tenants: state.tenants.tenantsList?.result || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	getIncidents: (payload: any) => dispatch(AlertActions.getIncidents(payload)),
	getIncidentHistory: (payload: any) => dispatch(AlertActions.getIncidentHistory(payload)),
	updateIncident: (payload: any) => dispatch(AlertActions.updateIncident(payload)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsComponent);
