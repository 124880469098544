import React from 'react';
import { CommonStyles, getMuiTheme } from '../../hooks/styles';
import { Grid, FormControl, TextField, Button, MuiThemeProvider, Paper } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Styles } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import { SelectBox } from '../selectBox';

const NestConfig: React.FC<any> = (props: any) => {
	const classes = Styles();

	const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
	const [valuesLocalCache, setValuesLocalCache] = React.useState<any>({
		productId: props.nestObj.productId,
		capacity: props.nestObj.capacity,
	});

	React.useEffect(() => {
		resetToLastSaved(); //on change via global reset from above
	}, [props.nestObj]);

	const offsetCords: number[] = [props.nestIndex] || [props.nestObj.rowOffset, props.nestObj.nestOffset];
	const compartmentOffset: number = props.compartmentOffset;
	const drawerOffset: number = props.drawerOffset;

	const saveUpdatedValues = () => {
		props.updateNestValues(offsetCords, drawerOffset, compartmentOffset, {
			productId: valuesLocalCache.productId,
			capacity: valuesLocalCache.capacity,
		});
	};

	const resetToLastSaved = () => {
		setValuesLocalCache({
			productId: props.nestObj.productId,
			capacity: props.nestObj.capacity,
		});
	};

	const updateProp = (value: any, propName: string) => {
		const copy = { ...valuesLocalCache };
		copy[propName] = value;

		setValuesLocalCache(copy);
	};

	const generateProductChoices = () => {
		const opts: any[] = [];

		// updated to include productId as a label 
		// in case the product name is not available
		props.productOptions.forEach((po: any) => {
			opts.push({
				label: po.product?.productName || po?.productName || po.productId,
				value: po.productId,
			});
		});

		return opts;
	};

	const lookUpProductNameById = (id: string) => {
		if (!props.productOptions) return '';

		const prod = props.productOptions.find((p: any) => {
			return p.productId == id;
		});

		return prod?.productName || prod?.displayName || '';
	};

	const deleteSelf = () => {
		if (props.deleteSelf) {
			props.deleteSelf();
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'start',
			}}
		>
			{props.showCoords && (
				<div className={classes.rowNestCoords}>
					Row {props.nestObj.rowOffset + 1}, Nest {props.nestObj.nestOffset + 1}
				</div>
			)}
			<div className={classes.configCellBody}>
				{!isEditMode && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								marginRight: 10,
							}}
						>
							<EditIcon
								onClick={() => {
									setIsEditMode(true);
								}}
								style={{
									width: 15,
									height: 15,
									cursor: 'pointer',
								}}
							/>
							<CancelIcon
								onClick={deleteSelf}
								style={{
									width: 15,
									height: 15,
									marginLeft: 10,
									cursor: 'pointer',
								}}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								fontSize: 13,
							}}
						>
							<div>
								<span className={classes.configCellLabel}>Product:</span>{' '}
								{valuesLocalCache.productId
									? `${lookUpProductNameById(valuesLocalCache.productId)} (${valuesLocalCache.productId})`
									: 'None'}
							</div>
							<div>
								<span className={classes.configCellLabel}>Capacity:</span> {valuesLocalCache.capacity}
							</div>
						</div>
					</div>
				)}
				{isEditMode && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<CheckCircleIcon
							onClick={() => {
								saveUpdatedValues();
								setIsEditMode(false);
							}}
							style={{
								cursor: 'pointer',
							}}
						/>

						<CancelIcon
							onClick={() => {
								resetToLastSaved();
								setIsEditMode(false);
							}}
							style={{
								marginRight: 6,
								cursor: 'pointer',
							}}
						/>

						<FormControl className={classes.formControl}>
							<SelectBox
								style={{
									width: 300,
								}}
								selected={valuesLocalCache.productId}
								inputLabel={'Select a product'}
								listItems={generateProductChoices()}
								onChangeItem={(value: any) => {
									updateProp(value, 'productId');
								}}
							/>
						</FormControl>

						<FormControl className={classes.formControl}>
							<TextField
								value={valuesLocalCache.capacity}
								label="Capacity"
								onChange={({ target }) => {
									updateProp(target.value, 'capacity');
								}}
							/>
						</FormControl>
					</div>
				)}
			</div>
		</div>
	);
};

export default NestConfig;
