import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import InvoiceHistory from './invoice-history.component';
import { DashboardStyles } from '../../hooks/styles';
import { UIAction } from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import InvoiceRequestsComponent from './invoice-requests.component';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component="span">{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `invoice-tab-${index}`,
		'aria-controls': `invoice-tabpanel-${index}`,
	};
};

const Invoices: React.FC<any> = (props: any) => {
	const [isAdminPage, setIsAdminPage] = React.useState(window.location.pathname.indexOf('/admin-invoices') > -1);
	const classes = DashboardStyles();
	const isFirstRender = useRef(true);
	const dispatch = useDispatch();
	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	const [selectedTab, setSelectedTab] = React.useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	React.useEffect(() => {
		setIsAdminPage(window.location.pathname.indexOf('/admin-invoices') > -1);
	});

	return (
		<BasePage title={Constants.PAGE_TITLE.INVOICES}>
			<div>
				<div className={classes.appBarSpacer}></div>
				<>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs">
							<Tab label="Invoice Requests" {...a11yProps(0)} />
							<Tab label="Invoice History" {...a11yProps(1)} />
						</Tabs>
					</Box>
					<TabPanel value={selectedTab} index={0}>
						Invoice Requests
						<InvoiceRequestsComponent />
					</TabPanel>
					<TabPanel value={selectedTab} index={1}>
						<InvoiceHistory />
					</TabPanel>
				</>
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {
		authUser: state.user && state.user.authUser && state.user.authUser.record ? state.user.authUser.record : null,
	};
};

export default connect(mapStateToProps)(Invoices);
