import Api from '../lib/api';
import { AUDIT } from '../constants/api.url';

export class AuditService {
	private static instance: AuditService;
	private constructor() {}

	public static getInstance(): AuditService {
		if (!AuditService.instance) {
			AuditService.instance = new AuditService();
		}
		return AuditService.instance;
	}

	public getAuditList = async (filter?: any): Promise<any> => {
		const params = filter ? [{ key: 'filter', value: JSON.stringify(filter) }] : undefined;
		return await Api.get(`${AUDIT.URL}`, params);
	};

	public getAuditDetails = async (id?: any): Promise<any> => {
		return await Api.get(`${AUDIT.URL}/${id}`);
	};

	public getEventTypes = async (): Promise<any> => {
		return await Api.get(`${AUDIT.EVENT_TYPES}`);
	};
}
