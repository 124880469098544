import * as React from 'react';
import { CommonStyles } from '../../../hooks/styles';
import {
	Button,
	TextField,
	FormControl,
	Grid,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Loading from '../../../components/loading';
import { useParams } from 'react-router-dom';

interface InventorySyncComponentProps {
	flexCabinetProductList: any[] | null;
	sendInventorySync: (payload: any) => void;
}

const InventorySyncComponent: React.FC<any> = (props: InventorySyncComponentProps) => {
	const classes = CommonStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
	const [inputs, setInputs] = React.useState<any>({});
	const [syncAction, setSyncAction] = React.useState<string>('add');
	const [allProductsFlag, setAllProductsFlag] = React.useState<string>('YES');
	const [formErrors, setFormErrors] = React.useState<any>({});

	const submitInventorySync = () => {
		let hasErrors = false;
		let errors: any = {};
		let inputKeys = [];
		if (allProductsFlag === 'NO') inputKeys.push('productId');
		inputKeys.forEach((key: string) => {
			if (!inputs[key]) {
				hasErrors = true;
				errors[key] = true;
			}
		});

		if (hasErrors) {
			setFormErrors(errors);
			return;
		}

		let requestBody: any = {
			allProducts: allProductsFlag === 'YES' ? true : false,
		};

		if (allProductsFlag === 'NO') {
			requestBody.productData = {
				productId: inputs.productId,
			};
		}

		const payload = {
			requestBody: requestBody,
			syncAction: syncAction,
			cabinetId: cabinetId,
			success: () => {
				// clear form
				setInputs({});
				setAllProductsFlag('YES');
			},
		};

		props.sendInventorySync(payload);
	};

	return (
		<>
			{!props.flexCabinetProductList ? (
				<Loading message="" />
			) : (
				<Grid container>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<ToggleButtonGroup
								style={{ margin: '5px 0 8px 0' }}
								size="small"
								value={syncAction}
								exclusive
								onChange={(event: any, value: any) => setSyncAction(value)}
							>
								<ToggleButton style={{ fontSize: '10px' }} value="add" aria-label="insert">
									Add
								</ToggleButton>
								<ToggleButton style={{ fontSize: '10px' }} value="delete" aria-label="remove">
									Delete
								</ToggleButton>
							</ToggleButtonGroup>
						</FormControl>
						<Grid item xs={12}>
							<FormControl component="fieldset" className={classes.formControl}>
								<FormLabel component="legend">All Products</FormLabel>
								<RadioGroup
									className={classes.radioGroup}
									aria-label="all-products"
									name="all-products"
									value={allProductsFlag}
									onChange={(event: any) => setAllProductsFlag(event.target.value)}
								>
									<FormControlLabel
										className={classes.radioLabel}
										value={'YES'}
										control={<Radio color="default" />}
										label="Yes"
									/>
									<FormControlLabel value={'NO'} control={<Radio color="default" />} label="No" />
								</RadioGroup>
							</FormControl>
						</Grid>
						{allProductsFlag === 'NO' && (
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<Autocomplete
										id="product-combo-box"
										options={props.flexCabinetProductList || []}
										getOptionLabel={(option: any) => '(' + option.productId + ') ' + option.product?.productName}
										style={{ width: '95%' }}
										onChange={(event: any, value: any) => {
											setInputs({ ...inputs, productId: value?.productId || '' });
											setSelectedProduct(value || null);
											setFormErrors({ ...formErrors, productId: null });
										}}
										value={selectedProduct || null}
										renderInput={(params: any) => (
											<TextField
												{...params}
												variant="outlined"
												label={'Select Product'}
												error={formErrors.productId ? true : false}
												helperText={formErrors.productId ? 'Please enter a product id' : ''}
												required
											/>
										)}
									/>
								</FormControl>
							</Grid>
						)}

						<Grid item xs={12}>
							<Button
								onClick={submitInventorySync}
								type="button"
								className={`${classes.searchButton}`}
								variant="contained"
								color="primary"
								style={{ marginTop: 20 }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default InventorySyncComponent;
