import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const PersonaManagementStyles = makeStyles(theme => ({
	personaBlock: {
		margin: '10px',
		padding: '18px',
		border: '1px solid #ccc',
	},
	personaName: {
		fontSize: '16px',
		fontWeight: 800,
	},
	personaList: {
		listStyleType: 'none',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: '16px 0 0 0',
		alignItems: 'center',
		margin: 0,
	},
	personaListItem: {
		margin: '0 10px 6px 0',
	},
	personaListItemEdit: {
		padding: '10px',
		margin: '10px',
		border: '1px solid #ccc',
		borderRadius: '5px',
		display: 'flex',
		flexDirection: 'row',
	},
	roleKeyContainer: {
		width: 'auto',
		textAlign: 'right',
		paddingRight: '10px',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	roleSelectorContainer: {
		width: '100px',
		height: '30px',
	},
	roleDeleteContainer: {
		width: '20px',
		height: '30px',
		display: 'flex',
		alignItems: 'center',
	},
	personaEditWrapper: {
		marginLeft: '10px',
	},
	personaButtonsRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	personaListEditItem: {
		display: 'flex',
		flexDirection: 'row',
	},
	personaEditIcon: {
		width: '15px',
		height: '15px',
	},
	newPersonaInput: {
		margin: '10px',
	},
	newPersonaInputWrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
	errorMessage: {
		padding: '10px 0 0 10px',
		color: globalColors.ERROR_RED,
	},
	inputWrapper: {
		margin: '10px 0',
		display: 'flex',
	},
}));
