import * as React from 'react';
import { getModalClasses, getModalStyle } from '../../hooks/styles';
import { Grid, FormControl, Button, Modal, Paper, TextField } from '@material-ui/core';
import { CabinetStyles } from './cabinet.styles';
import { useMediaQuery } from 'react-responsive';
import { CabinetActions } from '../../redux/actions';
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { cloneDeep } from 'lodash';

const CabinetLocationTracking: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();

	const [cabinet, setCabinet] = React.useState(props.cabinet);
	const [locationName, setLocationName] = React.useState('');
	const [zipCode, setZipCode] = React.useState('');
	const [addLocationModalOpen, setAddLocationModalOpen] = React.useState(false);
	const [zipCodeError, setZipCodeError] = React.useState(false);
	const [locationNameError, setLocationNameError] = React.useState(false);

    const zipRegex = new RegExp(/^\d{5}$/);

	const handleAddLocationModalClose = (modalState: boolean) => {
		setAddLocationModalOpen(modalState);
	};

	const handleAddLocation = () => {
		let errors = 0
		if(!locationName){
			setLocationNameError(true)
			errors++
		}
        if(!zipRegex.test(zipCode)) {
            setZipCodeError(true)
			errors++
        }
		if(errors > 0) return
		
		props.addCabinetLocationTracking({
			location: {cabinetId: cabinet.cabinetId, locationName, zipCode},
			success: (cabinetData: any) => {
                setCabinet(cabinetData)
				setAddLocationModalOpen(false);
                setLocationName('')
                setZipCode('')
			}
		});
	};

	const onDelete = async (rowsDeleted: any) => {
		let deletedLocationIndexes: any[] = [];
		const locationIdsToDelete = rowsDeleted.data.map((indexMeta: { index: number; dataIndex: number }) => {
			deletedLocationIndexes.push(indexMeta.dataIndex);
			return cabinet.cabinetLocationTrackings[indexMeta.dataIndex].id;
		});

		props.deleteCabinetLocationTracking({
			locations: {
				cabinetId: cabinet.cabinetId,
				locationIds: locationIdsToDelete,
			},
			success: (cabinetData: any) => {
                setCabinet(cabinetData)
			}
		});
	};

	const locationTrackingColumns = [
		{
			name: 'locationName',
			label: 'Location Name',
		},
		{
			name: 'zipCode',
			label: 'Zip Code',
		},
	];

	const classes = CabinetStyles();

	return (
		<div>
			{props.authUser?.authorizationCheck({
				action: 'create',
				resource: 'cabinets',
				tenantId: props.selectedCabinet.customerId,
			}) && (
				<FormControl className={classes.buttonFormControl} style={isDesktopOrLaptop ? { marginTop: 14 } : {}}>
					<Button
						variant="contained"
						color="secondary"
						className={classes.viewAllBtn}
						style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
						onClick={() => {
							setAddLocationModalOpen(true);
						}}
					>
						Add Location
					</Button>
				</FormControl>
			)}
			<MUIDataTable
				options={{
					onRowsDelete: onDelete,
				}}
				columns={locationTrackingColumns}
				data={cabinet.cabinetLocationTrackings}
			/>

			<Modal
				open={addLocationModalOpen}
				onClose={() => handleAddLocationModalClose(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{ ...getModalStyle(), padding: '20px', width: isDesktopOrLaptop ? 'auto' : '90%' }}
					className={modalClasses.paper}
				>
					<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
						<div className={classes.inventoryActionHeading}>Add Location</div>
						<div style={{ width: '420px' }}>
							<Grid container>
								<Grid item xs={6}>
									<FormControl required variant="outlined" className={classes.formControl}>
										<TextField
											required
											style={{ width: 210 }}
											type="text"
											label="Location Name"
											variant="outlined"
											error={locationNameError}
											helperText={locationNameError ? 'Please enter a Location Name' : ''}
											value={locationName}
											onChange={e => {
                                                setLocationNameError(false)
												setLocationName(e.target.value)
											}}
										/>
									</FormControl>
								</Grid>
                                <Grid item xs={6}>
									<FormControl required variant="outlined" className={classes.formControl}>
										<TextField
											required
											style={{ width: 210 }}
											type="text"
											label="Zip Code"
											variant="outlined"
											error={zipCodeError}
											helperText={zipCodeError ? 'Please enter a 5 digit Zip Code' : ''}
											value={zipCode}
											onChange={e => {
                                                setZipCodeError(false)
                                                setZipCode(e.target.value)
                                            }}
										/>
									</FormControl>
								</Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.viewAllBtn}
                                        style={{ margin: `12px 0` }}
                                        onClick={handleAddLocation}
                                    >
                                        Add Location
                                    </Button>
                                </Grid>
							</Grid>
						</div>
					</Paper>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	selectedCabinet: state.cabinet.selectedCabinet,
	foundProvider: state.cabinet.foundProvider,
	error: state.cabinet.error,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
	addCabinetLocationTracking: (payload: any) => dispatch(CabinetActions.addCabinetLocationTracking(payload)),
	refreshSelectedCabinet: (payload: any) => dispatch(CabinetActions.setCabinet(payload)),
	deleteCabinetLocationTracking: (payload: any) => dispatch(CabinetActions.deleteCabinetLocationTracking(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetLocationTracking);
