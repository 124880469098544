import { action } from 'typesafe-actions';
import { USER_MANAGEMENT } from '../constants/actions';
import { UserRecord } from '../models/user/user';

export const getUserListStartAction = (payload: any | null) => action(USER_MANAGEMENT.LIST_REQUEST, payload);

export const getUserListCompleteAction = (response: UserRecord[]) => action(USER_MANAGEMENT.LIST_RESPONSE, response);

export const createUserAction = (userData: UserRecord) => action(USER_MANAGEMENT.CREATE_REQUEST, userData);
export const updateUserAction = (userData: UserRecord) => action(USER_MANAGEMENT.UPDATE_REQUEST, userData);
export const userExistsCheckAction = (email: string) => action(USER_MANAGEMENT.USER_EXISTS_REQUEST, email);
export const userExistsCheckCompleteAction = (response: string) =>
	action(USER_MANAGEMENT.USER_EXISTS_RESPONSE, response);
export const clearUserCheckAction = () => action(USER_MANAGEMENT.CLEAR_USER_CHECK);
export const userErrorAction = (error: any) => action(USER_MANAGEMENT.ERROR, error);
export const importUsers = (payload: any) => action(USER_MANAGEMENT.IMPORT_USERS, payload);
export const setUserImportResult = (result: any) => action(USER_MANAGEMENT.SET_IMPORT_RESULT, result);
export const setVimeoAppAuthTokenResult = (result: any) =>
	action(USER_MANAGEMENT.SET_VIMEO_APP_AUTH_TOKEN_RESULT, result);
export const resetUsersData = () => action(USER_MANAGEMENT.RESET_USERS_DATA);
export const getVimeoAppAuthToken = () => action(USER_MANAGEMENT.GET_VIMEO_APP_AUTH_TOKEN);
export const sendSupportRequest = (supportParams: any) => action(USER_MANAGEMENT.SEND_SUPPORT_REQUEST, supportParams);
export const setCreateUserError = (error: string[]) => action(USER_MANAGEMENT.SET_CREATE_USER_ERROR, error);
export const setUserImportSuccess = (message: string[]) => action(USER_MANAGEMENT.SET_USER_IMPORT_SUCCESS, message);
export const getSingleUser = (payload: any) => action(USER_MANAGEMENT.GET_SINGLE_USER, payload);
export const setSingleUser = (singleUser: any) => action(USER_MANAGEMENT.SET_SINGLE_USER, singleUser);
export const resendInviteEmail = (userEmail: string) => action(USER_MANAGEMENT.RESEND_INVITE_EMAIL, userEmail);
export const setPassword = (payload: any) => action(USER_MANAGEMENT.SET_PASSWORD, payload);
export const deleteUser = (user: any) => action(USER_MANAGEMENT.DELETE_USER, user);

export const getInvoiceLinkUserListStartAction = (payload: any | null) =>
	action(USER_MANAGEMENT.INVOICE_LINK_LIST_REQUEST, payload);
export const getInvoiceLinkUserListCompleteAction = (response: any) =>
	action(USER_MANAGEMENT.INVOICE_LINK_LIST_RESPONSE, response);
export const createInvoiceLinkUser = (payload: any | null) => action(USER_MANAGEMENT.CREATE_INVOICE_LINK_USER, payload);
export const deleteInvoiceLinkUser = (payload: any | null) => action(USER_MANAGEMENT.DELETE_INVOICE_LINK_USER, payload);
