import * as React from 'react';
import { CommonStyles, getMuiTheme } from '../../hooks/styles';
import { Grid, FormControl, TextField, Button, MuiThemeProvider } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { CabinetStyles } from './cabinet.styles';
import { SelectBox } from '../../components/selectBox';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import Loading from '../../components/loading';

const CabinetConfiguration: React.FC<any> = (props: any) => {
	const searchStyles = CommonStyles();
	const classes = CabinetStyles();
	const tableTheme = getMuiTheme();

	return (
		<div>
			{!props.error && !props.configError && (
				<div className={searchStyles.searchWrap}>
					<Grid container>
						<Grid item xs={12}>
							<FormControl className={classes.formControl}>
								<Autocomplete
									id="product-select-box"
									options={props.getProductFilterList()}
									getOptionLabel={(option: any) => '(' + option.itemId + ') ' + option.name}
									style={{ width: 300 }}
									value={props.filter.product}
									onChange={(event: any, value: any) => props.setFilter({ ...props.filter, product: value })}
									renderInput={(params: any) => <TextField {...params} label="Search Products" />}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<SelectBox
									refresh={props.filterReset}
									style={{ width: 100 }}
									inputLabel={'Holder'}
									emptyItemLabel={'Choose'}
									listItems={[
										{ label: '0', value: '0' },
										{ label: '1', value: '1' },
									]}
									onChangeItem={(value: any) => props.setFilter({ ...props.filter, holder: value })}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<SelectBox
									refresh={props.filterReset}
									style={{ width: 100 }}
									inputLabel={'Dispenser'}
									emptyItemLabel={'Choose'}
									listItems={props.getDispenserList()}
									onChangeItem={(value: any) => props.setFilter({ ...props.filter, dispenser: value })}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<SelectBox
									refresh={props.filterReset}
									style={{ width: 100 }}
									inputLabel={'Capacity'}
									emptyItemLabel={'Choose'}
									listItems={props.getCapacityFilterList()}
									onChangeItem={(value: any) => props.setFilter({ ...props.filter, capacity: value })}
								/>
							</FormControl>
							<FormControl variant="outlined" className={classes.buttonFormControl}>
								<Button
									onClick={props.resetFilter}
									type="button"
									className={classes.searchButton}
									variant="contained"
									color="primary"
								>
									Reset
								</Button>
							</FormControl>
						</Grid>
					</Grid>
				</div>
			)}

			{props.configError ? (
				<>
					<Alert severity="error">
						Unable to retrieve product configuration for this cabinet. Please contact support.
					</Alert>
					<br />
					<br />
				</>
			) : (
				<>
					{props.tableData ? (
						<MUIDataTable
							data={props.tableData}
							columns={[
								{
									label: 'Holder',
									name: 'holder',
								},
								{
									label: 'Dispenser',
									name: 'dispenser',
								},
								{
									label: 'Product Name',
									name: 'name',
								},
								{
									label: 'Product Id',
									name: 'itemId',
								},
								{
									label: 'PAR Active',
									name: 'parActive',
									customBodyRender: (value: number, tableMeta: any, updateValue: any) => {
										return value === 1 ? 'True' : 'False'
									}
								},
							].map(column => ({
								label: column.label,
								name: column.name,
								options: {
									filter: true,
									display: true,
									change: false,
									customBodyRender: column.customBodyRender ? column.customBodyRender : undefined
								},
							}))}
							options={{
								filterType: 'dropdown',
								responsive: 'simple',
								filter: false,
								search: false,
								download: true,
								print: true,
								selectableRows: 'none',
							}}
						/>
					) : (
						<Loading message="" />
					)}
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	configError: state.cabinet.configError,
});

export default connect(mapStateToProps)(CabinetConfiguration);
