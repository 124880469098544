import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { DashboardStyles } from '../../hooks/styles';

import InvoiceRequestsComponent from './invoice-requests.component';
import { Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AbwSelectionParams } from './abw-selection-params.model';

const AbwSelection: React.FC<any> = (props: any) => {
	const classes = DashboardStyles();
	const isFirstRender = useRef(true);
	const dispatch = useDispatch();
	const params: AbwSelectionParams = useParams();

	const autoload = params.autoload === 'true' ? true : false;

	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	return (
		<BasePage title={'ABW Selection'}>
			<div>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					<InvoiceRequestsComponent autoload={autoload} />
				</Container>
			</div>
		</BasePage>
	);
};

export default AbwSelection;
