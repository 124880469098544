import * as React from 'react';
import { Grid, Modal } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import EmptyContent from '../../components/emptyContent';
import { connect } from 'react-redux';
import { InventoryManagementActions, TenantsActions, UIAction } from '../../redux/actions';
import { CommonStyles } from '../../hooks/styles';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import dataList from '../../constants/dataList';
import SuspendedTransactionModal from './suspended-transaction.modal';
import { tablePagination } from '../../hooks/functions/TablePagination';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { UserTimezoneFormat } from '../../hooks/functions';

const getLocalTimestamp = (value: any) => {
	if (!value) {
		return '';
	}
	const timestamp = moment.utc(value, 'YYYY/MM/DD HH:mm:ss');
	return timestamp.local().format('YYYY/MM/DD HH:mm:ss');
};

const SuspendedTransactionsComponent: React.FC<any> = (props: any) => {
	const styles = CommonStyles();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});

	const firstRender = React.useRef(true);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [currentTransaction, setCurrentTransaction] = React.useState<any>(null);
	const [order, setOrder] = React.useState<string>('');
	const [pagination, setPagination] = React.useState({
		limit: props.globalRowsPerPage,
		page: 0,
		offset: 0,
	});

	const getColumns = () => {
		const columns = [
			{
				label: 'Customer Id',
				name: 'customerId',
			},
			{
				label: 'Device Id',
				name: 'cabinetId',
			},
			{
				label: 'Product',
				name: 'productItemId',
			},
			{
				label: 'Lot',
				name: 'lot',
			},
			{
				label: 'Trx Type',
				name: 'transactionType',
			},
			{
				label: 'Reason',
				name: 'transactionReason',
			},
			{
				label: 'Customer Note',
				name: 'customerNote',
			},
			{
				label: 'Trx Status',
				name: 'resolutionStatus',
			},
			{
				label: 'Timestamp',
				name: 'createdAt',
				customRender: (value?: any) => {
					return value ? UserTimezoneFormat(props.properties, value) : '';
				},
			},
		];
		return columns.map(column => {
			return {
				label: column.label,
				name: column.name,
				options: {
					customBodyRender: column.customRender ? column.customRender : null,
					filter: true,
					display: true,
					change: false,
				},
			};
		});
	};

	const onSearchClick = () => {
		const filter = [];
		if (props.selectedCustomer) {
			filter.push({ key: 'customerId', value: props.selectedCustomer.customerId });
		}

		const filterValues = props.selectedFilterValues || {};

		if (filterValues['CABINET_ID']) {
			filter.push({ key: 'cabinetId', value: filterValues['CABINET_ID'] });
		}

		if (filterValues['TRANSACTION_STATUS']) {
			filter.push({ key: 'status', value: filterValues['TRANSACTION_STATUS'] });
		}

		if (filterValues['TRANSACTION_TYPE']) {
			filter.push({ key: 'type', value: filterValues['TRANSACTION_TYPE'] });
		}

		filter.push({ key: 'limit', value: props.globalRowsPerPage });
		filter.push({ key: 'page', value: pagination.page + 1 });
		order && filter.push({ key: 'order', value: order });
		props.getSuspendedTransactions({useLoader: true, filter});
	};

	const handleModal = (rowData: any, tableMeta: any) => {
		const transaction = props.suspendedTransactions.result[tableMeta.dataIndex];
		setModalOpen(true);
		setCurrentTransaction(transaction);
	};

	const handleCloseSuspendedTransactionModal = () => {
		setModalOpen(false);
	};

	const updateSuspendedTransaction = (payload: any) => {
		props.updateSuspendedTransaction({
			transactionId: currentTransaction.id,
			payload: payload,
			callback: () => {
				handleCloseSuspendedTransactionModal();
				onSearchClick();
			},
		});
	};

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			onSearchClick();
		} else {
			firstRender.current = false;
		}
	}, [pagination, order]);

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 72; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
			<div className={styles.pageWrap}>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer pageName="ADMIN_SUSPENDED_TRANSACTIONS" executeSearch={onSearchClick} />
					</Grid>
				</Grid>
			</div>

			{props.suspendedTransactions &&
			props.suspendedTransactions.result &&
			props.suspendedTransactions.result.length > 0 ? (
				<div>
					<MUIDataTable
						data={props.suspendedTransactions?.result || []}
						columns={getColumns()}
						options={{
							filterType: 'dropdown',
							responsive: 'simple',
							filter: false,
							search: false,
							download: true,
							print: true,
							selectableRows: 'none',
							serverSide: true,
							count:
								props.suspendedTransactions && props.suspendedTransactions.total_record
									? props.suspendedTransactions.total_record.count
									: 0,
							page: pagination.page,
							rowsPerPage: props.globalRowsPerPage,
							rowsPerPageOptions: dataList.PageLimitOptions,
							onChangeRowsPerPage: (numberOfRows: number) => {
								setPagination({ ...pagination, limit: numberOfRows });
								props.setGlobalRowsPerPage(numberOfRows);
							},
							onRowClick: handleModal,
							onTableChange: (tableAction: any, tableState: any) => {
								const newPagination = tablePagination(tableAction, tableState, pagination);
								newPagination && setPagination(newPagination);
							},
							onColumnSortChange: (changedColumn: string, direction: string) => {
								setOrder(`${changedColumn} ${direction}`);
							},
							fixedHeader: true, // This locks the table headers at the top
							tableBodyHeight: tableHeight + 'px', // Set the height for the table body
						}}
					/>
				</div>
			) : (
				<EmptyContent message="Select the filter criteria above to view suspended transactions." />
			)}
			<Modal
				open={modalOpen}
				onClose={handleCloseSuspendedTransactionModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<SuspendedTransactionModal
						suspendedTransaction={currentTransaction}
						history={props.incidentHistory}
						close={handleCloseSuspendedTransactionModal}
						getLocalTimestamp={getLocalTimestamp}
						updateSuspendedTransaction={updateSuspendedTransaction}
					/>
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	suspendedTransactions: state.inventoryManagement.suspendedTransactions,
	selectedFilterValues: state.ui.selectedFilterValues,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getSuspendedTransactions: (filter: any) => dispatch(InventoryManagementActions.getSuspendedTransactions(filter)),
	updateSuspendedTransaction: (payload: any) =>
		dispatch(InventoryManagementActions.updateSuspendedTransactions(payload)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendedTransactionsComponent);
