import * as React from 'react';
import {
	makeStyles,
	createStyles,
	Theme,
	Grid,
	FormControl,
	TextField,
	Button,
	FormControlLabel,
	Switch,
	FormHelperText,
	Box,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import { CabinetStyles } from '../cabinets/cabinet.styles';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';
import { startCase, snakeCase } from 'lodash';
import { SelectBox } from '../../components/selectBox';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from '../../components/loading';
import dataList from '../../constants/dataList';

function getModalStyle() {
	return {
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(2, 4, 3),
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			position: 'absolute',
			boxShadow: theme.shadows[5],
			borderRadius: '3px',
			maxHeight: '700px',
			overflow: 'scroll',
		},
		modalActionError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
	}),
);

const initMetaData = (metaDataFromProps: any) => {
	if (metaDataFromProps.property_key) {
		const valueType =
			metaDataFromProps.value_type === 'string' && metaDataFromProps.values ? 'select' : metaDataFromProps.value_type;
		return {
			cabinetType: metaDataFromProps.cabinet_type,
			propertyKey: startCase(metaDataFromProps.property_key),
			valueType: valueType,
			values: metaDataFromProps.values,
			visibleTo: metaDataFromProps.visible_to,
			editableBy: metaDataFromProps.editable_by,
			editable: metaDataFromProps.editable ? true : false,
			searchable: metaDataFromProps.searchable ? true : false,
			is_part_of_edge_config: metaDataFromProps.is_part_of_edge_config ? true : false,
		};
	}

	return {
		cabinetType: null,
		propertyKey: null,
		valueType: null,
		values: null,
		visibleTo: null,
		editableBy: null,
		editable: false,
		searchable: false,
		is_part_of_edge_config: false,
	};
};

const CabinetPropertyMetaModal: React.FC<any> = (props: any) => {
	const modalClasses = useStyles();
	const classes = CabinetStyles();
	const [modalStyle] = React.useState(getModalStyle);
	const [formErrors, setFormErrors] = React.useState<any>({
		propertyKey: null,
		valueType: null,
		visibleTo: null,
	});
	const [metaData, setMetaData] = React.useState<any>(initMetaData(props.cabinetPropertyMeta));
	const [dropdownOptionInputs, setDropdownOptionInputs] = React.useState<string[]>(
		props.cabinetPropertyMeta.values ? props.cabinetPropertyMeta.values.split(',') : ['', ''],
	);
	const [apiError, setApiError] = React.useState<any>(null);
	const [submitting, setSubmitting] = React.useState<boolean>(false);

	const handleInputChange = (value: any, inputName: string) => {
		setApiError(null);
		setFormErrors({ ...formErrors, [inputName]: null });
		setMetaData({ ...metaData, [inputName]: value });
	};

	const isValid = () => {
		const requiredInputs = ['cabinetType', 'propertyKey', 'valueType', 'visibleTo'];
		const inputs = Object.keys(metaData);
		const errors: any = {};
		inputs.forEach(input => {
			if (!metaData[input] && requiredInputs.includes(input)) {
				errors[input] = `${startCase(input)} is required.`;
			}
		});

		if (metaData.editable && !metaData.editableBy) {
			errors.editableBy = 'Editable properties require Editable By to be set.';
		}

		if (
			metaData.valueType === 'select' &&
			dropdownOptionInputs.some(dropdownOption => {
				return dropdownOption === '';
			})
		) {
			errors.valueType = 'One or more dropdown option is missing a value.';
		}

		setFormErrors(errors);

		if (Object.keys(errors).length > 0) {
			return false;
		}
		return true;
	};

	const submit = () => {
		setApiError(null);
		if (!isValid()) {
			return;
		}

		setSubmitting(true);
		let payloadData = { ...metaData };
		payloadData.propertyKey = snakeCase(metaData.propertyKey);
		payloadData.valueType = metaData.valueType === 'select' ? 'string' : metaData.valueType;
		payloadData.values = metaData.valueType === 'select' ? dropdownOptionInputs.join(',') : null;
		if (!payloadData.editable) payloadData.editableBy = null;

		props.savePropertyMeta({
			data: payloadData,
			success: () => {
				setSubmitting(false);
				props.closeModal(true);
			},
			error: () => {
				setSubmitting(false);
				setApiError('Failed to save cabinet property.');
			},
		});
	};

	const handleCancel = () => {
		props.closeModal();
	};

	const addDropdownOption = () => {
		const options = [...dropdownOptionInputs];
		options.push('');
		setDropdownOptionInputs(options);
	};

	const removeDropdownOption = (index: number) => {
		if (dropdownOptionInputs.length === 2) return;
		const options = [...dropdownOptionInputs];
		options.splice(index, 1);
		setDropdownOptionInputs(options);
	};

	const setDropdownOptionValue = (value: string, index: number) => {
		value = value || '';
		let options = [...dropdownOptionInputs];
		options[index] = value;
		setDropdownOptionInputs(options);
	};

	const getDrowdownValueInputs = () => {
		return (
			<FormControl className={classes.formControl} style={{ marginBottom: '20px' }}>
				<h4 className={classes.propertyDropdownOptionsHeading}>
					Dropdown Menu Options
					<Tooltip title="Add another option">
						<IconButton onClick={addDropdownOption}>
							<AddIcon />
						</IconButton>
					</Tooltip>
				</h4>
				{dropdownOptionInputs.map((input: string, index: number) => (
					<TextField
						style={{ marginBottom: '8px' }}
						key={index}
						label="Option"
						variant="outlined"
						value={input}
						error={input ? false : true}
						onChange={event => {
							setApiError(null);
							setFormErrors({ ...formErrors, valueType: null });
							setDropdownOptionValue(event.target.value, index);
						}}
						required
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() => {
										removeDropdownOption(index);
									}}
								>
									<DeleteIcon />
								</IconButton>
							),
						}}
					/>
				))}
			</FormControl>
		);
	};

	return (
		<div style={modalStyle} className={modalClasses.paper}>
			<h3>{props.cabinetPropertyMeta.property_key ? 'Update Property Settings' : 'Add New Property'}</h3>
			<Grid container>
				{!props.cabinetPropertyMeta.property_key && (
					<Grid item md={4} sm={12} className={metaData.valueType !== 'select' ? classes.propertyMetaInputItem : ''}>
						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBox
								required
								error={formErrors.cabinetType ? true : false}
								errorText={
									formErrors.cabinetType
										? formErrors.cabinetType
										: 'This determines the type of cabinet this property will belong to.'
								}
								inputLabel="Device Type"
								emptyItemLabel={'Choose'}
								listItems={dataList.CabinetTypeDropdownList}
								onChangeItem={value => handleInputChange(value, 'cabinetType')}
								selected={metaData.cabinetType}
								style={{ maxWidth: '200px', width: '100%' }}
							/>
						</FormControl>
					</Grid>
				)}
				<Grid item md={props.cabinetPropertyMeta.property_key ? 6 : 4} sm={6} className={classes.propertyMetaInputItem}>
					<FormControl className={classes.formControl}>
						<TextField
							label="Property Name"
							variant="outlined"
							value={metaData.propertyKey}
							error={formErrors.propertyKey ? true : false}
							helperText={
								formErrors.propertyKey
									? formErrors.propertyKey
									: 'The name of the property as it will display in Cabinet Details.'
							}
							onChange={event => {
								handleInputChange(event.target.value, 'propertyKey');
							}}
							style={{ maxWidth: props.cabinetPropertyMeta.property_key ? '370px' : '200px' }}
							required
						/>
					</FormControl>
				</Grid>
				<Grid
					item
					md={props.cabinetPropertyMeta.property_key ? 6 : 4}
					sm={6}
					className={metaData.valueType !== 'select' ? classes.propertyMetaInputItem : ''}
				>
					<FormControl variant="outlined" className={classes.formControl}>
						<SelectBox
							required
							error={formErrors.valueType ? true : false}
							errorText={
								formErrors.valueType
									? formErrors.valueType
									: 'This determines the type of information this property contains and how it is set.'
							}
							inputLabel="Property Value Type"
							emptyItemLabel={'Choose'}
							listItems={[
								{ label: 'Text', value: 'string' },
								{ label: 'Dropdown', value: 'select' },
								{ label: 'Switch', value: 'boolean' },
								{ label: 'Number', value: 'number' },
							]}
							onChangeItem={value => handleInputChange(value, 'valueType')}
							selected={metaData.valueType}
							style={{ maxWidth: '200px', width: '100%' }}
						/>
					</FormControl>
				</Grid>
				{metaData.valueType === 'select' && (
					<>
						<Grid item xs={props.cabinetPropertyMeta.property_key ? 6 : 8}></Grid>
						<Grid item xs={props.cabinetPropertyMeta.property_key ? 6 : 4}>
							{getDrowdownValueInputs()}
						</Grid>
					</>
				)}
				<Grid item sm={6} xs={12} className={classes.propertyMetaInputItem}>
					<FormControl className={classes.formControl}>
						<FormControlLabel
							control={
								<Switch
									checked={metaData.editable ? true : false}
									onChange={() => {
										const currentValue = metaData.editable ? true : false;
										handleInputChange(!currentValue, 'editable');
									}}
									name={'editable'}
								/>
							}
							label={'Is Editable'}
						/>
						<FormHelperText style={{ marginLeft: 14 }}>
							Allows this property to be edited by either Users or Admins.
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.propertyMetaInputItem}>
					<FormControl className={classes.formControl}>
						<FormControlLabel
							control={
								<Switch
									checked={metaData.searchable ? true : false}
									onChange={() => {
										const currentValue = metaData.searchable ? true : false;
										handleInputChange(!currentValue, 'searchable');
									}}
									name={'searchable'}
								/>
							}
							label={'Is Searchable'}
						/>
						<FormHelperText style={{ marginLeft: 14 }}>
							Allows this property to be used as a filter on the cabinet list page.
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.propertyMetaInputItem}>
					<FormControl className={classes.formControl}>
						<FormControlLabel
							control={
								<Switch
									checked={metaData.is_part_of_edge_config ? true : false}
									onChange={() => {
										const currentValue = metaData.is_part_of_edge_config ? true : false;
										handleInputChange(!currentValue, 'is_part_of_edge_config');
									}}
									name={'is_part_of_edge_config'}
								/>
							}
							label={'Is Part of Edge Device Configuration'}
						/>
						<FormHelperText style={{ marginLeft: 14 }}>
							Allows this property to be included as part of the edge device configuration.
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.propertyMetaInputItem}>
					<FormControl variant="outlined" className={classes.formControl}>
						<SelectBox
							inputLabel="Visible To"
							error={formErrors.visibleTo ? true : false}
							errorText={
								formErrors.visibleTo
									? formErrors.visibleTo
									: 'Setting this to Users will allow all users to view this property.'
							}
							emptyItemLabel={'Choose'}
							listItems={[
								{ label: 'Users', value: 'user' },
								{ label: 'Admins', value: 'admin' },
							]}
							onChangeItem={value => handleInputChange(value, 'visibleTo')}
							selected={metaData.visibleTo}
							style={{ width: '150px' }}
						/>
					</FormControl>
				</Grid>
				{metaData.editable && (
					<Grid item sm={6} xs={12} className={classes.propertyMetaInputItem}>
						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBox
								required={metaData.editable}
								inputLabel="Editable By"
								error={formErrors.editableBy ? true : false}
								errorText={
									formErrors.editableBy
										? formErrors.editableBy
										: 'Setting this to Users will allow all users to edit this property.'
								}
								emptyItemLabel={'Choose'}
								listItems={[
									{ label: 'Users', value: 'user' },
									{ label: 'Admins', value: 'admin' },
								]}
								onChangeItem={value => handleInputChange(value, 'editableBy')}
								selected={metaData.editableBy}
								style={{ width: '150px' }}
							/>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={12}></Grid>
				{apiError && <p className={classes.cabinetFormError}>{apiError}</p>}
				{submitting ? (
					<Grid item xs={1}>
						<Loading message="" />
					</Grid>
				) : (
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<Button
								onClick={submit}
								type="button"
								style={{ marginTop: 12 }}
								className={classes.searchButton}
								variant="contained"
								color="primary"
							>
								SAVE
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleCancel}
								type="button"
								style={{ marginTop: 12, marginLeft: 0 }}
								className={classes.cancelPropertyEditBtn}
								variant="contained"
								color="default"
							>
								CANCEL
							</Button>
						</FormControl>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinet: state.cabinet.selectedCabinet,
	isLoading: state.ui.isLoading,
	apiError: state.ui.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
	savePropertyMeta: (payload: any) => dispatch(CabinetActions.saveCabinetPropertyMeta(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetPropertyMetaModal);
