import { connect } from 'react-redux';
import ProductListComponent from './product-list.component';
import { ProductActions, UIAction } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	products: state.product.productList || {
		result: [],
		total: 0,
	},
	autoCompleteProducts: state.product.autoCompleteList || [],
	selectedFilterValues: state.ui.selectedFilterValues,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	getProducts: (filter: any) => dispatch(ProductActions.getProductList(filter)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export const ProductsContainer = connect(mapStateToProps, mapDispatchToProps)(ProductListComponent);
