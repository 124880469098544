import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const InventoryManagementStyles = makeStyles(theme => ({
	card: {
		'margin-bottom': '2em',
		height: '100%',
	},
	cardHeader: {
		//'background-color': theme.palette.grey[300]
	},
	tableHead: {
		//'background-color': theme.palette.grey[300]
	},
	tableTitle: {
		margin: '2em 0 0 0.5em',
		width: '100%',
	},
	searchWrap: {
		'margin-bottom': '2em',
		padding: 9,
	},
	cabinetModal: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	tabs: {
		textTransform: 'none',
	},
	viewAllBtn: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
	},
	groupByWrap: {
		marginBottom: '-20px',
	},
	groupByWrapper: {
		position: 'absolute',
		top: -5,
		right: 20,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		zIndex: 10,

		[theme.breakpoints.down('lg')]: {
			top: -50,
			left: 0,
			right: 'unset',
		},
	},
	groupByWrapperSmall: {
		position: 'absolute',
		top: 55,
		left: 0,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		zIndex: 10,
	},
	groupByHeading: {
		marginRight: '6px',
	},
	groupByLinks: {
		margin: '0 20px 0 0',
	},
	groupByButtons: {
		// color: 'rgba(0, 0, 0, 0.87)'
	},
	tableHeader: {
		paddingLeft: '24px',
	},
	tableWrap: {
		'margin-bottom': '2em',
	},
}));

export const InventoryTableTheme = (createMuiTheme as any)({
	overrides: {
		MuiTableCell: {
			root: {
				cursor: 'pointer',
			},
		},
		MuiTableSortLabel: {
			root: {
				'margin-top': '7px',
			},
		},
		MUIDataTableHeadCell: {
			root: {
				fontSize: 12,
			},
		},
		MUIDataTableBodyCell: {
			root: {
				fontSize: 12,
			},
		},
		MuiTypography: {
			h6: {
				fontSize: 14,
			},
		},
	},
});
