import { UserProperty } from '../../redux/models/user/user';

export const GetUserDefaultInventory = (properties: UserProperty[]) => {
	const defaultInventory = 'LOT';

	const defaultInventory_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'DEFAULT_INVENTORY',
	);
	return defaultInventory_key_index > -1 ? properties[defaultInventory_key_index].propertyValue : defaultInventory;
};
