import React from 'react';
import { connect } from 'react-redux';
import { InvoicesActions } from '../../redux/actions';
import { useLocation, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Loading from '../../components/loading';
import BasePage from '../common/base';
import { DashboardStyles } from '../../hooks/styles';

const PaymentConfirmation: React.FC = (props: any) => {
	const styles = DashboardStyles();

	const [isConfirming, setIsConfirming] = React.useState(false);

	const location = useLocation();
	const queryString = new URLSearchParams(location.search);
	const payment: any = {};
	queryString.forEach((value: string, key: string) => {
		payment[key] = value;
	});

	React.useEffect(() => {
		if (props.authUser && !isConfirming) {
			props.confirmPayment(payment);
			setIsConfirming(true);
		}
		if (props.paymentIsConfirmed) {
			setIsConfirming(false);
		}
	}, [props, payment, isConfirming]);

	return (
		<div>
			{props.paymentIsConfirmed && <Redirect to="/admin-invoices" />}
			<BasePage title="">
				<div className={styles.appBarSpacer}></div>
				<Container maxWidth="sm">
					{!props.paymentIsConfirmed && <Loading message="Updating invoice records..." />}
				</Container>
			</BasePage>
		</div>
	);
};
const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	paymentIsConfirmed: state.invoices.paymentIsConfirmed,
});
const mapDispatchToProps = (dispatch: any) => ({
	confirmPayment: (payment: any) => dispatch(InvoicesActions.paymentConfirmation(payment)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);
