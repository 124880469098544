import * as React from 'react';
import Loading from '../../components/loading';
import { CommonStyles } from '../../hooks/styles';
import {
	FormControl,
	Grid,
	Button,
	Modal,
	Chip,
	IconButton,
	TextField,
	FormControlLabel,
	Checkbox,
	InputLabel,
	Select,
	MenuItem,
	Box,
} from '@material-ui/core';
import { orderBy, camelCase } from 'lodash';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import MUIDataTable from 'mui-datatables';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import StorageIcon from '@material-ui/icons/Storage';
import { CabinetStyles } from '../cabinets/cabinet.styles';
import dataList from '../../constants/dataList';
import LinkableActions from '../../components/linkableActions';
import { valueMapper } from '../../hooks/functions';
import { Constants } from '../../constants/Constants';
import EmptyContent from '../../components/emptyContent';
import EditCabinetModal from '../cabinets/cabinet-edit.modal';
import CabinetForm from '../cabinets/cabinet-form.component';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { formatCabinetPropertyKey, formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import { EdgeConfigProperty } from './edge-config-property.type';
import { EdgeConfigPropteryResponseDto } from './edge-config-property-response.dto';
import {
	PropertyValues,
	UpdateDeviceEdgePropertiesPayloadDTO,
} from '../../redux/models/cabinets/device-edge-properties-payload.dto';
import { MUIDataTableMeta } from '../../interfaces/mui-data-table-meta.type';
import {Severities, SendLog} from '../../hooks/functions/SendLog';

const findEdgePropertyValueInPayload = (payload: UpdateDeviceEdgePropertiesPayloadDTO | undefined, valueToFind: string, valueType: string) : string | number | boolean |  null  => {
	
	if (payload && payload.propertyValues) {
		const edgeProp = payload.propertyValues.find( (x) => x.name === valueToFind )
		if (edgeProp) {
			if (valueType === "boolean") {
				if (edgeProp.value === '' || edgeProp.value === undefined) {
					return '';
				} else {
					return edgeProp.value ? "True" : "False"
				}
				
			} else if (valueType === "number") {
				return parseInt(edgeProp.value);
			} else {
			    return edgeProp.value;
			}
		} else {
			return null;
		}
	}
	// couldn't find property
	return null;

}


const findEdgePropertyValueInArray = (propArray: Array<PropertyValues>, valueToFind: string)  => {
	return propArray.findIndex( (x) => x.name === valueToFind);
}

const DeviceConfigManagementComponent: React.FC<any> = (props: any) => {
	const styles = CommonStyles();
	const classes = CabinetStyles();
	const [selectedCabinet] = React.useState(null as any);
	const [pagination, setPagination] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	} as any);
	const [cabinetsFetched, setCabinetsFetched] = React.useState(false);
	const [refresh, doRefresh] = React.useState(false);
	const [isActionDialog, setActionDialog] = React.useState(false);
	let [links, setLinks] = React.useState(null as any);
	const [cabinetModalOpen, setCabinetModalOpen] = React.useState(false);
	const firstRender = React.useRef(true);
	const [cabinetModalType, setCabinetModalType] = React.useState('edit' as string);
	const [expandedPropertyRows, setExpandedPropertyRows] = React.useState<any>({});
	const [sortOrder, setSortOrder] = React.useState(null as any);
	const [isDev, setIsDev] = React.useState(false);
	const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = React.useState(false);

	const handleUpdatePropCheckboxChange = (newValue: boolean, deviceId: string, deviceListIndex: number) => {
		if (updateEdgePropsPayload) {
			if (!updateEdgePropsPayload.deviceList) {
				updateEdgePropsPayload.deviceList = [];
			}
			const newDeviceList = [...updateEdgePropsPayload.deviceList];
			if (newValue) {
				newDeviceList.push(deviceId);
			} else {
				newDeviceList.splice(deviceListIndex, 1);
			}
			setUpdateEdgePropsPayload({
				...updateEdgePropsPayload,
				deviceList: newDeviceList,
			});
		}
	};

	const tableColumns = [
		{
			label: 'Update',
			name: 'updateDeviceProperties',
			options: {
				filter: false,
				display: true,
				change: true,
				customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
					const indexOfDeviceInPayload = updateEdgePropsPayload?.deviceList?.indexOf(tableMeta.rowData[1].cabinetId);
					return (
						<FormControlLabel
							control={
								<Checkbox
									name={'update-checkbox-' + tableMeta.rowData[1].cabinetId}
									value={indexOfDeviceInPayload === -1 ? false : true}
									onChange={event =>
										handleUpdatePropCheckboxChange(
											event.target.checked,
											tableMeta.rowData[1].cabinetId,
											indexOfDeviceInPayload === undefined ? -1 : indexOfDeviceInPayload,
										)
									}
								/>
							}
							label={'Update ' + tableMeta.rowData[1].cabinetId}
						/>
					);
				},
			},
		},
		{
			label: 'Device',
			name: 'cabinetInfo',
			options: {
				filter: true,
				display: true,
				change: false,
				customBodyRender: (value: any) => {
					return value.cabinetName ? `${value.cabinetName} (${value.cabinetId})` : `(${value.cabinetId})`;
				},
			},
		},
		{
			label: 'Customer Id',
			name: 'customerId',
			options: {
				filter: true,
				display: true,
				change: false,
			},
		},
		{
			label: 'State',
			name: 'cabinetState',
			options: {
				filter: true,
				display: true,
				change: true,
				customBodyRender: (value: any) => {
					return value ? valueMapper(value, 'cabinetStates') : null;
				},
			},
		},
		{
			label: 'Properties',
			name: 'cabinetProperties',
			options: {
				filter: true,
				display: true,
				change: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any) => {
					const propertyKeys = Object.keys(value);
					if (propertyKeys.length > 0) {
						propertyKeys.sort();
						const filterValues = props.selectedFilterValues || {};
						if (filterValues['CABINET_PROPERTIES'] && Object.keys(filterValues['CABINET_PROPERTIES']).length > 0) {
							const filterProp = Object.keys(filterValues['CABINET_PROPERTIES'])[0];
							const filterPropIndex = propertyKeys.findIndex((key: string) => key === camelCase(filterProp));
							filterPropIndex > -1 && propertyKeys.splice(0, 0, propertyKeys.splice(filterPropIndex, 1)[0]);
						}
					}

					let hiddenPropCount = 0;
					let hiddenPropertyKeys: any[] = [];
					if (propertyKeys.length > 3) {
						hiddenPropCount = propertyKeys.length - 3;
						hiddenPropertyKeys = propertyKeys.splice(2, hiddenPropCount);
					}
					return (
						<>
							{propertyKeys.map((propertyKey: any, index: number) => (
								<Chip
									key={index}
									variant="outlined"
									style={{ margin: 3 }}
									label={`${formatCabinetPropertyKey(propertyKey)}: ${formatCabinetPropertyValue(
										propertyKey,
										value[propertyKey].toString(),
									)}`}
									size="small"
								/>
							))}
							{expandedPropertyRows[tableMeta.rowIndex] ? (
								<>
									{hiddenPropertyKeys.map((propertyKey: any, index: number) => (
										<Chip
											key={index}
											variant="outlined"
											style={{ margin: 3 }}
											label={`${formatCabinetPropertyKey(propertyKey)}: ${formatCabinetPropertyValue(
												propertyKey,
												value[propertyKey].toString(),
											)}`}
											size="small"
										/>
									))}
									<span
										onClick={() => {
											setExpandedPropertyRows({ ...expandedPropertyRows, [tableMeta.rowIndex]: false });
										}}
										style={{ textDecoration: 'underline', cursor: 'pointer' }}
									>
										Show Less
									</span>
								</>
							) : (
								<span
									onClick={() => {
										setExpandedPropertyRows({ ...expandedPropertyRows, [tableMeta.rowIndex]: true });
									}}
									style={{ textDecoration: 'underline', cursor: 'pointer' }}
								>
									{hiddenPropCount > 0 ? `+${hiddenPropCount}` : ``}
								</span>
							)}
						</>
					);
				},
			},
		},
		{
			label: '',
			name: '',
			options: {
				change: false,
				filter: false,
				viewColumns: false,
				customBodyRenderLite: (dataIndex: number) => (
					<IconButton
						onClick={() => {
							handleOpen(dataIndex);
						}}
					>
						<MoreVertIcon />
					</IconButton>
				),
			},
		},
	];
	const [tableHeight, setTableHeight] = React.useState(0);
	const [selectedTechType, setSelectedTechType] = React.useState<string>('WEIGHT');
	const [updateEdgePropsPayload, setUpdateEdgePropsPayload] = React.useState<UpdateDeviceEdgePropertiesPayloadDTO>();

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);

		props.getEdgeConfigProperties();
	}, []);

	React.useEffect(() => {
		if (props.statesList.length < 1 && props.authUser) {
			const filter = {
				offset: 0,
				limit: 100,
				skip: 0,
				order: ['cabinetStateDesc asc'],
				where: {},
				fields: {},
			};
			props.getState(filter);
		}
		setIsDev(
			props.authUser.record.email === 'dgwilt@fffenterprises.com' ||
				props.authUser.record.email === 'dweaver@fffenterprises.com',
		);
	}, [props.authUser]);

	/*
	 * execute the cabinet search if authorized
	 */
	const fetchCabinets = (customerId?: any, ignoreFilters: boolean = false) => {
		const filterValues = props.selectedFilterValues || {};

		let cabinetId = '';
		if (selectedCabinet && !ignoreFilters) {
			//TODO: special handling; clicking from table pop-up adds it to url
			cabinetId = selectedCabinet;
		} else if (filterValues['CABINET_ID'] && !ignoreFilters) {
			cabinetId = filterValues['CABINET_ID'];
		}

		const filter: any = {
			cabinetId: cabinetId,
			customerId: props.selectedCustomer ? props.selectedCustomer.customerId : '',
			cabinetType: selectedTechType,
			cabinetState: filterValues['CABINET_STATE'] && !ignoreFilters ? `${filterValues['CABINET_STATE']}` : '',
			limit: pagination.limit,
			offset: pagination.offset,
			order: sortOrder ? sortOrder : 'cabinetId ASC',
		};

		if (!props.authUser.record.claims['ALL']) {
			filter.cabinetState = '30';
		}

		if (
			filterValues['CABINET_PROPERTIES'] &&
			Object.keys(filterValues['CABINET_PROPERTIES']).length > 0 &&
			!ignoreFilters
		) {
			filter.properties = filterValues['CABINET_PROPERTIES'];
		}

		console.log('Getting cabinets! ', filter);
		props.getCabinets(filter);
		setCabinetsFetched(true);
		firstRender.current = false;
	};

	const showLoadingMsg = () => !props.authUser && props.tenants.length === 0;

	const resetSelectedDevicesToUpdate = () => {
		// Copy the payload
		const newPayload: UpdateDeviceEdgePropertiesPayloadDTO = {
			...updateEdgePropsPayload,
		} as UpdateDeviceEdgePropertiesPayloadDTO;
		// Delete the device list
		newPayload.deviceList = [];
		// Update the payload with the deleted list
		setUpdateEdgePropsPayload(newPayload);
	};

	const onSearchClick = () => {
		firstRender.current = false;
		resetSelectedDevicesToUpdate();

		if (props.authUser?.authorizationCheck({ action: 'read', resource: 'cabinets', tenantId: 'ALL' })) {
			setPagination({
				offset: 0,
				limit: props.globalRowsPerPage,
				page: 0,
			});
			return;
		}
		if (props.selectedCustomer) {
			setPagination({
				offset: 0,
				limit: props.globalRowsPerPage,
				page: 0,
			});
		} else {
			console.log('Fetching Cabinets from useEffect onSearchClick');
			fetchCabinets();
		}
	};

	const generateCabinetList = () => {
		if (props.cabinets.length === 0) {
			return [];
		}
		let cabinetList: any[] = [];
		props.cabinets.forEach((item: any, key: number) => {
			item.cabinetInfo = {
				cabinetId: item.cabinetId,
				cabinetName: item.cabinetName,
			};
			cabinetList.push(item);
		});
		return cabinetList;
	};

	const handleClose = () => {
		setActionDialog(false);
	};

	const handleOpen = (index: number) => {
		const thisCabinet = props.cabinets[index];
		if (!thisCabinet.cabinetId) {
			return;
		}
		setActionDialog(true);
		const queryString = `?filter=${JSON.stringify({ cabinetId: thisCabinet.cabinetId })}`;
		const rowObj = {
			cabinetId: thisCabinet.cabinetId,
			cabinetName: thisCabinet.cabinetName,
			customerId: thisCabinet.customerId,
			cabinetType: thisCabinet.cabinetType, //rowData[2],
			cabinetState: thisCabinet.cabinetState, //rowData[3],
			tempControl: thisCabinet.tempControl,
			cabinetProperties: thisCabinet.cabinetProperties,
		};

		const customer = props.tenants.find((tenant: any) => {
			return tenant.customerId === thisCabinet.customerId;
		});

		setCabinetModalType('edit');
		links = [
			{
				title: Constants.PAGE_TITLE.INVENTORY,
				suffix: thisCabinet.cabinetName
					? `In ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `In Cabinet: ${thisCabinet.cabinetId}`,
				url: `/inventory-management/${thisCabinet.customerId}${queryString}`,
				icon: <StorageIcon />,
			},
			{
				title: Constants.PAGE_TITLE.TRANSACTION_LOG,
				suffix: thisCabinet.cabinetName
					? `For Products in ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `For Products in Cabinet: ${thisCabinet.cabinetId}`,
				url: `/transaction-log/${thisCabinet.customerId}${queryString}`,
				icon: <IconLibraryBooks />,
			},
			{
				title: Constants.PAGE_TITLE.CABINET_DETAILS,
				suffix: thisCabinet.cabinetName
					? `For ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `For Cabinet: ${thisCabinet.cabinetId}`,
				url: `/cabinet-details/${thisCabinet.customerId}/${thisCabinet.cabinetId}`,
				callback: { data: rowObj, action: 'view_details' },
				icon: <AllInboxIcon />,
			},
		];
		setLinks(orderBy(links, [(link: any) => link.title.toLowerCase()], ['asc']));
	};

	const setCabinetForDetails = (callbackObj: any) => {
		if (callbackObj.action === 'edit_name') {
			setActionDialog(false);
			setCabinetModalOpen(true);
		}
		props.setCabinet(callbackObj.data);
	};

	const handleCabinetModalClose = (refreshData: boolean = false) => {
		setCabinetModalOpen(false);
		if (refreshData) {
			doRefresh(!refresh);
			console.log('Fetching Cabinets from handleCabinetModalClose');
			fetchCabinets(props.selectedCustomer.customerId, true);
		}
	};

	const enqueueCsvReportForCurrentQuery = (tableColumns: any[], ignoreFilters: boolean = false) => {
		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);
		const filterValues = props.selectedFilterValues || {};

		let cabinetId = '';
		if (selectedCabinet && !ignoreFilters) {
			//TODO: special handling; clicking from table pop-up adds it to url
			cabinetId = selectedCabinet;
		} else if (filterValues['CABINET_ID'] && !ignoreFilters) {
			cabinetId = filterValues['CABINET_ID'];
		}

		const filter: any = {
			cabinetId: cabinetId,
			customerId: props.selectedCustomer ? props.selectedCustomer.customerId : '',
			cabinetType: filterValues['CABINET_TYPE'] && !ignoreFilters ? filterValues['CABINET_TYPE'] : '',
			cabinetState: filterValues['CABINET_STATE'] && !ignoreFilters ? `${filterValues['CABINET_STATE']}` : '',
			limit: 99999,
			order: sortOrder ? sortOrder : 'cabinetId ASC',
		};

		if (!props.authUser.record.claims['ALL']) {
			filter.cabinetState = '30';
		}

		if (
			filterValues['CABINET_PROPERTIES'] &&
			Object.keys(filterValues['CABINET_PROPERTIES']).length > 0 &&
			!ignoreFilters
		) {
			filter.properties = filterValues['CABINET_PROPERTIES'];
		}

		const params = constructEnqueueParams('RNI_UIEXPORT_CabinetsReport', props.authUser?.record.email, filter, {
			columnsRequested: columnsRequested,
		});

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (props.backFromDetails) {
			console.log('Fetching Cabinets from useEffect backFromDetails');
			fetchCabinets();
			props.setBackFromDetails(false);
		}
	}, [props.backFromDetails]);

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			console.log(
				'Fetching Cabinets from useEffect[pagination, sortOrder]: ',
				props.authUser,
				selectedTechType,
				firstRender,
			);
			resetSelectedDevicesToUpdate();
			fetchCabinets();
		} else {
			firstRender.current = false;
		}
		setExpandedPropertyRows({});
	}, [pagination, sortOrder]);

	React.useEffect(() => {
		fetchCabinets();
		initializeUpdatePayload();
	}, [selectedTechType]);

	React.useEffect(() => {
		initializeUpdatePayload();
	}, [props.edgeConfigProperties]);

	const initializeUpdatePayload = () => {
		// Once Edge Config Props are obtained from server, initialize the update payload
		if (!props.edgeConfigProperties) {
			return;
		}
		const propValArray: Array<PropertyValues> = [];
		props.edgeConfigProperties[selectedTechType as keyof EdgeConfigPropteryResponseDto].map(
			(property: EdgeConfigProperty, index: number) => {
				propValArray.push({
					name: property.value,
					value: '',
				});
			},
		);
		setUpdateEdgePropsPayload({
			deviceList: [],
			propertyValues: propValArray,
		});
	};

	const handleOnSelectedTechTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedTechType(event.target.value as string);
	};

	const handleOnEdgePropertyChange = (newValue: any, valueName: string, valueType: string) => {
		console.log('new value: ', newValue, 'name: ', valueName);
		let result;
 	if (updateEdgePropsPayload) {
			const newPropValues = [...updateEdgePropsPayload.propertyValues];
			const index = findEdgePropertyValueInArray(newPropValues, valueName);
			if (index !== -1) {
				if (valueType === "boolean") {

					if (newValue === '' || newValue === undefined) {
						newPropValues[index].value = '';
					} else {
						newPropValues[index].value = newValue === "True" ? true : false;
					}

				} else if (valueType === "number") {
					newPropValues[index].value = parseInt(newValue);
				} else {
					newPropValues[index].value = newValue;
					
				}
				result = newPropValues[index].value
			} 
			else {
				// SendLog(`Invalid index: ${valueName} ${newPropValues}, adding to array`, 
				// 	Severities.Error, 
				// 	'handleOnEdgePropertyChange');
				let newValueToAdd = newValue;
				if (valueType === "boolean") {

					if (newValue === '' || newValue === undefined) {
						newValueToAdd = '';
					} else {
						newValueToAdd = newValue === "True" ? true : false;
					}

				} else if (valueType === "number") {
					newValueToAdd= parseInt(newValue);
				}

				newPropValues.push({
					name: valueName,
					value: newValueToAdd
				});
			}
			setUpdateEdgePropsPayload({
				...updateEdgePropsPayload,
				propertyValues: newPropValues,
			});
			return result;
		}
	};

	const handleSubmitUpdatePayload = () => {
		console.log('handleSubmitUpdatePayload', updateEdgePropsPayload, selectedTechType);
		if (updateEdgePropsPayload?.deviceList?.length === 0) {
			delete updateEdgePropsPayload.deviceList;
		}
		if (updateEdgePropsPayload) {
			
			updateEdgePropsPayload.propertyValues = 
				updateEdgePropsPayload.propertyValues.filter( (x) => (x.value !== '' && x.value !== undefined) );	
		}
		props.updateEdgeConfigProperties(updateEdgePropsPayload, selectedTechType);
		handleCloseUpdateConfirmationModal();
	};


	const generateEdgeConfigPropertiesHtml = (edgeConfigProperties: Array<EdgeConfigProperty>) => {
		// VISION => RightNow Secure
		// WEIGHT => RightNow Flex

		return (
			<form>
				<Grid container spacing={2}>
					{edgeConfigProperties.map((item: EdgeConfigProperty, index: number) => {
						return (
							<Grid key={index} item xs={12} sm={6} md={4} xl={3}>
								{item.value_type === 'boolean' ? (
									<FormControl fullWidth>
										<InputLabel id={`${item.value}-label`}>{item.label}</InputLabel>
										<Select
											labelId={`${item.value}-label`}
											id={item.value}
											name={item.value}
											value={findEdgePropertyValueInPayload(updateEdgePropsPayload, item.value, item.value_type)}
											onChange={event => handleOnEdgePropertyChange(event.target.value, item.value, item.value_type)}
										>
											<MenuItem key={0} value="">
												-
											</MenuItem>
											<MenuItem key={1} value="True">
												True
											</MenuItem>
											<MenuItem key={2} value="False">
												False
											</MenuItem>
										</Select>
									</FormControl>
								) : Array.isArray(item.values) ? (
									<FormControl fullWidth>
										<InputLabel id={`${item.value}-label`}>{item.label}</InputLabel>
										<Select
											labelId={`${item.value}-label`}
											id={item.value}
											name={item.value}
											value={findEdgePropertyValueInPayload(updateEdgePropsPayload, item.value, item.value_type)}
											onChange={event => handleOnEdgePropertyChange(event.target.value, item.value, item.value_type)}
										>
											<MenuItem key={-1} value="">
												-
											</MenuItem>
											{item.values.map((val: string | number, valIndex: number) => (
												<MenuItem key={valIndex} value={val}>
													{val}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								) : (
									<TextField
										fullWidth
										label={item.label}
										id={item.value}
										name={item.value}
										type={item.value_type === 'string' ? 'text' : 'number'}
										value={findEdgePropertyValueInPayload(updateEdgePropsPayload, item.value,item.value_type)}
										onChange={event => handleOnEdgePropertyChange(event.target.value, item.value, item.value_type)}
									/>
								)}
							</Grid>
						);
					})}
				</Grid>
			</form>
		);
	};

	const handleOpenUpdateConfirmationModal = () => {
		setConfirmUpdateModalOpen(true);
	};
	const handleCloseUpdateConfirmationModal = () => {
		setConfirmUpdateModalOpen(false);
	};
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 600,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<div>
			<FormControl fullWidth>
				<InputLabel id="tech-type-select-label">Device Tech Type</InputLabel>
				<Select
					labelId="tech-type-select-label"
					id="tech-type-select"
					name="tech-type-select"
					value={selectedTechType}
					onChange={handleOnSelectedTechTypeChange}
				>
					<MenuItem key="0" value="WEIGHT">
						RightNow Flex
					</MenuItem>
					<MenuItem key="1" value="VISION">
						RightNow Secure
					</MenuItem>
				</Select>
			</FormControl>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={styles.searchWrap}>
						<Grid item xs={12}>
							<GlobalFiltersComponentContainer pageName="DEVICE_CONFIG_MANAGEMENT" executeSearch={onSearchClick} />
						</Grid>

						<Grid item xs={12}></Grid>
					</div>

					{props.edgeConfigProperties && (
						<>
							<h3>Edge Config Properties</h3>
							{generateEdgeConfigPropertiesHtml(
								props.edgeConfigProperties[selectedTechType as keyof EdgeConfigPropteryResponseDto],
							)}
							<br />
							<Box display="flex" justifyContent="flex-end" mt={2}>
								<Button
									variant="contained"
									style={{ backgroundColor: '#ff9800', color: '#fff' }}
									onClick={handleOpenUpdateConfirmationModal}
								>
									Update Edge Properties
								</Button>
							</Box>
							<br />
						</>
					)}

					{props.cabinets && props.cabinets.length > 0 ? (
						<div>
							<MUIDataTable
								data={generateCabinetList()}
								columns={tableColumns}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										setPagination({ ...pagination, limit: numberOfRows });
										props.setGlobalRowsPerPage(numberOfRows);
									},
									onColumnSortChange: (changedColumn: string, direction: string) => {
										if (changedColumn === 'cabinetInfo') {
											changedColumn = 'cabinetId';
										}
										let order = `${changedColumn} ${direction}`;
										setSortOrder(order);
									},
									onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
										enqueueCsvReportForCurrentQuery(cols);
										return false;
									},
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: true,
									print: true,
									selectableRows: 'none',
									serverSide: true,
									page: pagination.page,
									count: props.totalCabinets,
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
									textLabels: {
										body: {
											noMatch: cabinetsFetched ? 'Sorry, no matching records found' : '',
										},
									},
									onTableChange: (tableAction: any, tableState: any) => {
										switch (tableAction) {
											case 'changePage':
												if (tableState.page > pagination.page) {
													setPagination({
														offset: pagination.offset + pagination.limit,
														limit: pagination.limit,
														page: tableState.page,
													});
												} else if (tableState.page < pagination.page) {
													setPagination({
														offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit,
														limit: pagination.limit,
														page: tableState.page,
													});
												}
												break;
										}
									},
								}}
							/>
							{isDev && (
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<h2>Edge Config Props:</h2>
										<pre>{JSON.stringify(props.edgeConfigProperties, null, 2)}</pre>
									</Grid>
									<Grid item xs={12} sm={6}>
										<h2>Edge Config Update Payload:</h2>
										<pre>{JSON.stringify(updateEdgePropsPayload, null, 2)}</pre>
									</Grid>
								</Grid>
							)}
						</div>
					) : (
						<EmptyContent message="Select the filter criteria above to view device information." />
					)}
				</div>
			)}
			<Modal
				disableBackdropClick={false}
				open={cabinetModalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					{cabinetModalType === 'edit' ? (
						<EditCabinetModal closeModal={handleCabinetModalClose} />
					) : (
						<CabinetForm closeModal={handleCabinetModalClose} />
					)}
				</div>
			</Modal>
			<LinkableActions onCallBack={setCabinetForDetails} open={isActionDialog} onClose={handleClose} links={links} />
			<Modal open={confirmUpdateModalOpen} onClose={handleCloseUpdateConfirmationModal}>
				<Box sx={style}>
					<h2>Confirm Updating Edge Properties</h2>
					{updateEdgePropsPayload?.deviceList?.length ? (
						<p>{updateEdgePropsPayload.deviceList.length} devices will be updated</p>
					) : (
						<p>All {valueMapper(selectedTechType, 'cabinetTypes')} type devices will be updated</p>
					)}
					<Box mt={2} display="flex" justifyContent="flex-end">
						<Button
							variant="contained"
							color="secondary"
							onClick={handleCloseUpdateConfirmationModal}
							style={{ marginRight: '10px' }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							style={{ backgroundColor: '#ff9800', color: '#fff' }}
							onClick={handleSubmitUpdatePayload}
						>
							Confirm
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default DeviceConfigManagementComponent;
