import { connect, useDispatch } from 'react-redux';
import { HelpVideosComponent } from './help-videos.component';
import { UserManagementActions } from '../../redux/actions';
//import { UserManagementActions, TenantsActions, UIAction } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	vimeoAppToken: state.userManagement.vimeoAppToken,
});

const mapDispatchToProps = (dispatch: any) => ({
	getVimeoAppAuthToken: () => dispatch(UserManagementActions.getVimeoAppAuthToken()),
});

const HelpVideosContainer = connect(mapStateToProps, mapDispatchToProps)(HelpVideosComponent);

export default HelpVideosContainer;
