import { call, put, delay } from 'redux-saga/effects';
import { AlertActions, UIAction, UserActions } from '../actions/index';
import { AlertsService } from '../services';
import { Constants } from '../../constants/Constants';

export function* getAlerts(action: ReturnType<typeof AlertActions.alertsRequestStartAction>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const alertsList: any = yield call(AlertsService.getInstance().getAlerts, action.payload.filter);
		yield put(AlertActions.alertsRequestCompletedAction(alertsList));
		yield put(UIAction.showLoader(false));
		if (action.payload.showError && (!alertsList.data.result || alertsList.data.result.length === 0)) {
			yield put(
				UserActions.setSnackbarMessage({
					message: `An error occurred: ${Constants.ALERT.NO_RECORD_FOUND}`,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield handleAlertSagaError(err, 'getAlerts');
	}
}

export function* getNotificationTypes(action: ReturnType<typeof AlertActions.getNotificationTypesAction>) {
	try {
		yield put(AlertActions.setNotificationChannelGroupsAction(null));
		const { data } = yield call(AlertsService.getInstance().getNotificationTypes);
		yield put(AlertActions.setNotificationTypesAction(data));
	} catch (err) {
		console.log('get notification type error:', err);
	}
}

export function* getChannelTypes(action: ReturnType<typeof AlertActions.getChannelTypesAction>) {
	try {
		yield put(UIAction.setApiError(null));
		const { data } = yield call(AlertsService.getInstance().getChannelTypes);
		yield put(AlertActions.setChannelTypesAction(data));
	} catch (err) {
		console.log('get notification channel types error:', err);

		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${Constants.ALERT.FAILED_TO_GET_CHANNEL}`,
				type: 'error',
			}),
		);
	}
}

export function* getChannelGroups(action: ReturnType<typeof AlertActions.getNotificationChannelGroupsAction>): any {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		const data = yield call(AlertsService.getInstance().getChannelGroups, action.payload);
		yield put(AlertActions.setNotificationChannelGroupsAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		console.log('get notification channel groups error:', err);
		yield handleAlertSagaError(err, 'getChannelGroups');
	}
}

export function* submitChannelGroup(action: ReturnType<typeof AlertActions.submitChannelGroupAction>): any {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		yield call(AlertsService.getInstance().submitChannelGroups, action.payload);
		yield delay(Constants.ALERT.DELAY);
		const responseData = yield call(AlertsService.getInstance().getChannelGroups, action.payload.customerId);
		yield put(AlertActions.setNotificationChannelGroupsAction(responseData));
		yield put(UIAction.showLoader(false));
	} catch (error) {
		console.log('failed to save channel group:', error);

		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${Constants.ALERT.ERROR_ON_SAVING_CHANNEL}`,
				type: 'error',
			}),
		);
	}
}

export function* deleteChannelGroup(action: ReturnType<typeof AlertActions.deleteChannelGroupAction>): any {
	try {
		yield put(UIAction.setApiError(null));
		yield put(UIAction.showLoader(true));
		yield call(AlertsService.getInstance().deleteChannelGroups, action.payload);
		yield delay(Constants.ALERT.DELAY);
		const data = yield call(AlertsService.getInstance().getChannelGroups, action.payload.customerId);
		yield put(AlertActions.setNotificationChannelGroupsAction(data));
		yield put(UIAction.showLoader(false));
	} catch (err) {
		console.log('delete channel group saga error:', err);
		yield put(UIAction.showLoader(false));

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${Constants.ALERT.ERROR_ON_SAVING_CHANNEL}`,
				type: 'error',
			}),
		);
	}
}

export function* getIncidents(action: ReturnType<typeof AlertActions.getIncidents>) {
	try {
		const options = action.payload.find((param: any) => param.key == 'options')?.value;
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(AlertsService.getInstance().getIncidents, action.payload);
		yield put(UIAction.showLoader(false));
		if (options?.type == 'csv') {
			var blob = new Blob([data]);
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'incidents_report.csv';
			link.click();
			return;
		}
		yield put(AlertActions.setIncidents(data));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: `An error occurred: ${Constants.ALERT.NO_RECORD_FOUND}`,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield handleAlertSagaError(err,`getIncidents`);
	}
}

export function* getIncidentHistory(action: ReturnType<typeof AlertActions.getIncidentHistory>) {
	try {
		yield put(AlertActions.setIncidentHistory(null));
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(AlertsService.getInstance().getIncidentHistory, action.payload.incidentId);
		yield put(AlertActions.setIncidentHistory(data));
		if (action.payload.callback) {
			action.payload.callback();
		}
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleAlertSagaError(err,`getIncidentHistory`);
	}
}

export function* getIncidentRules(action: ReturnType<typeof AlertActions.getIncidentRules>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(AlertsService.getInstance().getIncidentRules, action.payload);
		yield put(AlertActions.setIncidentRules(data));
		yield put(UIAction.showLoader(false));
		if (!data || data.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: `An error occurred: ${Constants.ALERT.NO_RECORD_FOUND}`,
					type: 'info',
				}),
			);
		}
	} catch (err) {
		yield handleAlertSagaError(err,`getIncidentRules`);
	}
}

export function* updateIncident(action: ReturnType<typeof AlertActions.updateIncident>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const callback = action.payload.callback || null;
		delete action.payload.callback;
		yield call(AlertsService.getInstance().updateIncident, action.payload.incidentId, action.payload.body);
		if (callback) {
			callback();
		}
		yield put(UIAction.showLoader(false));
	} catch (err) {
		yield handleAlertSagaError(err,`updateIncident`);
	}
}

function* handleAlertSagaError(err: any, description?: string) {
	yield put(UIAction.showLoader(false));
	const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

	yield put(
		UserActions.setSnackbarMessage({
			message: `An error occurred: ${errorMessage} in ${description || 'Alerts'}`,
			type: 'error',
		}),
	);
}
