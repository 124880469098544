import React from 'react';

import { connect } from 'react-redux';
import { FormControl, Button, Grid, TextField, ClickAwayListener, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Customer } from '../../components/autoComplete';
import { InvoiceStyles } from './invoice.styles';
import FilterList from '../../components/filterList';
import dataList from '../../constants/dataList';
import { SelectBox } from '../../components/selectBox';
import dateFormat from 'dateformat';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { UIAction } from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { MobileDateRangePopup, DesktopDateRangePopup } from '../../components/dateRangePopup';
import { UserDateFormat } from '../../hooks/functions';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import axios from 'axios';
import { API_BASE_URL } from '../../redux/constants/api.url';
import Api from '../../redux/lib/api';


interface SearchProps {
	selectedCustomer: any;
	selectedDateRange: any;
	properties: any;
	isCCPaymentMode?: boolean;
	setCCMetadata?: Function;
	onSearch: (filter: any) => void;
}

const SearchSection = (props: SearchProps) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const invoiceClasses = InvoiceStyles();
	const [invoicesFetched, setInvoicesFetched] = React.useState(false);
	const [showValidationError, setShowValidationError] = React.useState<boolean>(false);

	const onSearchClick = async (customerId?: any) => {


		setShowValidationError(false);

		if (!props.selectedCustomer) {
			setShowValidationError(true);
			return;
		}

		if (props.isCCPaymentMode) {
			let ccresult: any = await Api.get(`${API_BASE_URL.API_GATEWAY}/users/credit-card-payable?customer_id=${props.selectedCustomer.customerId}`);

			//with the new way of marking invoices CC payable, this method is really just to set the product productMetadata
			//so we know the manufacturer and thus the proper terminal to use
			if (props.setCCMetadata) {
				props.setCCMetadata(ccresult.data.productMetadata);
			}
		}

		let payload: any = [];

		payload.push({
			key: 'customerId',
			value: props.selectedCustomer.customerId,
		});

		const dates = props.selectedDateRange || null;

		if (dates) {
			payload.push({
				key: 'startDate',
				value: dateFormat(dates.startDate, 'yyyy-mm-dd'),
			});

			payload.push({
				key: 'endDate',
				value: dateFormat(dates.endDate, 'yyyy-mm-dd'),
			});
		}

		if (payload.length > 0) {
			props.onSearch(payload);
			setInvoicesFetched(true);
		}
	};

	return (
		<div>
			<Grid container>
				<Grid item xs={12}>
					<GlobalFiltersComponentContainer pageName="INVOICE" executeSearch={onSearchClick} />
				</Grid>
			</Grid>
		</div>
	);
};

const mapDispatchToProps = (dispatch: any) => ({
	//setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
//	checkCustomerCCPaymentEligibility: (customerId: string) => dispatch(UIAction.checkCustomerCCPaymentEligibility(customerId))
});

const mapStateToProps = (state: any) => ({
	selectedCustomer: state.ui.selectedCustomer,
	selectedDateRange: state.ui.selectedDateRange,
	properties: Array.isArray(state.user?.authUser?.record?.properties) ? state.user.authUser.record.properties : []
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
