import { connect } from 'react-redux';
import { UsersComponent } from './users.component';
import { UserManagementActions, TenantsActions, UIAction } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	usersList: state.userManagement.userList ? state.userManagement.userList.result : [],
	totalUsers: state.userManagement.userList ? state.userManagement.userList.total_record : 0,
	isLoading: state.ui.isLoading,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getUsersList: (payload: any | null) => dispatch(UserManagementActions.getUserListStartAction(payload)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(UsersComponent);

export default UsersContainer;
