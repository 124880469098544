export const AlphaSortObject = (obj: any) => {
	if (!obj || !Object.keys(obj).length) {
		return {};
	}

	const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b));
	const sortedObj: any = {};

	sortedKeys.forEach(sortedKey => {
		sortedObj[sortedKey] = obj[sortedKey];
	});

	return sortedObj;
};
