import * as React from 'react';
import { Button, FormControl } from '@material-ui/core';
import { UserManagementActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { userModalStyles } from './users.styles';

function getImportModalStyle() {
	const modalTop = 50;
	const modalLeft = 50;

	return {
		top: `${modalTop}%`,
		left: `${modalLeft}%`,
		transform: `translate(-${modalTop}%, -${modalLeft}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

const DeleteUser: React.FC<any> = props => {
	const importClasses = useStyles();

	return (
		<div style={getImportModalStyle()} className={importClasses.paper}>
			<h2>Delete User</h2>
			<p>Are you sure you want to delete the user with email {props.thisUser.email}?</p>
			<>
				<FormControl className={importClasses.input}>
					<Button
						className={importClasses.addPermBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							props.deleteUser({
								user: props.thisUser,
								callback: () => {
									props.close(true);
								},
							});
						}}
					>
						Yes
					</Button>
				</FormControl>
				<FormControl className={importClasses.input}>
					<Button className={importClasses.cancelBtn} variant="contained" color="default" onClick={props.close}>
						No
					</Button>
				</FormControl>
			</>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	deleteUser: (payload: any) => dispatch(UserManagementActions.deleteUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
