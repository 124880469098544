import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { MuiThemeProvider, TextField, Button, FormControl, Snackbar } from '@material-ui/core';
import PersonaManagementControl from './persona-management-control.component';
import { Role } from '../../redux/models/user/user';
import { Persona } from '../../redux/models/persona/persona';
import { UserActions, UIAction } from '../../redux/actions';
import { CommonStyles } from '../../hooks/styles';
import { cloneDeep } from 'lodash';
import Loading from '../../components/loading';
import { PersonaManagementStyles } from './persona-management.styles';

interface PersonasManagementProps {
	allAvailablePersonas: Persona[];
	personSaveInProgress: boolean;
	personSaveSuccess: boolean;
	personaSaveErrorCode: string;
	setSnackbarOpen: Function;
	setSnackbarMessage: Function;
}

export const PersonasManagementComponent: React.FC<PersonasManagementProps> = props => {
	const [personasListToSave, setPersonasListToSave] = React.useState(cloneDeep(props.allAvailablePersonas));
	const [addPersonaFormVisible, setAddPersonaFormVisible] = React.useState(false);
	const [newPersonaName, setNewPersonaName] = React.useState('');
	const [showSaveResult, setShowSaveResult] = React.useState(null) as any;
	const [personaSaveResultMsg, setPersonaSaveResultMsg] = React.useState('');
	const [personaErrorMsg, setPersonaErrorMsg] = React.useState('');
	const [showIsSaving, setShowIsSaving] = React.useState(false);
	const [isLoadingPersonas, setIsLoadingPersonas] = React.useState(true);
	const [personaBeingEditedName, setPersonaBeingEditedName] = React.useState(null) as any;
	const styles = CommonStyles();
	const personaClasses = PersonaManagementStyles();
	const dispatch = useDispatch();

	React.useEffect(() => {
		setPersonasListToSave(cloneDeep(props.allAvailablePersonas));

		if (props.allAvailablePersonas) {
			setIsLoadingPersonas(false);
		}
	}, [props.allAvailablePersonas]);

	React.useEffect(() => {
		if (props.personSaveSuccess === undefined || props.personSaveSuccess === null) return;

		const message = props.personSaveSuccess
			? 'Persona save succeeded.'
			: props.personaSaveErrorCode === '403'
			? "Persona saved failed because you don't have the necessary permissions."
			: 'An error occurred saving the persona.';

		//pass message to global snackbar component and show it
		props.setSnackbarOpen(true);
		props.setSnackbarMessage({
			message: message,
			type: 'success',
		});

		if (!props.personSaveSuccess) {
			revertChanges();
		}
	}, [props.personSaveSuccess]);

	const showLoading = () => {
		return isLoadingPersonas;
	};

	const handleNewPersonaNameChange = (e: any) => {
		setNewPersonaName(e.target.value);
	};

	const getPersonaListCopy = () => {
		return personasListToSave && personasListToSave.length
			? [...personasListToSave]
			: cloneDeep(props.allAvailablePersonas);
	};

	const getPersonaByName = (personasList: any, personaName: string) => {
		return personasList.find((persona: Persona) => {
			return persona.identifier === personaName;
		});
	};

	/*
	 * a select was changed, so update the reference object
	 */
	const handleRoleChange = (e: any, roleName: string, personaName: string) => {
		const personasListToSaveCopy: any = getPersonaListCopy();
		const personaToUpdate: any = getPersonaByName(personasListToSaveCopy, personaName);

		personaToUpdate.roles[roleName] = e.target.value;
		setPersonasListToSave(personasListToSaveCopy);
	};

	const handlePersonaRoleRemoval = (e: any, roleName: string, personaName: string) => {
		const personasListToSaveCopy: any = getPersonaListCopy();
		const personaToUpdate: any = getPersonaByName(personasListToSaveCopy, personaName);

		delete personaToUpdate.roles[roleName];

		setPersonasListToSave(personasListToSaveCopy);
	};

	const handlePersonaFlagForDeletion = (e: any, personaName: string) => {
		const personasListToSaveCopy: any = getPersonaListCopy();
		const personaToUpdate: any = getPersonaByName(personasListToSaveCopy, personaName);

		personaToUpdate.hidden = true;

		setPersonasListToSave(personasListToSaveCopy);
	};

	const handleNewPersonaAdd = (newPersonsObj: Persona) => {
		const personasListToSaveCopy: any = getPersonaListCopy();

		personasListToSaveCopy.push(newPersonsObj);
		setPersonasListToSave(personasListToSaveCopy);
	};

	const showAddPersonaForm = () => {
		setAddPersonaFormVisible(true);
	};

	const hideAddPersonaForm = () => {
		setAddPersonaFormVisible(false);
	};

	const revertChanges = () => {
		setPersonasListToSave(props.allAvailablePersonas);
		setAddPersonaFormVisible(false);
	};

	async function handlePersonaSave(personaName: string) {
		const personaObj = getPersonaByName(personasListToSave, personaName);

		setShowIsSaving(true);
		await dispatch(UserActions.updatePersonaAction(personaObj));
		setPersonaBeingEditedName(null);
	}

	const addResourceToPersona = (personaName: string, newResourceName: string, newResourceRoleName: string) => {
		const personasListToSaveCopy: any = getPersonaListCopy();
		const personaToUpdate: any = getPersonaByName(personasListToSaveCopy, personaName);

		personaToUpdate.roles[newResourceName] = newResourceRoleName;
		setPersonasListToSave(personasListToSaveCopy);
	};

	const handlePersonaRevert = (personaName: string) => {
		const cachedPersonaObj = getPersonaByName(props.allAvailablePersonas, personaName);
		const personasListToSaveCopy: any = getPersonaListCopy();

		const moddedObj = getPersonaByName(personasListToSaveCopy, personaName);
		moddedObj.roles = cloneDeep(cachedPersonaObj.roles);

		setPersonasListToSave(personasListToSaveCopy);
	};

	const handleCreatePersona = () => {
		if (!newPersonaName) {
			setPersonaErrorMsg('An error occurred: Persona name cannot be blank.');
			return;
		}

		const valResult = /^[a-zA-Z\_]+$/.test(newPersonaName); //allow only letters and underscores

		if (!valResult) {
			setNewPersonaName('');
			setPersonaErrorMsg('An error occurred: Persona names may only contain letters and underscores.');
			return;
		}

		const newNameTransformed = newPersonaName.toUpperCase().trim().replace(/ /g, '_');

		const personaObj: Persona = {
			identifier: newNameTransformed,
			roles: {},
			appType: 'customer', //personas created in this UI are always "customer"
		};

		const isDupe = personasListToSave.some((p: Persona) => {
			return p.identifier === personaObj.identifier;
		});

		if (isDupe) {
			setNewPersonaName('');
			setPersonaErrorMsg('An error occurred: A persona with this name already exists.');
			return;
		}

		setPersonaErrorMsg('');
		dispatch(UserActions.createPersonaAction(personaObj));
		setAddPersonaFormVisible(false);

		//todo: this is a hack. It should be added to the rendered list via the state but that isn't working for an unknown reason
		const plCopy = [...personasListToSave];
		plCopy.push(personaObj);
		setPersonasListToSave(plCopy);
	};

	return (
		<div>
			{showLoading() ? (
				<Loading message="Loading Personas..." />
			) : (
				<>
					{personasListToSave.map((persona: any, index: number) => {
						if (persona.hidden) {
							return null;
						}

						const isEditMode = persona.identifier === personaBeingEditedName;

						return (
							<PersonaManagementControl
								persona={persona}
								key={index}
								handleRoleChange={handleRoleChange}
								handlePersonaRoleRemoval={handlePersonaRoleRemoval}
								handlePersonaSave={handlePersonaSave}
								addResourceToPersona={addResourceToPersona}
								isEditMode={isEditMode}
								setIsEditMode={setPersonaBeingEditedName}
								revertChanges={handlePersonaRevert}
								handlePersonaFlagForDeletion={handlePersonaFlagForDeletion}
							/>
						);
					})}

					{!addPersonaFormVisible && (
						<Button onClick={showAddPersonaForm} className={styles.searchButton} variant="contained" color="primary">
							Add new Persona
						</Button>
					)}

					{addPersonaFormVisible && (
						<div className={personaClasses.newPersonaInputWrapper}>
							<FormControl>
								<TextField
									label="New Persona Name"
									variant="outlined"
									value={newPersonaName}
									onChange={e => {
										handleNewPersonaNameChange(e);
									}}
									className={personaClasses.newPersonaInput}
									style={{
										width: '300px',
									}}
									required
								/>
							</FormControl>
							<Button
								onClick={handleCreatePersona}
								className={styles.searchButton}
								variant="contained"
								color="primary"
								style={{
									height: '40px',
								}}
							>
								Create New Persona
							</Button>
							<Button
								onClick={() => {
									setAddPersonaFormVisible(false);
									setPersonaErrorMsg('');
								}}
								className={styles.searchButton}
								variant="contained"
								color="primary"
								style={{
									height: '40px',
								}}
							>
								Cancel
							</Button>
						</div>
					)}

					{personaErrorMsg && <div className={personaClasses.errorMessage}>{personaErrorMsg}</div>}
				</>
			)}
		</div>
	);
};
