import * as React from 'react';
import Loading from '../../components/loading';
import { CommonStyles } from '../../hooks/styles';
import { FormControl, Grid, Button } from '@material-ui/core';
import { CabinetStyles } from '../cabinets/cabinet.styles';
import EmptyContent from '../../components/emptyContent';
import { useMediaQuery } from 'react-responsive';
import CabinetsChartComponent from '../cabinets/cabinet-chart.component';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { MobileDateRangePopup } from '../../components/dateRangePopup';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { GetUserTimezone, UserDateFormat } from '../../hooks/functions';
import { ConvertDateTimeForRequest } from '../../hooks/functions/ConvertDateTimeForRequest';
import { SelectBox } from '../../components/selectBox';
import { Alert } from '@material-ui/lab';
import { select } from 'd3';

const moment = extendMoment(Moment as any);

const CabinetTemperatureComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const styles = CommonStyles();
	const classes = CabinetStyles();
	let [componentKey, setComponentKey] = React.useState('1');
	const [selectedItem, setSelectedItem] = React.useState(null as any);
	const [selectedCabinet, setSelectedCabinet] = React.useState(null as any);
	const [calendarOpen, setCalendarOpen] = React.useState(false);
	const [chartDateFormat, setChartDateFormat] = React.useState<any>(null);
	const endDate = moment().endOf('day').format();
	const startDate = moment().subtract(1, 'day').startOf('day').format();
	const [dateRange, setDateRange] = React.useState('');
	const [dates, setDates] = React.useState<any>(null);
	const [showCompartmentSelect, setShowCompartmentSelect] = React.useState(false)
	const [loadingCompartments, setLoadingCompartments] = React.useState(false)
	const [secureDeviceCompartments, setSecureDeviceCompartments] = React.useState<any[]>([])
	const [deviceCompartmentsError, setDeviceCompartmentsError] = React.useState(false)
	const [selectedDeviceCompartmentsError, setSelectedDeviceCompartmentsError] = React.useState(false)
	const [selectedSecureCompartment, setSelectedSecureCompartment] = React.useState<any>(null)

	const firstRender = React.useRef(true);

	const [showValidationError, setShowValidationError] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (props.selectedFilterValues && props.selectedFilterValues['CABINET_ID']) {
			const thisCabinet = props.cabinetList.find((cabinet: any) => cabinet.cabinetId === props.selectedFilterValues['CABINET_ID'])
			setSelectedCabinet(thisCabinet)
		}
	}, [props.selectedFilterValues])

	React.useEffect(() => {
		if (selectedCabinet?.cabinetType === 'VISION') {
			setShowCompartmentSelect(true)
			setLoadingCompartments(true)
			setDeviceCompartmentsError(false)
			props.getSecureDeviceCompartments({
				deviceId: selectedCabinet.cabinetId,
				success: (compartments: any) => {
					setLoadingCompartments(false)
					setSecureDeviceCompartments(compartments)
				},
				error: () => {
					setDeviceCompartmentsError(true)
				}
			})
		} else {
			setShowCompartmentSelect(false)
		}
	}, [selectedCabinet])

	const onCustomerSelect = (event: any, value: any) => {
		setSelectedCabinet(null);
		componentKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		setComponentKey(componentKey);
		props.clearCabinetList();
		if (!value) {
			setSelectedItem(null);
			props.setSelectedCustomer(null);
			window.history.pushState({}, '', `/cabinet-temperature`);
			return;
		}
		setSelectedItem(value);
		props.setSelectedCustomer(value);
		firstRender.current = false;
		window.history.pushState({}, '', `/cabinet-temperature/${value.customerId}`);
	};



	const showLoadingMsg = () => !props.authUser && props.tenants.length === 0;

	const onDateRangeSelect = (dates: any) => {
		setDates(dates);
		const startDateString = dates?.startDate ? UserDateFormat(props.properties, dates.startDate, false) : '';
		const endDateString = dates?.endDate ? UserDateFormat(props.properties, dates.endDate, false) : '';
		setDateRange(startDateString + ' - ' + endDateString);
	};

	const onSearchClick = () => {
		firstRender.current = false;
		setShowValidationError(false);

		if (
			!props.selectedCustomer ||
			!props.selectedDateRange
		) {
			setShowValidationError(true);
			return;
		}
		handleSearch();
	};

	const handleSearch = () => {
		const cabinetId = props.selectedFilterValues ? props.selectedFilterValues['CABINET_ID'] : '';
		const customerId = props.selectedCustomer ? props.selectedCustomer.customerId : '';

		const payloadStartDate = props.selectedDateRange
			? ConvertDateTimeForRequest(props.properties, props.selectedDateRange.startDate)
			: null;
		const payloadEndDate = props.selectedDateRange
			? ConvertDateTimeForRequest(props.properties, props.selectedDateRange.endDate)
			: new Date();

		let tempPayload: any = {
			order: ["tempTimestamp asc"],
			where: {
				customerId: customerId,
				tempTimestamp: {between: [payloadStartDate, payloadEndDate]},
				...(cabinetId && {deviceId: cabinetId})
			}
		}

		if (selectedCabinet?.cabinetType === 'VISION') {
			if (selectedSecureCompartment === null) {
				setSelectedDeviceCompartmentsError(true)
				return
			}
			tempPayload.where.compartmentOffset = selectedSecureCompartment
		}

		const alertsPayload: any = {
			include: ["sourceType"],
			order: ["alertTimestamp asc"],
			where: {
				customerId: customerId,
				alertTimestamp: {between: [payloadStartDate, payloadEndDate]},
				...(cabinetId && {deviceId: cabinetId})
			}
		}

		props.getTemperature({ tempFilter: JSON.stringify(tempPayload), alertFilter: JSON.stringify(alertsPayload) });
	};

	const exportTemperatureCsv = () => {
		if (!props.cabinetTemperature || Object.keys(props.cabinetTemperature).length === 0) {
			return;
		}

		const filter = {
			customerId: props.selectedCustomer.customerId,
			...(props.selectedFilterValues && props.selectedFilterValues['CABINET_ID'] && {deviceId: props.selectedFilterValues['CABINET_ID']}),
			timezone: moment.tz.guess(),
			startTime: moment(props.selectedDateRange.startDate).format(),
			endTime: moment(props.selectedDateRange.endDate.setHours(23, 59, 59)).format(),
		};

		const params = {
			filter_object_from_ui: filter,
			report_type: 'RNI_UIEXPORT_CabinetTemperatureReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: moment.tz.guess(),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
		};

		props.sendOneTimeReportRequest(params);
	};

	const getOnlyTemperatureAlerts = (alerts: any[]) => {
		if (!alerts) return [];
		const tempOnlyAlerts = alerts.filter((alert: any) => alert.alertType.alertGroup === 'TEMPERATURE');

		// Group by deviceId for displaying multiple devices
		const groupedAlerts = tempOnlyAlerts.reduce((previous: any, current: any) => {
			previous[`${current.deviceId}`] = [
			  ...(previous[`${current.deviceId}`] || []),
			  current,
			];
			return previous;
		  }, {})

		return groupedAlerts
	};

	const getChartDateFormat = () => {
		if (!dates) {
			return '{value:%e-%b}';
		}
		const momentRange = moment().range(dates.startDate, dates.endDate);
		const daysInRange = momentRange.end.diff(dates.startDate, 'days');
		if (daysInRange < 7) {
			return '{value:%e-%b %H:%M}';
		}
		return '{value:%e-%b}';
	};

	React.useEffect(() => {
		if (!selectedCabinet && !dates) {
			setDates({
				startDate: new Date(startDate),
				endDate: new Date(),
				key: 'selection',
			});
			setDateRange(
				UserDateFormat(props.properties, new Date(startDate)) +
					' - ' +
					UserDateFormat(props.properties, new Date(endDate)),
			);
		}
	}, [selectedCabinet]);

	React.useEffect(() => {
		if (props.selectedCustomer) {
			onCustomerSelect(null, props.selectedCustomer);
			setSelectedItem(props.selectedCustomer);
		}
	}, [props.selectedCustomer]);

	React.useEffect(() => {
		setChartDateFormat(getChartDateFormat());
	}, [props.cabinetTemperature]);

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={styles.searchWrap}>
						<Grid item xs={12}>
							<GlobalFiltersComponentContainer
								pageName="CABINET_TEMPERATURE"
								executeSearch={onSearchClick}
								handleTenantSelect={() => {}}
							/>
						</Grid>
						{showCompartmentSelect && <FormControl className={classes.formControl}>
						{deviceCompartmentsError &&
							<Alert severity="error">Failed to load device compartments</Alert>
						}
							{loadingCompartments && <Loading message=''/>}
							{!loadingCompartments && <SelectBox
								style={{ width: 200 }}
								inputLabel="Select Compartment"
								emptyItemLabel={'Choose'}
								listItems={secureDeviceCompartments.map((compartment: any) => ({label: compartment.name, value: compartment.compartmentOffset.toString()}))}
								onChangeItem={(value: string) => {
									setSelectedDeviceCompartmentsError(false)
									setSelectedSecureCompartment(value)
								}}
								selected={selectedSecureCompartment}
								error={selectedDeviceCompartmentsError}
								errorText='Please select a compartment to view temperature data.'
								required
							/>}
						</FormControl>}
						<FormControl className={classes.formControl} style={{ verticalAlign: 'bottom' }}>
							<div>
								<Button
									id="export_button"
									className={classes.searchButton}
									style={{ width: 100 }}
									type="button"
									variant="contained"
									color="primary"
									onClick={() => exportTemperatureCsv()}
								>
									Export
								</Button>
							</div>
						</FormControl>
					</div>
					{props.cabinetTemperature && Object.keys(props.cabinetTemperature).length > 0 ? Object.keys(props.cabinetTemperature).map(deviceId => (
						<div>
							<CabinetsChartComponent
								data={{ temp: props.cabinetTemperature[deviceId], alerts: getOnlyTemperatureAlerts(props.alertsList)[deviceId] || [] }}
								dateFormat={chartDateFormat}
								cabinetType={selectedCabinet?.type}
								timezone={GetUserTimezone(props.properties)}
								deviceId={deviceId}
							/>
							<br/>
						</div>
					)) : (
						<EmptyContent message="Select the filter criteria above to view device temperature." />
					)}
					{calendarOpen && !isDesktopOrLaptop && (
						<MobileDateRangePopup setOpen={setCalendarOpen} onSelect={onDateRangeSelect} dates={dates} />
					)}
				</div>
			)}
		</div>
	);
};

export default CabinetTemperatureComponent;
