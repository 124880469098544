export const CustomerSearch = (searchTenants: any, values?: any) => {
	if (values) {
		searchTenants({
			offset: 0,
			limit: 100,
			skip: 0,
			order: ['id'],
			where: {
				or: [
					{
						name: { like: '%' + values + '%' },
					},
					{
						customerId: { like: '%' + values + '%' },
					},
				],
			},
			fields: {
				id: true,
				customerId: true,
				name: true,
			},
		});
	}
};
