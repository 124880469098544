import React, { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { PracticePageStyles } from '../../../hooks/styles';
import { GridContainer } from '../../../hooks/functions';

import { TenantsActions } from '../../../redux/actions';

interface AdditionalDetailsProps {
	additionalInfo: any;
}

const AdditionalDetails = (props: AdditionalDetailsProps) => {
	// define states
	const { customerId }: { customerId?: string } = useParams();
	const classes = PracticePageStyles();
	const isFirstRender = useRef(true);
	const dispatch = useDispatch();

	const callApi = () => {
		isFirstRender.current = false;
		let payload: any = [];
		if (customerId) {
			payload.push(customerId);
		}
		dispatch(TenantsActions.getTenantAdditionalInfo(payload));
	};

	useEffect(() => {
		if (isFirstRender.current) {
			callApi();
		}
	});

	return (
		<div>
			<Paper className={clsx(classes.paper, 'container-wrapper')}>
				{GridContainer([
					{
						label: 'GpoName',
						value: props.additionalInfo && props.additionalInfo.GpoName ? props.additionalInfo.GpoName : '',
					},
					{
						label: 'Invoice Cycle,',
						value: props.additionalInfo && props.additionalInfo.invoiceCycle ? props.additionalInfo.invoiceCycle : '',
					},
					{
						label: 'Payment Terms',
						value: props.additionalInfo && props.additionalInfo.PaymentTerm ? props.additionalInfo.PaymentTerm : '',
					},
					{
						label: 'Invoice Date of week',
						value: props.additionalInfo && props.additionalInfo.invoiceDow ? props.additionalInfo.invoiceDow : '',
					},
				])}
				{GridContainer([
					{
						label: 'Sales Person Name',
						value:
							props.additionalInfo && props.additionalInfo.SalesPersonName ? props.additionalInfo.SalesPersonName : '',
					},
					{
						label: 'Phone No',
						value: props.additionalInfo && props.additionalInfo.Telephone1 ? props.additionalInfo.Telephone1 : '',
					},
					{
						label: 'Address',
						value:
							props.additionalInfo && props.additionalInfo.Soldtopartyaddress
								? props.additionalInfo.Soldtopartyaddress
								: '',
					},
				])}
			</Paper>
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		additionalInfo: state.tenants.additionalInfo ? state.tenants.additionalInfo : [],
	};
};

export default connect(mapStateToProps)(AdditionalDetails);
