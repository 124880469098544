import * as React from 'react';
import { makeStyles, createStyles, Theme, Grid, FormControl, TextField, Button } from '@material-ui/core';
import { CabinetStyles } from './cabinet.styles';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';

function getModalStyle() {
	return {
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(2, 4, 3),
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			position: 'absolute',
			boxShadow: theme.shadows[5],
			borderRadius: '3px',
		},
		modalActionError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
	}),
);

interface EditCabinetProps {
	cabinet: any;
	isLoading: any;
	apiError: any;
	closeModal: (refreshData?: boolean) => void;
	updateCabinet: (payload: any) => void;
}

const EditCabinetModal: React.FC<EditCabinetProps> = (props: EditCabinetProps) => {
	const modalClasses = useStyles();
	const classes = CabinetStyles();
	const [modalStyle] = React.useState(getModalStyle);
	const [cabinet, setCabinet] = React.useState<any>(props.cabinet);
	const [submitted, setSubmitted] = React.useState(false);

	const handleTextFieldChange = (event: any, field: string) => {
		if (field === 'name') {
			setCabinet({ ...cabinet, cabinetName: event.target.value });
		}
	};

	const isValid = () => {
		if (!cabinet.cabinetName) {
			return false;
		}
		return true;
	};

	const submit = () => {
		if (!isValid()) {
			return;
		}

		const cabinetId = cabinet.cabinetId;
		const customerId = cabinet.customerId;
		const data = {
			cabinetName: cabinet.cabinetName,
		};
		props.updateCabinet({ cabinetId: cabinetId, customerId: customerId, data: data });
	};

	const handleCancel = () => {
		props.closeModal();
	};

	React.useEffect(() => {
		if (props.isLoading) {
			setSubmitted(true);
		}
		if (submitted && !props.isLoading && !props.apiError) {
			props.closeModal(true);
		}
		if (props.apiError) {
			setSubmitted(false);
		}
	}, [props, submitted]);

	return (
		<div style={modalStyle} className={modalClasses.paper}>
			<h3>Edit Cabinet Info</h3>
			<Grid container>
				<Grid item xs={12}>
					<FormControl className={classes.formControl}>
						<TextField
							label="Cabinet Name"
							variant="outlined"
							value={cabinet.cabinetName}
							error={!cabinet.cabinetName ? true : false}
							helperText={!cabinet.cabinetName ? 'A cabinet name is required' : ''}
							onChange={event => {
								handleTextFieldChange(event, 'name');
							}}
							required
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Button
							onClick={submit}
							type="button"
							style={{ marginTop: 12 }}
							className={classes.searchButton}
							variant="contained"
							color="primary"
						>
							Submit
						</Button>
					</FormControl>
					<FormControl className={classes.formControl}>
						<Button
							onClick={handleCancel}
							type="button"
							style={{ marginTop: 12 }}
							className={classes.cancelBtn}
							variant="contained"
							color="default"
						>
							Cancel
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinet: state.cabinet.selectedCabinet,
	isLoading: state.ui.isLoading,
	apiError: state.ui.errorMessage,
});

const mapDispatchToProps = (dispatch: any) => ({
	updateCabinet: (payload: any) => dispatch(CabinetActions.updateCabinet(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCabinetModal);
