import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { PracticePageStyles } from '../../../hooks/styles';
import {
	Paper,
	Grid,
	TextField,
	FormControl,
	makeStyles,
	Button,
	Typography,
	FormControlLabel,
	Checkbox,
	Chip,
	withStyles,
	Switch,
	Dialog,
	DialogTitle,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AlertActions } from '../../../redux/actions';
import { useParams } from 'react-router-dom';
import { emailRegex } from '../../users/users.helper';
import { cloneDeep } from 'lodash';
import { SelectBox } from '../../../components/selectBox';
import { globalColors } from '../../../hooks/styles/muiTheme';

const NotificationsSwitch = withStyles({
	switchBase: {
		color: '#e0e0e0',
		'&$checked': {
			color: globalColors.FFF_BLUE,
		},
		'&$checked + $track': {
			backgroundColor: '#e0e0e0',
		},
	},
	checked: {},
	track: {},
})(Switch);

const iconStyles = makeStyles(theme => ({
	groupHeading: {
		margin: 0,
	},
	changeCustomerIcon: {
		fontSize: '1.7em',
		cursor: 'pointer',
		verticalAlign: 'middle',
		color: globalColors.FFF_BLUE,
		'&:hover': {
			color: '#aaa',
		},
	},
	saveBtn: {
		backgroundColor: globalColors.FFF_BLUE,
		fontSize: '10px',
		cursor: 'pointer',
		verticalAlign: 'middle',
		margin: '10px 10px 15px 0px',
		'&:hover': {
			backgroundColor: globalColors.FFF_BLUE,
		},
	},
	addBtn: {
		marginLeft: '10px',
	},
	deleteBtn: {
		backgroundColor: '#737373',
		color: 'white',
		fontSize: '10px',
		cursor: 'pointer',
		verticalAlign: 'middle',
		margin: '10px 10px 15px 0px',
		'&:hover': {
			backgroundColor: '#737373',
		},
	},
	cancelBtn: {
		backgroundColor: '#e0e0e0',
		fontSize: '10px',
		cursor: 'pointer',
		verticalAlign: 'middle',
		margin: '10px 0 0 0',
		color: '#555',
		'&:hover': {
			backgroundColor: '#e0e0e0',
		},
	},
	chipWrap: {
		margin: '0.5em',
		minWidth: '125px',
	},
	addNotificationType: {
		color: globalColors.FFF_BLUE,
		fontWeight: 'bold',
		width: '240px',
		marginTop: 15,
	},
}));

const ConfirmDeleteDialog = (props: any) => {
	const iconClasses = iconStyles();
	const { toggleOpen, onClose, deleteData } = props;

	const confirm = () => {
		toggleOpen(false);
		onClose(deleteData);
	};

	const cancel = () => {
		toggleOpen(false);
	};

	return (
		<Dialog aria-labelledby="simple-dialog-title" open={props.open}>
			<DialogTitle id="simple-dialog-title">Delete {deleteData.notificationLabel} Notification Type?</DialogTitle>
			<Grid container>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<FormControl>
						<Button className={iconClasses.saveBtn} variant="contained" color="primary" onClick={confirm}>
							CONFIRM
						</Button>
					</FormControl>
					<FormControl>
						<Button className={iconClasses.cancelBtn} variant="contained" color="primary" onClick={cancel}>
							CANCEL
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		</Dialog>
	);
};

const NotificationSettings = (props: any) => {
	const classes = PracticePageStyles();
	const iconClasses = iconStyles();
	const { customerId } = useParams<any>();
	const [notificationDataFetched, setNotificationDataFetched] = React.useState(false);
	const [channelGroups, setChannelGroups] = React.useState([] as any[]);
	const [channelGroupsExpanded, setChannelGroupsExpanded] = React.useState([] as boolean[]);
	const [channelGroupsEditMode, setChannelGroupsEditMode] = React.useState([] as boolean[]);
	const [channelGroupsEmailText, setChannelGroupsEmailText] = React.useState([] as any[]);
	const [selectedNotificationTypes, setSelectedNotificationTypes] = React.useState([] as any[]);
	const [notificationTypeErrors, setNotificationTypeErrors] = React.useState({} as any);
	const [recipientErrors, setRecipientErrors] = React.useState({} as any);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
	const [deleteData, setDeleteData] = React.useState({} as any);
	const emailTemplatesMap: any = {
		po_approval_email: 'po_approval_template',
		incident: 'incident_notification_template',
		shipping_receipt_errors: 'shipping_receipt_error_template',
		inventory_reminder: 'inventory_reminder',
		suspended_transaction: 'suspended_transaction',
		viewpoint_temp_excursion: 'viewpoint_temp_excursion',
	};

	React.useEffect(() => {
		if (!notificationDataFetched) {
			props.getChannelTypes();
			props.getNotificationTypes();
			props.getChannelGroups(customerId);
			setNotificationDataFetched(true);
		}
	});

	React.useEffect(() => {
		setChannelGroups(cloneDeep(props.channelGroups));
		setChannelGroupsEditMode(new Array(props.channelGroups.length).fill(false));
		setChannelGroupsEmailText(props.channelGroups.map(() => ''));
		channelGroupsExpanded.length === 0 && setChannelGroupsExpanded(new Array(props.channelGroups.length).fill(false));
		getSelectedNotificationTypes();
	}, [props.channelGroups]);

	const getSelectedNotificationTypes = () => {
		setSelectedNotificationTypes(
			props.channelGroups.map((channelGroup: any) => {
				return channelGroup.notificationType;
			}),
		);
	};

	const getNotificationLabelByType = (selectedType: any) => {
		if (!props.notificationTypes || !selectedType) {
			return;
		}
		const notificationType = props.notificationTypes.find((type: any) => type.notificationType === selectedType);
		if (!notificationType) {
			return selectedType;
		}
		return notificationType.notificationLabel;
	};

	const submit = (groupIndex: number) => {
		if (notificationTypeErrors[groupIndex] || recipientErrors[groupIndex]) {
			return;
		}
		if (!channelGroups[groupIndex].notificationType) {
			setNotificationTypeErrors({
				...notificationTypeErrors,
				...{ [groupIndex]: 'Please select a notification type.' },
			});
			return;
		}
		if (!channelGroups[groupIndex].channelData || channelGroups[groupIndex].channelData.length === 0) {
			setRecipientErrors({ ...recipientErrors, ...{ [groupIndex]: 'Please add a recipient email.' } });
			return;
		}

		const updatedChannelGroup = cloneDeep(channelGroups[groupIndex]);

		const payload: any = {
			customerId: customerId,
			notificationType: updatedChannelGroup.notificationType,
			data: {
				notificationType: updatedChannelGroup.notificationType,
				channelGroupName: updatedChannelGroup.channelGroupName || null,
				channel_data: updatedChannelGroup.channelData,
				active: updatedChannelGroup.active.toString(),
			},
		};

		props.submitChannelGroup(payload);
		let editModes = [...channelGroupsEditMode];
		editModes[groupIndex] = true;
		setChannelGroupsEditMode(editModes);
	};

	const notificationTypeAlreadyConfigured = (index: number, type: any) => {
		if (!type) {
			return null;
		}
		const result = props.channelGroups.find(
			(group: any, findIndex: number) => group.notificationType === type && findIndex !== index,
		)
			? 'This notification type is already configured.'
			: null;
		return result;
	};

	const handleNotificationTypeDelete = (deleteData: any) => {
		props.deleteChannelGroup({ notificationType: deleteData.notificationType, customerId: deleteData.customerId });
	};

	return (
		<div>
			{notificationDataFetched && (
				<Paper className={clsx(classes.paper, 'container-wrapper')} style={{ flexGrow: 1 }}>
					<Grid container>
						<Grid item xs={12}>
							{channelGroups.map((channelGroup: any, index: number) => (
								<Accordion key={index} expanded={channelGroupsExpanded[index] ? true : false}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										onClick={() => {
											let expanded = [...channelGroupsExpanded];
											expanded[index] = !channelGroupsExpanded[index];
											setChannelGroupsExpanded(expanded);
										}}
									>
										<h3 className={iconClasses.groupHeading} style={!channelGroup.active ? { color: 'grey' } : {}}>
											Notification Type: {getNotificationLabelByType(channelGroup.notificationType)}
											{!channelGroup.active && ' (Disabled)'}
										</h3>
									</AccordionSummary>
									<AccordionDetails>
										<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
											{channelGroupsEditMode[index] && !props.channelGroups[index]?.notificationType && (
												<div>
													<SelectBox
														style={{ width: 200 }}
														inputLabel={'Notification Type'}
														emptyItemLabel={'Choose'}
														selected={channelGroups[index].notificationType}
														listItems={props.notificationTypes
															.filter(
																(type: any) =>
																	type.supportedChannelType.channel_types.includes('email') ||
																	type.supportedChannelType.channel_types.includes('email_template'),
															)
															.map((type: any) => ({ label: type.notificationLabel, value: type.notificationType }))}
														onChangeItem={(value: any) => {
															let chGroups = cloneDeep(channelGroups);
															chGroups[index].notificationType = value;
															setChannelGroups(chGroups);
															setNotificationTypeErrors({
																...notificationTypeErrors,
																...{ [index]: notificationTypeAlreadyConfigured(index, value) },
															});
														}}
														error={notificationTypeErrors[index] ? true : false}
														errorText={notificationTypeErrors[index] ? notificationTypeErrors[index] : ''}
													/>
													<br />
												</div>
											)}
											<p>Recipients:</p>
											{channelGroup.channelData?.map((channelData: any, emailIndex: number) => {
												return (
													channelData.SEND_EMAIL_TO &&
													['email', 'email_template'].includes(channelData.channel_type) && (
														<FormControl key={emailIndex} className={iconClasses.chipWrap}>
															<Chip
																label={channelData.SEND_EMAIL_TO}
																onDelete={
																	channelGroupsEditMode[index]
																		? () => {
																				let chGroups = cloneDeep(channelGroups);
																				chGroups[index].channelData.splice(emailIndex, 1);
																				setChannelGroups(chGroups);
																		  }
																		: undefined
																}
															/>
														</FormControl>
													)
												);
											})}
											{channelGroupsEditMode[index] && (
												<div style={{ marginTop: 20 }}>
													<FormControl>
														<TextField
															style={{ minWidth: '200px' }}
															label="Recipient Email"
															value={channelGroupsEmailText[index] || ''}
															onChange={e => {
																let emailTextArr = [...channelGroupsEmailText];
																emailTextArr[index] = e.target.value || '';
																setChannelGroupsEmailText(emailTextArr);
																const error =
																	e.target.value && !emailRegex.test(e.target.value)
																		? 'Please enter a valid email'
																		: null;
																setRecipientErrors({ ...recipientErrors, ...{ [index]: error } });
															}}
															error={recipientErrors[index] ? true : false}
															helperText={recipientErrors[index] || ''}
														/>
													</FormControl>
													<FormControl>
														<Button
															className={[iconClasses.saveBtn, iconClasses.addBtn].join(' ')}
															variant="contained"
															color="primary"
															onClick={() => {
																if (!channelGroupsEmailText[index] || !emailRegex.test(channelGroupsEmailText[index])) {
																	return;
																}
																if (!channelGroups[index].notificationType) {
																	setNotificationTypeErrors({
																		...notificationTypeErrors,
																		...{ [index]: 'Please select a notification type first.' },
																	});
																	return;
																}

																let chGroups = cloneDeep(channelGroups);
																if (!chGroups[index].channelData) {
																	chGroups[index].channelData = [];
																}
																let channelType = 'email_template';
																const notificationType = props.notificationTypes.find(
																	(type: any) => type.notificationType === channelGroups[index].notificationType,
																);
																if (
																	!notificationType ||
																	!notificationType.supportedChannelType.channel_types.includes('email_template')
																) {
																	channelType = 'email';
																}
																const channelData: any = {
																	channel_type: channelType,
																	SEND_EMAIL_TO: channelGroupsEmailText[index],
																};
																if (channelType === 'email_template') {
																	// if it doesn't exist in the emailTemplatesMap, then it is the same name in email_templates table
																	channelData.TEMPLATE_NAME =
																		emailTemplatesMap[channelGroups[index].notificationType] ??
																		channelGroups[index].notificationType;
																}
																chGroups[index].channelData.push(channelData);
																setChannelGroups(chGroups);
																let emailTextArr = [...channelGroupsEmailText];
																emailTextArr[index] = '';
																setChannelGroupsEmailText(emailTextArr);
																setRecipientErrors({ ...recipientErrors, ...{ [index]: false } });
															}}
														>
															ADD
														</Button>
													</FormControl>
												</div>
											)}
											<div style={{ marginTop: 15 }}>
												{channelGroupsEditMode[index] && (
													<FormControl>
														<FormControlLabel
															control={
																<NotificationsSwitch
																	checked={channelGroups[index].active}
																	onClick={() => {
																		let chGroups = cloneDeep(channelGroups);
																		chGroups[index].active = !chGroups[index].active;
																		setChannelGroups(chGroups);
																	}}
																/>
															}
															label={channelGroups[index].active ? 'Disable' : 'Enable'}
														/>
													</FormControl>
												)}
											</div>
											{!channelGroupsEditMode[index] && (
												<FormControl>
													<Button
														className={iconClasses.saveBtn}
														variant="contained"
														color="primary"
														onClick={() => {
															let editModes = [...channelGroupsEditMode];
															editModes[index] = true;
															setChannelGroupsEditMode(editModes);
														}}
													>
														EDIT
													</Button>
												</FormControl>
											)}
											{!channelGroupsEditMode[index] && (
												<FormControl>
													<Button
														className={iconClasses.deleteBtn}
														variant="contained"
														color="primary"
														onClick={() => {
															setDeleteData({
																notificationLabel: getNotificationLabelByType(channelGroups[index].notificationType),
																notificationType: channelGroups[index].notificationType,
																customerId: customerId,
															});
															setConfirmDeleteOpen(true);
														}}
													>
														DELETE
													</Button>
												</FormControl>
											)}
											{channelGroupsEditMode[index] && (
												<FormControl>
													<Button
														className={iconClasses.saveBtn}
														variant="contained"
														color="primary"
														onClick={() => {
															submit(index);
														}}
													>
														SAVE
													</Button>
												</FormControl>
											)}
											{channelGroupsEditMode[index] && (
												<FormControl>
													<Button
														className={iconClasses.cancelBtn}
														variant="contained"
														color="primary"
														onClick={() => {
															let chGroups = cloneDeep(channelGroups);
															if (!props.channelGroups[index]) {
																chGroups.splice(index, 1);
																setChannelGroups(chGroups);
																return;
															}
															let prevChannelGroup = cloneDeep(props.channelGroups[index]);
															chGroups[index] = prevChannelGroup;
															setChannelGroups(chGroups);
															let editModes = [...channelGroupsEditMode];
															editModes[index] = false;
															setChannelGroupsEditMode(editModes);
														}}
													>
														CANCEL
													</Button>
												</FormControl>
											)}
										</Paper>
									</AccordionDetails>
								</Accordion>
							))}
						</Grid>
					</Grid>
					<FormControl>
						<Button
							className={iconClasses.addNotificationType}
							startIcon={<AddAlertIcon />}
							onClick={() => {
								let editModes = [...channelGroupsEditMode];
								editModes.push(true);
								setChannelGroupsEditMode(editModes);
								let expanded = [...channelGroupsExpanded];
								expanded.push(true);
								setChannelGroupsExpanded(expanded);
								let chGroups = cloneDeep(channelGroups);
								chGroups.push({
									notificationType: null,
									channelGroupName: null,
									channelData: [],
									active: false,
								});
								setChannelGroups(chGroups);
							}}
						>
							Add Notification Type
						</Button>
					</FormControl>
				</Paper>
			)}
			<ConfirmDeleteDialog
				onClose={handleNotificationTypeDelete}
				open={confirmDeleteOpen}
				toggleOpen={setConfirmDeleteOpen}
				deleteData={deleteData}
			/>
		</div>
	);
};

/*
 * standardize so that channelData is on top level for frontend code convenience,
 * and to make sure the data is in the expected format to submit back to backend when needed
 */
const formatChannelGroups = (channelGroups: any) => {
	if (!channelGroups) {
		return [];
	}

	const newCg: any[] = [];

	channelGroups.forEach((cg: any) => {
		if (cg.channelData?.channel_data) {
			cg.channelData = cg.channelData.channel_data;
		}

		newCg.push(cg);
	});

	return newCg;
};

const mapStateToProps = (state: any) => {
	return {
		tenantDetails: state.tenants.basicInfo,
		notificationTypes: state.alerts.notificationTypes,
		channelTypes: state.alerts.channelTypes,
		channelGroups: formatChannelGroups(state.alerts.channelGroups) || [],
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getNotificationTypes: () => dispatch(AlertActions.getNotificationTypesAction()),
	getChannelTypes: () => dispatch(AlertActions.getChannelTypesAction()),
	getChannelGroups: (customerId: string) => dispatch(AlertActions.getNotificationChannelGroupsAction(customerId)),
	submitChannelGroup: (payload: any) => dispatch(AlertActions.submitChannelGroupAction(payload)),
	deleteChannelGroup: (payload: any) => dispatch(AlertActions.deleteChannelGroupAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
