import * as React from 'react';
import { Button, FormControl, IconButton, TextField, Tooltip } from '@material-ui/core';
import { UserManagementActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { userModalStyles } from './users.styles';
import LockResetIcon from '@mui/icons-material/LockReset';

function getImportModalStyle() {
	const modalTop = 50;
	const modalLeft = 50;

	return {
		top: `${modalTop}%`,
		left: `${modalLeft}%`,
		transform: `translate(-${modalTop}%, -${modalLeft}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

const SetPassword: React.FC<any> = props => {
	const importClasses = useStyles();
	const [newPass, setNewPass] = React.useState<string>('');
	const [copied, setCopied] = React.useState(false);

	const genPass = (length: number) => {
		var result = '';
		var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	return (
		<div style={getImportModalStyle()} className={importClasses.paper}>
			<h2>Set Temporary Password</h2>
			<p>
				Set a temporary password for the user: {props.thisUser.email}.<br></br>
				They will <b>not</b> be notified of this action automatically so correspondence may be required. Once this
				password is used to log in, the user will be prompted to set a new password.<br></br>
				<br></br>
				<b>Note:</b> If this user hasn't completed registration, once they've set a new password, they will be prompted
				to enter a verification code which they received in the invite email. If needed, a new verification code can be
				sent by using the "Resend Invite Email" action.
			</p>
			<>
				<FormControl className={importClasses.input}>
					<TextField
						id="standard-name"
						label="New Password"
						value={newPass}
						onChange={e => {
							setNewPass(e.target.value);
							setCopied(false);
						}}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() => {
										const generatedPassword = genPass(10);
										setNewPass(generatedPassword);
										setCopied(false);
									}}
								>
									<Tooltip title="Generate a password">
										<LockResetIcon />
									</Tooltip>
								</IconButton>
							),
						}}
					/>
				</FormControl>
				{newPass && (
					<FormControl className={importClasses.input} style={{ paddingTop: '20px' }}>
						<Tooltip title={copied ? 'Copied!' : 'Copy password to clipboard'}>
							<Button
								className={importClasses.addPermBtn}
								variant="contained"
								color="secondary"
								onClick={() => {
									navigator.clipboard.writeText(newPass);
									setCopied(true);
								}}
							>
								Copy Password
							</Button>
						</Tooltip>
					</FormControl>
				)}
				<br></br>
				<br></br>
				<FormControl className={importClasses.input}>
					<Button
						className={importClasses.addPermBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							props.setPassword({ email: props.thisUser.email, tempPassword: newPass });
							props.close();
						}}
					>
						Set
					</Button>
				</FormControl>
				<FormControl className={importClasses.input}>
					<Button className={importClasses.cancelBtn} variant="contained" color="default" onClick={props.close}>
						Cancel
					</Button>
				</FormControl>
			</>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	setPassword: (payload: object) => dispatch(UserManagementActions.setPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
