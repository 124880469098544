import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import { FixViewColumns, SyncCartridgeInventoryColumns } from '../../hooks/functions';
import { InventoryManagementStyles } from './inventory-management.styles';
import moment from 'moment';
import { getMuiTheme } from '../../hooks/styles';
import { tablePagination } from '../../hooks/functions/TablePagination';
import { globalColors } from '../../hooks/styles/muiTheme';
import { UIAction } from '../../redux/actions';
import { connect } from 'react-redux';

interface InventoryTableProps {
	columns: any;
	data: any;
	totalRecord: any;
	globalRowsPerPage: any;
	title?: any;
	tableMessage?: any;
	onRowClick?: any;
	tablePersist?: any;
	onPagination: (pagination: any, sortOrder: any) => void;
	onGetCsv: (tableColumns: any) => void;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
	setTablePersist?: (tableState: any) => void;
}

const CartridgeInventoryTable: React.FC<InventoryTableProps> = props => {
	const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	let theme = getMuiTheme();
	const classes = InventoryManagementStyles();
	const [tableColumns, setTableColumns] = React.useState(props.columns);
	const [paginationInit, setPaginationInit] = React.useState(false);
	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	React.useEffect(() => {
		SyncCartridgeInventoryColumns(props, setTableColumns);
	}, [props.columns, props.tablePersist]);

	React.useEffect(() => {
		//Check to see if the column we are sorting by exists in tableColumns
		let sortColumn = sortOrder.split(' ')[0];
		let newSortOrder = '';
		if (tableColumns.some((column: any) => column.name === sortColumn)) {
			newSortOrder = sortOrder;
		}
		//Handle pagination/sorting
		paginationInit && props.onPagination({ ...pagination, limit: props.globalRowsPerPage }, newSortOrder);
		setPaginationInit(true);
	}, [pagination, sortOrder]);

	return (
		<div>
			<MUIDataTable
				title={props.title ? <h3 className={classes.tableHeader}>{props.title}</h3> : ''}
				columns={tableColumns}
				data={props.data}
				options={{
					rowsPerPage: props.globalRowsPerPage,
					rowsPerPageOptions: dataList.PageLimitOptions,
					onChangeRowsPerPage: (numberOfRows: number) => {
						setPagination({ ...pagination, limit: numberOfRows });
						props.setGlobalRowsPerPage(numberOfRows);
					},
					onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
						props.onGetCsv(cols);
						return false;
					},
					textLabels: {
						body: {
							noMatch: props.tableMessage,
						},
					},
					selectableRows: 'none',
					serverSide: true,
					page: pagination.page,
					count: props.totalRecord,
					filterType: 'multiselect',
					filter: false,
					search: false,
					setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
						if (!props.data[dataIndex].expiration) {
							return;
						}
						const expiration = moment(props.data[dataIndex].expiration, 'YYYY-MM-DD');
						if (moment().diff(expiration, 'day') >= 0) {
							return { style: { backgroundColor: globalColors.TABLE_WARNING } };
						}
						return;
					},
					onRowClick: props.onRowClick,
					onColumnViewChange: (changedColumn: string, direction: string) => {
						setTableColumns(FixViewColumns(changedColumn, direction, tableColumns));
					},
					onTableChange: (action: string, tableState: any) => {
						if (action == 'changePage') {
							setPagination(tablePagination(action, tableState, pagination));
						} else if (action == 'sort') {
							setSortOrder(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
						} else if (action == 'viewColumnsChange') {
							props.setTablePersist && props.setTablePersist(tableState);
						}
					},
					fixedHeader: true, // This locks the table headers at the top
					tableBodyHeight: tableHeight + 'px', // Set the height for the table body
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartridgeInventoryTable);
