import dataList from '../../constants/dataList';
import { UserProperty } from '../../redux/models/user/user';

export const GetUserPickerTimeFormat = (properties: UserProperty[]) => {
	const timeFormat_key_index: any = properties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'DATEFORMAT',
	);
	const timeFormatLabel =
		timeFormat_key_index > -1 && properties[timeFormat_key_index].propertyValue != 'LOCAL'
			? properties[timeFormat_key_index].propertyValue
			: undefined;
	let timeFormat = dataList.PickerDateFormats.find(format => {
		return format.label === timeFormatLabel;
	})?.value;

	return timeFormat ?? 'yyyy-MM-dd';
};
