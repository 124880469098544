import * as React from 'react';
import Loading from '../../components/loading';
import { CommonStyles } from '../../hooks/styles';
import { FormControl, Grid, Button, Modal, Chip, IconButton } from '@material-ui/core';
import { orderBy, camelCase } from 'lodash';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import MUIDataTable from 'mui-datatables';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import StorageIcon from '@material-ui/icons/Storage';
import { getMuiTheme } from '../../hooks/styles';
import { CabinetStyles } from './cabinet.styles';
import dataList from '../../constants/dataList';
import LinkableActions from '../../components/linkableActions';
import { GetUserTimezone, valueMapper } from '../../hooks/functions';
import { Constants } from '../../constants/Constants';
import EmptyContent from '../../components/emptyContent';
import EditCabinetModal from './cabinet-edit.modal';
import CabinetForm from './cabinet-form.component';
import { useMediaQuery } from 'react-responsive';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import { formatCabinetPropertyKey, formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment-timezone';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';

const CabinetsComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const styles = CommonStyles();
	const classes = CabinetStyles();
	const [selectedCabinet] = React.useState(null as any);
	const [pagination, setPagination] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	} as any);
	const [cabinetsFetched, setCabinetsFetched] = React.useState(false);
	const [refresh, doRefresh] = React.useState(false);
	const [isActionDialog, setActionDialog] = React.useState(false);
	let [links, setLinks] = React.useState(null as any);
	const [cabinetModalOpen, setCabinetModalOpen] = React.useState(false);
	const firstRender = React.useRef(true);
	const [cabinetModalType, setCabinetModalType] = React.useState('edit' as string);
	const [expandedPropertyRows, setExpandedPropertyRows] = React.useState<any>({});
	const [sortOrder, setSortOrder] = React.useState(null as any);
	const tableColumns = [
		{
			label: 'Device',
			name: 'cabinetInfo',
			options: {
				filter: true,
				display: true,
				change: false,
				customBodyRender: (value: any) => {
					return value.cabinetName ? `${value.cabinetName} (${value.cabinetId})` : `(${value.cabinetId})`;
				},
			},
		},
		{
			label: 'Customer Id',
			name: 'customerId',
			options: {
				filter: true,
				display: true,
				change: false,
			},
		},
		{
			label: 'Type',
			name: 'cabinetType',
			options: {
				filter: true,
				display: true,
				change: true,
				customBodyRender: (value: any) => {
					return value ? valueMapper(value, 'cabinetTypes') : null;
				},
			},
		},
		{
			label: 'State',
			name: 'cabinetState',
			options: {
				filter: true,
				display: true,
				change: true,
				customBodyRender: (value: any) => {
					return value ? valueMapper(value, 'cabinetStates') : null;
				},
			},
		},
		{
			label: 'Properties',
			name: 'cabinetProperties',
			options: {
				filter: true,
				display: true,
				change: true,
				sort: false,
				customBodyRender: (value: any, tableMeta: any) => {
					const propertyKeys = Object.keys(value);
					if (propertyKeys.length > 0) {
						propertyKeys.sort();
						const filterValues = props.selectedFilterValues || {};
						if (filterValues['CABINET_PROPERTIES'] && Object.keys(filterValues['CABINET_PROPERTIES']).length > 0) {
							const filterProp = Object.keys(filterValues['CABINET_PROPERTIES'])[0];
							const filterPropIndex = propertyKeys.findIndex((key: string) => key === camelCase(filterProp));
							filterPropIndex > -1 && propertyKeys.splice(0, 0, propertyKeys.splice(filterPropIndex, 1)[0]);
						}
					}

					let hiddenPropCount = 0;
					let hiddenPropertyKeys: any[] = [];
					if (propertyKeys.length > 3) {
						hiddenPropCount = propertyKeys.length - 3;
						hiddenPropertyKeys = propertyKeys.splice(2, hiddenPropCount);
					}
					return (
						<>
							{propertyKeys.map((propertyKey: any, index: number) => (
								<Chip
									key={index}
									variant="outlined"
									style={{ margin: 3 }}
									label={`${formatCabinetPropertyKey(propertyKey)}: ${formatCabinetPropertyValue(
										propertyKey,
										value[propertyKey].toString(),
									)}`}
									size="small"
								/>
							))}
							{expandedPropertyRows[tableMeta.rowIndex] ? (
								<>
									{hiddenPropertyKeys.map((propertyKey: any, index: number) => (
										<Chip
											key={index}
											variant="outlined"
											style={{ margin: 3 }}
											label={`${formatCabinetPropertyKey(propertyKey)}: ${formatCabinetPropertyValue(
												propertyKey,
												value[propertyKey].toString(),
											)}`}
											size="small"
										/>
									))}
									<span
										onClick={() => {
											setExpandedPropertyRows({ ...expandedPropertyRows, [tableMeta.rowIndex]: false });
										}}
										style={{ textDecoration: 'underline', cursor: 'pointer' }}
									>
										Show Less
									</span>
								</>
							) : (
								<span
									onClick={() => {
										setExpandedPropertyRows({ ...expandedPropertyRows, [tableMeta.rowIndex]: true });
									}}
									style={{ textDecoration: 'underline', cursor: 'pointer' }}
								>
									{hiddenPropCount > 0 ? `+${hiddenPropCount}` : ``}
								</span>
							)}
						</>
					);
				},
			},
		},
		{
			label: '',
			name: '',
			options: {
				change: false,
				filter: false,
				viewColumns: false,
				customBodyRenderLite: (dataIndex: number) => (
					<IconButton
						onClick={() => {
							handleOpen(dataIndex);
						}}
					>
						<MoreVertIcon />
					</IconButton>
				),
			},
		},
	];
	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	React.useEffect(() => {
		if (props.statesList.length < 1 && props.authUser) {
			const filter = {
				offset: 0,
				limit: 100,
				skip: 0,
				order: ['cabinetStateDesc asc'],
				where: {},
				fields: {},
			};
			props.getState(filter);
		}
	}, [props.authUser]);

	/*
	 * execute the cabinet search if authorized
	 */
	const fetchCabinets = (customerId?: any, ignoreFilters: boolean = false) => {
		const filterValues = props.selectedFilterValues || {};

		let cabinetId = '';
		if (selectedCabinet && !ignoreFilters) {
			//TODO: special handling; clicking from table pop-up adds it to url
			cabinetId = selectedCabinet;
		} else if (filterValues['CABINET_ID'] && !ignoreFilters) {
			cabinetId = filterValues['CABINET_ID'];
		}

		const filter: any = {
			cabinetId: cabinetId,
			customerId: props.selectedCustomer ? props.selectedCustomer.customerId : '',
			cabinetType: filterValues['CABINET_TYPE'] && !ignoreFilters ? filterValues['CABINET_TYPE'] : '',
			cabinetState: filterValues['CABINET_STATE'] && !ignoreFilters ? `${filterValues['CABINET_STATE']}` : '',
			limit: pagination.limit,
			offset: pagination.offset,
			order: sortOrder ? sortOrder : 'cabinetId ASC',
		};

		if (!props.authUser.record.claims['ALL']) {
			filter.cabinetState = '30';
		}

		if (
			filterValues['CABINET_PROPERTIES'] &&
			Object.keys(filterValues['CABINET_PROPERTIES']).length > 0 &&
			!ignoreFilters
		) {
			filter.properties = filterValues['CABINET_PROPERTIES'];
		}

		props.getCabinets(filter);
		setCabinetsFetched(true);
		firstRender.current = false;
	};

	const showLoadingMsg = () => !props.authUser && props.tenants.length === 0;

	const onSearchClick = () => {
		firstRender.current = false;
		if (props.authUser?.authorizationCheck({ action: 'read', resource: 'cabinets', tenantId: 'ALL' })) {
			setPagination({
				offset: 0,
				limit: props.globalRowsPerPage,
				page: 0,
			});
			return;
		}
		if (props.selectedCustomer) {
			setPagination({
				offset: 0,
				limit: props.globalRowsPerPage,
				page: 0,
			});
		} else {
			fetchCabinets();
		}
	};

	const generateCabinetList = () => {
		if (props.cabinets.length === 0) {
			return [];
		}
		let cabinetList: any[] = [];
		props.cabinets.forEach((item: any, key: number) => {
			item.cabinetInfo = {
				cabinetId: item.cabinetId,
				cabinetName: item.cabinetName,
			};
			cabinetList.push(item);
		});
		return cabinetList;
	};

	const handleClose = () => {
		setActionDialog(false);
	};

	const handleOpen = (index: number) => {
		const thisCabinet = props.cabinets[index];
		if (!thisCabinet.cabinetId) {
			return;
		}
		setActionDialog(true);
		const queryString = `?filter=${JSON.stringify({ cabinetId: thisCabinet.cabinetId })}`;
		const rowObj = {
			cabinetId: thisCabinet.cabinetId,
			cabinetName: thisCabinet.cabinetName,
			customerId: thisCabinet.customerId,
			cabinetType: thisCabinet.cabinetType, //rowData[2],
			cabinetState: thisCabinet.cabinetState, //rowData[3],
			tempControl: thisCabinet.tempControl,
			cabinetProperties: thisCabinet.cabinetProperties,
		};

		const customer = props.tenants.find((tenant: any) => {
			return tenant.customerId === thisCabinet.customerId;
		});

		setCabinetModalType('edit');
		links = [
			{
				title: Constants.PAGE_TITLE.INVENTORY,
				suffix: thisCabinet.cabinetName
					? `In ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `In Cabinet: ${thisCabinet.cabinetId}`,
				url: `/inventory-management/${thisCabinet.customerId}${queryString}`,
				icon: <StorageIcon />,
			},
			{
				title: Constants.PAGE_TITLE.TRANSACTION_LOG,
				suffix: thisCabinet.cabinetName
					? `For Products in ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `For Products in Cabinet: ${thisCabinet.cabinetId}`,
				url: `/transaction-log/${thisCabinet.customerId}${queryString}`,
				icon: <IconLibraryBooks />,
			},
			{
				title: Constants.PAGE_TITLE.CABINET_DETAILS,
				suffix: thisCabinet.cabinetName
					? `For ${thisCabinet.cabinetName} (${thisCabinet.cabinetId})`
					: `For Cabinet: ${thisCabinet.cabinetId}`,
				url: `/cabinet-details/${thisCabinet.customerId}/${thisCabinet.cabinetId}`,
				callback: { data: rowObj, action: 'view_details' },
				icon: <AllInboxIcon />,
			},
		];
		setLinks(orderBy(links, [(link: any) => link.title.toLowerCase()], ['asc']));
	};

	const setCabinetForDetails = (callbackObj: any) => {
		if (callbackObj.action === 'edit_name') {
			setActionDialog(false);
			setCabinetModalOpen(true);
		}
		props.setCabinet(callbackObj.data);
	};

	const handleCabinetModalClose = (refreshData: boolean = false) => {
		setCabinetModalOpen(false);
		if (refreshData) {
			doRefresh(!refresh);
			fetchCabinets(props.selectedCustomer.customerId, true);
		}
	};

	const enqueueCsvReportForCurrentQuery = (tableColumns: any[], ignoreFilters: boolean = false) => {
		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);
		const filterValues = props.selectedFilterValues || {};

		let cabinetId = '';
		if (selectedCabinet && !ignoreFilters) {
			//TODO: special handling; clicking from table pop-up adds it to url
			cabinetId = selectedCabinet;
		} else if (filterValues['CABINET_ID'] && !ignoreFilters) {
			cabinetId = filterValues['CABINET_ID'];
		}

		const filter: any = {
			cabinetId: cabinetId,
			customerId: props.selectedCustomer ? props.selectedCustomer.customerId : '',
			cabinetType: filterValues['CABINET_TYPE'] && !ignoreFilters ? filterValues['CABINET_TYPE'] : '',
			cabinetState: filterValues['CABINET_STATE'] && !ignoreFilters ? `${filterValues['CABINET_STATE']}` : '',
			limit: 99999,
			order: sortOrder ? sortOrder : 'cabinetId ASC',
		};

		if (!props.authUser.record.claims['ALL']) {
			filter.cabinetState = '30';
		}

		if (
			filterValues['CABINET_PROPERTIES'] &&
			Object.keys(filterValues['CABINET_PROPERTIES']).length > 0 &&
			!ignoreFilters
		) {
			filter.properties = filterValues['CABINET_PROPERTIES'];
		}

		const params = constructEnqueueParams('RNI_UIEXPORT_CabinetsReport', props.authUser?.record.email, filter, {
			columnsRequested: columnsRequested,
		});

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (props.backFromDetails) {
			fetchCabinets();
			props.setBackFromDetails(false);
		}
	}, [props.backFromDetails]);

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			fetchCabinets();
		}
		else{
			firstRender.current = false
		}
		setExpandedPropertyRows({});
	}, [pagination, sortOrder]);

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={styles.searchWrap}>
						<Grid item xs={12}>
							<GlobalFiltersComponentContainer pageName="CABINET_DETAILS" executeSearch={onSearchClick} />
						</Grid>

						<Grid item xs={12}>
							{props.selectedCustomer &&
								props.authUser?.authorizationCheck({ action: 'create', resource: 'cabinets', tenantId: 'ALL' }) && (
									<FormControl className={classes.buttonFormControl}>
										<Button
											className={classes.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={() => {
												setCabinetModalType('add');
												setCabinetModalOpen(true);
											}}
										>
											Create Device
										</Button>
									</FormControl>
								)}
						</Grid>
					</div>

					{props.cabinets && props.cabinets.length > 0 ? (
						<div>
							<MUIDataTable
								data={generateCabinetList()}
								columns={tableColumns}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										setPagination({ ...pagination, limit: numberOfRows });
										props.setGlobalRowsPerPage(numberOfRows);
									},
									onColumnSortChange: (changedColumn: string, direction: string) => {
										if (changedColumn === 'cabinetInfo') {
											changedColumn = 'cabinetId';
										}
										let order = `${changedColumn} ${direction}`;
										setSortOrder(order);
									},
									onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
										enqueueCsvReportForCurrentQuery(cols);
										return false;
										// data = data.map((row: any) => {
										//   row.data[0] = row.data[0].cabinetId;
										//   if (row.data[0].cabinetName) {
										//     row.data[0] += " - " + row.data[0].cabinetName;
										//   }

										//   //row.data[2] = cabinet type, convert to proper display name
										//   row.data[2] = row.data[2] ? valueMapper(row.data[2], 'cabinetTypes') : null;

										//   // converting cabinet properties to a string
										//   row.data[4] = JSON.stringify(row.data[4]);
										//   return row;
										// })
										// return "\uFEFF" + buildHead(cols) + buildBody(data);
									},
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: true,
									print: true,
									selectableRows: 'none',
									serverSide: true,
									page: pagination.page,
									count: props.totalCabinets,
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
									textLabels: {
										body: {
											noMatch: cabinetsFetched ? 'Sorry, no matching records found' : '',
										},
									},
									onTableChange: (tableAction: any, tableState: any) => {
										switch (tableAction) {
											case 'changePage':
												if (tableState.page > pagination.page) {
													setPagination({
														offset: pagination.offset + pagination.limit,
														limit: pagination.limit,
														page: tableState.page,
													});
												} else if (tableState.page < pagination.page) {
													setPagination({
														offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit,
														limit: pagination.limit,
														page: tableState.page,
													});
												}
												break;
										}
									},
								}}
							/>
						</div>
					) : (
						<EmptyContent message="Select the filter criteria above to view device information." />
					)}
				</div>
			)}
			<Modal
				disableBackdropClick={false}
				open={cabinetModalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					{cabinetModalType === 'edit' ? (
						<EditCabinetModal closeModal={handleCabinetModalClose} />
					) : (
						<CabinetForm closeModal={handleCabinetModalClose} />
					)}
				</div>
			</Modal>
			<LinkableActions onCallBack={setCabinetForDetails} open={isActionDialog} onClose={handleClose} links={links} />
		</div>
	);
};

export default CabinetsComponent;
