import React, { useEffect } from 'react';
import { InputLabel, Select, MenuItem, ListSubheader, FormHelperText, Checkbox, ListItemText } from '@material-ui/core';
import { Styles } from '../styles';

interface selectBoxProps {
	listItems?: any[];
	inputLabel?: string;
	emptyItemLabel?: string;
	emptyItemValue?: string;
	onChangeItem: (practice: any) => void;
	className?: string;
	disabled?: boolean;
	refresh?: any;
	style?: any;
	selected?: any;
	error?: boolean;
	errorText?: string;
	required?: boolean;
	multiple?: boolean;
	hideEmptyLabel?: boolean;
	getOptionLabel?: (option: any) => void;
	getOptionValue?: (option: any) => void;
}

const SelectBox = (props: selectBoxProps) => {
	const [selected, setSelected] = React.useState<any>(props.selected || null);
	const [multipleSelected, setMultipleSelected] = React.useState<any[]>(props.selected || null);
	const classes = Styles();
	const onChangeItem = (event: any) => {
		if (event) {
			handleOnChange(event.target.value !== null && event.target.value !== 'null' ? event.target.value : '');
		} else {
			handleOnChange('');
		}
	};

	React.useEffect(() => {
		if (props.refresh === true) {
			props.multiple ? setMultipleSelected([]) : setSelected(null);
		}
	}, [props.refresh]);

	React.useEffect(() => {
		props.multiple ? setMultipleSelected(props.selected) : setSelected(props.selected);
	}, [props.selected]);

	const handleOnChange = (value: any) => {
		props.multiple ? setMultipleSelected(value) : setSelected(value);
		props.onChangeItem(value);
	};

	const onChangeItemCallback = React.useCallback(handleOnChange, []);
	useEffect(() => {
		onChangeItemCallback(props.selected ? props.selected : null);
	}, [props.refresh, onChangeItemCallback]);

	const singleSelectMenuItem = (item: any, optionLabel: string, optionValue: string) => {
		return item.listSubheader ? (
			<ListSubheader style={{ color: '#548db1' }}>{optionLabel}</ListSubheader>
		) : (
			<MenuItem value={optionValue}>{optionLabel}</MenuItem>
		);
	};

	const renderMultipleValues = (values: any) => {
		console.log('VALUES', values)
		let displayValues = values;
		const options = props.listItems ? props.listItems : null;
		if (options && values && values.length > 0) {
			displayValues = values.map((value: string) => {
				const selectedOption = options.find((option: any) => {
					return option.value === value;
				});
				return selectedOption.label;
			});
		}
		return Array.isArray(displayValues) ? displayValues.join(', ') : displayValues;
	};

	const multipleSelectMenuItem = (item: any, optionLabel: string, optionValue: string) => {
		return item.listSubheader ? (
			<ListSubheader style={{ color: '#548db1' }}>{optionLabel}</ListSubheader>
		) : (
			<MenuItem value={optionValue}>
				<Checkbox checked={multipleSelected?.indexOf(optionValue) > -1} />
				<ListItemText primary={optionLabel} />
			</MenuItem>
		);
	};

	return (
		<span>
			<InputLabel required={props.required} id="demo-expire-select-outlined-label">
				{props.inputLabel ? props.inputLabel : 'Select Box'}
			</InputLabel>
			<Select
				style={props.style}
				className={props.className}
				disabled={props.disabled}
				multiple={props.multiple}
				labelId="demo-expire-select-outlined-label"
				id="demo-expire-select-outlined"
				label={props.inputLabel ? props.inputLabel : 'Select Box'}
				onChange={onChangeItem}
				value={props.multiple ? multipleSelected || [] : selected || ''}
				renderValue={props.multiple ? renderMultipleValues : undefined}
				MenuProps={props.multiple ? { variant: 'menu' } : undefined}
				error={props.error}
				required={props.required}
			>
				{!props.multiple && !props.hideEmptyLabel && (
					<MenuItem value={props.emptyItemValue ? props.emptyItemValue : ''}>
						{props.emptyItemLabel ? props.emptyItemLabel : 'Choose'}
					</MenuItem>
				)}
				{props.listItems &&
					props.listItems.map((item: any, key: number) => {
						const optionLabel: string = props.getOptionLabel ? props.getOptionLabel(item) : item.label;
						const optionValue: string = props.getOptionValue ? props.getOptionValue(item) : item.value;
						return [
							props.multiple
								? multipleSelectMenuItem(item, optionLabel, optionValue)
								: singleSelectMenuItem(item, optionLabel, optionValue),
						];
					})}
			</Select>
			{props.errorText && <FormHelperText error={props.error}>{props.errorText}</FormHelperText>}
		</span>
	);
};
export default SelectBox;
