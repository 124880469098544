import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import { CommonStyles } from '../../../hooks/styles';
import ReportTable from './reportTable';
import SearchSection from './searchSection';
import { ReportActions, UserActions } from '../../../redux/actions';
import EmptyContent from '../../../components/emptyContent';
import { GetUserDefaultInventory, GetUserTimezone } from '../../../hooks/functions';
import moment from 'moment';
import {Severities, SendLog} from '../../../hooks/functions/SendLog';

const HistoryReport = (props: any) => {
	const searchStyles = CommonStyles();
	let [searchClicked, setSearchClicked] = React.useState(false);
	const [tabView, setTabView] = React.useState(GetUserDefaultInventory(props.properties).toLowerCase());
	const [filter, setFilter] = React.useState<Array<Filter>>([]);
	const [pagination, setPagination] = React.useState({ offset: 0, limit: 10, page: 0 } as any);
	const [sortOrder, setSortOrder] = React.useState('');
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);

	type Filter = {
		key: string;
		value: any;
	};

	React.useEffect(() => {
		// Search for the default inventory history when pagination is updated
		setSearchClicked(true);
		let searchFilter: Array<Filter> = [];
		if (filter) {
			console.debug('React.useEffect[pagination] - filter: ', filter);
			searchFilter = filter;
			updateFilter('offset', pagination.offset, searchFilter);
			updateFilter('limit', pagination.limit, searchFilter);
			updateFilter('order', pagination.order ? pagination.order : '', searchFilter);
		} else {
			searchFilter.push({ key: 'offset', value: pagination.offset });
			searchFilter.push({ key: 'limit', value: pagination.limit });
			searchFilter.push({ key: 'order', value: pagination.order ? pagination.order : '' });
		}

		console.debug('React.useEffect[pagination] - searchFilter: ', searchFilter);
		props.getInventoryHistory({ filter: searchFilter, table: tabView });
	}, [pagination]);

	/**
	 * Updates the filter value or creates a new filter if it doesn't exist
	 * @param filterName The key of the filter to be updated
	 * @param newFilterValue The new value of the filter
	 * @param filters Array of filters to be updated
	 * @returns filters
	 */
	const updateFilter = (filterName: string, newFilterValue: any, filters: Array<Filter>) => {
		const foundFilter = filters.find((filter: Filter) => filter.key === filterName);
		if (foundFilter) {
			foundFilter.value = newFilterValue;
		} else {
			filters.push({ key: filterName, value: newFilterValue });
		}
		return filters;
	};

	const onFilterUpdate = (searchFilter: any[]) => {
		console.debug('onFilterUpdate - searchFilter: ', searchFilter);
		setFilter(searchFilter);
		props.getInventoryHistory({ filter: searchFilter, table: tabView });
		setSearchClicked(true);
	};

	const handleTabChange = (event: any, newValue: string) => {
		if (newValue === tabView) {
			return;
		}
		setTableStatePersist(null);
		setPagination({ offset: 0, limit: props.globalRowsPerPage, page: 0 });
		setSortOrder('');
		setTabView(newValue);
		setSearchClicked(true);
		let searchFilter = filter.filter((singleFilter: any) => {
			if (['offset', 'limit', 'order'].includes(singleFilter.key)) {
				return false;
			}
			return true;
		});
		searchFilter.push({ key: 'offset', value: 0 });
		searchFilter.push({ key: 'limit', value: props.globalRowsPerPage });
		searchFilter.push({ key: 'order', value: '' });
		props.getInventoryHistory({ filter: searchFilter, table: newValue });
	};

	const handlePagingAndSorting = (newPagination: any, newSortOrder: any, newTableStatePersist: any) => {
		setTableStatePersist(newTableStatePersist);
		setPagination(newPagination);
		setSortOrder(newSortOrder);
		let searchFilter = filter.length === 6 ? filter.slice(0, 3) : filter.slice(0, 2);
		searchFilter.push({ key: 'offset', value: newPagination.offset });
		searchFilter.push({ key: 'limit', value: props.globalRowsPerPage });
		searchFilter.push({ key: 'order', value: newSortOrder });
		props.getInventoryHistory({ filter: searchFilter, table: tabView });
	};

	const onExport = (buildHead: any, buildBody: any, tableColumns: any, data: any) => {
		const filterValues = props.selectedFilterValues || {};

		//strangely, these filter selections aren't in the global state so we need to grab from local state!
		const date = filter.find((f: any) => {
			return f.key === 'startDate';
		});
		if (!date) {
			SendLog(`No date filter found!  Date filter is required for this report`, 
			Severities.Error, 
			'onExport_HistoryReport');

			return;
		}

		const cabinetType = filter.find((f: any) => {
			return f.key === 'cabinetType';
		});

		const filtersForExport = {
			limit: 9999,
			customerId: props.selectedCustomer?.customerId,
			startDate: date.value,
			endDate: date.value,
			cabinetType: cabinetType ? cabinetType.value : undefined,
		};

		const columnsRequested: string[] = [];
		tableColumns.forEach((tc: any) => {
			//tableColumns passed by table comp
			if (tc.display === 'true' || tc.display === true) {
				//sometimes no `options
				columnsRequested.push(tc.name);
			}
		});

		console.log('CR*******', columnsRequested);

		const params = {
			filter_object_from_ui: filtersForExport, //uses filter set in state
			report_type: 'RNI_UIEXPORT_InventoryHistoryReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				viewType: tabView || 'lot',
				columnsRequested: columnsRequested,
			},
		};

		props.sendOneTimeReportRequest(params);
	};

	return (
		<div className={'history-table'}>
			<Grid item xs={12} className={searchStyles.searchWrap}>
				<SearchSection tabView={tabView} onFilterUpdate={onFilterUpdate} />
			</Grid>

			{props.inventoryHistory && props.inventoryHistory.length > 0 ? (
				<Grid item xs={12}>
					<AppBar position="static" color="default">
						<Tabs
							variant="scrollable"
							scrollButtons="on"
							aria-label="scrollable tabs"
							value={tabView}
							indicatorColor="secondary"
							textColor="primary"
							onChange={handleTabChange}
						>
							<Tab value="lot" label="Lot Inventory" />
							<Tab value="cartridge" label="Dispenser Inventory" />
							<Tab value="serial" label="Serial Inventory" />
						</Tabs>
					</AppBar>

					<ReportTable
						searchClicked={searchClicked}
						onFilterUpdate={handlePagingAndSorting}
						tabView={tabView}
						pagination={pagination}
						sortOrder={sortOrder}
						tableStatePersist={tableStatePersist}
						onExport={onExport}
					/>
				</Grid>
			) : (
				<EmptyContent message="Select the filter criteria above to view inventory history." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		inventoryHistory: state.reports.inventoryHistory,
		inventoryData: state.reports.inventoryData,
		globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
		properties:
			state.user &&
			state.user.authUser &&
			state.user.authUser.record &&
			state.user.authUser.record.properties &&
			state.user.authUser.record.properties instanceof Array
				? state.user.authUser.record.properties
				: [],
		authUser: state.user.authUser,
		selectedCustomer: state.ui.selectedCustomer,
		selectedFilterValues: state.ui.selectedFilterValues,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	getInventoryHistory: (params: any) => dispatch(ReportActions.getInventoryHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryReport);
