import Api from '../lib/api';
import TimestampNormalizer from '../lib/timestamp-normalizer';
import { PRODUCTS } from '../constants/api.url';

export class ProductService {
	private static instance: ProductService;
	private constructor() {}

	public static getInstance(): ProductService {
		if (!ProductService.instance) {
			ProductService.instance = new ProductService();
		}
		return ProductService.instance;
	}

	public getAutoCompleteProducts = async (filter: any): Promise<any> => {
		const params = [
			{
				key: 'filter',
				value: JSON.stringify(filter),
			},
		];

		return await Api.get(`${PRODUCTS.URL}/search`, params);
	};

	public getAllProductsList = async (): Promise<any> => {
		const filter = {
			limit: 100000,
			offset: 0,
			order: ['productId'],
			skip: 0,
		};

		const params = [
			{
				key: 'filter',
				value: JSON.stringify(filter),
			},
		];

		return await Api.get(`${PRODUCTS.URL}/ids`, params);
	};

	public getProducts = async (filter: any): Promise<any> => {
		const params = [{ key: 'filter', value: JSON.stringify(filter) }];
		const response = await Api.get(PRODUCTS.URL, params);
		return this.transformResponse(response);
	};

	public createProduct = async (payload: any): Promise<any> => {
		return await Api.post(PRODUCTS.URL, payload);
	};

	public updateProduct = async (payload: any): Promise<any> => {
		const source = payload.source || 'FFF';
		return await Api.patch(`${PRODUCTS.URL}/${source}/${payload.productId}`, payload);
	};

	private transformResponse(response: any) {
		const { data } = response;
		const timestampNormalizer = new TimestampNormalizer(data.result, true);
		const resultTimestampsConverted = timestampNormalizer.convertToLocal('createdAt');
		data.result = resultTimestampsConverted;
		return { ...response, data: data };
	}

	public getProductGroups = async (payload: any): Promise<any> => {
		const params = [{ key: 'source', value: payload.source }];
		return await Api.get(PRODUCTS.GROUPS, params);
	};
}
