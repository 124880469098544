import React from 'react';
import { connect } from 'react-redux';
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { getExpandedReportTable, getReportExportOptions, getTableColumns, resetPagination } from '../report-utils';
import { ReportActions, UserActions } from '../../../redux/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommonStyles from '../../../hooks/styles/common';
import GlobalFiltersComponentContainer from '../../../components/globalFiltersComponent';
import EmptyContent from '../../../components/emptyContent';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import dataList from '../../../constants/dataList';
import { GetUserTimezone, UserTimezoneFormat } from '../../../hooks/functions';
import { ConvertDateTimeForRequest } from '../../../hooks/functions/ConvertDateTimeForRequest';

const DispensedProducts = (props: any) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(true);
	const [expandedIndexes, setExpandedIndexes] = React.useState<number[]>([]);
	const [pagination, setPagination] = React.useState({
		page: 1,
		limit: 10,
		offset: 0,
		order: 'ASC',
	});

	const tableColumnNames = [
		'productName',
		'productId',
		'lot',
		'expiration',
		'orderNumber',
		'datetime',
		'qty',
		'lotSize',
		'lotAssay',
		'lotUnitOfMeasure',
	];
	const tableColumnLabels = [
		'Product Name',
		'Item',
		'Lot',
		'Expiry',
		'Order No',
		'Time',
		'Qty',
		'Lot Size',
		'Lot Assay',
		'Lot Unit of Measure',
	];
	const customRenderFunctions = {
		datetime: (value: any) => {
			return UserTimezoneFormat(props.userProperties, value);
		},
	};
	const colDisplay = {
		productName: false,
		lotSize: false,
		lotAssay: false,
		lotUnitOfMeasure: false,
	};
	const tableColumns = getTableColumns(tableColumnNames, tableColumnLabels, {
		display: colDisplay,
		customRenderFunctions: customRenderFunctions,
	});

	const handleSearchClick = (currentPagination: any, doExport: boolean = false) => {
		if (!props.selectedCustomer || !props.selectedDateRange) {
			return;
		}

		setExpandedIndexes([]);
		const filter: any = {
			customerId: props.selectedCustomer.customerId,
			startDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.startDate),
			endDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.endDate),
			limit: currentPagination.limit,
			offset: currentPagination.offset,
			order: currentPagination.order,
		};
		if (props.selectedFilterValues?.PRODUCT_NAME) {
			filter.productId = props.selectedFilterValues.PRODUCT_NAME.productItemId;
		}
		if (props.selectedFilterValues?.PRODUCT_GROUP) {
			filter.productGroup = props.selectedFilterValues.PRODUCT_GROUP;
		}
		if (props.selectedFilterValues?.LOT_NUMBER) {
			filter.lot = props.selectedFilterValues.LOT_NUMBER;
		}
		if (doExport) {
			const exportOptions = getReportExportOptions(props.userProperties);
			filter.export = {
				type: 'csv',
				timezone: exportOptions.timezone,
				dateFormat: exportOptions.dateFormat,
			};
		}

		props.getDispensedProducts(filter);
	};

	const handlePagination = (tableEvent: any, value: any) => {
		const offset = (value - 1) * pagination.limit;
		const newPagination = { page: value, offset: offset, limit: pagination.limit, order: pagination.order };
		setPagination(newPagination);
		handleSearchClick(newPagination);
		setExpandedIndexes([]);
	};

	const handleExportDownload = () => {
		enqueueCsvExportForCurrentSearch();
	};

	const handleExportOfDispensedProductGroup = (dispensedProduct: any, columns: any[]) => {
		enqueueCsvExportForCurrentSearch(columns, dispensedProduct);
	};

	const enqueueCsvExportForCurrentSearch = (columns?: string[], dispensedProduct?: any) => {
		const filter: any = {
			customerId: props.selectedCustomer.customerId, //required
			startDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.startDate),
			endDate: ConvertDateTimeForRequest(props.userProperties, props.selectedDateRange.endDate),
		};

		if (props.selectedFilterValues?.PRODUCT_NAME) {
			filter.productId = props.selectedFilterValues.PRODUCT_NAME.productItemId;
		}
		if (props.selectedFilterValues?.LOT_NUMBER) {
			filter.lot = props.selectedFilterValues.LOT_NUMBER;
		}

		if (dispensedProduct) {
			filter.productName = dispensedProduct.productName;
		}

		const columnsRequested: string[] = [];

		if (!dispensedProduct) {
			//no product indicates whole-page export, and since there is no global column chooser, give all of them
			tableColumns.forEach((gtc: any) => {
				columnsRequested.push(gtc.name);
			});
		} else if (columns) {
			columns.forEach((tc: any) => {
				if (tc.display === 'true' || tc.display === true) {
					columnsRequested.push(tc.name);
				}
			});
		}

		const params = {
			filter_object_from_ui: filter,
			report_type: 'RNI_UIEXPORT_DispensedProductsReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.userProperties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				columnsRequested: columnsRequested,
			},
		};

		props.sendOneTimeReportRequest(params);
	};

	React.useEffect(() => {
		if (props.selectedCustomer && props.selectedDateRange && !firstRender.current) {
			handleSearchClick(pagination);
		} else {
			firstRender.current = false;
		}
	}, []);

	return (
		<div>
			<div>
				<Grid container>
					<Grid item xs={12}>
						<GlobalFiltersComponentContainer
							pageName="DISPENSED_PRODUCTS"
							executeSearch={() => {
								resetPagination(setPagination);
								handleSearchClick({ limit: 10, offset: 0, order: 'ASC' });
							}}
							doExport={
								props.dispensedProducts && props.dispensedProducts.results.length > 0 ? handleExportDownload : null
							}
							handleChangeDatePicker={() => {}}
						/>
					</Grid>
				</Grid>
			</div>
			{props.dispensedProducts && props.dispensedProducts.results.length > 0 ? (
				<Grid container>
					<Grid item xs={12}>
						{props.dispensedProducts.results.map((dispensedProduct: any, index: number) => (
							<Accordion
								key={index}
								expanded={expandedIndexes.indexOf(index) >= 0 ? true : false}
								onChange={() => {
									const expandedIndexesClone = [...expandedIndexes];
									if (expandedIndexes.indexOf(index) >= 0) {
										expandedIndexesClone.splice(expandedIndexes.indexOf(index), 1);
									} else {
										expandedIndexesClone.push(index);
									}
									setExpandedIndexes(expandedIndexesClone);
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
									<Typography className={classes.expansionHeading}>{dispensedProduct.productName}</Typography>
									<Typography className={classes.expansionSecondary}>
										Total Dispensed: {dispensedProduct.totalDispensed}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{getExpandedReportTable(
										dispensedProduct.dispensedProducts,
										tableColumns,
										dataList.PageLimitOptions,
										(cols: any) => handleExportOfDispensedProductGroup(dispensedProduct, cols),
									)}
								</AccordionDetails>
							</Accordion>
						))}
						<br />
						<Pagination
							count={Math.ceil(props.dispensedProducts.total / pagination.limit)}
							page={pagination.page}
							onChange={handlePagination}
						/>
					</Grid>
				</Grid>
			) : (
				<EmptyContent message="Select the filter criteria above to view dispensed product information." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	dispensedProducts: state.reports.dispensedProducts,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	userProperties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	authUser: state.user.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
	getDispensedProducts: (filter: any) => dispatch(ReportActions.getDispensedProducts(filter)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DispensedProducts);
