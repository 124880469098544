import * as React from 'react';
import { CommonStyles } from '../../hooks/styles';
import { Grid, FormControl, Button, Modal } from '@material-ui/core';
import { CabinetStyles } from './cabinet.styles';
import CabinetProductInventory from './cabinet-product-inventory';
import ReportCabinetInventory from './report-cabinet-inventory';
import { useMediaQuery } from 'react-responsive';
import { CabinetActions, InventoryManagementActions, UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import EmptyContent from '../../components/emptyContent';
import Loading from '../../components/loading';
import { Alert } from '@material-ui/lab';
import {
	GetCartridgeInventoryColumns,
	GetInventoryColumns,
	GetSerialInventoryColumns,
	GetUserDatetimeFormat,
	GetUserDefaultInventory,
	GetUserTimezone,
} from '../../hooks/functions';
import CartridgeInventoryTable from '../inventory-management/cartridge-inventory-table';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';

const CabinetInventory: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [initLoadInventory, setInitLoadInventory] = React.useState(false);
	const [inventoryLoaded, setInventoryLoaded] = React.useState(false);
	const searchStyles = CommonStyles();
	const classes = CabinetStyles();
	const [modalOpen, setModalOpen] = React.useState(false);
	const [modalType, setModalType] = React.useState(null as any);
	const [tablePagination, setTablePagination] = React.useState(null as any);
	const [tableSortOrder, setTableSortOrder] = React.useState(null as any);
	const [cabinetConfigurationErrors, setCabinetConfigurationErrors] = React.useState([] as any[]);
	const [tablePersist, setTablePersist] = React.useState({} as any);

	React.useEffect(() => {
		if (!initLoadInventory && props.selectedCustomer && props.cabinet) {
			fetchCabinetInventory();
			fetchSuspendedTransactions()
		}

		if (props.cabinet) {
			checkCabinetConfigurationState();
		}
	}, [initLoadInventory, props.selectedCustomer, props.cabinet]);

	const fetchCabinetInventory = (pagination?: any, sortOrder?: any, options?: any, showLoader: boolean = false) => {
		setTablePagination(pagination);
		setTableSortOrder(sortOrder);

		let payload: any = {};
		payload.showLoader = showLoader;
		payload.callback = setInventoryLoaded;
		payload.defaultView = GetUserDefaultInventory(props.properties).toLowerCase();

		let filter: any = [];
		filter.push({ key: 'customerId', value: props.cabinet.customerId });
		filter.push({ key: 'cabinetId', value: props.cabinet.cabinetId });
		filter.push({ key: 'offset', value: pagination ? pagination.offset : 0 });
		filter.push({ key: 'limit', value: pagination ? pagination.limit : 10 });
		filter.push({ key: 'order', value: sortOrder ?? 'productLabelName asc' });
		if (options) filter.push({ key: 'options', value: options });
		payload.filter = filter;

		options ? props.getInventoryCsv(filter) : props.getCabinetInventory(payload);
		setInitLoadInventory(true);
	};

	const fetchSuspendedTransactions = () => {
		const filter = [];
		if (props.selectedCustomer) {
			filter.push({ key: 'customerId', value: props.selectedCustomer.customerId });
		}

		const filterValues = props.selectedFilterValues || {};

		if (filterValues['CABINET_ID']) {
			filter.push({ key: 'cabinetId', value: filterValues['CABINET_ID'] });
		}

		filter.push({ key: 'status', value: "Suspended" });
		filter.push({ key: 'limit', value: 100 });
		props.getSuspendedTransactions({useLoader: true, filter});
	}

	const handleModalClose = (refreshData: boolean = false) => {
		setModalOpen(false);
		if (refreshData) {
			fetchCabinetInventory(tablePagination, tableSortOrder, false, true);
		}
	};

	const tenantPagination = (pagination: any, sortOrder: any) => {
		if (!initLoadInventory) {
			setInitLoadInventory(true);
		}
		fetchCabinetInventory(pagination, sortOrder, false, true);
	};

	const handleGetCsv = (tableColumns: any) => {
		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);
		const filterValues = props.selectedFilterValues || {};

		const filter: any = {
			cabinetId: props.cabinet.cabinetId,
			customerId: props.cabinet.customerId,
			limit: 9999,
			order: 'productLabelName asc',
		};

		const params = constructEnqueueParams(
			'RNI_UIEXPORT_CabinetInventoryReport',
			props.authUser?.record.email,
			filter,
			{ columnsRequested: columnsRequested },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	const displayInventoryTable = () => {
		let columns: any = [];
		switch (GetUserDefaultInventory(props.properties).toLowerCase()) {
			case 'lot':
				columns = GetInventoryColumns(tablePersist, props.properties, props.suspendedTransactions);
				break;
			case 'cartridge':
				columns = GetCartridgeInventoryColumns(props.properties, props.suspendedTransactions);
				break;
			case 'serial':
				columns = GetSerialInventoryColumns(props.properties, props.suspendedTransactions);
				break;
		}

		return (
			<CartridgeInventoryTable
				data={props.cabinetInventory}
				totalRecord={props.cabinetInventoryRecords}
				onPagination={tenantPagination}
				columns={columns}
				onGetCsv={handleGetCsv}
				tablePersist={tablePersist}
				setTablePersist={setTablePersist}
			/>
		);
	};

	const checkCabinetConfigurationState = () => {
		let errors = [];

		const isVirtual = props.cabinet.cabinetType === 'Virtual' ? true : false;
		const providerTrackingRequired =
			props.cabinet.cabinetProperties.providerTracking && props.cabinet.cabinetProperties.providerTracking === 'Yes'
				? true
				: false;
		const hasProviders = props.cabinet.cabinetProviders?.length > 0 ? true : false;
		if (isVirtual && providerTrackingRequired && !hasProviders) {
			errors.push(
				'This cabinet is configured to require a provider in order to remove product but no providers have been assigned to this cabinet.',
			);
		}

		setCabinetConfigurationErrors(errors);
	};

	return (
		<div>
			{inventoryLoaded && !props.error && (
				<div style={{ margin: '25px 0 10px 0' }} className={searchStyles.searchWrap}>
					<Grid container>
						{props.cabinet?.cabinetType === 'Virtual' && (
							<Grid item xs={12}>
								{!isDesktopOrLaptop && <div></div>}
								{(!props.cabinet.cabinetProperties.consignment || props.cabinet.cabinetProperties.consignment == '0') &&
									props.selectedCustomer &&
									props.authUser?.authorizationCheck({
										action: 'create',
										resource: 'inventory',
										tenantId: props.selectedCustomer ? props.selectedCustomer.customerId : 'ALL',
									}) && (
										<FormControl
											className={classes.buttonFormControl}
											style={isDesktopOrLaptop ? { marginTop: 14 } : {}}
										>
											<Button
												variant="contained"
												color="secondary"
												className={classes.viewAllBtn}
												style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
												onClick={() => {
													setModalType('insert');
													setModalOpen(true);
												}}
											>
												Insert
											</Button>
										</FormControl>
									)}
								{props.selectedCustomer &&
									props.cabinetInventory?.length > 0 &&
									props.authUser?.authorizationCheck({
										action: 'create',
										resource: 'inventory',
										tenantId: props.selectedCustomer ? props.selectedCustomer.customerId : 'ALL',
									}) &&
									cabinetConfigurationErrors.length === 0 && (
										<FormControl
											className={classes.buttonFormControl}
											style={isDesktopOrLaptop ? { marginTop: 14 } : {}}
										>
											<Button
												variant="contained"
												className={classes.viewAllBtn}
												color="secondary"
												style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
												onClick={() => {
													setModalType('remove');
													setModalOpen(true);
												}}
											>
												Remove
											</Button>
										</FormControl>
									)}
								{props.cabinetInventory?.length > 0 &&
									props.authUser?.authorizationCheck({
										action: 'create',
										resource: 'inventory',
										tenantId: props.selectedCustomer ? props.selectedCustomer.customerId : 'ALL',
									}) && (
										<FormControl
											className={classes.buttonFormControl}
											style={isDesktopOrLaptop ? { marginTop: 14 } : {}}
										>
											<Button
												variant="contained"
												className={classes.viewAllBtn}
												color="secondary"
												style={!isDesktopOrLaptop ? { marginLeft: 0 } : {}}
												onClick={() => {
													setModalType('count');
													setModalOpen(true);
													props.clearCycleCountInv();
												}}
											>
												Cycle Count
											</Button>
										</FormControl>
									)}
							</Grid>
						)}
						<Grid item xs={12}>
							{cabinetConfigurationErrors.map((error: string) => (
								<Alert severity="error">{error}</Alert>
							))}
						</Grid>
					</Grid>
				</div>
			)}
			{inventoryLoaded ? (
				<div>
					{props.cabinetInventory?.length > 0 ? (
						displayInventoryTable()
					) : (
						<EmptyContent message="This cabinet has no products in inventory." />
					)}
				</div>
			) : (
				<div>
					<Loading message="" />
				</div>
			)}
			<Modal
				disableBackdropClick={true}
				open={modalOpen}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					{['insert', 'remove'].includes(modalType) && (
						<CabinetProductInventory
							action={modalType}
							cabinet={props.cabinet}
							inventory={props.cabinetInventory}
							closeModal={handleModalClose}
						/>
					)}
					{modalType === 'count' && (
						<ReportCabinetInventory
							inventory={props.cabinetInventory}
							inventoryRecordCount={props.cabinetInventoryRecords}
							cabinet={props.cabinet}
							fetchInventory={fetchCabinetInventory}
							closeModal={handleModalClose}
						/>
					)}
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinetInventory: state.cabinet.cabinetProductInventory ? state.cabinet.cabinetProductInventory.result : [],
	cabinetInventoryRecords: state.cabinet.cabinetProductInventory
		? state.cabinet.cabinetProductInventory.total_record
		: 0,
	error: state.cabinet.error,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	authUser: state.user.authUser,
	suspendedTransactions: state.inventoryManagement.suspendedTransactions,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
	getInventoryCsv: (payload: any) => dispatch(InventoryManagementActions.inventoryLogExport(payload)),
	clearCycleCountInv: () => dispatch(CabinetActions.setCycleCountInventory(null)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	getSuspendedTransactions: (filter: any) => dispatch(InventoryManagementActions.getSuspendedTransactions(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetInventory);
