import React from 'react';
import { connect } from 'react-redux';
import { Button, FormControl, Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TextField, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';
import { Autocomplete } from '@material-ui/lab';
import { InventoryManagementActions } from '../../redux/actions';

const BulkActionModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());
  const [tagCustomer, setTagCustomer] = React.useState<any>(null);
  const [productOwnership, setProductOwnership] = React.useState<string | null>(null);
  const [productOrderNo, setProductOrderNo] = React.useState<string | null>(null);
  const [productActive, setProductActive] = React.useState<string | null>(null);
  const [rga, setRga] = React.useState<string | null>(null);
  const [rgaError, setRgaError] = React.useState(false)

  const actionProperties: any = {
    forceVend: {
      title: 'Force Vend',
      action: props.bulkForceVend
    },
    revert: {
      title: 'Revert',
      action: props.bulkRevertTag
    },
    return: {
      title: 'Return',
      action: props.bulkReturnProductTagSerialsRecord
    },
    exclude: {
      title: 'Exclude',
      // action: 
    },
    edit: {
      title: 'Edit',
      action: props.bulkUpdateProductTagSerials
    }
  }

  const getPayload = () => {
    let payload: any = {
      requestBody: {
        ids: props.selectedRows.map((row: any) => (row.id)),
      },
      success: () => {
        props.filterTags()
        props.closeModal()
      }
    }

    if (props.action === 'edit') {
      payload.requestBody.customerId = tagCustomer?.customerId || undefined
      payload.requestBody.orderNo = productOrderNo || undefined
      payload.requestBody.active = productActive ? (productActive === 'true' ? 1 : 0) : undefined
      payload.requestBody.productOwnership = productOwnership || undefined
      return payload
    }
    if (props.action === 'return') {
      if (!rga) {
        setRgaError(true)
        return
      }
      payload.requestBody.rgaNumber = rga || undefined
      return payload
    }

    return payload
  }


	const handleClose = () => {
		props.closeModal();
	};

	const handleConfirm = () => {
    console.log(getPayload())
    actionProperties[props.action].action(getPayload())
	};

	return (
		<div style={{ ...modalStyle, maxWidth: 800 }} className={modalClasses.paper}>
      <h3 id="simple-modal-title" style={{ marginBottom: 15 }}>{actionProperties[props.action].title}</h3>
			<TableContainer component={Paper} style={{maxHeight: 500, overflow: 'scroll'}}>
        <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Tag</TableCell>
            <TableCell>Customer ID</TableCell>
            <TableCell>Product ID</TableCell>
            <TableCell>Lot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.selectedRows.map((row: any) => (
            <TableRow>
              <TableCell>{row.tagId}</TableCell>
              <TableCell>{row.customerId}</TableCell>
              <TableCell>{row.productId}</TableCell>
              <TableCell>{row.lot}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      <Grid container style={{marginTop: 15}}>
        {props.action === 'edit' && <>
          <Grid item xs={6} style={{ padding: 5 }}>
            <Autocomplete
              id="customer-combo-box"
              options={props.customers}
              value={tagCustomer || null}
              getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
              fullWidth
              onChange={(e: any, value: any) => {
                if (!value) {
                  setTagCustomer(null);
                  return;
                }
                setTagCustomer(value);
              }}
              renderInput={(params: any) => (
                <TextField
                  value={tagCustomer || ''}
                  {...params}
                  label="Search Customers"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: 5 }}>
            <TextField
              label="Owner"
              id="outlined-select-ownership"
              select
              fullWidth
              variant="outlined"
              value={productOwnership || ''}
              onChange={event => {
                setProductOwnership(event.target.value as string);
              }}
            >
              <MenuItem value={'FFF Enterprises'}>FFF Enterprises</MenuItem>
              <MenuItem value={'Customer'}>Customer</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} style={{ padding: 5 }}>
            <TextField
              label="Order Number"
              variant="outlined"
              value={productOrderNo || ''}
              fullWidth
              onChange={event => {
                setProductOrderNo(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} style={{ padding: 5 }}>
            <TextField
              label="Active"
              id="outlined-select-ownership"
              select
              fullWidth
              variant="outlined"
              value={productActive || ''}
              onChange={event => {
                const value = !event.target.value ? null : event.target.value
                setProductActive(value);
              }}
            >
              <MenuItem value={''}>Choose</MenuItem>
              <MenuItem value={'true'}>True</MenuItem>
              <MenuItem value={'false'}>False</MenuItem>
            </TextField>
          </Grid>
        </>}
        {props.action === 'return' && <>
          <Grid item xs={6} style={{ padding: 5 }}>
            <TextField
              label="RGA No"
              variant="outlined"
              value={rga || ''}
              error={rgaError}
              helperText={rgaError ? 'An Rga Number is required' : ''}
              required
              onChange={event => {
                setRgaError(false)
                setRga(event.target.value);
              }}
            />
          </Grid>
        </>}
        <Grid item xs={12}>
          <b>{`Confirm ${actionProperties[props.action].title.toLowerCase()} for tag(s)`}</b>
        </Grid>
        <Grid item xs={12} >
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleConfirm}
								type="button"
								style={{ textAlign: 'center', marginTop: 0, width: 'auto' }}
								className={classes.searchButton}
								variant="contained"
								color="primary"
							>
								Submit
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleClose}
								type="button"
								style={{ textAlign: 'center', width: 'auto' }}
								className={classes.cancelBtn}
								variant="contained"
								color="default"
							>
								Cancel
							</Button>
						</FormControl>
					</Grid>
      </Grid>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
  customers: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  bulkForceVend: (payload: string) => dispatch(InventoryManagementActions.bulkForceVend(payload)),
	bulkRevertTag: (payload: any) => dispatch(InventoryManagementActions.bulkRevertTag(payload)),
	bulkReturnProductTagSerialsRecord: (payload: any) => dispatch(InventoryManagementActions.bulkReturnProductTagSerialsRecord(payload)),
	bulkUpdateProductTagSerials: (payload: any) => dispatch(InventoryManagementActions.bulkUpdateProductTagSerials(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionModal);
