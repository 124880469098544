import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import config from '../config';
// import { getAnalytics } from "firebase/analytics";

export const firebase = initializeApp(config.firebase);
// export const fbAnalytics = getAnalytics(firebase);


