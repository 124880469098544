import Api from '../lib/api';
import TimestampNormalizer from '../lib/timestamp-normalizer';
import { ORDERS } from '../constants/api.url';

export class OrdersService {
	private static instance: OrdersService;
	private constructor() {}

	public static getInstance(): OrdersService {
		if (!OrdersService.instance) {
			OrdersService.instance = new OrdersService();
		}
		return OrdersService.instance;
	}

	public getOrders = async (payload: any): Promise<any> => {
		let params = [{ key: 'filter', value: JSON.stringify(payload.filter) }];
		if (payload.options) params.push({ key: 'options', value: JSON.stringify(payload.options) });
		const response = await Api.get(ORDERS.URL, params);
		if (payload.options?.type == 'csv') {
			return response;
		}
		return this.transformResponse(response);
	};

	public getGroupedOrders = async (payload: any): Promise<any> => {
		const params = Object.keys(payload.filter).map((key: string) => {
			return {
				key: key,
				value: payload.filter[key],
			};
		});
		if (payload.options) params.push({ key: 'options', value: JSON.stringify(payload.options) });
		return await Api.get(ORDERS.URL + '/statuses', params);
	};

	public getShipments = async (params: any[]): Promise<any> => {
		return await Api.get(ORDERS.SHIPMENTS, params);
	};

	public updateOrderStatus = async (payload: any): Promise<any> => {
		return await Api.patch(`${ORDERS.URL}/status`, payload.requestBody);
	};

	private transformResponse(response: any) {
		const { result } = response.data;
		const timestampNormalizer = new TimestampNormalizer(result);
		const resultTimestampsConverted = timestampNormalizer.convertToLocal('createdAt');
		const data = { result: resultTimestampsConverted, total_record: response.data.total_record };
		return { ...response, data: data };
	}

	public getRecommendations = async (filter: any): Promise<any> => {
		return await Api.post(`${ORDERS.URL}/restockRecommendations`, filter);
	};

	public getInventoryExceptions = async (filter: any): Promise<any> => {
		return await Api.post(`${ORDERS.URL}/restockRecommendations/inventoryExceptions`, filter);
	};

	public getOrderExclusions = async (params: any[]): Promise<any> => {
		return await Api.get(`${ORDERS.URL}/exclusions/list`, params);
	};

	public releaseOrders = async (payload: any): Promise<any> => {
		return await Api.post(`${ORDERS.URL}/orderRequests`, payload);
	};

	public syncOrderExclusions = async (): Promise<any> => {
		return await Api.post(`${ORDERS.URL}/exclusions/sync`, []);
	};

	public getCustomerProductsForOrder = async (customerId: string, params: any[]): Promise<any> => {
		return await Api.get(`${ORDERS.URL}/restockRecommendations/${customerId}`, params);
	};

	public updateOrder = async (payload: any): Promise<any> => {
		return await Api.patch(`${ORDERS.URL}/${payload.orderNumber}`, payload.requestBody);
	};

	public checkJobStatus = async (): Promise<any> => {
		return await Api.get(`${ORDERS.JOB_STATUS}`);
	};
}
