import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';
import HelpVideosContainer from './help-videos.container';

const HelpVideos = () => {
	const classes = DashboardStyles();

	return (
		<div>
			<BasePage title={Constants.PAGE_TITLE.HELP_VIDEOS}>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					<HelpVideosContainer />
				</Container>
			</BasePage>
		</div>
	);
};

export default HelpVideos;
