import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../../common/base';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../../hooks/styles';

import RestockOrderDetailContainer from './restock-order-detail.container';

const RestockOrderDetail = (props: any) => {
	const classes = DashboardStyles();
	const urlSplit = window.location.pathname.split('/');

	return (
		<div>
			<BasePage title="Restock Order Details">
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					<RestockOrderDetailContainer
						handleFetchOrders={props.handleFetchOrders}
						close={() => {}}
						orderId={urlSplit[urlSplit.length - 1]}
						isModal={false}
					/>
				</Container>
			</BasePage>
		</div>
	);
};

export default RestockOrderDetail;
