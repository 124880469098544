import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const PracticePageStyles = makeStyles(theme => ({
	appBarSpacer: theme.mixins.toolbar,
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
	authorizedUsersFormControl: {
		margin: '2em 0 1em 1em !important',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	searchBox: {
		padding: 9,
		border: '1 solid #f2f2f2',
		marginBottom: '10px!important',
		background: '#fff',
	},

	formControlBlank: {
		minWidth: 0,
	},

	floatRight: {
		float: 'none',
	},
	datebox: {
		marginTop: 0,
		marginBottom: 0,
		marginRight: 10,
		marginLeft: 10,
		border: '1 solid #c7c7c7',
		borderRadius: 5,
	},
	dateRange: {
		width: '75%',
		position: 'fixed',
		zIndex: 9999,
	},
	rowButton: {
		padding: '5px!important',
		fontSize: '12px',
		width: '120px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		marginLeft: '10px',
		textTransform: 'capitalize',
	},
	orderDetailsButton: {
		padding: '5px!important',
		fontSize: '12px',
		width: '120px',
		marginLeft: '10px',
		textTransform: 'capitalize',
	},
	dateRangeInput: {
		width: '140px',
	},
	zipInput: {
		width: '108px',
	},
	PracticeName: {
		width: '350px',
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	basicInfoForm: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	PracticeDetailsInput: {
		width: '200px',
	},
	practiceDetailsBox: {
		margin: 'auto',
		width: '90%',
	},
	steperButtonsBox: {
		float: 'right',
		marginRight: '5%',
	},
	steperButtons: {
		marginLeft: '20px',
	},

	basicInfoFormControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		marginRight: '5px!important',
		minWidth: 240,
	},

	keyboardDatePicker: {
		margin: 0,
		marginRight: '20px!important',
	},

	fullLengthFormControl: {
		margin: theme.spacing(1),
		marginLeft: 0,
		/*marginRight: '5px!important',*/
		width: '100%',
	},
	demographicsAddress: {
		margin: theme.spacing(1),
		marginLeft: 0,
		marginRight: '5px!important',
		width: '84%',
	},
	radioGroupInline: {
		display: 'inline',
	},

	threeColumnText: {
		width: 286,
	},

	fourColumnText: {
		width: 200,
	},
	inputBox: {
		margin: 0,
		marginRight: '20px!important',
	},
	oneColumnText: {
		width: '95%',
		/*marginLeft:'20px'*/
	},
	gridSpacer: {
		height: 25,
	},
	detailPageContainer: {
		marginBottom: 20,
	},
	detailPageSpacer: {
		minHeight: 30,
	},

	chipSpacing: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
	dateQuickSelect: {
		paddingTop: 2,
		paddingBottom: 2,
	},
	actionDialogList: {
		minWidth: '400px',
		width: 'auto',
	},
	actionDialogListMobile: {
		minWidth: '90%',
		width: 'auto',
	},
	searchButton: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px!important',
	},
	buttonFormControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '16px!important',
		minWidth: '60px!important',
		verticalAlign: 'bottom',
	},
	addMoreButton: {
		color: `${globalColors.FFF_BLUE} !important`,
		'&:hover': {
			background: 'none!important',
		},
		width: '40px!important',
		fontSize: '10px!important',
		marginTop: '10px!important',
	},
	customerDetailsTab: {
		width: '100%',
	},
	customerDetailsLink: {
		textDecoration: 'none',
	},
	customerDetailsLinkName: {
		margin: '0 0 10px 0',
		fontSize: 16,
		fontWeight: 'bold',
	},
}));
export default PracticePageStyles;
