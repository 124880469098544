import * as React from 'react';
import Loading from '../../components/loading';
import { Grid, Modal, IconButton, Menu, MenuItem, Button, FormControl } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import ProductLifecycleModal from './tag-lifecycle-modal.component';
import { useMediaQuery } from 'react-responsive';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditTagModal from './edit-tag-serials-modal.component';
import { UserTimezoneFormat } from '../../hooks/functions';
import ConfirmationModal from './confirmation-modal.component';
import ReturnProductModal from './return-product-modal.component';
import { SelectBox } from '../../components/selectBox';
import BulkActionModal from './bulk-action-modal';

const TagsComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const classes = CommonStyles();
	const firstRender = React.useRef(true);
	const [selectedTableRowIndexes, setSelectedTableRowIndexes] = React.useState<number[]>([]);
	const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
	const [selectedBulkAction, setSelectedBulkAction] = React.useState('');
	const [modalOpen, setModalOpen] = React.useState(false);
	const [modalName, setModalName] = React.useState<string | null>(null);
	const [productRecord, setProductRecord] = React.useState(null as any);
	const [sortOrder, setSortOrder] = React.useState(null as any);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	});

	const columnData = [
		{
			label: 'Tag',
			name: 'tagId',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return value.substr(value.length - 6);
			},
		},
		{
			label: 'Full Tag',
			name: 'tagId',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Serial No',
			name: 'serialNo',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Product Owner',
			name: 'productOwnership',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Customer Id',
			name: 'customerId',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Product Id',
			name: 'productId',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'NDC',
			name: 'ndc',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Lot',
			name: 'lot',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Shipment Id',
			name: 'igShipmentId',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Order Number',
			name: 'orderNo',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Shipped Warehouse',
			name: 'shipWarehouse',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Received',
			name: 'received',
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return UserTimezoneFormat(props.properties, value, false);
			},
		},
		{
			label: 'Returned',
			name: 'returned',
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return UserTimezoneFormat(props.properties, value, false);
			},
		},
		{
			label: 'RGA',
			name: 'rgaNumber',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Expiration',
			name: 'expiration',
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return UserTimezoneFormat(props.properties, value, true);
			},
		},
		{
			label: 'Created',
			name: 'createdAt',
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return UserTimezoneFormat(props.properties, value, false);
			},
		},
		{
			label: 'Updated',
			name: 'updatedAt',
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any) => {
				if (!value) return '';
				return UserTimezoneFormat(props.properties, value, false);
			},
		},
		{
			label: 'Active',
			name: 'active',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value === 1 ? 'True' : 'False';
			},
		},
		{
			label: '',
			name: '',
			change: false,
			viewColumns: false,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => {
				const ptsRecord = props.tags.result[tableMeta.rowIndex];
				return <ActionsMenu recordToEdit={ptsRecord} />;
			},
		},
	];

	const ActionsMenu: React.FC<any> = (actionProps: any) => {
		const [menuOpen, setMenuOpen] = React.useState(false);
		const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
		if (
			!props.authUser?.authorizationCheck({
				action: 'create',
				resource: 'inventory',
				tenantId: 'ALL',
			})
		) {
			return <></>;
		}
		return (
			<div>
				<IconButton
					aria-haspopup="true"
					aria-controls="long-menu"
					aria-label="more"
					style={{ padding: 0 }}
					onClick={e => {
						setMenuAnchor(e.currentTarget);
						setMenuOpen(!menuOpen);
					}}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					keepMounted
					id="long-menu"
					open={menuOpen}
					anchorEl={menuAnchor}
					MenuListProps={{ id: menuOpen ? 'shipment-actions' : '' }}
					PaperProps={{
						style: {
							height: 'auto',
							width: '20ch',
						},
					}}
					onClose={() => {
						setMenuOpen(false);
						setMenuAnchor(null);
					}}
				>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.recordToEdit);
							setModalName('lifecycle');
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'tag-lifecycle' : ''}
					>
						View Lifecycle
					</MenuItem>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.recordToEdit);
							setModalName('edit');
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'edit-tag-serials' : ''}
					>
						Edit
					</MenuItem>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.recordToEdit);
							setModalName('forceVend');
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'force-vend' : ''}
					>
						Force Vend
					</MenuItem>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.recordToEdit);
							setModalName('revert');
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'revert-record' : ''}
					>
						Revert
					</MenuItem>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.recordToEdit);
							setModalName('return');
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'return-record' : ''}
					>
						Return
					</MenuItem>
				</Menu>
			</div>
		);
	};
	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	const forceVend = () => {
		props.forceVend(productRecord.id);
	};

	const revertTag = () => {
		props.revertTag(productRecord.id);
	};

	const returnProductTagSerialsRecord = () => {
		props.returnProductTagSerialsRecord(productRecord.id);
	};

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					viewColumns: column.viewColumns,
					...(column.sort && { sort: column.sort }),
					...(column.customBodyRender && {
						customBodyRender: column.customBodyRender,
					}),
				},
			};
		});
	};

	React.useEffect(() => {
		console.log('DISABLED TAG FILTER');
		props.disableSerialNumberFilter(false);
		props.disableOwnershipFilter(false);
	}, []);

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterTags();
		} else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder]);

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			limit: props.globalRowsPerPage,
			offset: pagination.offset,
			order: sortOrder ? [sortOrder] : ['createdAt DESC'],
			where: {}, //filters ultimately end up here
		};

		const filterValues = props.selectedFilterValues || {};

		if (props.selectedCustomer) {
			filter.where.customerId = props.selectedCustomer.customerId;
		}

		if (filterValues['SERIAL_NUMBER']) {
			filter.where.serialNo = { like: `%${filterValues['SERIAL_NUMBER']}` };
		}

		if (filterValues['RFID_TAG']) {
			filter.where.tagId = { like: `%${filterValues['RFID_TAG']}` };
		}

		if (filterValues['PRODUCT_ID']) {
			filter.where.ndc = filterValues['PRODUCT_ID'];
		}

		if (filterValues['LOT_NUMBER']) {
			filter.where.lot = filterValues['LOT_NUMBER'];
		}

		return filter;
	};

	const filterTags = (reset?: boolean) => {
		props.getTags(getFilter(reset));
		setSelectedRows([])
		setSelectedTableRowIndexes([])
	};

	const handleSearch = () => {
		setPageState({
			offset: 0,
			limit: props.globalRowsPerPage,
			page: 0,
		});
	};

	const showLoadingMsg = () => !props.authUser && props.tags.result.length === 0;

	const handleModalClose = () => {
		setModalOpen(false);
		setProductRecord(null);
	};

	const triggerModal = (product: any) => {
		setProductRecord(product);
		setModalOpen(true);
	};

	const handleRowsSelected = (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected: any[]) => {
		let selections: any[] = []
		rowsSelected.forEach((selectedIndex: number) => {
			selections.push(props.tags.result[selectedIndex])
		})
		setSelectedRows(selections);
		setSelectedTableRowIndexes(rowsSelected);
	}

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								<GlobalFiltersComponentContainer pageName="ADMIN_TAGS" executeSearch={handleSearch} />
							</Grid>
						</Grid>
					</div>

					<div style={{textAlign: 'right', marginBottom: 10}}>
						<FormControl variant="outlined">
							<SelectBox
								inputLabel='Bulk Action'
								emptyItemLabel={'Select Bulk Action'}
								emptyItemValue={''}
								style={{ width: 200, textAlign: 'left' }}
								selected={selectedBulkAction}
								listItems={[{label: 'Edit', value: 'edit'}, {label: 'Force Vend', value: 'forceVend'}, {label: 'Return', value: 'return'}, {label: 'Revert', value: 'revert'}]}
								onChangeItem={(value: any) => {
									setSelectedBulkAction(value || '')
								}}
							/>
						</FormControl>
						<Button
							style={{ marginTop: 10, fontSize: '10px' }}
							className={classes.tableButton}
							variant="contained"
							color="secondary"
							disabled={selectedRows.length < 1 || !selectedBulkAction}
							onClick={() => {
								setModalName('bulkAction');
								setModalOpen(true);
							}}
						>
							Apply Action
						</Button>
					</div>
					{props.tags.result.length > 0 ? (
						<div>
							<MUIDataTable
								data={props.tags.result}
								columns={getTableColumns()}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										setPageState({
											...pagination,
											limit: numberOfRows,
										});
										props.setGlobalRowsPerPage(numberOfRows);
									},
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: true,
									print: true,
									serverSide: true,
									page: pagination.page,
									count: props.tags.total_record,
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
									selectableRows: 'multiple',
									selectToolbarPlacement: 'none',
									rowsSelected: selectedTableRowIndexes,
									onRowSelectionChange: handleRowsSelected,
									onColumnSortChange: (changedColumn: string, direction: string) => {
										if (changedColumn === 'serialNo') return;
										setSortOrder(`${changedColumn} ${direction.toUpperCase()}`);
									},
									onTableChange: (tableAction: any, tableState: any) => {
										switch (tableAction) {
											case 'changePage':
												if (tableState.page > pagination.page) {
													setPageState({
														offset: pagination.offset + props.globalRowsPerPage,
														limit: props.globalRowsPerPage,
														page: tableState.page,
													});
												} else if (tableState.page < pagination.page) {
													setPageState({
														offset: pagination.offset - props.globalRowsPerPage,
														limit: props.globalRowsPerPage,
														page: tableState.page,
													});
												}
												break;
											case 'viewColumnsChange':
												setTableStatePersist(tableState);
												break;
										}
									},
									setRowProps: (row: any[], dataIndex: number) => {
										return {
											style: {
												backgroundColor: props.tags.result[dataIndex].active ? '' : 'red',
											},
										};
									},
								}}
							/>
						</div>
					) : (
						<EmptyContent message="Change the filter criteria above to view product information." />
					)}
					<div>
						<Modal
							disableBackdropClick={true}
							open={modalOpen}
							onClose={handleModalClose}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<div>
								{modalName}
								{modalName === 'lifecycle' && <ProductLifecycleModal product={productRecord} closeModal={handleModalClose} />}
								{modalName === 'edit' && (
									<EditTagModal product={productRecord} refreshList={filterTags} closeModal={handleModalClose} />
								)}
								{modalName === 'forceVend' && (
									<ConfirmationModal
										product={productRecord}
										message={'Confirm force vend for this product?'}
										confirm={forceVend}
										closeModal={handleModalClose}
									/>
								)}
								{modalName === 'revert' && (
									<ConfirmationModal
										product={productRecord}
										message={'Confirm revert product?'}
										confirm={revertTag}
										closeModal={handleModalClose}
									/>
								)}
								{modalName === 'return' && (
									<ReturnProductModal
										product={productRecord}
										message={'Confirm return product?'}
										confirm={returnProductTagSerialsRecord}
										closeModal={handleModalClose}
									/>
								)}
								{modalName === 'bulkAction' && (
									<BulkActionModal
										selectedRows={selectedRows}
										action={selectedBulkAction}
										filterTags={filterTags}
										parentProps={props}
										closeModal={handleModalClose}
									/>
								)}
							</div>
						</Modal>
					</div>
				</div>
			)}
		</div>
	);
};

export default TagsComponent;
