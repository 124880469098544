import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import CabinetPropertySettingsComponent from './cabinet-property-settings.component';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';

const CabinetPropertySettings = () => {
	const classes = DashboardStyles();

	const isFirstRender = useRef(true);
	const dispatch = useDispatch();
	useEffect(() => {
		isFirstRender.current = false;
	}, [dispatch]);

	return (
		<div>
			<BasePage title={Constants.PAGE_TITLE.CABINET_PROPERTY_SETTINGS}>
				<div className={classes.appBarSpacer}></div>
				<Container maxWidth={false} className={classes.container}>
					<CabinetPropertySettingsComponent />
				</Container>
			</BasePage>
		</div>
	);
};

export default CabinetPropertySettings;
