import * as React from 'react';
import { TextField, Button, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { getModalStyle, getModalClasses } from '../../../hooks/styles';
import { CabinetStyles } from '../../cabinets/cabinet.styles';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { TenantsActions } from '../../../redux/actions';

const AddCustomer: React.FC<any> = props => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();
	const [customerId, setCustomerId] = React.useState('');
	const [error, setError] = React.useState('');
	const [igCustomer, setIgCustomer] = React.useState(false);

	const submit = () => {
		if (!customerId) {
			setError('Please enter a customer ID.');
			return;
		}
		props.createCustomer({ customerId: customerId, igCustomer: igCustomer, callback: props.closeModal });
	};

	return (
		<div style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%' }} className={modalClasses.paper}>
			<div>
				<h2 className={classes.cabinetFormHeading}>Add Customer</h2>
				<form noValidate autoComplete="off">
					<div style={{ textAlign: 'center', marginTop: '15px' }}>
						<FormControl className={classes.formControl}>
							<TextField
								label="Customer ID"
								variant="outlined"
								style={{ width: 200 }}
								value={customerId || ''}
								error={error ? true : false}
								helperText={error || ''}
								onChange={event => {
									setCustomerId(event.target.value);
									setError('');
								}}
								required
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<FormControlLabel
								control={
									<Switch
										checked={igCustomer}
										onChange={() => {
											setIgCustomer(!igCustomer);
										}}
										name={'igCustomer'}
									/>
								}
								label={'Is IG Customer'}
							/>
						</FormControl>
					</div>
					<div>
						<FormControl className={classes.formControl}>
							<Button
								className={classes.searchButton}
								style={{ marginTop: 12 }}
								variant="contained"
								color="secondary"
								onClick={submit}
							>
								SUBMIT
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								className={classes.cancelBtn}
								variant="contained"
								color="default"
								onClick={() => {
									props.closeModal();
								}}
							>
								CANCEL
							</Button>
						</FormControl>
					</div>
					{props.error && (
						<p className={classes.cabinetFormError}>Failed to {props.cabinet ? 'update' : 'create'} cabinet.</p>
					)}
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	error: state.cabinet.error,
});

const mapDispatchToProps = (dispatch: any) => ({
	createCustomer: (payload: any) => dispatch(TenantsActions.syncCustomer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
