import createReducer from './createReducer';
import { OrderActions } from '../actions';
import { ORDERS } from '../constants/actions';

export const orders = createReducer(
	{},
	{
		[ORDERS.LIST_RESPONSE](state = {}, action: ReturnType<typeof OrderActions.setOrderList>) {
			return { ...state, orderList: action.payload };
		},

		[ORDERS.GROUPED_LIST_RESPONSE](state = {}, action: ReturnType<typeof OrderActions.setGroupedOrderList>) {
			return { ...state, groupedOrderList: action.payload };
		},

		[ORDERS.SET_SHIPMENTS](state = {}, action: ReturnType<typeof OrderActions.setShipments>) {
			return { ...state, shipments: action.payload };
		},

		[ORDERS.RESET_ORDERS](state = {}, action: ReturnType<typeof OrderActions.resetOrders>) {
			return { ...state, orderList: [], shipments: [] };
		},

		[ORDERS.SET_RECOMMENDATIONS](state = {}, action: ReturnType<typeof OrderActions.setRecommendations>) {
			return { ...state, recommendations: action.payload };
		},

		[ORDERS.SET_RECOMMENDATIONS_TOTAL](state = {}, action: ReturnType<typeof OrderActions.setRecommendationsTotal>) {
			return { ...state, recommendationsTotal: action.payload };
		},

		[ORDERS.SET_INVENTORY_EXCEPTIONS_TOTAL](state = {}, action: ReturnType<typeof OrderActions.setInventoryExceptionsTotal>) {
			return { ...state, inventoryExceptionsTotal: action.payload };
		},

		[ORDERS.SET_ORDER_EXCLUSIONS](state = {}, action: ReturnType<typeof OrderActions.setOrderExclusions>) {
			return { ...state, exclusions: action.payload };
		},

		[ORDERS.SET_CUSTOMER_PRODUCTS_FOR_ORDER](state = {}, action: ReturnType<typeof OrderActions.setCustomerProductsForOrder>) {
			return { ...state, customerProductsForOrder: action.payload };
		},
	},
);
