import { connect } from 'react-redux';
import CabinetsComponent from './cabinets.component';
import { CabinetActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	cabinets: state.cabinet.cabinets ? state.cabinet.cabinets.result : [],
	totalCabinets: state.cabinet.cabinets ? state.cabinet.cabinets.total_record : 0,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedFilterValues: state.ui.selectedFilterValues,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	statesList: state.cabinet.stateList ? state.cabinet.stateList.result : [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	backFromDetails: state.cabinet.backFromDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
	clearCabinetList: () => dispatch(CabinetActions.clearCabinetList()),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getCabinets: (payload: any) => dispatch(CabinetActions.cabinetsTableRequestStartAction(payload)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	getState: (payload: any) => dispatch(CabinetActions.getStateListAction(payload)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setCabinet: (cabinet: any) => dispatch(CabinetActions.setCabinet(cabinet)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	setBackFromDetails: (backFromDetails: boolean) => dispatch(CabinetActions.setBackFromDetails(backFromDetails)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export const CabinetsContainer = connect(mapStateToProps, mapDispatchToProps)(CabinetsComponent);
