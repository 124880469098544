import React from 'react';
import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { getMuiTheme } from '../../../hooks/styles';
import dataList from '../../../constants/dataList';
import { tablePagination } from '../../../hooks/functions/TablePagination';
import { UserDateFormat, valueMapper } from '../../../hooks/functions';
import { UIAction } from '../../../redux/actions';

interface ReportProps {
	inventoryHistoryRes: any;
	totalRecord: any;
	pagination: any;
	sortOrder: any;
	tableStatePersist: any;
	searchClicked: boolean;
	tabView: string;
	properties: any;
	globalRowsPerPage: any;
	setGlobalRowsPerPage: (rowsPerPage: number) => void;
	onFilterUpdate: (pagination: any, sortOrder: any, tableStatePersist: any) => void;
	onExport: Function;
}

const customBodyRenderFn = (value?: any, tableMeta?: any, updateValue?: any) => {
	let cabinetIndex = dataList.CabinetTypeDropdownList.findIndex((obj: { value: string }) => obj.value === value);
	return dataList.CabinetTypeDropdownList[cabinetIndex] ? dataList.CabinetTypeDropdownList[cabinetIndex].label : value;
};

const blankValueToZero = (value?: any) => {
	return value ? value : 0;
};

const ReportTable = (props: ReportProps) => {
	const muiTheme = getMuiTheme();
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);

	React.useEffect(() => {
		setTableStatePersist(props.tableStatePersist);
	}, []);

	const handlePagination = (newPagination: any) => {
		props.onFilterUpdate(newPagination, props.sortOrder, tableStatePersist);
	};

	const handleSorting = (newSortOrder: any) => {
		props.onFilterUpdate({ ...props.pagination, limit: props.globalRowsPerPage }, newSortOrder, tableStatePersist);
	};

	const tableColumnList = [
		{
			label: 'Date',
			name: 'day',
			change: false,
			filter: false,
			display: true,
			viewColumns: true,
			customBodyRender: (value?: any) => {
				return value ? UserDateFormat(props.properties, value) : '';
			},
		},
		{
			label: 'Customer Id',
			name: 'customerId',
			change: false,
			filter: false,
			display: true,
		},
		{
			label: 'Device Id',
			name: 'cabinetId',
			change: false,
			filter: false,
			display: true,
		},
		{
			label: 'Device Type',
			name: 'cabinetType',
			change: false,
			filter: false,
			display: true,
			customBodyRender: (value: any) => {
				return value ? valueMapper(value, 'cabinetTypes') : null;
			},
		},
		{
			label: 'Product Id',
			name: 'productItemId',
			change: false,
			filter: false,
			display: true,
			customFilterListOptions: {
				render: (v: any) => `Product ID : ${v}`,
			},
		},
		{
			label: 'Product Name',
			name: 'productLabelName',
			change: false,
			filter: false,
			display: true,
		},
		{
			label: 'Lot',
			name: 'lot',
			change: false,
			filter: false,
			display: true,
		},
		{
			label: 'Expiration',
			name: 'expiration',
			change: false,
			filter: false,
			display: true,
			customBodyRender: (value?: any) => {
				return value ? UserDateFormat(props.properties, value) : '';
			},
		},
		{
			label: 'On Hand Qty',
			name: 'salesUoUQty',
			change: false,
			filter: false,
			display: true,
			customBodyRender: blankValueToZero,
		},
	];

	const getCartridgeColumns = (columns: any[]) => {
		switch (props.tabView) {
			case 'cartridge':
				columns.splice(
					2,
					0,
					{
						label: 'Holder',
						name: 'holderOffset',
						change: false,
						filter: false,
						display: true,
					},
					{
						label: 'Dispenser',
						name: 'dispenserOffset',
						change: false,
						filter: false,
						display: true,
					},
				);
				break;
			case 'serial':
				columns.splice(5, 0, {
					label: 'Serial Number',
					name: 'serialNo',
					change: false,
					filter: false,
					display: true,
				});
				break;
		}
		return columns;
	};

	const getTableColumns = () => {
		let columns = getCartridgeColumns([...tableColumnList]);

		const colData = columns.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: column.name ? true : false,
					display: tableStatePersist ? tableStatePersist.columns[index].display : true,
					customBodyRender: column.customBodyRender ? column.customBodyRender : undefined,
					customBodyRenderLite: column.customBodyRenderLite ? column.customBodyRenderLite : undefined,
					change: column.change,
					viewColumns: column.viewColumns,
					sort: column.sort ?? true,
				},
			};
		});

		return colData;
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 65; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<>
			{props.inventoryHistoryRes && props.inventoryHistoryRes.length && (
				<MUIDataTable
					data={props.inventoryHistoryRes}
					columns={getTableColumns()}
					options={{
						rowsPerPage: props.globalRowsPerPage,
						rowsPerPageOptions: dataList.PageLimitOptions,
						onChangeRowsPerPage: (numberOfRows: number) => {
							handlePagination({ ...props.pagination, limit: numberOfRows });
							props.setGlobalRowsPerPage(numberOfRows);
						},
						search: false,
						filterType: 'dropdown',
						responsive: 'simple',
						filter: false,
						download: true,
						print: true,
						selectableRows: 'none',
						serverSide: true,
						page: props.pagination.page,
						count: props.totalRecord,
						sortOrder: { name: props.sortOrder.split(' ')[0], direction: props.sortOrder.split(' ')[1] },
						fixedHeader: true, // This locks the table headers at the top
						tableBodyHeight: tableHeight + 'px', // Set the height for the table body
						textLabels: {
							body: {
								noMatch:
									props.searchClicked && props.inventoryHistoryRes.length === 0
										? 'Sorry, no matching records found'
										: '',
							},
						},
						onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
							props.onExport(buildHead, buildBody, cols, data);
							return false;
						},
						onTableChange: (tableAction: any, tableState: any) => {
							if (tableAction == 'changePage') {
								handlePagination(
									tablePagination(tableAction, tableState, { ...props.pagination, limit: props.globalRowsPerPage }),
								);
							} else if (tableAction == 'sort') {
								handleSorting(tableState.sortOrder.name + ' ' + tableState.sortOrder.direction);
							} else if (tableAction == 'viewColumnsChange') {
								setTableStatePersist(tableState);
							}
						},
					}}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	inventoryHistoryRes: state.reports.inventoryHistory,
	totalRecord: state.reports.inventoryHistoryCount,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
