import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasePageStyles } from '../../../hooks/styles';
import { UIAction, UserActions } from '../../../redux/actions';
import { Avatar } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button, Modal } from '@material-ui/core';
import QueueModal from './queueModal';

const MenuListComposition = (props: any) => {
	const classes = BasePageStyles();
	const [open, setOpen] = React.useState(false);
	const [authenticatedUser, setAuthenticatedUser] = React.useState({} as any);
	const [showReportQueueModal, setShowReportQueueModal] = React.useState(false);
	const [undownloadedInQueueCount, setUndownloadedInQueueCount] = React.useState(0);
	const anchorRef = React.useRef(null);
	const history = useHistory();

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = (event: any) => {
		setOpen(false);
	};
	const handleLogout = (event: any) => {
		window.location.href = '/logout';
	};

	const navigateToProfile = () => {
		props.clearApiError();
		history.push('/profile');
	};

	const navigateToHelp = () => {
		props.clearApiError();
		history.push('/help');
	};

	const navigateToQueue = () => {
		setShowReportQueueModal(true);
	};

	function handleListKeyDown(event: any) {
		if (event.key === 'Tab') {
			event.preventDefault();
			// setOpen(false);
		}
	}
	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (props.authUser) {
			setAuthenticatedUser(props.authUser.record);
		}

		if (prevOpen.current === true && open === false) {
			//anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open, props]);

	//show on UI when there are unread reports in the queue
	React.useEffect(() => {
		if (!props.reportQueue?.length) {
			setUndownloadedInQueueCount(0);
			return;
		}

		const undownloadedCount = props.reportQueue.filter(function (queueItem: any) {
			return !queueItem.hasBeenDownloaded;
		}).length;

		setUndownloadedInQueueCount(undownloadedCount);
	}, [props.reportQueue]);

	const markQueueItemReadLocally = () => {
		if (undownloadedInQueueCount > 0) {
			setUndownloadedInQueueCount(undownloadedInQueueCount - 1);
		}
	};

	const showHelpLink = () => {
		if (!props.authUser?.record?.claims || !Object.keys(props.authUser?.record?.claims).length) return false;

		const key = Object.keys(props.authUser?.record?.claims)[0];

		if (!key) return false;

		const helpViewsAllowed = props.authUser?.record?.claims[key]['help_viewer'];

		if (!helpViewsAllowed || !helpViewsAllowed.length) return false;

		const helpTopics = props.authUser?.record?.helpTopics || {};

		return Boolean(Object.keys(helpTopics).length)
	}

	return (
		<>
			<div>
				<Button
					aria-controls={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					className={classes.profileMenuBtn}
					style={{ color: 'white' }}
				>
					{Boolean(undownloadedInQueueCount > 0) && (
						<div
							style={{
								position: 'absolute',
								top: -2,
								left: 40,
								color: 'red',
								zIndex: 2,
								borderRadius: '50%',
								backgroundColor: '#fff',
								padding: '1px 8px',
								fontSize: 14,
								border: '2px solid #ddd',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									fontWeight: 800,
								}}
							>
								{undownloadedInQueueCount}
							</div>
						</div>
					)}
					<Avatar className={classes.avatar} ref={anchorRef}>
						{`${authenticatedUser?.firstName?.charAt(0)}${authenticatedUser?.lastName?.charAt(0)}`}
					</Avatar>
					<MenuItem
						className={classes.profileName}
					>{`${authenticatedUser?.firstName} ${authenticatedUser?.lastName}`}</MenuItem>
					<ArrowDropDownIcon />
				</Button>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					placement="bottom-start"
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow {...TransitionProps}>
							<Paper elevation={0}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
										{props.authUser && <MenuItem onClick={navigateToProfile}>Profile Settings</MenuItem>}
										{props.authUser && (
											<MenuItem onClick={navigateToQueue}>Download Queue ({undownloadedInQueueCount})</MenuItem>
										)}
										{props.authUser && 
											showHelpLink() &&
											<MenuItem onClick={navigateToHelp}>Help</MenuItem>}
										<MenuItem onClick={handleLogout}>Logout</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
			<Modal disableBackdropClick={true} open={showReportQueueModal}>
				<QueueModal
					close={() => {
						setShowReportQueueModal(false);
					}}
					markQueueItemReadLocally={markQueueItemReadLocally}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	reportQueue: state.user.authUser?.record?.reportQueue || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getUserReportQueue: () => dispatch(UserActions.getUserReportQueue()),
	clearApiError: () => dispatch(UIAction.setApiError(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);
