import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper } from '@material-ui/core';
import { UserManagementActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { userModalStyles } from './users.styles';
import Loading from '../../components/loading';
import CancelIcon from '@material-ui/icons/Cancel';
import { UserTimezoneFormat } from '../../hooks/functions';

function getImportModalStyle() {
	const modalTop = 50;
	const modalLeft = 50;

	return {
		top: `${modalTop}%`,
		left: `${modalLeft}%`,
		transform: `translate(-${modalTop}%, -${modalLeft}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

const UserEmailActivity: React.FC<any> = props => {
	const importClasses = useStyles();
	const [emailActivity, setEmailActivity] = React.useState<any[]>([]);
	const [messagesExpanded, setMessagesExpanded] = React.useState<any>({});

	React.useEffect(() => {
		props.getSingleUser(props.thisUser.id);
	}, [props.thisUser]);

	React.useEffect(() => {
		if (props.singleUser) {
			setEmailActivity(props.singleUser.emailActivity || []);
		}
	}, [props.singleUser]);

	return (
		<div style={getImportModalStyle()} className={importClasses.paper}>
			<h2>User Email Activity</h2>
			<div style={{ position: 'absolute', top: 5, right: 5, color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}>
				<CancelIcon onClick={() => props.close()} />
			</div>
			<p>For {props.thisUser.email}</p>
			{props.singleUser ? (
				<>
					{emailActivity.length > 0 ? (
						<>
							{emailActivity.map((message: any, index: number) => (
								<Paper style={{ padding: 10, marginBottom: 5 }} key={index}>
									<Grid>
										<Grid item xs={12}>
											<b>From: </b>
											{message.from_email}
										</Grid>
										<Grid item xs={12}>
											<b>Subject: </b>
											{message.subject}
										</Grid>
										<Grid item xs={12}>
											<b>Status: </b>
											{message.status === 'delivered' ? (
												<span style={{ color: 'green', fontWeight: 'bold' }}>{message.status}</span>
											) : (
												<span style={{ color: 'red', fontWeight: 'bold' }}>{message.status}</span>
											)}
										</Grid>
										<Grid item xs={12}>
											<b>Times Viewed: </b>
											{message.opens_count}
										</Grid>
										<Grid item xs={12}>
											<b>Last Event: </b>
											{UserTimezoneFormat(props.properties, message.last_event_time)}
										</Grid>
									</Grid>
								</Paper>
							))}
						</>
					) : (
						<>This user has no email activity.</>
					)}
				</>
			) : (
				<Loading message="" />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	singleUser: state.userManagement.singleUser,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getSingleUser: (payload: object) => dispatch(UserManagementActions.getSingleUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailActivity);
