import { connect, useDispatch } from 'react-redux';
import { RestockOrderDetailComponent } from './restock-order-detail.component';
import { OrderActions, ReportActions } from '../../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	restockOrderDetails_igShipmentItems: state.reports.restockOrderDetails_igShipmentItems,
	restockOrderDetails_warehouseShipment: state.reports.restockOrderDetails_warehouseShipment,
	restockOrderDetails_restockRequest: state.reports.restockOrderDetails_restockRequest,
	restockOrderDetails_transactions: state.reports.restockOrderDetails_transactions,
	restockOrderDetails_sapOrders: state.reports.restockOrderDetails_sapOrders,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getRestockOrderDetails_igShipmentItems: (orderId: string) =>
		dispatch(ReportActions.getRestockOrderDetails_igShipmentItems(orderId)),
	getRestockOrderDetails_warehouseShipment: (orderId: string) =>
		dispatch(ReportActions.getRestockOrderDetails_warehouseShipment(orderId)),
	getRestockOrderDetails_restockRequest: (orderId: string) =>
		dispatch(ReportActions.getRestockOrderDetails_restockRequest(orderId)),
	getRestockOrderDetails_transactions: (orderId: string) =>
		dispatch(ReportActions.getRestockOrderDetails_transactions(orderId)),
	getRestockOrderDetails_sapOrders: (orderId: string) =>
		dispatch(ReportActions.getRestockOrderDetails_sapOrders(orderId)),
	updateRestockOrderStatus: (payload: any) => dispatch(OrderActions.updateRestockOrderStatus(payload)),
});

const RestockOrderDetailContainer = connect(mapStateToProps, mapDispatchToProps)(RestockOrderDetailComponent);

export default RestockOrderDetailContainer;
