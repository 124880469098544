import { Chip } from '@material-ui/core';
import React from 'react';

interface StyledChipProps {
	label: string;
	color?: string;
}

const StyledChip = (props: StyledChipProps) => {
	const defaultColor = '#808080';

	return (
		<Chip
			style={{
				color: props.color || defaultColor,
				border: `1px solid ${props.color || defaultColor}`,
				backgroundColor: `transparent !important`,
			}}
			label={props.label}
			variant={'outlined'}
			size={'small'}
		/>
	);
};

export default StyledChip;
