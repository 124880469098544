import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';
import { Alert, Autocomplete } from '@material-ui/lab';
import Loading from '../../components/loading';
import { CabinetStyles } from './cabinet.styles';
import { initUserType } from '../users/users.helper';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Paper,
	Switch,
	TextField,
	Tooltip,
	Zoom,
} from '@material-ui/core';
import { SelectBox } from '../../components/selectBox';
import { DashboardStyles } from '../../hooks/styles';
import { valueMapper } from '../../hooks/functions';
import { startCase, camelCase } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';

const DeviceTags: React.FC<any> = (props: any) => {
	const classes = CabinetStyles();
	const styles = DashboardStyles();
	const [localDeviceTags, setLocalDeviceTags] = React.useState<any>(null);
	const [deviceTagKeys, setDeviceTagKeys] = React.useState<string[]>([]);
	const [deviceTagValues, setDeviceTagValues] = React.useState<string[]>([]);
	const [deviceTagsEditMode, setDeviceTagsEditMode] = React.useState(false);
	const [dupeDeviceTagKeys, setDupeDeviceTagKeys] = React.useState<any[]>([]);
	const [notConfigured, setNotConfigured] = React.useState(false);

	React.useEffect(() => {
		props.selectedCabinet && props.getDeviceTags(props.selectedCabinet.cabinetId);
	}, [props.selectedCabinet]);

	React.useEffect(() => {
		if (props.deviceTags) {
			if (props.deviceTags.resultCode === 0) {
				setDeviceTagKeys(Object.keys(props.deviceTags.tags));
				setDeviceTagValues(Object.values(props.deviceTags.tags));
				setLocalDeviceTags(props.deviceTags.tags);
			}
			if (props.deviceTags.resultCode !== 0) {
				setNotConfigured(true);
			}
		}
	}, [props.deviceTags]);

	const handleDeviceTagsSave = () => {
		let errors = false;
		if (deviceTagKeys.includes('')) errors = true;
		if (deviceTagValues.includes('')) errors = true;
		const dupeKeys = deviceTagKeys.filter((item: string, index: number) => deviceTagKeys.indexOf(item) != index) || [];
		setDupeDeviceTagKeys(dupeKeys);
		if (dupeKeys.length > 0) errors = true;

		if (errors) return;

		let requestBody: any = {};
		deviceTagKeys.forEach((key: string, index: number) => {
			requestBody[camelCase(key)] = deviceTagValues[index];
		});
		const payload = {
			cabinetId: props.selectedCabinet.cabinetId,
			requestBody: requestBody,
			success: () => {
				props.getDeviceTags(props.selectedCabinet.cabinetId);
				setDeviceTagsEditMode(false);
			},
		};

		props.setDeviceTags(payload);
	};

	const getTagNameErrorMsg = (tagName: string) => {
		if (dupeDeviceTagKeys.includes(tagName)) return 'Duplicate tag names are not allowed';
		if (!tagName) return 'Tag names cannot be blank';
		return '';
	};

	const addDeviceTag = () => {
		setDeviceTagKeys([...deviceTagKeys, '']);
		setDeviceTagValues([...deviceTagValues, '']);
		setLocalDeviceTags({ ...localDeviceTags, [`newTagName${deviceTagKeys.length}`]: '' });
	};

	const removeDeviceTag = (index: number) => {
		let deviceTagKeysCopy = [...deviceTagKeys];
		deviceTagKeysCopy.splice(index, 1);
		setDeviceTagKeys(deviceTagKeysCopy);
		let deviceTagValuesCopy = [...deviceTagValues];
		deviceTagValuesCopy.splice(index, 1);
		setDeviceTagValues(deviceTagValuesCopy);
		let localDeviceTagsCopy = { ...localDeviceTags };
		delete localDeviceTagsCopy[Object.keys(localDeviceTags)[index]];
		setLocalDeviceTags(localDeviceTagsCopy);
	};

	return (
		<Paper className={classes.paper} style={{ marginBottom: '25px' }}>
			<div>
				<h3>Device Tags</h3>
				<Grid container>
					{notConfigured ? (
						<>
							<p>{props.deviceTags?.message || 'There are no tags for this device.'}</p>
						</>
					) : (
						<>
							{deviceTagsEditMode && (
								<Grid item xs={12}>
									<FormControl style={{ marginBottom: 20 }}>
										<Button
											onClick={addDeviceTag}
											type="button"
											className={classes.searchButton}
											variant="contained"
											color="primary"
										>
											ADD
										</Button>
									</FormControl>
								</Grid>
							)}
							{deviceTagsEditMode}
							{deviceTagsEditMode &&
								localDeviceTags &&
								Object.keys(localDeviceTags).map((tagName: string, index: number) => {
									return (
										<Grid item xs={6} sm={3} key={index}>
											<>
												<FormControl variant="outlined" className={classes.formControl}>
													<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
														<TextField
															style={{ width: 200 }}
															label="Tag Name"
															value={deviceTagKeys[index] || ''}
															error={
																dupeDeviceTagKeys.includes(deviceTagKeys[index]) || !deviceTagKeys[index] ? true : false
															}
															helperText={getTagNameErrorMsg(deviceTagKeys[index])}
															onChange={(e: any) => {
																let deviceTagKeysCopy = [...deviceTagKeys];
																deviceTagKeysCopy[index] = e.target.value || '';
																setDeviceTagKeys(deviceTagKeysCopy);
																setDupeDeviceTagKeys([]);
															}}
															placeholder=""
															variant="outlined"
															required
														/>
													</Box>
												</FormControl>
												<FormControl variant="outlined" className={classes.formControl}>
													<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
														<TextField
															style={{ width: 200 }}
															label="Tag Value"
															value={deviceTagValues[index] || ''}
															error={!deviceTagValues[index]}
															helperText={!deviceTagValues[index] ? 'A value is required' : ''}
															onChange={e => {
																let deviceTagValuesCopy = [...deviceTagValues];
																deviceTagValuesCopy[index] = e.target.value || '';
																setDeviceTagValues(deviceTagValuesCopy);
															}}
															placeholder=""
															variant="outlined"
															required
														/>
													</Box>
												</FormControl>
												<IconButton onClick={() => removeDeviceTag(index)}>
													<DeleteIcon />
												</IconButton>
											</>
										</Grid>
									);
								})}
							{!deviceTagsEditMode &&
								props.deviceTags?.tags &&
								localDeviceTags &&
								Object.keys(localDeviceTags).map((tagName: string, index: number) => {
									return (
										<Grid item xs={6} sm={3} key={index}>
											<>
												<div className={styles.detailTitle}>{startCase(tagName)}</div>
												<div className={styles.detailValue}>{props.deviceTags.tags[tagName]}</div>
											</>
										</Grid>
									);
								})}
							{
								//only cabinets owners can edit these props
								!deviceTagsEditMode &&
									props.deviceTags &&
									props.selectedCabinet.cabinetProperties?.serialNumber &&
									props.authUser?.authorizationCheck({ action: 'create', resource: 'cabinets', tenantId: 'ALL' }) && (
										<Grid item xs={12}>
											<FormControl>
												<Button
													onClick={() => {
														setDeviceTagsEditMode(true);
													}}
													type="button"
													className={classes.searchButton}
													variant="contained"
													color="primary"
												>
													EDIT
												</Button>
											</FormControl>
										</Grid>
									)
							}
							{deviceTagsEditMode && (
								<Grid item xs={12}>
									<FormControl>
										<Button
											onClick={handleDeviceTagsSave}
											type="button"
											className={classes.searchButton}
											variant="contained"
											color="primary"
										>
											SAVE
										</Button>
									</FormControl>
									<FormControl>
										<Button
											className={classes.cancelPropertyEditBtn}
											variant="contained"
											color="default"
											onClick={() => {
												setDeviceTagKeys(Object.keys(props.deviceTags.tags));
												setDeviceTagValues(Object.values(props.deviceTags.tags));
												setLocalDeviceTags(props.deviceTags.tags);
												setDeviceTagsEditMode(false);
											}}
										>
											CANCEL
										</Button>
									</FormControl>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</div>
		</Paper>
	);
};

const mapStateToProps = (state: any) => ({
	deviceTags: state.cabinet.deviceTags,
});

const mapDispatchToProps = (dispatch: any) => ({
	getDeviceTags: (payload: any) => dispatch(CabinetActions.getDeviceTags(payload)),
	setDeviceTags: (payload: any) => dispatch(CabinetActions.setDeviceTags(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTags);
