import { call, put, select } from 'redux-saga/effects';
import { UserManagementActions, UIAction, UserActions } from '../actions/index';
import { UserService } from '../services';
import { Constants } from '../../constants/Constants';
import csv from 'csvtojson';

export function* getUserList(action: ReturnType<typeof UserManagementActions.getUserListStartAction>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		if (action.payload.find((params: any) => params.key === 'csvExport')) {
			yield call(UserService.getInstance().downloadUsersCsv, action.payload);
		} else {
			const usersList = yield call(UserService.getInstance().getUserList, action.payload);
			yield put(UserManagementActions.getUserListCompleteAction(usersList));

			if (!usersList.result || usersList.result.length === 0) {
				yield put(
					UserActions.setSnackbarMessage({
						message: Constants.ALERT.NO_RECORD_FOUND,
						type: 'info',
					}),
				);
			}
		}

		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		console.log('User saga error:', err);
		yield put(UserManagementActions.getUserListCompleteAction([]));
		yield put(UIAction.showLoader(false));

		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getUserList`,
				type: 'error',
			}),
		);
	}
}

export function* createUser(action: ReturnType<typeof UserManagementActions.createUserAction>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UserManagementActions.setCreateUserError([]));
		yield put(UserManagementActions.userErrorAction(false));
		const refreshFilter = action.payload.refreshFilter || [];
		delete action.payload.refreshFilter;
		yield call(UserService.getInstance().createUser, action.payload);
		const usersList = yield call(UserService.getInstance().getUserList, refreshFilter);
		yield put(UserManagementActions.getUserListCompleteAction(usersList));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		const createUserErrors: string[] = yield select(state => state.user.createUserErrors);
		yield put(UserManagementActions.setCreateUserError([...createUserErrors, err.response.data]));
		yield put(UserManagementActions.userErrorAction(true));
		yield put(UIAction.showLoader(false));
		console.log('Create user saga error:', err);
	}
}

export function* importUsers(action: ReturnType<typeof UserManagementActions.importUsers>): any {
	yield put(UserManagementActions.setUserImportResult(null));
	yield put(UserManagementActions.userErrorAction(false));

	try {
		//loop through so we can show results as we go
		const jsonData = action.payload.jsonData;
		let results: any[] = [];
		for (const userObj of jsonData) {
			const response: any = yield call(UserService.getInstance().importUser, userObj, action.payload.claims);
			const importResults = results.concat([response]);
			results = importResults;
			yield put(UserManagementActions.setUserImportResult(importResults));
		}
		action.payload.callback(results);
	} catch (err) {
		console.log('Import users saga error:', err);

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred importing users: ${err}`,
				type: 'error',
			}),
		);
	}
}

export function* getVimeoAppAuthToken(action: ReturnType<typeof UserManagementActions.getVimeoAppAuthToken>) {
	try {
		const { data } = yield call(UserService.getInstance().getVimeoAppAuthToken);
		yield put(UserManagementActions.setVimeoAppAuthTokenResult(data));
	} catch (err) {
		console.log('getVimeoAppAuthToken saga error:', err);
	}
}

export function* sendSupportRequest(action: ReturnType<typeof UserManagementActions.sendSupportRequest>) {
	try {
		yield call(UserService.getInstance().sendSupportRequest, action.payload);
	} catch (err) {
		console.log('sendSupportRequest saga error:', err);
	}
}

export function* updateUser(action: ReturnType<typeof UserManagementActions.updateUserAction>): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UserManagementActions.userErrorAction(false));
		const refreshFilter = action.payload.refreshFilter || [];
		delete action.payload.refreshFilter;
		yield call(UserService.getInstance().updateUser, action.payload);
		const usersList = yield call(UserService.getInstance().getUserList, refreshFilter);
		yield put(UserManagementActions.getUserListCompleteAction(usersList));
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		let errorMessage: any = true;
		if (err.response?.data) errorMessage = err.response.data;
		yield put(UserManagementActions.userErrorAction(errorMessage));
		yield put(UIAction.showLoader(false));
		console.log('Create user saga error:', err);
	}
}

export function* checkIfUserExists(action: ReturnType<typeof UserManagementActions.userExistsCheckAction>) {
	try {
		yield call(UserService.getInstance().getUserByEmail, action.payload);
		yield put(UserManagementActions.userExistsCheckCompleteAction('User already exists'));
	} catch (err: any) {
		const status = err.response.status;
		let message = 'User does not exist';
		if (status === 403) {
			message = 'User already exists';
		}
		yield put(UserManagementActions.userExistsCheckCompleteAction(message));
	}
}

export function* getSingleUser(action: ReturnType<typeof UserManagementActions.getSingleUser>): any {
	try {
		yield put(UserManagementActions.setSingleUser(null));
		const data = yield call(UserService.getInstance().getUserRecord, action.payload);
		yield put(UserManagementActions.setSingleUser(data));
	} catch (err) {
		yield put(
			UserActions.setSnackbarMessage({
				message: 'Failed to get user email activity',
				type: 'error',
			}),
		);
		console.log('Get single user saga failed', err);
	}
}

export function* resendInviteEmail(action: ReturnType<typeof UserManagementActions.resendInviteEmail>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(UserService.getInstance().resendInviteEmail, action.payload);
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `Invite email sent to ${action.payload}`,
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `Failed to send invite email to ${action.payload}`,
				type: 'error',
			}),
		);
		console.log('Resend invite email saga error:', err);
	}
}

export function* setPassword(action: ReturnType<typeof UserManagementActions.setPassword>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(UserService.getInstance().setPassword, action.payload);
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `Temporary password set for ${action.payload.email}`,
				type: 'success',
			}),
		);
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `Failed to set temporary password for ${action.payload.email}`,
				type: 'error',
			}),
		);
		console.log('Set temp password saga error:', err);
	}
}

export function* deleteUser(action: ReturnType<typeof UserManagementActions.deleteUser>) {
	try {
		yield put(UIAction.showLoader(true));
		yield call(UserService.getInstance().deleteUser, action.payload.user);
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `User ${action.payload.user.email} has been deleted`,
				type: 'success',
			}),
		);
		action.payload.callback();
	} catch (err) {
		yield put(UIAction.showLoader(false));
		yield put(
			UserActions.setSnackbarMessage({
				message: `Failed to delete user ${action.payload.user.email}`,
				type: 'error',
			}),
		);
		console.log('Delete user saga error:', err);
	}
}

export function* getInvoiceLinkUserList(
	action: ReturnType<typeof UserManagementActions.getInvoiceLinkUserListStartAction>,
): any {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const usersList = yield call(UserService.getInstance().getInvoiceLinkUserList, action.payload);
		yield put(UserManagementActions.getInvoiceLinkUserListCompleteAction(usersList));
		yield put(UIAction.showLoader(false));
		if (!usersList.result || usersList.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err: any) {
		console.log('User saga error:', err);
		yield put(UserManagementActions.getInvoiceLinkUserListCompleteAction([]));
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getInvoiceLinkUserList`,
				type: 'error',
			}),
		);
	}
}

export function* createInvoiceLinkUser(action: ReturnType<typeof UserManagementActions.createInvoiceLinkUser>) {
	try {
		yield call(UserService.getInstance().createInvoiceLinkUser, action.payload);
		action.payload.success();
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in createInvoiceLinkUser`,
				type: 'error',
			}),
		);
	}
}

export function* deleteInvoiceLinkUser(action: ReturnType<typeof UserManagementActions.deleteInvoiceLinkUser>) {
	try {
		yield call(UserService.getInstance().deleteInvoiceLinkUser, action.payload);
		action.payload.success();
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in deleteInvoiceLinkUser`,
				type: 'error',
			}),
		);
	}
}
