import * as React from 'react';
import Loading from '../../components/loading';
import { Grid, FormControl, Button, Modal, IconButton, Menu, MenuItem } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import EmptyContent from '../../components/emptyContent';
import ProductFormModal from './product-form-modal.component';
import { useMediaQuery } from 'react-responsive';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ProductListComponent: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const classes = CommonStyles();
	const firstRender = React.useRef(true);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [productToEdit, setProductToEdit] = React.useState(null as any);
	const [sortOrder, setSortOrder] = React.useState(null as any);
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0,
	});

	const columnData = [
		{
			label: 'Source',
			name: 'source',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Product Group',
			name: 'productGroup',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Product Id',
			name: 'productId',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'MBRX Product Id',
			name: 'mbrxProductId',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'NDC',
			name: 'ndc',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Supplier ID',
			name: 'supplierNumber',
			change: false,
			viewColumns: true,
			display: false,
		},
		{
			label: 'GTIN',
			name: 'gtin',
			change: false,
			viewColumns: true,
			display: false,
		},
		{
			label: 'MBRX Id Ext',
			name: 'mbrxProductIdExt',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Description',
			name: 'productName',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Weight (grams)',
			name: 'weight',
			change: false,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Dose Type',
			name: 'doseType',
			change: false,
			viewColumns: false,
			display: true,
		},
		{
			label: 'Pack UOM',
			name: 'packUom',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'Pack Qty',
			name: 'packQty',
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: 'CRL',
			name: 'crl',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Max Fill',
			name: 'maxFill',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Lum Qty (Units Per Box)',
			name: 'lumQty',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Related NDC',
			name: 'relatedNdc',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Lum Product Id',
			name: 'lumProductId',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Lum Ancillary Weight',
			name: 'lumAncillaryWeight',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Cost Value',
			name: 'value',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: 'Active',
			name: 'active',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value === 1 ? 'Yes' : 'No';
			},
		},
		{
			label: 'Returnable',
			name: 'returnable',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value === 1 ? 'Yes' : 'No';
			},
		},
		{
			label: 'Track Provider Training',
			name: 'trackProviderTraining',
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value === 1 ? 'Yes' : 'No';
			},
		},
		{
			label: 'Notes',
			name: 'notes',
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: '',
			name: '',
			change: false,
			viewColumns: false,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => {
				const thisProduct = props.products.result[tableMeta.rowIndex]
				return <ActionsMenu productToEdit={thisProduct} />;
			},
		},
	];

	const ActionsMenu: React.FC<any> = (actionProps: any) => {
		const [menuOpen, setMenuOpen] = React.useState(false);
		const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
		if (!props.authUser?.authorizationCheck({ action: 'create', resource: 'products', tenantId: 'ALL' })) {
			return <></>;
		}
		return (
			<div>
				<IconButton
					aria-haspopup="true"
					aria-controls="long-menu"
					aria-label="more"
					style={{ padding: 0 }}
					onClick={e => {
						setMenuAnchor(e.currentTarget);
						setMenuOpen(!menuOpen);
					}}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					keepMounted
					id="long-menu"
					open={menuOpen}
					anchorEl={menuAnchor}
					MenuListProps={{ id: menuOpen ? 'shipment-actions' : '' }}
					PaperProps={{
						style: {
							height: 'auto',
							width: '20ch',
						},
					}}
					onClose={() => {
						setMenuOpen(false);
						setMenuAnchor(null);
					}}
				>
					<MenuItem
						onClick={() => {
							triggerModal(actionProps.productToEdit);
							setMenuOpen(false);
							setMenuAnchor(null);
						}}
						id={menuOpen ? 'edit-product' : ''}
					>
						Edit Product
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					viewColumns: column.viewColumns,
					...(column.customBodyRender && { customBodyRender: column.customBodyRender }),
				},
			};
		});
	};

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterProducts();
		} else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder]);

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			fields: {
				source: true,
				productId: true,
				productName: true,
				ndc: true,
				gtin: true,
				doseType: true,
				packUom: true,
				packQty: true,
				lumQty: true,
				relatedNdc: true,
				lumProductId: true,
				lumAncillaryWeight: true,
				productGroup: true,
				mbrxProductId: true,
				mbrxProductIdExt: true,
				value: true,
				weight: true,
				crl: true,
				maxFill: true,
				active: true,
				returnable: true,
				isSerializable: true,
				trackProviderTraining: true,
				supplierNumber: true,
				notes: true
			},
			limit: props.globalRowsPerPage,
			offset: pagination.offset,
			order: sortOrder ? [sortOrder] : ['productId'],
			where: {}, //filters ultimately end up here
		};

		const filterValues = props.selectedFilterValues || {};

		if (filterValues['PRODUCT_NAME']) {
			const productId = filterValues['PRODUCT_NAME'].productItemId;
			filter.where.or = [{ productId: productId }, { mbrxProductId: productId }];
		}

		if (filterValues['DOSE_TYPE']) {
			filter.where.doseType = filterValues['DOSE_TYPE'];
		}

		return filter;
	};

	const filterProducts = (reset?: boolean) => {
		props.getProducts(getFilter(reset));
	};

	const handleSearch = () => {
		setPageState({
			offset: 0,
			limit: props.globalRowsPerPage,
			page: 0,
		});
	};

	const showLoadingMsg = () => !props.authUser && props.products.result.length === 0;

	const handleModalClose = () => {
		setModalOpen(false);
		setProductToEdit(null);
	};

	const triggerModal = (product: any) => {
		setProductToEdit(product);
		setModalOpen(true);
	};

	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 66; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	return (
		<div>
			{showLoadingMsg() ? (
				<div>
					<Loading message="" />
				</div>
			) : (
				<div>
					<div className={classes.searchWrap}>
						<Grid container>
							<Grid item xs={12}>
								<GlobalFiltersComponentContainer pageName="ADMIN_PRODUCTS" executeSearch={handleSearch} />

								{props.authUser?.authorizationCheck({ action: 'create', resource: 'products', tenantId: 'ALL' }) && (
									<FormControl className={classes.formControl}>
										<Button
											className={classes.viewAllBtn}
											variant="contained"
											color="secondary"
											onClick={() => {
												triggerModal(null);
											}}
										>
											Add Product
										</Button>
									</FormControl>
								)}
							</Grid>
						</Grid>
					</div>

					{props.products.result.length > 0 ? (
						<div>
							<MUIDataTable
								data={props.products.result}
								columns={getTableColumns()}
								options={{
									rowsPerPage: props.globalRowsPerPage,
									rowsPerPageOptions: dataList.PageLimitOptions,
									onChangeRowsPerPage: (numberOfRows: number) => {
										setPageState({ ...pagination, limit: numberOfRows });
										props.setGlobalRowsPerPage(numberOfRows);
									},
									filterType: 'dropdown',
									responsive: 'simple',
									filter: false,
									search: false,
									download: true,
									print: true,
									selectableRows: 'none',
									serverSide: true,
									page: pagination.page,
									count: props.products.total,
									onColumnSortChange: (changedColumn: string, direction: string) => {
										setSortOrder(`${changedColumn} ${direction.toUpperCase()}`);
									},
									onTableChange: (tableAction: any, tableState: any) => {
										switch (tableAction) {
											case 'changePage':
												if (tableState.page > pagination.page) {
													setPageState({
														offset: pagination.offset + props.globalRowsPerPage,
														limit: props.globalRowsPerPage,
														page: tableState.page,
													});
												} else if (tableState.page < pagination.page) {
													setPageState({
														offset: pagination.offset - props.globalRowsPerPage,
														limit: props.globalRowsPerPage,
														page: tableState.page,
													});
												}
												break;
											case 'viewColumnsChange':
												setTableStatePersist(tableState);
												break;
										}
									},
									fixedHeader: true, // This locks the table headers at the top
									tableBodyHeight: tableHeight + 'px', // Set the height for the table body
								}}
							/>
						</div>
					) : (
						<EmptyContent message="Change the filter criteria above to view product information." />
					)}
					<div>
						<Modal
							disableBackdropClick={true}
							open={modalOpen}
							onClose={handleModalClose}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<div>
								<ProductFormModal getProducts={filterProducts} product={productToEdit} closeModal={handleModalClose} />
							</div>
						</Modal>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductListComponent;
