import React from 'react';
import { connect } from 'react-redux';
import { Button, FormControl, Grid } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';

const ConfirmationModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());

	const handleClose = () => {
		props.closeModal();
	};

	const handleConfirm = () => {
		props.confirm();
		props.closeModal();
	};

	return (
		<div style={{ ...modalStyle, maxWidth: 800 }} className={modalClasses.paper}>
			<div>
				<h3 id="simple-modal-title" style={{ marginBottom: 15 }}>Tag Id: {props.product.tagId}</h3>
				<h3 id="simple-modal-title" style={{ marginBottom: 15 }}>Serial No: {props.product.serialNo}</h3>
				<Grid container>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Customer Id:</b>
						<br />
						{props.product.customerId}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Product Id:</b>
						<br />
						{props.product.productId}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Product NDC:</b>
						<br />
						{props.product.ndc}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Lot:</b>
						<br />
						{props.product.lot}
					</Grid>
					<Grid item xs={3} style={{ marginBottom: 15 }}>
						<b>Expiration:</b>
						<br />
						{props.product.expiration.split('T')[0]}
					</Grid>
					{props.product.orderNo && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Order No:</b>
							<br />
							{props.product.orderNo}
						</Grid>
					)}
					{props.product.igShipmentId && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Shipment Id:</b>
							<br />
							{props.product.igShipmentId}
						</Grid>
					)}
					{props.product.received && (
						<Grid item xs={3} style={{ marginBottom: 15 }}>
							<b>Received:</b>
							<br />
							{props.product.received}
						</Grid>
					)}
					<Grid item xs={12} style={{ marginTop: 25 }}>
						<h3>{props.message}</h3>
					</Grid>
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleConfirm}
								type="button"
								style={{ marginTop: 12 }}
								className={classes.searchButton}
								variant="contained"
								color="primary"
							>
								YES
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								onClick={handleClose}
								type="button"
								style={{ marginTop: 12, marginLeft: 0 }}
								className={classes.cancelBtn}
								variant="contained"
								color="default"
							>
								NO
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
