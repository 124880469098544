import React from "react";
import { getModalClasses, getModalStyle, globalColors } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { UserTimezoneFormat, valueMapper } from "../../../hooks/functions";


const DashboardTableChartRendering: React.FC<any> = props => {

    console.log(`DashboardTableChartRendering called`)
    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());
    const [columns, setColumns] = React.useState(props.settings?.columns);
    const [records, setRecords] = React.useState(props.records);

    const handleClose = () => {
        props.closeChart();
    };

    React.useEffect(() => {
        let columns = props.settings?.columns;

        if (columns) {
            // Column names to add settings to
            const convertDateColumns = ['startDate', 'endDate', 'updatedAt', 'createdAt'];
            columns = columns.map((el: { [key: string]: any; }) => {
                if (convertDateColumns.includes(el.name)) {
                    // Convert timestamps to user timezone
                    el.options = {
                        customBodyRender: (value: any) => {
                            return UserTimezoneFormat(props.properties, value);
                        }
                    };
                }
                return el;
            })
            setColumns(columns);
        }

    }, [props.settings, props.properties]);

    React.useEffect(() => {
        let records = props.records;

        if (records) {
            // Column names to add settings to
            const cabinetTypes = ['EM', 'RFID', 'VISION', 'WEIGHT', 'Virtual'];

            records = records.map((el: { [key: string]: any; }) => {
                if (el?.cabinetType && cabinetTypes.includes(el.cabinetType)) {
                    // Convert cabinet type to name
                    el.cabinetType = valueMapper(el.cabinetType, 'cabinetTypes');
                }
                return el;
            })
            setRecords(records);
        }

    }, [props.records]);

    return (
        <div style={{ ...modalStyle, maxWidth: 1200 }} className={modalClasses.paper}>
            <div className={modalClasses.closeBtn} onClick={handleClose}>
                <CancelIcon color="disabled"></CancelIcon>
            </div>
            <div>
                <h3 id="simple-modal-title" style={{ marginBottom: 10 }}>
                    {props.settings?.displayName}
                </h3>
                <Grid container>
                    <Grid item xs={12}>
                        <MUIDataTable
                            data={records || []}
                            columns={columns || []}
                            options={{
                                filterType: 'dropdown',
                                responsive: 'simple',
                                filter: false,
                                search: false,
                                download: false,
                                print: false,
                                selectableRows: 'none',
                                viewColumns: false,
                                setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
                                    const status = props.records[dataIndex].status;
                                    // Highlight rows
                                    if (status === 'In Progress') {
                                        return { style: { backgroundColor: globalColors.WARNING_YELLOW } };
                                    }
                                    if (['Failure', 'Error'].includes(status)) {
                                        return { style: { backgroundColor: globalColors.TABLE_WARNING } };
                                    }
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTableChartRendering);