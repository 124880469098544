import * as React from 'react';
import { DashboardStyles, CommonStyles } from '../../hooks/styles';
import { Paper, Button, TextField, FormControl, Divider, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../../components/loading';
import { CabinetActions } from '../../redux/actions';
import { CabinetStyles } from './cabinet.styles';
import CabinetProviders from './cabinet-providers.component';
import CabinetUsers from './cabinet-users.component';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useMediaQuery } from 'react-responsive';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NestConfig from '../../components/nestConfig';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert } from '@material-ui/lab';
import ConfirmDialog from '../../components/dialog/confirm';
import { SelectBox } from '../../components/selectBox';

const moment = extendMoment(Moment as any);

const FlexDetailsComponent: React.FC<any> = (props: any) => {
	const classes = CabinetStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [isDataModified, setIsDataModified] = React.useState<boolean>(false);
	const [isLatestVersionSyncedToDevice, setIsLatestVersionSyncedToDevice] = React.useState<boolean>(false);
	const [providerChangeDetected, setProviderChangeDetected] = React.useState(false);
	const [userChangeDetected, setUserChangeDetected] = React.useState(false);
	const [globalSettingsChangeDetected, setGlobalSettingsChangeDetected] = React.useState(false);
	const [confirmOpen, setConfirmOpen] = React.useState(false);

	const [cabinetConfigLocalCopy, setCabinetConfigLocalCopy] = React.useState<any>(
		props.flexConfig?.config ? JSON.parse(JSON.stringify(props.flexConfig.config)) : {},
	);

	const checkIfModified = () => {
		if (!props.flexConfig?.config?.data?.compartments) return false;
		return Boolean(
			JSON.stringify(cabinetConfigLocalCopy.data.compartments) !==
				JSON.stringify(props.flexConfig.config.data.compartments),
		);
	};

	const generateNewFlexConfig = () => {
		return {
			data: {
				compartments: [
					{
						trays: [],
					},
				],
			},
		};
	};

	const showLoadingMsg = () => !props.authUser;

	const resetToLastSave = () => {
		setCabinetConfigLocalCopy(JSON.parse(JSON.stringify(props.flexConfig?.config || {})));
		setIsDataModified(false);
		// setSelectedNestRowsToAdd(1);
		// setSelectedNestColumnsToAdd(1);
	};

	const refreshConfigData = () => {
		props.getFlexConfig(cabinetId, props.selectedCustomer.customerId);
	};

	const resetToDefault = () => {
		props.resetToDefault(cabinetId);
	};

	const saveAllChanges = () => {
		let trayOnlyData = {
			...cabinetConfigLocalCopy.data,
		};
		delete trayOnlyData.users;
		delete trayOnlyData.providers;
		props.updateFlexConfig(
			{
				requestBody: {
					...cabinetConfigLocalCopy,
					data: trayOnlyData,
				},
				success: () => {
					setIsDataModified(false);
					setProviderChangeDetected(false);
					setUserChangeDetected(false);
					setGlobalSettingsChangeDetected(false);
				},
			},
			cabinetId,
		);
	};

	const toggleInventorySyncEnabled = (value: boolean) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		if (!configCopy.data.settings) configCopy.data.settings = {};
		configCopy.data.settings.inventorySyncEnabled = value ? true : false;
		setCabinetConfigLocalCopy(configCopy);
	};

	const toggleLoggingEnabled = (value: boolean) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		if (!configCopy.data.settings) configCopy.data.settings = {};
		configCopy.data.settings.loggingEnabled = value ? true : false;
		setCabinetConfigLocalCopy(configCopy);
	};

	const setGlobalSettingNumerical = (name: string, value: string) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		if (!configCopy.data.settings) configCopy.data.settings = {};
		configCopy.data.settings[name] = !(Number.isNaN(parseInt(value))) ? parseInt(value) : 0;
		setCabinetConfigLocalCopy(configCopy);
	};

	const addTray = (compartmentOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const newTray = {
			macAddress: '',
			maintenanceMode: false,
			numNestCols: 1,
			numNestRows: 1,
			sections: [],
			trayName: '',
			type: 2,
		};

		configCopy.data.compartments[compartmentOffset].trays = configCopy.data.compartments[compartmentOffset].trays || [];
		configCopy.data.compartments[compartmentOffset].trays.push(newTray);

		setCabinetConfigLocalCopy(configCopy);
	};

	const deleteSection = (compartmentOffset: number, trayOffset: number, sectionOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisSection = configCopy.data.compartments[compartmentOffset].trays[trayOffset].sections[sectionOffset];

		if (!thisSection) return;

		configCopy.data.compartments[compartmentOffset].trays[trayOffset].sections.splice(sectionOffset, 1);
		setCabinetConfigLocalCopy(configCopy);
	};

	const deleteTray = (compartmentOffset: number, trayOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisTray = configCopy.data.compartments[compartmentOffset].trays[trayOffset];

		if (!thisTray) return;

		configCopy.data.compartments[compartmentOffset].trays.splice(trayOffset, 1);
		setCabinetConfigLocalCopy(configCopy);
	};

	const updateTrayMacAddress = (newMacAddress: string, compartmentOffset: number, trayOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisTray = configCopy.data.compartments[compartmentOffset].trays[trayOffset];

		thisTray.macAddress = newMacAddress;

		setCabinetConfigLocalCopy(configCopy);
	};

	const updateTrayName = (newTrayName: string, compartmentOffset: number, trayOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisTray = configCopy.data.compartments[compartmentOffset].trays[trayOffset];

		thisTray.trayName = newTrayName;

		setCabinetConfigLocalCopy(configCopy);
	};

	const updateTrayToleranceValue = (propertyName: string, newVal: string, compartmentOffset: number, trayOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisTray = configCopy.data.compartments[compartmentOffset].trays[trayOffset];

		const toleranceVal = !(Number.isNaN(parseInt(newVal))) ? parseInt(newVal) : 0
		thisTray[propertyName] = toleranceVal;

		setCabinetConfigLocalCopy(configCopy);
	};

	const updateCompartmentName = (newCompartmentName: string, compartmentOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisCompartment = configCopy.data.compartments[compartmentOffset];

		thisCompartment.name = newCompartmentName;

		setCabinetConfigLocalCopy(configCopy);
	};

	const updateCompartmentType = (newCompartmentType: any, compartmentOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const thisCompartment = configCopy.data.compartments[compartmentOffset];

		thisCompartment.type = newCompartmentType;

		setCabinetConfigLocalCopy(configCopy);
	};

	const addSection = (compartmentOffset: number, trayOffset: number) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		const newSection = {
			capacity: 1,
			productId: '',
		};

		configCopy.data.compartments[compartmentOffset].trays = configCopy.data.compartments[compartmentOffset].trays || [];
		configCopy.data.compartments[compartmentOffset].trays[trayOffset].sections =
			configCopy.data.compartments[compartmentOffset].trays[trayOffset].sections || [];
		configCopy.data.compartments[compartmentOffset].trays[trayOffset].sections.push(newSection);

		setCabinetConfigLocalCopy(configCopy);
	};

	const updateNestItemValues = (
		nestOffsetCords: number[],
		drawerOffset: number,
		compartmentOffset: number,
		propsToUpdate: any,
	) => {
		const configCopy = { ...cabinetConfigLocalCopy };

		const compartmentIndex = compartmentOffset;
		const drawerIndex = drawerOffset;
		const nestIndex = nestOffsetCords[0]; //"coords" is for agreement with other places that share the same component, but in this case just use coords[0] as a simple index
		console.log('COMPARTMENT INDEX', compartmentIndex);
		console.log('DRAWER INDEX', drawerIndex);

		Object.keys(propsToUpdate).forEach((propName: any) => {
			configCopy.data.compartments[compartmentIndex].trays[drawerIndex].sections[nestIndex][propName] =
				propsToUpdate[propName];
		});

		setCabinetConfigLocalCopy(configCopy);
	};

	const addProviderToFlexCabinet = (providerData: object) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		configCopy.data.providers.provider_info = configCopy.data.providers.provider_info || [];

		configCopy.data.providers.provider_info.push(reformatProviderData(providerData));

		setCabinetConfigLocalCopy(configCopy);

		function reformatProviderData(providerData: any) {
			return {
				first_name: providerData.firstName,
				last_name: providerData.lastName,
				npi_id: providerData.npiId,
			};
		}
	};

	const addUserToFlexCabinet = (userData: object) => {
		const configCopy = { ...cabinetConfigLocalCopy };
		configCopy.data.users = configCopy.data.users || [];

		configCopy.data.users.push(reformatUserData(userData));

		setCabinetConfigLocalCopy(configCopy);

		function reformatUserData(userData: any) {
			return {
				displayName: `${userData.firstName} ${userData.lastName}`,
				role: userData.role,
			};
		}
	};

	//request flex cabinet config info
	React.useEffect(() => {
		const cId = props.selectedCustomer.customerId;

		if (props.authUser && cabinetId && cId) {
			props.getFlexConfig(cabinetId, cId);
		}
	}, [props.authUser]);

	//update UI on config load
	React.useEffect(() => {
		setCabinetConfigLocalCopy(
			props.flexConfig?.config ? JSON.parse(JSON.stringify(props.flexConfig.config)) : generateNewFlexConfig(),
		); //cache in local state to store changed to be saved
		setIsDataModified(false);
		setIsLatestVersionSyncedToDevice(Boolean(props.flexConfig?.deviceAckAvailable));
	}, [props.flexConfig]);

	React.useEffect(() => {
		setIsDataModified(checkIfModified());
	}, [cabinetConfigLocalCopy, props.flexConfig?.config]);

	return (
		<>
			<div style={{ margin: '15px 0' }}>
				{isLatestVersionSyncedToDevice ? (
					<Alert severity="success">This config is synced with the hardware device.</Alert>
				) : (
					<Alert severity="info">This config is not yet synced with the hardware device.</Alert>
				)}
			</div>
			<div>
				<Paper
					style={{
						padding: 20,
					}}
				>
					{showLoadingMsg() ? (
						<Loading message="" />
					) : (
						<div>
							{isDataModified && (
								<div style={{ marginBottom: 10 }}>
									<Alert severity="warning">
										Tray settings changes detected. Please click the "SEND TO DEVICE" to save this configuration.
									</Alert>
								</div>
							)}
							{cabinetConfigLocalCopy.data?.compartments?.length ? (
								<div>
									{cabinetConfigLocalCopy.data.compartments.map((compartment: any, i: number) => {
										const compIndex = i + 1;
										const compName = `compartment_${compIndex}`;

										return (
											<div key={compName}>
												<div>
													<FormControl
														className={classes.formControl}
														style={{
															width: 150,
															margin: '20px 0',
														}}
													>
														<TextField
															value={compartment.name || ''}
															label="Compartment Name"
															onChange={({ target }) => {
																updateCompartmentName(target.value, i);
															}}
														/>
													</FormControl>
													<FormControl
														className={classes.formControl}
														style={{
															width: 150,
															margin: '20px 0',
														}}
													>
														<SelectBox
															inputLabel={'Compartment Type'}
															emptyItemLabel={'Choose'}
															style={{ width: 150 }}
															selected={compartment.type}
															listItems={[
																{ label: 'Chilled', value: 1 },
																{ label: 'Ambient', value: 2 },
															]}
															onChangeItem={(value: any) => {
																updateCompartmentType(value, i);
															}}
														/>
													</FormControl>
												</div>
												<div>
													<Button onClick={() => addTray(i)} className={classes.searchButton}>
														Add tray
													</Button>
													{isDataModified && (
														<Button
															onClick={resetToLastSave}
															style={{ marginLeft: 10 }}
															className={classes.cancelPropertyEditBtn}
														>
															Discard Tray Changes
														</Button>
													)}
													{compartment.trays?.length ? (
														<div style={{ marginTop: 15 }}>
															{compartment.trays.map((tray: any, j: number) => {
																const trayIndex = j + 1;
																const trayName = `compartment_${compIndex}_tray_${trayIndex}`;
																return (
																	<div key={trayName}>
																		<Accordion>
																			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
																				<h3>Tray {trayIndex}</h3>
																			</AccordionSummary>
																			<AccordionDetails
																				style={{
																					width: '100%',
																					display: 'flex',
																					flexDirection: 'column',
																				}}
																			>
																				<div
																					style={{
																						width: '100%',
																						display: 'flex',
																						flexDirection: 'row',
																					}}
																				>
																					<Button onClick={() => addSection(i, j)} className={classes.searchButton}>
																						Add section
																					</Button>
																					<Button
																						onClick={() => deleteTray(i, j)}
																						style={{
																							marginLeft: 12,
																						}}
																						className={classes.cancelPropertyEditBtn}
																					>
																						Delete tray
																					</Button>
																				</div>

																				<FormControl
																					className={classes.formControl}
																					style={{
																						width: 150,
																						margin: '20px 0 0 0',
																					}}
																				>
																					<TextField
																						value={tray.macAddress}
																						label="MAC Address"
																						onChange={({ target }) => {
																							updateTrayMacAddress(target.value, i, j);
																						}}
																					/>
																				</FormControl>
																				<FormControl
																					className={classes.formControl}
																					style={{
																						width: 150,
																						margin: '20px 0',
																					}}
																				>
																					<TextField
																						value={tray.trayName}
																						label="Tray Name"
																						onChange={({ target }) => {
																							updateTrayName(target.value, i, j);
																						}}
																					/>
																				</FormControl>
																				<FormControl
																					className={classes.formControl}
																					style={{
																						width: 150,
																						margin: '20px 0',
																					}}
																				>
																					<TextField
																						type="number"
																						value={tray.packWeightTolerancePct}
																						label="Pack Weight Tolerance %"
																						onChange={({ target }) => {
																							updateTrayToleranceValue('packWeightTolerancePct', target.value, i, j);
																						}}
																					/>
																				</FormControl>
																				<FormControl
																					className={classes.formControl}
																					style={{
																						width: 150,
																						margin: '20px 0',
																					}}
																				>
																					<TextField
																						type="number"
																						value={tray.lumWeightTolerancePct}
																						label="Lum Weight Tolerance %"
																						onChange={({ target }) => {
																							updateTrayToleranceValue('lumWeightTolerancePct', target.value, i, j);
																						}}
																					/>
																				</FormControl>
																				{tray.sections?.length ? (
																					<div>
																						{tray.sections.map((section: any, k: number) => {
																							//"Section" and "nest" are synonymous
																							const sectionIndex = k + 1;
																							const sectionName = `compartment_${compIndex}_tray_${trayIndex}_section_${sectionIndex}`;

																							return (
																								<NestConfig
																									nestObj={section}
																									updateNestValues={updateNestItemValues}
																									drawerOffset={j}
																									compartmentOffset={i}
																									//productOptions={ cabinetConfigLocalCopy.data.menuProducts || [] }
																									productOptions={props.flexCabinetProductList || []}
																									key={sectionName}
																									nestIndex={k}
																									deleteSelf={() => {
																										deleteSection(i, j, k);
																									}}
																								/>
																							);
																						})}
																					</div>
																				) : (
																					<div>No sections.</div>
																				)}
																			</AccordionDetails>
																		</Accordion>
																	</div>
																);
															})}
														</div>
													) : (
														<div>No trays.</div>
													)}
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div>No compartments.</div>
							)}
						</div>
					)}
					<Divider variant="middle" style={{ margin: '15px 0' }} />
					<div>
						{/* accordions for other sections */}
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<div style={{ display: 'block' }}>
									{providerChangeDetected && (
										<Alert severity="warning">
											Provider settings changes detected. Please click the "SEND TO DEVICE" to save this configuration.
										</Alert>
									)}
									<h3>Provider Settings</h3>
								</div>
							</AccordionSummary>
							<AccordionDetails
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div>
									<CabinetProviders
										authUser={props.authUser}
										selectedCustomer={props.selectedCustomer}
										cabinet={props.selectedCabinet}
										isFlexCabinet={true}
										addProviderToFlexCabinet={addProviderToFlexCabinet}
										changeDetected={setProviderChangeDetected}
									/>
								</div>
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<div style={{ display: 'block' }}>
									{userChangeDetected && (
										<Alert severity="warning">
											User settings changes detected. Please click the "SEND TO DEVICE" to save this configuration.
										</Alert>
									)}
									<h3>User Settings</h3>
								</div>
							</AccordionSummary>
							<AccordionDetails
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<CabinetUsers
									cabinet={props.selectedCabinet}
									isFlexCabinet={true}
									addUserToFlexCabinet={addUserToFlexCabinet}
									changeDetected={setUserChangeDetected}
								/>
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<div style={{ display: 'block' }}>
									{globalSettingsChangeDetected && (
										<Alert severity="warning">
											Global settings changes detected. Please click the "SEND TO DEVICE" to save this configuration.
										</Alert>
									)}
									<h3>Global Settings</h3>
								</div>
							</AccordionSummary>
							<AccordionDetails
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<FormControl variant="filled" className={classes.formControl}>
									<FormControlLabel
										control={
											<Checkbox
												name={'inventorySyncEnabled'}
												checked={cabinetConfigLocalCopy?.data?.settings?.inventorySyncEnabled || false}
												onChange={(e: any, value: any) => {
													toggleInventorySyncEnabled(value);
													setGlobalSettingsChangeDetected(true);
												}}
											/>
										}
										label={'Inventory Sync Enabled'}
									/>
								</FormControl>
								<FormControl variant="filled" className={classes.formControl}>
									<FormControlLabel
										control={
											<Checkbox
												name={'loggingEnabled'}
												checked={cabinetConfigLocalCopy?.data?.settings?.loggingEnabled || false}
												onChange={(e: any, value: any) => {
													toggleLoggingEnabled(value);
													setGlobalSettingsChangeDetected(true);
												}}
											/>
										}
										label={'Logging Enabled'}
									/>
								</FormControl>
								<FormControl
									className={classes.formControl}
									style={{
										width: 150,
										margin: '20px 0',
									}}
								>
								<TextField
									type="number"
									value={cabinetConfigLocalCopy?.data?.settings?.logoutLumWeightToleranceGram || ''}
									label="Logout Weight Tolerance Gram"
									onChange={({ target }) => {
										setGlobalSettingNumerical('logoutLumWeightToleranceGram', target.value);
									}}
								/>
								</FormControl>

							</AccordionDetails>
						</Accordion>
					</div>
					<div
						style={{
							marginBottom: 16,
							marginTop: 16,
						}}
					>
						<Button
							onClick={saveAllChanges}
							type="button"
							className={`${classes.searchButton} ${classes.bigButton}`}
							variant="contained"
							color="primary"
						>
							Send to device
						</Button>
						<Button
							onClick={refreshConfigData}
							startIcon={<RefreshIcon />}
							type="button"
							className={`${classes.searchButton} ${classes.bigButton}`}
							variant="contained"
							color="primary"
						>
							Refresh
						</Button>
						<Button
							onClick={() => setConfirmOpen(true)}
							type="button"
							className={`${classes.searchButton} ${classes.bigButton}`}
							variant="contained"
							color="primary"
						>
							Reset Device Config
						</Button>
					</div>
				</Paper>
			</div>
			<div>
				<ConfirmDialog
					open={confirmOpen}
					handleClose={() => setConfirmOpen(false)}
					title="Reset to Empty Configuration?"
					content="This will remove all tray and section configurations."
					handleYes={() => {
						resetToDefault();
						setConfirmOpen(false);
					}}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	selectedCabinet: state.cabinet.selectedCabinet,
	cabinetDetails: state.cabinet.cabinetDetails,
	cabinetTypeProperties: state.cabinet.cabinetTypePropertyList,
	flexConfig: state.cabinet.cabinetFlexConfig,
	flexCabinetProductList: state.cabinet.flexCabinetProductList,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetDetails: (payload: { cabinetId: string; setCabinet: boolean }) =>
		dispatch(CabinetActions.getCabinetDetailsRequest(payload)),
	setBackFromDetails: (backFromDetails: boolean) => dispatch(CabinetActions.setBackFromDetails(backFromDetails)),
	resetCabinetTypeProperties: () => dispatch(CabinetActions.setPropertiesByCabinetType(null)),
	getFlexConfig: (cabinetId: string, customerId: string) =>
		dispatch(
			CabinetActions.getFlexConfig({
				cabinetId: cabinetId,
				customerId: customerId,
			}),
		),
	updateFlexConfig: (data: any, cabinetId: string) => dispatch(CabinetActions.updateFlexConfig(data, cabinetId)),
	resetToDefault: (cabinetId: string) => dispatch(CabinetActions.flexResetDefault(cabinetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlexDetailsComponent);
