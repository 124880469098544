import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const GridContainer = (dataList: any[]) => {
	let width: any = dataList.length ? 12 / dataList.length : 12;

	return (
		<Grid container style={{ marginBottom: 20 }}>
			{dataList.map((obj: any, key: number) => {
				return (
					<Grid item xs={width} key={key}>
						<Typography variant="subtitle1" display="block" style={{ color: '#948f8f' }}>
							{obj.label}
						</Typography>
						<Typography variant="subtitle1" display="block">
							{obj.value}
						</Typography>
					</Grid>
				);
			})}
		</Grid>
	);
};
export default GridContainer;
