import { DashboardActions } from "../actions";
import { DashboardService } from "../services";
import { call, put } from 'redux-saga/effects';

export function* getDashboardValues(action: ReturnType<typeof DashboardActions.getAllDashboardValues>) {
	try {
		const { data } = yield call(DashboardService.getInstance().getDashboardValues, action);

		yield put(DashboardActions.setAllDashboardValues(data));
	} catch (e) {
		console.log('dashboard values saga error', e);
	}
}