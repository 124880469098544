import React from 'react';
import MUIDataTable from 'mui-datatables';
import { UserTimezoneFormat } from '../../hooks/functions';
import dataList from '../../constants/dataList';
import moment from 'moment';

export const getExpandedReportTable = (
	data: any[],
	tableColumns: any[],
	pageOptions: any,
	exportHandler?: Function,
) => {
	//shared renderer for all reports sub-divided into groups
	//here we need to get some info from "data" to figure out what the backend needs to export
	return (
		<div style={{ width: '100%' }}>
			<MUIDataTable
				data={data}
				columns={tableColumns}
				options={{
					rowsPerPageOptions: pageOptions,
					search: false,
					filter: false,
					selectableRows: 'none',
					onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
						if (exportHandler) exportHandler(cols);
						return false;
					},
				}}
			/>
		</div>
	);
};

export const resetPagination = (setPagination: any) => {
	setPagination({
		page: 1,
		limit: 10,
		offset: 0,
		order: 'ASC',
	});
};

export const getTableColumns = (names: string[], labels: string[], tableOptions: any = {}) => {
	return names.map((colName: string, index: number) => {
		const options: any = {
			sort: true,
			filterList: null,
			display: true,
			viewColumns: true,
			change: true,
		};
		if (tableOptions.display && tableOptions.display[colName] === false) {
			options.display = tableOptions.display[colName];
		}
		if (tableOptions.customRenderFunctions && tableOptions.customRenderFunctions[colName]) {
			options.customBodyRender = tableOptions.customRenderFunctions[colName];
		}
		if (tableOptions.hints && tableOptions.hints[colName]) {
			options.hint = tableOptions.hints[colName];
		}
		return {
			name: colName,
			label: labels[index],
			options: options,
		};
	});
};

export const getReportExportOptions = (userProperties: any) => {
	const defaultTimezone = moment.tz.guess();
	const defaultDateLabel = dataList.DateFormats[0].label;
	const timezone_key_index: any = userProperties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'TIMEZONE',
	);
	const timezone = timezone_key_index > -1 ? userProperties[timezone_key_index].propertyValue : defaultTimezone;

	const dateFormat_key_index: any = userProperties.findIndex(
		(obj: { propertyKey: string }) => obj.propertyKey === 'DATEFORMAT',
	);
	const dateFormatLabel =
		dateFormat_key_index > -1 ? userProperties[dateFormat_key_index].propertyValue : defaultDateLabel;
	const dateFormat = dataList.DateFormats.find(format => {
		return format.label === dateFormatLabel;
	})?.value;

	return {
		timezone: timezone,
		dateFormat: dateFormat,
	};
};
