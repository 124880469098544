import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { Styles } from './style';
import { SelectBox } from '../../components/selectBox';
import { UserProperty } from '../../redux/models/user/user';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment-timezone';
import { mapStateToProps, mapDispatchToProps } from './redux-container';
import dataList from '../../constants/dataList';
import { themeNames } from '../../hooks/styles/muiTheme';
import { uniqBy } from 'lodash';

interface ProfileProps {
	user: any;
	userPropertiesCreate: (data: any) => void;
	properties: UserProperty[];
	user_properties: UserProperty[];
	setUserTheme?: any;
}
const Profile = (props: ProfileProps) => {
	const classes = Styles();
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [exampleFormat, setExampleFormat] = React.useState('');
	const [selectedTimeZones, setSelectedTimeZones] = React.useState('');
	const [selectedTimeZoneDisplay, setSelectedTimeZoneDisplay] = React.useState('');
	const [selectedDateFormat, setSelectedDateFormat] = React.useState('');
	const [selectedTimeFormat, setSelectedTimeFormat] = React.useState('');
	const [selectedDefaultInventory, setSelectedDefaultInventory] = React.useState('');
	const TIMEZONE: string = 'TIMEZONE';
	const DATEFORMAT: string = 'DATEFORMAT';
	const TIMEFORMAT: string = 'TIMEFORMAT';
	const USE_DARK_THEME: string = 'USE_DARK_THEME';
	const DEFAULT_INVENTORY: string = 'DEFAULT_INVENTORY';
	const [timeZoneReadOnly, setTimeZoneReadOnly] = React.useState(true);
	const [dateFormatReadOnly, setDateFormatReadOnly] = React.useState(true);
	const [timeFormatReadOnly, setTimeFormatReadOnly] = React.useState(true);
	const [preferredThemeReadOnly, setPreferredThemeReadOnly] = React.useState(true);
	const [defaultInventoryReadOnly, setDefaultInventoryReadOnly] = React.useState(true);
	const [isDarkTheme, setIsDarkTheme] = React.useState(false);

	const setTimeZone = (properties: UserProperty[]) => {
		if (properties && properties.length > 0) {
			const timezone_key_index: any = properties.findIndex(
				(obj: { propertyKey: string }) => obj.propertyKey === TIMEZONE,
			);
			const tzVal = timezone_key_index > -1 ? properties[timezone_key_index].propertyValue : '';

			if (tzVal) {
				const tzName = moment().tz(tzVal);
				const abbr = tzVal === 'LOCAL' ? moment.tz(moment.tz.guess()).zoneAbbr() : tzName.zoneAbbr();
				const offsetHours = tzName.utcOffset() / 60;

				setSelectedTimeZones(tzVal);
				setSelectedTimeZoneDisplay(tzVal === 'LOCAL' ? `LOCAL (${abbr} ${offsetHours})` : `${abbr} ${offsetHours}`);
			} else {
				setSelectedTimeZones('');
				setSelectedTimeZoneDisplay('');
			}
		} else {
			setSelectedTimeZones('');
			setSelectedTimeZoneDisplay('');
		}
	};

	const setDateFormat = (properties: UserProperty[]) => {
		if (properties && properties.length > 0) {
			const dateFormat_key_index: any = properties.findIndex(
				(obj: { propertyKey: string }) => obj.propertyKey === DATEFORMAT,
			);
			setSelectedDateFormat(dateFormat_key_index > -1 ? properties[dateFormat_key_index].propertyValue : '');
		} else {
			setSelectedDateFormat('');
		}
	};

	const setTimeFormat = (properties: UserProperty[]) => {
		if (properties && properties.length > 0) {
			const timeFormat_key_index: any = properties.findIndex(
				(obj: { propertyKey: string }) => obj.propertyKey === TIMEFORMAT,
			);
			setSelectedTimeFormat(timeFormat_key_index > -1 ? properties[timeFormat_key_index].propertyValue : '');
		} else {
			setSelectedTimeFormat('');
		}
	};

	const setUseDarkTheme = (properties: UserProperty[]) => {
		if (properties && properties.length > 0) {
			const dark_theme_key_index: any = properties.findIndex(
				(obj: { propertyKey: string }) => obj.propertyKey === USE_DARK_THEME,
			);

			//boolean comes back from server as string '0' or '1', so coerce
			let selectedIsDark =
				dark_theme_key_index > -1 ? Boolean(Number(properties[dark_theme_key_index].propertyValue)) : false;
			setIsDarkTheme(selectedIsDark);
		} else {
			setIsDarkTheme(false);
		}
	};

	const setDefaultInventory = (properties: UserProperty[]) => {
		if (properties && properties.length > 0) {
			const defaultInventory_key_index: any = properties.findIndex(
				(obj: { propertyKey: string }) => obj.propertyKey === DEFAULT_INVENTORY,
			);
			setSelectedDefaultInventory(
				defaultInventory_key_index > -1 ? properties[defaultInventory_key_index].propertyValue : '',
			);
		} else {
			setSelectedDefaultInventory('');
		}
	};

	React.useEffect(() => {
		console.log('loaded props', props.properties);
		//if (props.properties && props.properties.length > 0) {
		setTimeZone(props.properties);
		setDateFormat(props.properties);
		setTimeFormat(props.properties);
		setUseDarkTheme(props.properties);
		setDefaultInventory(props.properties);
		// } else {
		//     setTimeZone(props.properties)
		//     setDateFormat(props.properties)
		//     setTimeFormat(props.properties)
		// }
	}, [props.properties]);

	React.useEffect(() => {
		if (props.user_properties && props.user_properties.length > 0) {
			setTimeZone(props.user_properties);
			setDateFormat(props.user_properties);
			setTimeFormat(props.user_properties);
			setUseDarkTheme(props.user_properties);
		}
	}, [props.user_properties]);

	React.useEffect(() => {
		setFirstName(props.user && props.user.firstName ? props.user.firstName : '');
		setLastName(props.user && props.user.lastName ? props.user.lastName : '');
		setEmail(props.user && props.user.email ? props.user.email : '');
	}, [props.user]);

	const getTimeZonesList = () => {
		const list = moment.tz.zonesForCountry('US', true);
		const formattedList: any = [{ label: 'GMT 0', value: 'GMT' }];

		list.forEach(li => {
			const offsetHours = moment().tz(li.name).utcOffset() / 60;
			const abbr = moment().tz(li.name).zoneAbbr();
			const obj = {
				label: `${abbr} ${offsetHours}`,
				value: li.name,
			};

			formattedList.push(obj);
		});

		return uniqBy(formattedList, 'label').sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
	};

	const setUserTimeZone = (value: string) => {
		setSelectedTimeZones(value);
		const propertyValue = value ? value : 'LOCAL';
		if (!email || !propertyValue) {
			return;
		}
		let payload: any = {};
		let body: any = {};
		payload.email = email;
		payload.userId = props.user.id
		body.property_key = TIMEZONE;
		body.property_value = propertyValue;
		payload.body = body;
		props.userPropertiesCreate(payload);
	};

	const setUserDateFormat = (value: string) => {
		setSelectedDateFormat(value);
		const propertyValue = value ? value : 'LOCAL';
		if (!email || !propertyValue) {
			return;
		}
		let payload: any = {};
		let body: any = {};
		payload.email = email;
		payload.userId = props.user.id
		body.property_key = DATEFORMAT;
		body.property_value = propertyValue;
		payload.body = body;
		props.userPropertiesCreate(payload);
	};

	const setUserTimeFormat = (value: string) => {
		setSelectedTimeFormat(value);
		const propertyValue = value ? value : 'LOCAL';
		if (!email || !propertyValue) {
			return;
		}
		let payload: any = {};
		let body: any = {};
		payload.email = email;
		payload.userId = props.user.id
		body.property_key = TIMEFORMAT;
		body.property_value = propertyValue;
		payload.body = body;
		props.userPropertiesCreate(payload);
	};

	const updateThemePreference = (isDarkThemeVal: boolean) => {
		const newThemeName = isDarkThemeVal ? themeNames.DARK : themeNames.LIGHT;

		if (newThemeName === localStorage.getItem('fdiPortal_themePreference')) return;

		setIsDarkTheme(isDarkThemeVal);

		if (!email) {
			return;
		}
		let payload: any = {};
		let body: any = {};
		payload.email = email;
		payload.userId = props.user.id
		payload.newThemeName = newThemeName;
		payload.isThemeUpdate = true;

		body.property_key = USE_DARK_THEME;
		body.property_value = isDarkThemeVal;
		payload.body = body;
		props.userPropertiesCreate(payload);
	};

	const setUserDefaultInventory = (value: string) => {
		setSelectedDefaultInventory(value);
		const propertyValue = value ? value : 'LOT';
		if (!email || !propertyValue) {
			return;
		}
		let payload: any = {};
		let body: any = {};
		payload.email = email;
		payload.userId = props.user.id
		body.property_key = DEFAULT_INVENTORY;
		body.property_value = propertyValue;
		payload.body = body;
		props.userPropertiesCreate(payload);
	};

	const ExampleDateTimeText = () => {
		return (
			<TextField
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					inputProps: {
						disabled: true,
						className: classes.profileExampleText,
					},
				}}
				autoComplete="off"
				id="outlined-basic"
				label={`i.e., ${exampleFormat}`}
			/>
		);
	};

	React.useEffect(() => {
		const exampleTime = new Date();
		const exampleTimezone = selectedTimeZones && selectedTimeZones != 'LOCAL' ? selectedTimeZones : moment.tz.guess();
		const exampleDateFormat =
			dataList.DateFormats.find(format => {
				return format.label === selectedDateFormat;
			})?.value || dataList.DateFormats[0].value;
		const exampleTimeFormat =
			dataList.TimeFormats.find(format => {
				return format.label === selectedTimeFormat;
			})?.value || dataList.TimeFormats[0].value;
		setExampleFormat(moment(exampleTime).tz(exampleTimezone).format(`${exampleDateFormat} ${exampleTimeFormat}`));
	}, [selectedTimeZones, selectedDateFormat, selectedTimeFormat]);

	return (
		<div className={classes.profileBox}>
			<Grid container spacing={2}>
				<Grid item xs={5}>
					<FormControl className={classes.profileFormControl}>
						<TextField
							InputLabelProps={{ className: classes.inputLabel }}
							InputProps={{
								value: firstName,
								readOnly: true,
								disableUnderline: true,
								inputProps: {
									disabled: true,
								},
							}}
							className={classes.profileInput}
							autoComplete="off"
							id="outlined-basic"
							label={'First Name'}
							onChange={({ target }) => setFirstName(target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={5}>
					<FormControl className={classes.profileFormControl}>
						<TextField
							InputLabelProps={{ className: classes.inputLabel }}
							InputProps={{
								value: lastName,
								readOnly: true,
								disableUnderline: true,
								inputProps: {
									disabled: true,
								},
							}}
							className={classes.profileInput}
							autoComplete="off"
							id="outlined-basic"
							label={'Last Name'}
							onChange={({ target }) => setLastName(target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={5}>
					<FormControl className={classes.profileFormControl}>
						<TextField
							InputLabelProps={{ className: classes.inputLabel }}
							InputProps={{
								value: email,
								readOnly: true,
								disableUnderline: true,
								inputProps: {
									disabled: true,
								},
							}}
							className={classes.profileInput}
							autoComplete="off"
							id="outlined-basic"
							label={'Email'}
							onChange={({ target }) => setEmail(target.value)}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<FormControl className={classes.profileFormControl}>
						{timeZoneReadOnly ? (
							<div className={classes.profilePropertyWrap}>
								<TextField
									InputLabelProps={{ className: classes.inputLabel }}
									InputProps={{
										value: selectedTimeZoneDisplay,
										readOnly: true,
										disableUnderline: true,
										inputProps: {
											disabled: true,
										},
									}}
									className={classes.profileInput}
									autoComplete="off"
									id="outlined-basic"
									label={'Timezone'}
								/>

								<CreateIcon className={classes.formControlIcon} onClick={() => setTimeZoneReadOnly(false)} />
							</div>
						) : (
							<div className={classes.profilePropertyWrap}>
								<SelectBox
									selected={selectedTimeZones}
									style={{ width: 200 }}
									inputLabel={'Timezone'}
									emptyItemLabel={'LOCAL'}
									emptyItemValue={'LOCAL'}
									listItems={getTimeZonesList()}
									onChangeItem={(value: any) => {
										setSelectedTimeZones(value);
									}}
									// getOptionLabel={(option: any) =>
									//     `${option.name || ''}`
									// }
									// getOptionValue={(option: any) =>
									//     `${option.name || ''}`
									// }
								/>
								{ExampleDateTimeText()}
								<CheckIcon
									className={classes.formControlIcon}
									onClick={() => {
										setUserTimeZone(selectedTimeZones);
										setTimeZoneReadOnly(true);
									}}
								/>
							</div>
						)}
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<FormControl className={classes.profileFormControl}>
						{dateFormatReadOnly ? (
							<div className={classes.profilePropertyWrap}>
								<TextField
									InputLabelProps={{ className: classes.inputLabel }}
									InputProps={{
										value: selectedDateFormat,
										readOnly: true,
										disableUnderline: true,
										inputProps: {
											disabled: true,
										},
									}}
									className={classes.profileInput}
									autoComplete="off"
									id="outlined-basic"
									label={'Date Format'}
								/>
								<CreateIcon className={classes.formControlIcon} onClick={() => setDateFormatReadOnly(false)} />
							</div>
						) : (
							<div className={classes.profilePropertyWrap}>
								<SelectBox
									selected={selectedDateFormat}
									style={{ width: 200 }}
									inputLabel={'Date Format'}
									emptyItemLabel={'LOCAL'}
									emptyItemValue={'LOCAL'}
									listItems={dataList.DateFormats}
									onChangeItem={(value: any) => {
										setSelectedDateFormat(value);
									}}
									getOptionLabel={(option: any) => `${option.label || ''}`}
									getOptionValue={(option: any) => `${option.label || ''}`}
								/>
								{ExampleDateTimeText()}
								<CheckIcon
									className={classes.formControlIcon}
									onClick={() => {
										setUserDateFormat(selectedDateFormat);
										setDateFormatReadOnly(true);
									}}
								/>
							</div>
						)}
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<FormControl className={classes.profileFormControl}>
						{timeFormatReadOnly ? (
							<div className={classes.profilePropertyWrap}>
								<TextField
									InputLabelProps={{ className: classes.inputLabel }}
									InputProps={{
										value: selectedTimeFormat,
										readOnly: true,
										disableUnderline: true,
										inputProps: {
											disabled: true,
										},
									}}
									className={classes.profileInput}
									autoComplete="off"
									id="outlined-basic"
									label={'Time Format'}
								/>
								<CreateIcon className={classes.formControlIcon} onClick={() => setTimeFormatReadOnly(false)} />
							</div>
						) : (
							<div className={classes.profilePropertyWrap}>
								<SelectBox
									selected={selectedTimeFormat}
									style={{ width: 200 }}
									inputLabel={'Time Format'}
									emptyItemLabel={'LOCAL'}
									emptyItemValue={'LOCAL'}
									listItems={dataList.TimeFormats}
									onChangeItem={(value: any) => {
										setSelectedTimeFormat(value);
									}}
									getOptionLabel={(option: any) => `${option.label || ''}`}
									getOptionValue={(option: any) => `${option.label || ''}`}
								/>
								{ExampleDateTimeText()}
								<CheckIcon
									className={classes.formControlIcon}
									onClick={() => {
										setUserTimeFormat(selectedTimeFormat);
										setTimeFormatReadOnly(true);
									}}
								/>
							</div>
						)}
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<FormControl className={classes.profileFormControl}>
						{defaultInventoryReadOnly ? (
							<div className={classes.profilePropertyWrap}>
								<TextField
									InputLabelProps={{ className: classes.inputLabel }}
									InputProps={{
										value: selectedDefaultInventory,
										readOnly: true,
										disableUnderline: true,
										inputProps: {
											disabled: true,
										},
									}}
									className={classes.profileInput}
									autoComplete="off"
									id="outlined-basic"
									label={'Default Inventory'}
								/>
								<CreateIcon className={classes.formControlIcon} onClick={() => setDefaultInventoryReadOnly(false)} />
							</div>
						) : (
							<div className={classes.profilePropertyWrap}>
								<SelectBox
									selected={selectedDefaultInventory}
									style={{ width: 200 }}
									inputLabel={'Default Inventory'}
									emptyItemLabel={'Lot Inventory'}
									emptyItemValue={'LOT'}
									listItems={dataList.DefaultInventories.slice(1)}
									onChangeItem={(value: any) => {
										setSelectedDefaultInventory(value);
									}}
									getOptionLabel={(option: any) => `${option.label || ''}`}
									getOptionValue={(option: any) => `${option.value || ''}`}
								/>
								<CheckIcon
									className={classes.formControlIcon}
									onClick={() => {
										setUserDefaultInventory(selectedDefaultInventory);
										setDefaultInventoryReadOnly(true);
									}}
								/>
							</div>
						)}
					</FormControl>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<FormControl className={classes.profileFormControl}>
						{preferredThemeReadOnly ? (
							<div className={classes.profilePropertyWrap}>
								<TextField
									InputLabelProps={{ className: classes.inputLabel }}
									InputProps={{
										value: '',
										readOnly: true,
										disableUnderline: true,
										inputProps: {
											disabled: true,
										},
									}}
									className={classes.profileInput}
									autoComplete="off"
									label={'Theme'}
								/>
								<CreateIcon className={classes.formControlIcon} onClick={() => setPreferredThemeReadOnly(false)} />
							</div>
						) : (
							<div className={classes.profilePropertyWrap}>
								<FormControl required className={classes.formControl}>
									<FormControlLabel
										control={
											<Switch
												checked={isDarkTheme}
												onChange={() => {
													setIsDarkTheme(!isDarkTheme);
												}}
												name="themeSelector"
											/>
										}
										label="Use Dark Theme"
										color="primary"
									/>
								</FormControl>

								<CheckIcon
									className={classes.formControlIcon}
									onClick={() => {
										updateThemePreference(isDarkTheme);
										setPreferredThemeReadOnly(true);
									}}
								/>
							</div>
						)}
					</FormControl>
				</Grid>
			</Grid>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
