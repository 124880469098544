import React from 'react';
import { Container } from '@material-ui/core';
import { InventoryReportsStyles } from '../../../hooks/styles';
import WarehouseShipments from './warehouse-shipments';
import BasePage from '../../common/base';
import { connect } from 'react-redux';

const WarehouseShipmentsPage = (props: any) => {
	const classes = InventoryReportsStyles();

	return (
		<BasePage title={'Warehouse Shipments'}>
			<div>
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					<WarehouseShipments />
				</Container>
			</div>
		</BasePage>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};

export default connect(mapStateToProps)(WarehouseShipmentsPage);
