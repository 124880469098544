import * as React from 'react';
import Moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { extendMoment } from 'moment-range';
import { CabinetStyles } from './cabinet.styles';
import { Button, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Constants } from '../../constants/Constants';
import { connect } from 'react-redux';
import { CabinetActions, UserActions } from '../../redux/actions';
import { ConvertDateTimeForRequest } from '../../hooks/functions/ConvertDateTimeForRequest';
import dateFormat from 'dateformat';
import CabinetsChartComponent from './cabinet-chart.component';
import { useParams } from 'react-router-dom';
import { GetUserTimezone } from '../../hooks/functions';
import { YAxis } from 'recharts';
HighchartsMore(Highcharts);
window.moment = Moment;
const moment = extendMoment(Moment as any);

const CabinetsConnectivityComponent: React.FC<any> = (props: any) => {
	const classes = CabinetStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [lteRsrpData, setLteRsrpData] = React.useState<any[]>([])
	const [lteRssnrData, setLteRssnrData] = React.useState<any[]>([])
	const [lteLevelData, setLteLevelData] = React.useState<any[]>([])
	const [wifiRssiData, setWifiRssiData] = React.useState<any[]>([])
	const [wifiLevelData, setWifiLevelData] = React.useState<any[]>([])
	const [wifiSsid, setWifiSsid] = React.useState<string>('')
	const [weightData, setWeightData] = React.useState<any[]>([])

	const exportGraphCsv = (reportType: string) => {
		const filter = {
			customerId: customerId,
			deviceId: cabinetId,
			timezone: GetUserTimezone(props.properties),
			startTime: ConvertDateTimeForRequest(props.properties, moment(props.dates.startDate).format()),
			endTime: ConvertDateTimeForRequest(props.properties, moment(props.dates.endDate.setHours(23, 59, 59)).format()),
		};

		const connectivityParams = {
			filter_object_from_ui: filter,
			report_type: reportType,
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
		};

		props.sendOneTimeReportRequest(connectivityParams);
	};

	React.useEffect(() => {
		props.connectivityPayload.deviceId
		&& props.connectivityPayload.startTime
		&& props.connectivityPayload.endTime
		&& props.getConnectivityData(props.connectivityPayload)
	}, [props.connectivityPayload])

	// Handle LTE/WIFI Graph Data
	React.useEffect(() => {
		let lteRsrpGraphData: any[] = []
		let lteRssnrGraphData: any[] = []
		let lteLevelGraphData: any[] = []
		let wifiRssiGraphData: any[] = []
		let wifiLevelGraphData: any[] = []
		if (props.lteWifiData.length > 0) {
			props.lteWifiData.forEach((row: any) => {
				let dateValue: any = moment(row.eventTimestamp).valueOf();
				lteRsrpGraphData.push([dateValue, row.lteRsrp])
				lteRssnrGraphData.push([dateValue, row.lteRssnr])
				lteLevelGraphData.push([dateValue, row.lteLevel])
				wifiRssiGraphData.push([dateValue, row.wifiRssi])
				wifiLevelGraphData.push([dateValue, row.wifiLevel])
			})
			setLteRsrpData(lteRsrpGraphData)
			setLteRssnrData(lteRssnrGraphData)
			setLteLevelData(lteLevelGraphData)
			setWifiRssiData(wifiRssiGraphData)
			setWifiLevelData(wifiLevelGraphData)
			setWifiSsid(props.lteWifiData[0].wifiSsid)
		}
	}, [props.lteWifiData])

	const connectivityOptions = {
		title: false,
		xAxis: [{
			categories: props.connectivityData.map((row: any) => {
				const date = new Date(row.date)
				const month = date.toLocaleString('default', { month: 'short' });
				const day = date.getDate()
				return `${day}-${month}`
			}),
			crosshair: true
		}],
		yAxis: [{
			title: {
				text: 'Number of Timestamps',
				style: {
					color: '#4fa9f3'
				}
			},
			labels: {
				style: {
					color: '#4fa9f3'
				}
			}
		},
		{
			title: {
				text: 'Number of Packets',
				style: {
					color: '#238a30',
				}
			},
			labels: {
				style: {
					color: '#238a30',
				}
			},
			opposite: true
		}],
		tooltip: {
			shared: true
		},
		series: [{
			name: 'Number of Timestamps',
			type: 'spline',
			yAxis: 0,
			color: '#4fa9f3',
			data: props.connectivityData.map((row: any) => (row.timestampsCount))
		},
		{
			name: 'Number of Packets',
			type: 'spline',
			yAxis: 1,
			color: '#238a30',
			data: props.connectivityData.map((row: any) => (row.packetsCount))
		}]
	}

	const lteOptions = {
		title: false,
		xAxis: {
			type: 'datetime',
			labels: {
				format: props.dateFormat,
			}
		},
		yAxis: [{
			title: {
				text: 'RSRP (-dBm)',
				style: {
					color: '#4fa9f3'
				}
			},
			labels: {
				style: {
					color: '#4fa9f3'
				}
			}
		},
		{
			title: {
				text: 'RSSNR (dB)',
				style: {
					color: '#238a30',
				}
			},
			labels: {
				style: {
					color: '#238a30',
				}
			},
			opposite: true
		},
		{
			title: {
				text: 'Level',
				style: {
					color: '#544fc5'
				}
			},
			labels: {
				style: {
					color: '#544fc5',
				}
			},
			opposite: true
		}],
		tooltip: {
			shared: true
		},
		series: [{
			name: 'RSRP (-dBm)',
			type: 'spline',
			yAxis: 0,
			color: '#4fa9f3',
			data: lteRsrpData
		},
		{
			name: 'RSSNR (dB)',
			type: 'spline',
			yAxis: 1,
			color: '#238a30',
			data: lteRssnrData
		},
		{
			name: 'Level',
			type: 'spline',
			color: '#544fc5',
			yAxis: 2,
			data: lteLevelData
		}]
	}

	const wifiOptions = {
		title: false,
		xAxis: {
			type: 'datetime',
			labels: {
				format: props.dateFormat,
			}
		},
		yAxis: [{
			title: {
				text: 'RSSI (-dBm)',
				style: {
					color: '#4fa9f3'
				}
			},
			labels: {
				style: {
					color: '#4fa9f3'
				}
			}
		},
		{
			title: {
				text: 'Level',
				style: {
					color: '#544fc5'
				}
			},
			labels: {
				style: {
					color: '#544fc5',
				}
			},
			opposite: true
		}],
		tooltip: {
			shared: true
		},
		series: [{
			name: 'RSRP (-dBm)',
			type: 'spline',
			yAxis: 0,
			color: '#4fa9f3',
			data: wifiRssiData
		},
		{
			name: 'Level',
			type: 'spline',
			yAxis: 1,
			color: '#544fc5',
			data: wifiLevelData
		}]
	}

	const getWeightGraphOptions = () => {
		let weightGraphOptions: any = {
			title: false,
			xAxis: {
				type: 'datetime',
				labels: {
					format: props.dateFormat,
				}
			},
			tooltip: {
				shared: true
			},
			yAxis: [{
				title: {
					text: `Weight (g)`,
				}
			}],
			series: []
		}
		if (props.weightData) {
			const plotColors = ['#4fa9f3', '#238a30', '#544fc5', '#f3a94f', '#f34f4f', '#4f4ff3', '#4ff3f3', '#4ff34f', '#f34ff3', '#f3f34f']
			Object.keys(props.weightData).forEach((trayId: any, index: number) => {
				weightGraphOptions.series.push({
					name: `Tray ID ${trayId} Weight (g)`,
					type: 'spline',
					yAxis: 0,
					color: plotColors[index],
					data: props.weightData[index].map((row: any) => {
						const dateValue: any = moment(row.eventTimestamp).valueOf();
						return [dateValue, Number(row.weight)]
					})
				})
			})
		}
		return weightGraphOptions
	}

	return (
		<>
			<div>
				<CabinetsChartComponent
					data={props.data}
					dateFormat={props.dateFormat}
					timezone={props.timezone}
					showExport={true}
					exportTemperatureCsv={props.exportTemperatureCsv}
				/>
			</div>
			<div style={{marginTop: 20}}>
				<div className={classes.graphExportButton}>
					<Button
						id="export_button"
						className={classes.searchButton}
						style={{ width: 100 }}
						type="button"
						variant="contained"
						color="primary"
						onClick={() => exportGraphCsv('RNI_UIEXPORT_CabinetConnectivityReport')}
					>
						Export
					</Button>
				</div>
				<div className={classes.tmpGraphTitleDiv}>
					<h4 className={classes.tmpGraphTitle}>Telemetry Packet Transmission</h4>
				</div>
				<HighchartsReact highcharts={Highcharts} options={connectivityOptions} />
			</div>
			<div style={{marginTop: 20}}>
				<div className={classes.graphExportButton}>
					<Button
						id="export_button"
						className={classes.searchButton}
						style={{ width: 100 }}
						type="button"
						variant="contained"
						color="primary"
						onClick={() => exportGraphCsv('RNI_UIEXPORT_CabinetLteReport')}
					>
						Export
					</Button>
				</div>
				<div className={classes.tmpGraphTitleDiv}>
					<h4 className={classes.tmpGraphTitle}>LTE Signal Properties</h4>
				</div>
				{lteLevelData.length === 0 ?
					<div>
						<Alert severity="info">
							<AlertTitle>Info</AlertTitle>
							{Constants.ALERT.NO_RECORD_FOUND}
						</Alert>
					</div>
				:
					<HighchartsReact highcharts={Highcharts} options={lteOptions} />
				}
			</div>
			<div style={{marginTop: 20}}>
				<div className={classes.graphExportButton}>
					<Button
						id="export_button"
						className={classes.searchButton}
						style={{ width: 100 }}
						type="button"
						variant="contained"
						color="primary"
						onClick={() => exportGraphCsv('RNI_UIEXPORT_CabinetWifiReport')}
					>
						Export
					</Button>
				</div>
				<div className={classes.tmpGraphTitleDiv}>
					<h4 className={classes.tmpGraphTitle}>WIFI Signal Properties ({wifiSsid})</h4>
				</div>
				{wifiLevelData.length === 0 ?
					<div>
						<Alert severity="info">
							<AlertTitle>Info</AlertTitle>
							{Constants.ALERT.NO_RECORD_FOUND}
						</Alert>
					</div>
				:
					<HighchartsReact highcharts={Highcharts} options={wifiOptions} />
				}
			</div>
			<div style={{marginTop: 20}}>
				<div className={classes.graphExportButton}>
					<Button
						id="export_button"
						className={classes.searchButton}
						style={{ width: 100 }}
						type="button"
						variant="contained"
						color="primary"
						onClick={() => exportGraphCsv('RNI_UIEXPORT_CabinetWeightReport')}
					>
						Export
					</Button>
				</div>
				<div className={classes.tmpGraphTitleDiv}>
					<h4 className={classes.tmpGraphTitle}>Tray Weight</h4>
				</div>
				{wifiLevelData.length === 0 ?
					<div>
						<Alert severity="info">
							<AlertTitle>Info</AlertTitle>
							{Constants.ALERT.NO_RECORD_FOUND}
						</Alert>
					</div>
				:
					<HighchartsReact highcharts={Highcharts} options={getWeightGraphOptions()} />
				}
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	connectivityData: state.cabinet.connectivityGraph || [],
	lteWifiData: state.cabinet && state.cabinet.telemetryMetrics ? state.cabinet.telemetryMetrics.connectivity : [],
	weightData: state.cabinet && state.cabinet.telemetryMetrics ? state.cabinet.telemetryMetrics.weight : null,
	telemetryMetrics: state.cabinet && state.cabinet.telemetryMetrics ? state.cabinet.telemetryMetrics : {temperature: [], connectivity: []},
	authUser: state.user.authUser,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
})

const mapDispatchToProps = (dispatch: any) => ({
	getConnectivityData: (filter: any) => dispatch(CabinetActions.getCabinetConnectivityGraph(filter)),
	getLteWifiData: (filter: any) => dispatch(CabinetActions.getCabinetLteWifiGraph(filter)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CabinetsConnectivityComponent);