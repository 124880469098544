import { action } from 'typesafe-actions';
import { CABINET } from '../constants/actions';
import { Cabinet, CabinetFilter } from '../models/cabinets/cabinet';
import { UpdateDeviceEdgePropertiesPayloadDTO } from '../models/cabinets/device-edge-properties-payload.dto';

export const cabinetRequestStartAction = (data: any) => action(CABINET.LIST, data);
export const cabinetsRequestCompletedAction = (cabinets: Cabinet[]) => action(CABINET.LIST_RESPONSE, cabinets);

export const cabinetsTableRequestStartAction = (data: any) => action(CABINET.TABLE_LIST, data);
export const cabinetsTableRequestCompletedAction = (cabinets: Cabinet[]) =>
	action(CABINET.TABLE_LIST_RESPONSE, cabinets);

export const clearCabinetList = () => action(CABINET.CLEAR_CABINET_LIST, {});

export const getPropertyList = () => action(CABINET.GET_PROPERTY_LIST, {});
export const setPropertyList = (payload: any) => action(CABINET.SET_PROPERTY_LIST, payload);

export const getStateListAction = (data: any) => action(CABINET.STATE.LIST, data);
export const setStateListAction = (data: any) => action(CABINET.STATE.LIST_RESPONSE, data);

export const getCabinetDetailsRequest = (payload: { cabinetId: string; setCabinet: boolean }) =>
	action(CABINET.GET_CABINET_DETAILS_REQUEST, payload);
export const getMbrxCabinetDetails = (payload: any) => action(CABINET.GET_CABINET_DETAILS_MBRX, payload);
export const setCabinetDetails = (data: any) => action(CABINET.SET_CABINET_DETAILS, data);
export const setCabinet = (data: any) => action(CABINET.SET_CABINET, data);
export const setCabinetDetailsError = (flag: boolean) => action(CABINET.SET_DETAILS_ERROR, flag);

export const getCabinetProducts = (cabinetId: string) => action(CABINET.GET_PRODUCTS, cabinetId);
export const setCabinetProducts = (data: any) => action(CABINET.SET_PRODUCTS, data);

export const updatePoType = (data: any) => action(CABINET.UPDATE_PO_TYPE, data);

export const getTemperatureAction = (data: any) => action(CABINET.TEMPERATURE.GET_TEMPERATURE, data);
export const getTemperatureSuccessAction = (data: any) => action(CABINET.TEMPERATURE.GET_TEMPERATURE_SUCCESS, data);

export const getTelemetryMetrics = (data: any) => action(CABINET.GET_TELEMETRY_METRICS, data);
export const setTelemetryMetrics = (data: any) => action(CABINET.SET_TELEMETRY_METRICS, data);

export const getTemperatureCsvAction = (payload: {
	customerId: string;
	deviceId: number;
	startTime: string;
	endTime: string;
}) => action(CABINET.TEMPERATURE.GET_TEMPERATURE_CSV, payload);

export const updateCabinet = (data: any) => action(CABINET.UPDATE, data);

export const getNextCabinetId = (cabinetType: string) => action(CABINET.GET_NEXT_CABINET_ID, cabinetType);
export const setNextCabinetId = (data: any) => action(CABINET.SET_NEXT_CABINET_ID, data);
export const getCabinetUsers = (cabinetId: string) => action(CABINET.GET_CABINET_USERS, cabinetId);
export const setCabinetUsers = (data: any) => action(CABINET.SET_CABINET_USERS, data);
export const importDeviceSettings = (cabinetId: string) => action(CABINET.IMPORT_DEVICE_SETTINGS, cabinetId);

export const getSingleCabinetUser = (payload: any) => action(CABINET.GET_SINGLE_CABINET_USER, payload);
export const setSingleCabinetUser = (data: any) => action(CABINET.SET_SINGLE_CABINET_USER, data);

export const getAvailableUsers = (filters: any) => action(CABINET.GET_AVAILABLE_USERS, filters);
export const setAvailableUsers = (data: any) => action(CABINET.SET_AVAILABLE_USERS, data);

export const addCabinetUser = (payload: any) => action(CABINET.ADD_CABINET_USER, payload);
export const updateCabinetUser = (payload: any) => action(CABINET.UPDATE_CABINET_USER, payload);

export const createCabinet = (payload: any) => action(CABINET.CREATE_CABINET, payload);
export const setCabinetError = (errorMsg: string | null) => action(CABINET.CABINET_ERROR, errorMsg);

export const addCabinetProduct = (payload: any) => action(CABINET.ADD_PRODUCT, payload);

export const getCabinetProductInventory = (payload: any) => action(CABINET.GET_CABINET_PRODUCT_INVENTORY, payload);
export const setCabinetProductInventory = (payload: any) => action(CABINET.SET_CABINET_PRODUCT_INVENTORY, payload);
export const getCycleCountInventory = (payload: any) => action(CABINET.GET_CYCLE_COUNT_INVENTORY, payload);
export const setCycleCountInventory = (payload: any) => action(CABINET.SET_CYCLE_COUNT_INVENTORY, payload);
export const setCycleCountInventoryError = (payload: any) => action(CABINET.SET_CYCLE_COUNT_INVENTORY_ERROR, payload);
export const getConsignmentOrders = (payload: any) => action(CABINET.GET_CONSIGNMENT_ORDERS, payload);
export const setConsignmentOrders = (payload: any) => action(CABINET.SET_CONSIGNMENT_ORDERS, payload);
export const updateCabinetProductInventory = (payload: any) =>
	action(CABINET.UPDATE_CABINET_PRODUCT_INVENTORY, payload);
export const reportCabinetInventory = (payload: any) => action(CABINET.REPORT_CABINET_INVENTORY, payload);

export const reportCabinetInventoryTwo = (payload: any) => action(CABINET.REPORT_CABINET_INVENTORY_TWO, payload);

export const getCabinetLatestScan = (cabinetId: string) => action(CABINET.GET_CABINET_LATEST_SCAN, cabinetId);
export const setCabinetLatestScan = (payload: any) => action(CABINET.SET_CABINET_LATEST_SCAN, payload);

export const getCabinetConnectivityGraph = (payload: string) => action(CABINET.GET_CABINET_CONNECTIVITY_GRAPH, payload);
export const setCabinetConnectivityGraph = (payload: any) => action(CABINET.SET_CABINET_CONNECTIVITY_GRAPH, payload);

export const getCabinetLteWifiGraph = (payload: string) => action(CABINET.GET_CABINET_LTEWIFI_GRAPH, payload);
export const setCabinetLteWifiGraph = (payload: any) => action(CABINET.SET_CABINET_LTEWIFI_GRAPH, payload);

export const getProviderById = (payload: any) => action(CABINET.GET_PROVIDER_BY_ID, payload);
export const setFoundProvider = (payload: any) => action(CABINET.SET_FOUND_PROVIDER, payload);
export const addCabinetProvider = (payload: any) => action(CABINET.ADD_CABINET_PROVIDER, payload);
export const deleteCabinetProviders = (payload: any) => action(CABINET.DELETE_CABINET_PROVIDERS, payload);

export const addCabinetLocationTracking = (payload: any) => action(CABINET.ADD_CABINET_LOCATION_TRACKING, payload);
export const deleteCabinetLocationTracking = (payload: any) =>
	action(CABINET.DELETE_CABINET_LOCATION_TRACKING, payload);

export const getPropertiesByCabinetType = (payload: any) => action(CABINET.GET_PROPERTIES_BY_CABINET_TYPE, payload);
export const setPropertiesByCabinetType = (payload: any) => action(CABINET.SET_PROPERTIES_BY_CABINET_TYPE, payload);

export const saveCabinetProperties = (payload: any) => action(CABINET.SAVE_CABINET_PROPERTIES, payload);
export const deleteCabinetProperty = (payload: any) => action(CABINET.DELETE_CABINET_PROPERTY, payload);
export const saveCabinetPropertyMeta = (payload: any) => action(CABINET.SAVE_CABINET_PROPERTY_META, payload);

export const getEdgeConfigProperties = () => action(CABINET.GET_EDGE_CONFIG_PROPERTIES);
export const setEdgeConfigProperties = (data: any) => action(CABINET.SET_EDGE_CONFIG_PROPERTIES, data);
export const updateEdgeConfigProperties = (payload: UpdateDeviceEdgePropertiesPayloadDTO, techType: string) =>
	action(CABINET.UPDATE_EDGE_CONFIG_PROPERTIES, { payload, techType });

export const setBackFromDetails = (payload: any) => action(CABINET.SET_BACK_FROM_DETAILS, payload);

export const setCabinetConfigError = (payload: any) => action(CABINET.SET_CABINET_CONFIG_ERROR, payload);

export const getContextCabinets = (payload: any) => action(CABINET.GET_CONTEXT_CABINETS, payload);
export const setContextCabinets = (payload: any) => action(CABINET.SET_CONTEXT_CABINETS, payload);

export const setCabinetProductPar = (payload: any) => action(CABINET.SET_CABINET_PRODUCT_PAR, payload);

export const getFlexConfig = (payload: any) => action(CABINET.GET_FLEX_CONFIG, payload);
export const setFlexConfig = (payload: any) => action(CABINET.SET_FLEX_CONFIG, payload);
export const flexResetDefault = (cabinetId: string) => action(CABINET.FLEX_RESET_DEFAULT, cabinetId);
export const setFlexProductList = (payload: any) => action(CABINET.SET_FLEX_PRODUCT_LIST, payload);
export const updateFlexConfig = (payload: any, cabinetId: string) =>
	action(CABINET.UPDATE_FLEX_CONFIG, { payload: payload, cabinetId: cabinetId });
export const sendManualReceipt = (payload: any) => action(CABINET.SEND_MANUAL_RECEIPT, payload);
export const sendManualCorrection = (payload: any) => action(CABINET.SEND_MANUAL_CORRECTION, payload);
export const sendInventorySync = (payload: any) => action(CABINET.INVENTORY_SYNC, payload);
export const getDeviceTags = (payload: any) => action(CABINET.GET_DEVICE_TAGS, payload);
export const storeDeviceTags = (payload: any) => action(CABINET.STORE_DEVICE_TAGS, payload);
export const setDeviceTags = (payload: any) => action(CABINET.SET_DEVICE_TAGS, payload);
export const getCabinetReceipts = (payload: any) => action(CABINET.GET_CABINET_RECEIPTS, payload);
export const setCabinetReceipts = (payload: any) => action(CABINET.SET_CABINET_RECEIPTS, payload);
export const rejectCabinetReceipt = (payload: any) => action(CABINET.REJECT_CABINET_RECEIPT, payload);

export const getSecureDeviceCompartments = (payload: any) => action(CABINET.GET_SECURE_DEVICE_COMPARTMENTS, payload)
