import * as React from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableRow, TableCell, Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import { AuditLogStyles } from './audit-logs-styles';
import MUIDataTable from 'mui-datatables';
import dataList from '../../constants/dataList';
import ReactJson from 'react-json-view';
import EmptyContent from '../../components/emptyContent';
import { UIAction } from '../../redux/actions';

const AuditLogsTable: React.FC<any> = (props: any) => {
	const classes = AuditLogStyles();
	const isFirstRender = React.useRef(true);
	const [pagination, setPagination] = React.useState({ offset: 0, limit: props.globalRowsPerPage, page: 0 } as any);
	const columns = [
		{
			name: 'customerId',
			label: 'Customer Id',
		},
		{
			name: 'resourceId',
			label: 'Resource Id',
		},
		{
			name: 'resourceType',
			label: 'Resource Type',
		},
		{
			name: 'service',
			label: 'Service',
		},
		{
			name: 'eventType',
			label: 'Event Type',
		},
		{
			name: 'initiatorType',
			label: 'Initiator Type',
		},
		{
			name: 'createdBy',
			label: 'Created By',
		},
		{
			name: 'createdAt',
			label: 'Created At',
		},
	];

	const expandedRowView = (row?: any) => {
		const data = row.data ? JSON.parse(row.data) : {};
		const colSpan = columns.length + 1;
		return (
			<TableRow>
				<TableCell colSpan={colSpan}>
					<Grid container>
						<Grid item xs={12}>
							<Card>
								<CardHeader
									titleTypographyProps={{ variant: 'subtitle1' }}
									className={classes.cardHeading}
									title="Changelog"
								/>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<ReactJson
												src={data}
												name={false}
												theme={props.selectedTheme == 'dark' ? 'monokai' : 'rjv-default'}
											/>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</TableCell>
			</TableRow>
		);
	};
	const [tableHeight, setTableHeight] = React.useState(0);

	// Calculate table body height as a percentage of the viewport height
	React.useEffect(() => {
		const viewportHeight = window.innerHeight;
		const percentageHeight = 80; // Adjust this value as needed
		const calculatedHeight = (percentageHeight / 100) * viewportHeight;
		setTableHeight(calculatedHeight);
	}, []);

	React.useEffect(() => {
		if (!isFirstRender.current) {
			props.fetchList(pagination);
		} else {
			isFirstRender.current = false;
		}
	}, [pagination]);

	return (
		<div>
			{props.auditLogList && props.auditLogList.length > 0 ? (
				<MUIDataTable
					data={props.auditLogList}
					columns={columns}
					options={{
						responsive: 'simple',
						filter: false,
						search: false,
						download: false,
						print: false,
						selectableRows: 'none',
						serverSide: true,
						count: props.totalAuditLogs,
						page: pagination.page,
						rowsPerPage: props.globalRowsPerPage,
						rowsPerPageOptions: dataList.PageLimitOptions,
						viewColumns: false,
						expandableRows: true,
						expandableRowsOnClick: true,
						textLabels: {
							body: {
								noMatch: 'Sorry, no matching records found',
							},
						},
						renderExpandableRow: (rowData: any, rowMeta: any) => expandedRowView(props.auditLogList[rowMeta.dataIndex]),
						onChangeRowsPerPage: (numberOfRows: number) => {
							setPagination({ ...pagination, limit: numberOfRows });
							props.setGlobalRowsPerPage(numberOfRows);
						},
						onTableChange: (tableAction: any, tableState: any) => {
							switch (tableAction) {
								case 'changePage':
									if (tableState.page > pagination.page) {
										setPagination({
											offset: pagination.offset + pagination.limit,
											limit: pagination.limit,
											page: tableState.page,
										});
									} else if (tableState.page < pagination.page) {
										setPagination({
											offset: tableState.page === 0 ? 0 : pagination.offset - pagination.limit,
											limit: pagination.limit,
											page: tableState.page,
										});
									}
									break;
								case 'changeRowsPerPage':
									setPagination({ offset: 0, limit: tableState.rowsPerPage, page: 0 });
									break;
							}
						},
						fixedHeader: true, // This locks the table headers at the top
						tableBodyHeight: tableHeight + 'px', // Set the height for the table body
					}}
				/>
			) : (
				<EmptyContent message="Select the filter criteria above to view associated users." />
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	auditLogList: state.audit && state.audit.auditList && state.audit.auditList.data ? state.audit.auditList.data : [],
	totalAuditLogs:
		state.audit && state.audit.auditList && state.audit.auditList.total_count ? state.audit.auditList.total_count : 0,
	selectedTheme: state.user.selectedTheme,
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsTable);
