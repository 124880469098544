import React from 'react';
import { connect } from 'react-redux';
import { ProductActions } from '../../../redux/actions';
import { Button, Grid, FormControl, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../../hooks/styles';
import Loading from '../../../components/loading';

const CustomerProductFormModal: React.FC<any> = props => {
	const classes = CommonStyles();
	const modalClasses = getModalClasses();
	const [modalStyle] = React.useState(getModalStyle());

	const getInitInputs = () => ({
		packQty: props.product?.packQty || null,
		active: props.product?.active || false,
		productName: props.product?.productName || '',
		weight: props.product?.weight || '',
		gtin: props.product?.gtin || '',
	});
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [submitError, setSubmitError] = React.useState<string>('');
	const [inputs, setInputs] = React.useState<any>(getInitInputs());
	const [formErrors, setFormErrors] = React.useState<any>({});

	const handleSetInputs = (key: string, value: any) => {
		setInputs({ ...inputs, [key]: value });
		setFormErrors({ ...formErrors, [key]: null });
		setSubmitError('');
	};

	const handleCancel = () => {
		props.closeModal();
	};

	const handleSave = () => {
		const errors: any = {};
		if (!props.product) {
			if (!inputs.ndc) {
				errors.ndc = 'A product ndc is required';
			}
			inputs.ndc = inputs.ndc ? inputs.ndc.trim() : '';
			const ndcRegexResults: any[] = [
				/^\d{5}-\d{3}-\d{2}$/.test(inputs.ndc),
				/^\d{5}-\d{4}-\d{1}$/.test(inputs.ndc),
				/^\d{4}-\d{4}-\d{2}$/.test(inputs.ndc),
			];
			if (!ndcRegexResults.some((result: boolean) => result === true)) {
				errors.ndc = 'Invalid NDC. Valid Formats: 5-3-2, 5-4-1, or 4-4-2';
			}
		}
		if (!inputs.productName) {
			errors.productName = 'A product name is required';
		}
		if ( isNaN(parseInt(inputs.packQty)) ) { 
			errors.packQty = 'Pack Qty is required';
		}

		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			props.setProductPayload(null);
			return;
		}

		setIsLoading(true);

		const onSuccess = () => {
			setIsLoading(false);
		};

		const onError = (errorMessage: string) => {
			setIsLoading(false);
			setSubmitError(errorMessage);
			props.setProductPayload(null);
		};

		const requestBody: any = props.product ? getEditRequestBody() : getAddRequestBody();

		delete requestBody.createdAt;
		delete requestBody.customerID;
		delete requestBody.poType;
		delete requestBody.crl;
		delete requestBody.maxFill;

		if (props.product) {
			const payload = {
				requestBody: requestBody,
				success: onSuccess,
				error: onError,
			};

			props.updateProduct(payload);
		} else {
			props.setProductPayload(requestBody);
		}
	};

	const getAddRequestBody = () => ({
		ndc: inputs.ndc,
		productName: inputs.productName,
		packQty: +inputs.packQty,
		active: inputs.active ? 1 : 0,
		source: props.customerId,
		weight: inputs.weight ? +inputs.weight : undefined,
		gtin: inputs.gtin ? inputs.gtin : undefined,
	});

	const getEditRequestBody = () => ({
		...props.product,
		...{
			cabinetID: undefined,
			source: props.customerId,
			productName: inputs.productName,
			doseType: inputs.doseType,
			packQty: +inputs.packQty,
			active: inputs.active ? 1 : 0,
			weight: inputs.weight ? +inputs.weight : undefined,
			gtin: inputs.gtin ? inputs.gtin : undefined,
		},
	});

	React.useEffect(() => {
		props.submit && handleSave();
	}, [props.submit]);

	return (
		<div>
			<Grid container>
				{!props.product && (
					<Grid item xs={12}>
						<FormControl className={classes.formControl}>
							<TextField
								label="NDC"
								variant="outlined"
								value={inputs.ndc || ''}
								error={formErrors.ndc ? true : false}
								placeholder="####-####-##"
								helperText={formErrors.ndc ? formErrors.ndc : 'Valid Formats: 5-3-2, 5-4-1, or 4-4-2'}
								onChange={event => {
									handleSetInputs('ndc', event.target.value);
								}}
								required
							/>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={12}>
					<FormControl className={classes.formControl}>
						<TextField
							label="Product name"
							variant="outlined"
							value={inputs.productName || ''}
							error={formErrors.productName ? true : false}
							helperText={formErrors.productName ? formErrors.productName : ''}
							onChange={event => {
								handleSetInputs('productName', event.target.value);
							}}
							required
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<TextField
							label="Pack Qty"
							variant="outlined"
							type="number"
							value={inputs.packQty || ''}
							error={formErrors.packQty ? true : false}
							helperText={formErrors.packQty ? formErrors.packQty : ''}
							onChange={event => {
								handleSetInputs('packQty', event.target.value);
							}}
							required
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl className={classes.formControl}>
						<TextField
							label="GTIN"
							variant="outlined"
							value={inputs.gtin}
							onChange={event => {
								handleSetInputs('gtin', event.target.value);
							}}
						/>
					</FormControl>
					<FormControl className={classes.formControl}>
						<TextField
							label="Weight (grams)"
							variant="outlined"
							type="number"
							value={inputs.weight || ''}
							onChange={event => {
								handleSetInputs('weight', event.target.value);
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl className={classes.formControl}>
						<FormControlLabel
							label={'Is Active'}
							control={
								<Switch
									name={'active'}
									onChange={() => {
										const currentValue = inputs.active ? true : false;
										handleSetInputs('active', !currentValue);
									}}
									checked={inputs.active ? true : false}
								/>
							}
						/>
					</FormControl>
				</Grid>
				{submitError && <p className={classes.errorMsg}>{submitError}</p>}
				{/* {isLoading ?
          <Loading message="" />
          :
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                className={classes.searchButton}
                style={{ marginTop: 12 }}
                onClick={handleSave}
              >
                SAVE
              </Button>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Button
                type="button"
                color="default"
                variant="contained"
                className={classes.cancelBtn}
                style={{ marginTop: 12, marginLeft: 0 }}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
            </FormControl>
          </Grid>} */}
			</Grid>
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
	updateProduct: (payload: any) => dispatch(ProductActions.updateProduct(payload)),
	saveNewProduct: (payload: any) => dispatch(ProductActions.saveNewProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProductFormModal);
