import { makeStyles, createStyles, Theme } from '@material-ui/core';

export function getModalStyle() {
	return {
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
		maxWidth: '600px',
		maxHeight: '100%',
		overflow: 'scroll',
	};
}

export function getOrderDetailModalStyle() {
	return {
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
		maxWidth: '1280px',
	};
}

export const getModalClasses = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			borderRadius: '3px',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		closeBtn: {
			position: 'absolute',
			top: '10px',
			right: '10px',
			cursor: 'pointer',
		},
	}),
);
