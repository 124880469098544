import FDI_LOGO from '../assets/images/FDI-logo.gif';
import FFF_LOGO from '../assets/images/fff_logo.png';
import RNI_LOGO from '../assets/images/rni-logo.svg';
import RNI_LOGO_DARK from '../assets/images/rni-logo-inverted.svg';
import RNI_CLOUD_LOGO from '../assets/images/rni-cloud-logo.svg';
import RNI_CLOUD_LOGO_DARK from '../assets/images/rni-cloud-logo-inverted.svg';
import RNI_LOGO_SM from '../assets/images/rni-logo.svg';
import RNI_LOGO_SM_DARK from '../assets/images/rni-logo-inverted.svg';

export const IMAGES_URL = {
	FDI_LOGO: FDI_LOGO,
	FFF_LOGO: FFF_LOGO,
	RNI_LOGO: localStorage.getItem('fdiPortal_themePreference') === 'dark' ? RNI_LOGO_DARK : RNI_LOGO,
	RNI_LOGO_SM: localStorage.getItem('fdiPortal_themePreference') === 'dark' ? RNI_LOGO_SM_DARK : RNI_LOGO_SM,
	RNI_CLOUD_LOGO: localStorage.getItem('fdiPortal_themePreference') === 'dark' ? RNI_CLOUD_LOGO_DARK : RNI_CLOUD_LOGO,
};
