import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import InvoiceHistoryComponent from '../invoice/invoice-history.component';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component="span">{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `invoice-tab-${index}`,
		'aria-controls': `invoice-tabpanel-${index}`,
	};
};

const InvoiceHistory = () => {
	const classes = DashboardStyles();
	const firstRender = useRef(true); 
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = React.useState(0);

	useEffect(() => {
		firstRender.current = false;
	}, [dispatch]);

	const handleTabChange = (event: any, newValue: number) => {
		setSelectedTab(newValue);
	};

	return ( 
		<BasePage title={Constants.PAGE_TITLE.INVOICE_HISTORY}>
			<div>
				<div className={classes.appBarSpacer}></div>
				<>
					<InvoiceHistoryComponent />
				</>
			</div>
		</BasePage>
	);
};

export default InvoiceHistory;