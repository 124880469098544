import * as React from 'react';
import {
	Button,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Paper,
	Chip,
	Zoom,
	FormControlLabel,
	Checkbox,
	Typography,
	Tooltip,
} from '@material-ui/core';
import AuthUser from '../../redux/lib/authUser';
import { UserManagementActions, UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import { getUserResources, getUserRoles } from './users.helper';
import { userModalStyles } from './users.styles';
import { Role } from '../../redux/models/user/user';
import csv from 'csvtojson';
import { setUserImportResult } from '../../redux/actions/user-management.actions';
import Loading from '../../components/loading';

function getImportModalStyle() {
	const modalTop = 50;
	const modalLeft = 50;

	return {
		top: `${modalTop}%`,
		left: `${modalLeft}%`,
		transform: `translate(-${modalTop}%, -${modalLeft}%)`,
		maxHeight: '100%',
		overflowX: 'scroll' as 'scroll',
	};
}

const useStyles = userModalStyles;

interface UserImportProps {
	error: boolean;
	authUser?: AuthUser;
	importResults: any[];
	importUsers: (payload: any) => void;
	close: () => void;
}

const UserImportForm: React.FC<UserImportProps> = props => {
	const importClasses = useStyles();
	const [file, setFile] = React.useState(null as any);
	const [importResources, setResources] = React.useState(getUserResources(props.authUser?.record.claims) as string[]);
	const [importRoles, setImportRoles] = React.useState([] as string[]);
	const [claims, setClaims] = React.useState({} as any);
	const [newImportClaimResource, setNewImportClaimResource] = React.useState('' as string);
	const [newClaimRole, setNewClaimRole] = React.useState('' as string);
	const [submitted, setSubmitted] = React.useState(false as boolean);
	const [formErrors, setFormErrors] = React.useState({} as any);
	const [isLoading, setIsLoading] = React.useState(false);
	const [copyTooltip, setCopyToolTip] = React.useState('Copy results to clipboard');

	React.useEffect(() => {
		if (isLoading) {
			setSubmitted(true);
		}
		if (submitted && !isLoading && !importResultsHasErrors()) {
			props.close();
		}
	}, [props]);

	const importResultsHasErrors = () => {
		if (props.importResults) {
			let errorCount = 0;
			props.importResults.forEach((result: any) => {
				if (!result.imported) {
					errorCount++;
				}
			});
			if (errorCount > 0) {
				return true;
			}
		}
		return false;
	};

	const handleFileSelect = (event: any) => {
		setFile(event.target.files[0]);
		setFormErrors({ ...formErrors, file: false });
		setSubmitted(false);
		setCopyToolTip('Copy results to clipboard');
	};

	const handleResourceSelect = (event: any) => {
		const value = event.target.value ?? '';
		if (!value) {
			setNewImportClaimResource('');
			setImportRoles([]);
			return;
		}
		setNewImportClaimResource(value);
		setImportRoles(getUserRoles(props.authUser?.record.claims, value));
	};

	const handleRoleSelect = (event: any) => {
		const value = event.target.value ?? '';
		if (!value) {
			setNewClaimRole('');
			return;
		}
		setNewClaimRole(value);
	};

	const handleAddClaim = () => {
		if (!newImportClaimResource || !newClaimRole) {
			return;
		}

		let newClaims = Object.assign({}, claims);
		newClaims[newImportClaimResource] = newClaimRole as Role;
		setClaims(newClaims);
		setFormErrors({ ...formErrors, claims: false });
		setSubmitted(false);
	};

	const handleDeletePerm = (resource: string) => {
		let newClaims = Object.assign({}, claims);
		delete newClaims[resource];
		setClaims(newClaims);
	};

	const submitUserForm = async () => {
		if (!formIsValid()) {
			return;
		}

		setIsLoading(true);
		setCopyToolTip('Copy results to clipboard');
		const fileAsText = await file.text();
		const jsonData = await csv().fromString(fileAsText);
		props.importUsers({
			jsonData: jsonData,
			claims: claims,
			callback: (results: any[]) => {
				setIsLoading(false);
			},
		});
	};

	const formIsValid = () => {
		let errors: any = {};

		if (!file) {
			errors.file = 'A csv file must be selected';
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const renderResultBox = () => {
		return (
			<pre className={importClasses.importResultBox}>
				{props.importResults.map((result: any, index: number) => {
					return result.imported ? (
						<span key={index} className={importClasses.importSuccess}>
							{result.userEmail} imported successfully<br></br>
						</span>
					) : (
						<span key={index} className={importClasses.createUserError}>
							{result.errors}
							<br></br>
						</span>
					);
				})}
			</pre>
		);
	};

	return (
		<div style={getImportModalStyle()} className={importClasses.paper}>
			<div>
				<h2>Import Users</h2>
				<form noValidate autoComplete="off">
					<FormControl className={importClasses.input}>
						<Typography>
							Select a csv file containing first name, last name, email, customer ID(s), and persona(s) for the users
							you'd like to import. Multiple customer IDs and personas can be included for a single user by separating
							them with the pipe "|" character.
						</Typography>
						<Button
							variant="contained"
							component="label"
							className={importClasses.addPermBtn}
							style={{ width: '150px', color: 'white' }}
						>
							Choose CSV File
							<input accept=".csv" type="file" hidden onChange={(e: any) => handleFileSelect(e)} />
						</Button>
						<span style={{ margin: '10px 0 0 10px', fontSize: '16px' }}>{file ? file.name : ''}</span>
						{formErrors.file && <div className={importClasses.permissionsError}>{formErrors.file}</div>}
					</FormControl>
					<h3>Additional User Permissions (Optional)</h3>
					<FormControl variant="filled" className={importClasses.input}>
						<InputLabel id="resource-select">Resource</InputLabel>
						<Select
							labelId="resource-select"
							value={newImportClaimResource}
							onChange={handleResourceSelect}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value={''}>
								<em>None</em>
							</MenuItem>
							{importResources.map((resource, index) => {
								return (
									<MenuItem key={index} value={resource}>
										{resource.charAt(0).toUpperCase() + resource.slice(1)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<FormControl variant="filled" className={importClasses.input}>
						<InputLabel id="role-select">Role</InputLabel>
						<Select
							labelId="role-select"
							value={newClaimRole}
							onChange={handleRoleSelect}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							<MenuItem value={''}>
								<em>None</em>
							</MenuItem>
							{importRoles.map((role, index) => {
								return (
									<MenuItem key={index} value={role}>
										{role}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<FormControl className={importClasses.input}>
						<Button className={importClasses.addPermBtn} variant="contained" color="secondary" onClick={handleAddClaim}>
							ADD
						</Button>
					</FormControl>
					{formErrors.claims && <div className={importClasses.permissionsError}>{formErrors.claims}</div>}
					{Object.keys(claims).length > 0 && (
						<Paper className={importClasses.permPaper}>
							{Object.keys(claims).map((resource, index) => {
								return (
									<FormControl key={index} className={importClasses.input}>
										<Chip
											label={`${resource}: ${claims[resource]}`}
											onDelete={() => {
												handleDeletePerm(resource);
											}}
										/>
									</FormControl>
								);
							})}
						</Paper>
					)}
					<br></br>
					{isLoading && <Loading message="Importing Users..." />}
					{submitted && props.importResults.length > 0 && (
						<>
							<p>
								<b>Import Results:</b>
							</p>
							{renderResultBox()}
							{isLoading ? (
								<></>
							) : (
								<Tooltip title={copyTooltip} placement="bottom-start">
									<div
										style={{ cursor: 'pointer' }}
										onClick={() => {
											let resultsText = ``;
											props.importResults.forEach((result: any) => {
												resultsText += result.imported ? `${result.userEmail} imported successfully` : result.errors;
												// add newline
												resultsText += `
`;
											});
											navigator.clipboard.writeText(resultsText);
											setCopyToolTip('Copied!');
										}}
									>
										Copy Results
									</div>
								</Tooltip>
							)}
						</>
					)}
					{isLoading ? (
						<></>
					) : (
						<>
							<FormControl className={importClasses.input}>
								<Button
									className={importClasses.addPermBtn}
									variant="contained"
									color="secondary"
									onClick={submitUserForm}
								>
									SUBMIT
								</Button>
							</FormControl>
							<FormControl className={importClasses.input}>
								<Button className={importClasses.cancelBtn} variant="contained" color="default" onClick={props.close}>
									CLOSE
								</Button>
							</FormControl>
						</>
					)}
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	error: state.userManagement.error,
	authUser: state.user.authUser,
	importResults: state.userManagement.importResults || [],
});

const mapDispatchToProps = (dispatch: any) => ({
	importUsers: (payload: any) => dispatch(UserManagementActions.importUsers(payload)),
	setSnackbarMessage: (payload: object) => dispatch(UserActions.setSnackbarMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserImportForm);
