import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const SupportStyles = makeStyles(theme => ({
	emailLink: {
		color: theme.palette.text.secondary,
	},
	formRowContainer: {
		marginBottom: 18,
	},
	formField: {
		width: 400,
	},
}));
