import * as React from 'react';
import { connect } from 'react-redux';
import {
	Button,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Paper,
	Chip,
	Zoom,
	FormControlLabel,
	Checkbox,
	Typography,
} from '@material-ui/core';
import { Persona } from '../../redux/models/persona/persona';
import { UserManagementActions } from '../../redux/actions';
import { Constants } from '../../constants/Constants';
import { CommonStyles } from '../../hooks/styles';
import { AlphaSortObject } from '../../hooks/functions/AlphaSortObject';
import DeleteIcon from '@material-ui/icons/Delete';
import { PersonaManagementStyles } from './persona-management.styles';
import EditIcon from '@material-ui/icons/Edit';
import { SelectBox } from '../../components/selectBox';
import ConfirmDialog from '../../components/dialog/confirm';

interface PersonaManagementControlProps {
	persona: Persona;
	handleRoleChange: Function;
	handlePersonaRoleRemoval: Function;
	handlePersonaSave: Function;
	addResourceToPersona: Function;
	isEditMode: boolean;
	setIsEditMode: Function;
	revertChanges: Function;
	handlePersonaFlagForDeletion: Function;
}

const PersonaManagementControl: React.FC<PersonaManagementControlProps> = props => {
	const styles = CommonStyles();
	const personaClasses = PersonaManagementStyles();
	const allRoles = Constants.ROLES;
	const [addRoleFormVisible, setAddRoleFormVisible] = React.useState(false);
	const [newResourceName, setNewResourceName] = React.useState('');
	const [newResourceRoleName, setNewResourceRoleName] = React.useState('');
	const [isThisPersonaModified, setIsThisPersonaModified] = React.useState(false);
	const [thisPersonaError, setThisPersonaError] = React.useState('');
	const [lastSavedVersion, setLastSavedVersion] = React.useState(null) as any;
	const [confirmDelete, setConfirmDelete] = React.useState(false);

	const showAddRoleForm = () => {
		setAddRoleFormVisible(true);
	};

	const hideAddRoleForm = () => {
		setAddRoleFormVisible(false);
	};

	const handleNewResourceRoleChange = (e: any) => {
		setNewResourceRoleName(e.target.value);
	};

	const handleNewResourceNameChange = (e: any) => {
		setNewResourceName(e.target.value);
	};

	const getReadOnlyRolesListHtml = (roles: any, personaName: string) => {
		const sortedRoles = AlphaSortObject(roles);

		return Object.keys(sortedRoles).length ? (
			<ul className={personaClasses.personaList}>
				{Object.keys(sortedRoles).map((roleKey: any, i) => {
					return (
						<li key={i} className={personaClasses.personaListItem}>
							<Chip label={`${roleKey}: ${sortedRoles[roleKey]} `} />
						</li>
					);
				})}
			</ul>
		) : (
			<div>No Roles</div>
		);
	};

	const getRolesListHtml = (roles: any, personaName: string) => {
		const sortedRoles = AlphaSortObject(roles);

		return Object.keys(sortedRoles).length ? (
			<ul className={personaClasses.personaList}>
				{Object.keys(sortedRoles).map((roleKey: any, i) => {
					return (
						<li key={i} className={personaClasses.personaListItemEdit}>
							<div className={personaClasses.roleKeyContainer}>{roleKey}:</div>
							<div className={personaClasses.roleSelectorContainer}>
								{constructRoleSelect(roleKey, sortedRoles[roleKey], personaName)}
							</div>
							<div className={personaClasses.roleDeleteContainer}>
								<DeleteIcon
									onClick={e => {
										props.handlePersonaRoleRemoval(e, roleKey, personaName);
										setIsThisPersonaModified(true);
									}}
								/>
							</div>
						</li>
					);
				})}
			</ul>
		) : (
			<div>No Roles</div>
		);
	};

	const constructRoleSelect = (roleName: string, roleValue: string, personaName: string) => {
		return (
			<Select
				onChange={e => {
					props.handleRoleChange(e, roleName, personaName);
					setIsThisPersonaModified(true);
				}}
				value={roleValue}
				style={{
					width: '90px',
				}}
			>
				{allRoles.map(roleChoice => {
					return (
						<option value={roleChoice} key={roleChoice}>
							{roleChoice}
						</option>
					);
				})}
			</Select>
		);
	};

	const handleAddResource = () => {
		if (!newResourceName || !newResourceRoleName) {
			setThisPersonaError('An error occurred: Please complete all fields.');
			return;
		}

		setThisPersonaError('');
		props.addResourceToPersona(props.persona.identifier, newResourceName, newResourceRoleName);
		setNewResourceName('');
		setNewResourceRoleName('');
		hideAddRoleForm();
		setIsThisPersonaModified(true);
	};

	return (
		<>
			{/*****************************************************
			 ********************************** read-only mode ****/}
			{!props.isEditMode && (
				<Paper className={personaClasses.personaBlock}>
					<div className={personaClasses.personaName}>
						<span>{props.persona.identifier}</span>
						<span className={personaClasses.personaEditWrapper}>
							<EditIcon
								onClick={() => {
									props.setIsEditMode(props.persona.identifier);
									//setLastSavedVersion(props.persona);
								}}
								className={personaClasses.personaEditIcon}
							/>
						</span>
					</div>
					<div>{getReadOnlyRolesListHtml(props.persona.roles, props.persona.identifier)}</div>
				</Paper>
			)}

			{/***************************************************************
			 ************* edit mode ***************************************/}
			{props.isEditMode && (
				<Paper className={personaClasses.personaBlock}>
					<div className={personaClasses.personaName}>{props.persona.identifier}</div>
					<div>{getRolesListHtml(props.persona.roles, props.persona.identifier)}</div>

					{
						addRoleFormVisible && (
							<div className={personaClasses.inputWrapper}>
								<div>
									<InputLabel shrink id="resource-select-label">
										Resource
									</InputLabel>
									<Select
										labelId="resource-select-label"
										onChange={handleNewResourceNameChange}
										value={newResourceName}
										style={{ width: 150, marginRight: 10 }}
									>
										{Constants.RESOURCES.map(resourceChoice => {
											if (Object.keys(props.persona.roles).indexOf(resourceChoice) === -1) {
												return (
													<option value={resourceChoice} key={resourceChoice}>
														{resourceChoice}
													</option>
												);
											}

											return null;
										})}
									</Select>
								</div>

								<div>
									<InputLabel shrink id="role-select-label">
										Role
									</InputLabel>
									<Select
										onChange={handleNewResourceRoleChange}
										value={newResourceRoleName}
										labelId="role-select-label"
										style={{ width: 150 }}
									>
										{allRoles.map(roleChoice => {
											return (
												<option value={roleChoice} key={roleChoice}>
													{roleChoice}
												</option>
											);
										})}
									</Select>
								</div>

								<Button
									onClick={e => handleAddResource()}
									className={styles.searchButton}
									variant="contained"
									color="primary"
								>
									Add Role
								</Button>
								<Button
									onClick={e => {
										setAddRoleFormVisible(false);
										setNewResourceName('');
										setNewResourceRoleName('');
										setThisPersonaError('');
									}}
									className={styles.searchButton}
									variant="contained"
									color="primary"
								>
									Cancel
								</Button>
							</div>
						)
						/* end addRole form */
					}

					<div className={personaClasses.personaButtonsRow}>
						{!addRoleFormVisible && (
							<Button onClick={showAddRoleForm} className={styles.searchButton} variant="contained" color="secondary">
								Add Role To Persona
							</Button>
						)}

						<Button
							onClick={() => {
								props.handlePersonaSave(props.persona.identifier);
								setThisPersonaError('');
								setIsThisPersonaModified(false);
							}}
							disabled={!isThisPersonaModified}
							className={styles.searchButton}
							variant="contained"
							color="primary"
						>
							Save Persona
						</Button>

						<Button
							onClick={e => {
								setConfirmDelete(true);
								setThisPersonaError('');
								setIsThisPersonaModified(false);
							}}
							className={styles.searchButton}
							variant="contained"
							color="primary"
						>
							Delete Persona
						</Button>
						<ConfirmDialog
							open={confirmDelete}
							handleClose={() => setConfirmDelete(false)}
							title="Are you sure?"
							content="This will remove the persona from view but users assigned this persona will still have access to these roles."
							handleYes={() => {
								props.handlePersonaFlagForDeletion({}, props.persona.identifier);
								props.handlePersonaSave(props.persona.identifier);
							}}
						/>

						<Button
							onClick={() => {
								props.setIsEditMode(null);
								setNewResourceName('');
								setNewResourceRoleName('');
								setAddRoleFormVisible(false);
								setThisPersonaError('');
								props.revertChanges(props.persona.identifier);
							}}
							className={styles.searchButton}
							variant="contained"
							color="primary"
						>
							Cancel
						</Button>
					</div>
					{thisPersonaError && <div className={personaClasses.errorMessage}>{thisPersonaError}</div>}
				</Paper>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PersonaManagementControl);
