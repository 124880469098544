import createReducer from './createReducer';
import { InventoryManagementActions } from '../actions';
import { INVENTORY_MANAGEMENT } from '../constants/actions';

export const inventoryManagement = createReducer(
	{},
	{
		[INVENTORY_MANAGEMENT.LIST_PAR_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.parInventoryRequestCompleteAction>,
		) {
			return { ...state, parInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.LIST_PAR_GROUP_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.parInventoryGroupRequestCompleteAction>,
		) {
			return { ...state, parInventoryGroup: action.payload };
		},
		[INVENTORY_MANAGEMENT.SET_INVENTORY_FILTER](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.setInventoryFilterAction>,
		) {
			return { ...state, inventoryFilter: action.payload };
		},
		[INVENTORY_MANAGEMENT.LIST_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.tenantInventoryRequestCompleteAction>,
		) {
			//TODO: some problem here as sometimes it sends action.payload.result, sometimes not RM
			//const objsToReturn = (action.payload && action.payload.result) ? action.payload.result || action.payload;
			return { ...state, tenantInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.CARTRIDGE_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.cartridgeInventoryRequestCompleteAction>,
		) {
			return { ...state, cartridgeInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.SERIAL_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.serialInventoryRequestCompleteAction>,
		) {
			return { ...state, serialInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.GROUPED_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.groupedInventoryRequestCompleteAction>,
		) {
			//TODO: some problem here as sometimes it sends action.payload.result, sometimes not RM
			//const objsToReturn = (action.payload && action.payload.result) ? action.payload.result || action.payload;
			return { ...state, groupedTenantInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.GROUPED_CARTRIDGE_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.groupedCartridgeInventoryRequestCompleteAction>,
		) {
			return { ...state, groupedCartridgeInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.GROUPED_SERIAL_INVENTORY_RESPONSE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.groupedSerialInventoryRequestCompleteAction>,
		) {
			return { ...state, groupedSerialInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.ERROR](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.inventoryManagementError>,
		) {
			return { ...state, error: action.payload };
		},
		[INVENTORY_MANAGEMENT.SET_COMPLETE_INVENTORY](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.setCompleteCustomerInventory>,
		) {
			return { ...state, completeInventory: action.payload };
		},
		[INVENTORY_MANAGEMENT.SET_SUSPENDED_TRANSACTIONS](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.setSuspendedTransactions>,
		) {
			return { ...state, suspendedTransactions: action.payload };
		},
		[INVENTORY_MANAGEMENT.CLEAR_TENANT_INVENTORY](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.tenantInventoryClearAction>,
		) {
			return {
				...state,
				tenantInventory: [],
				cartridgeInventory: [],
				serialInventory: [],
				parInventoryGroup: [],
				parInventory: [],
				suspendedTransactions: {},
			};
		},
		[INVENTORY_MANAGEMENT.SET_TAGS](state = {}, action: ReturnType<typeof InventoryManagementActions.setTags>) {
			return { ...state, tags: action.payload };
		},
		[INVENTORY_MANAGEMENT.SET_TAG_LIFECYCLE](
			state = {},
			action: ReturnType<typeof InventoryManagementActions.setTagLifecycle>,
		) {
			return { ...state, tagLifecycle: action.payload };
		},
		[INVENTORY_MANAGEMENT.SET_RESTOCK_RECOMMENDATIONS](state = {}, action: ReturnType<typeof InventoryManagementActions.setRestockRecommendations>) {
			return { ...state, restockRecommendations: action.payload };
		},
	},
);
