import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const ParUsageStyles = makeStyles(theme => ({
	card: {
		textAlign: 'center',
	},
	cardHeader: {
		//'background-color': theme.palette.grey[300]
	},
	tableHead: {
		//'background-color': theme.palette.grey[300]
	},
	tableTitle: {
		margin: '2em 0 0 0.5em',
		width: '100%',
	},
	tableWrap: {
		'margin-bottom': '2em',
	},
	searchWrap: {
		'margin-bottom': '2em',
		padding: 9,
	},
	tableCell: {
		textAlign: 'center',
	},
	cabinetModal: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	viewAllBtn: {
		margin: '12px 0 0 10px',
		backgroundColor: `${globalColors.FFF_BLUE} !important`,
		fontSize: '10px',
	},
	formControl: {
		marginBottom: '12px!important',
		marginLeft: 0,
		marginRight: '20px!important',
	},
}));

export const ParUsageTableTheme = (createMuiTheme as any)({
	overrides: {
		MuiTableSortLabel: {
			root: {
				'margin-top': '7px',
			},
		},
		MUIDataTableHeadCell: {
			root: {
				fontSize: 12,
			},
			hintIconAlone: {
				fontSize: '1.2em !important',
				marginTop: '3px !important',
				color: '#757575',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				fontSize: 12,
			},
		},
		MuiTypography: {
			h6: {
				fontSize: 14,
			},
		},
		'MuiTooltip-tooltip': {
			fontSize: '100px !important',
		},
	},
});
