import createReducer from './createReducer';
import { UserManagementActions } from '../actions';
import { USER_MANAGEMENT } from '../constants/actions';
import { Persona } from '../../redux/models/persona/persona';

export const userManagement = createReducer(
	{},
	{
		[USER_MANAGEMENT.LIST_RESPONSE](
			state = {},
			action: ReturnType<typeof UserManagementActions.getUserListCompleteAction>,
		) {
			return { ...state, userList: action.payload };
		},
		[USER_MANAGEMENT.ERROR](state = {}, action: ReturnType<typeof UserManagementActions.userErrorAction>) {
			return { ...state, error: action.payload };
		},
		[USER_MANAGEMENT.USER_EXISTS_RESPONSE](
			state = {},
			action: ReturnType<typeof UserManagementActions.userExistsCheckCompleteAction>,
		) {
			return { ...state, existingUser: action.payload };
		},
		[USER_MANAGEMENT.CLEAR_USER_CHECK](
			state = {},
			action: ReturnType<typeof UserManagementActions.clearUserCheckAction>,
		) {
			return { ...state, existingUser: null };
		},
		[USER_MANAGEMENT.SET_IMPORT_RESULT](
			state = {},
			action: ReturnType<typeof UserManagementActions.setUserImportResult>,
		) {
			return { ...state, importResults: action.payload };
		},

		[USER_MANAGEMENT.RESET_USERS_DATA](state = {}, action: ReturnType<typeof UserManagementActions.resetUsersData>) {
			return { ...state, userList: {} };
		},

		[USER_MANAGEMENT.SET_VIMEO_APP_AUTH_TOKEN_RESULT](
			state = {},
			action: ReturnType<typeof UserManagementActions.setVimeoAppAuthTokenResult>,
		) {
			return { ...state, vimeoAppToken: action.payload };
		},
		[USER_MANAGEMENT.SET_CREATE_USER_ERROR](
			state = {},
			action: ReturnType<typeof UserManagementActions.setCreateUserError>,
		) {
			return { ...state, createUserErrors: action.payload };
		},
		[USER_MANAGEMENT.SET_USER_IMPORT_SUCCESS](
			state = {},
			action: ReturnType<typeof UserManagementActions.setUserImportSuccess>,
		) {
			return { ...state, userImportSuccess: action.payload };
		},
		[USER_MANAGEMENT.SET_SINGLE_USER](state = {}, action: ReturnType<typeof UserManagementActions.setSingleUser>) {
			return { ...state, singleUser: action.payload };
		},
		[USER_MANAGEMENT.INVOICE_LINK_LIST_RESPONSE](
			state = {},
			action: ReturnType<typeof UserManagementActions.getInvoiceLinkUserListCompleteAction>,
		) {
			return { ...state, invoiceLinkUserList: action.payload };
		},
	},
);
