import { Tooltip } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

export const QtyRow = (value: string, modifiedValue?: string) => {
	return (
		<>
			<span>{value}</span>

			{modifiedValue && value !== modifiedValue && (
				<span
					style={{
						color: 'red',
					}}
				>
					({modifiedValue})
				</span>
			)}
		</>
	);
};

export const InventoryIcons: any = () => {
    return (
        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            <Tooltip title="Inventory count may be inaccurate due to a pending suspended transaction" placement="bottom-start">
                <InfoIcon/>
            </Tooltip>
        </div>
    );
};