import { connect } from 'react-redux';
import CabinetTemperatureComponent from './cabinet-temperature.component';
import { AlertActions, CabinetActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	alertsList: state.alerts.alertsList ? state.alerts.alertsList.data.result : [],
	cabinetTemperature: state.cabinet && state.cabinet.cabinetTemperature ? state.cabinet.cabinetTemperature : [],
	selectedFilterValues: state.ui.selectedFilterValues,
	selectedDateRange: state.ui.selectedDateRange,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	cabinetList: state.cabinet.cabinetCollection ? state.cabinet.cabinetCollection : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	clearCabinetList: () => dispatch(CabinetActions.clearCabinetList()),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	getAlerts: (filter: any) => dispatch(AlertActions.alertsRequestStartAction({ filter, showError: false })),
	getTemperature: (filter: any) => dispatch(CabinetActions.getTemperatureAction(filter)),
	getTemperatureCsv: (payload: {
		customerId: string;
		deviceId: number;
		timezone: string;
		startTime: string;
		endTime: string;
	}) => dispatch(CabinetActions.getTemperatureCsvAction(payload)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setCabinet: (cabinet: any) => dispatch(CabinetActions.setCabinet(cabinet)),
	setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIAction.setGlobalRowsPerPage(rowsPerPage)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	getSecureDeviceCompartments: (payload: any) => dispatch(CabinetActions.getSecureDeviceCompartments(payload)),
});

export const CabinetTemperatureContainer = connect(mapStateToProps, mapDispatchToProps)(CabinetTemperatureComponent);
