import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../../hooks/styles/muiTheme';

export const RestockOrderDetailsStyles = makeStyles(theme => ({
	titleWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	overallOrderStatusWrapper: {
		marginLeft: 40,
		display: 'flex',
		backgroundColor: '#000',
		color: '#fff',
		height: 40,
		fontSize: 24,
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 20px',
		fontWeight: 800,
	},
	statusBreakdownWrapper: {
		paddingBottom: 20,
		display: 'flex',
	},
	statusBreakdownNumberWrapper: {
		justifyContent: 'center',
		display: 'flex',
		marginLeft: 16,
		backgroundColor: '#000',
		color: '#fff',
		width: 'auto',
		padding: '0 8px',
		height: 30,
		fontSize: 20,
		fontWeight: 800,
		alignItems: 'center',
	},
	statusBreakdownLabel: {
		justifyContent: 'center',
		display: 'flex',
		fontSize: 18,
	},
	statusWarning: {
		backgroundColor: globalColors.ERROR_RED,
	},
	summaryRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	restockRequestStatusModalHeading: {
		width: 'fit-content',
		margin: '-28px 0 15px 0',
		padding: '0 5px',
		fontSize: '1.2em',
		backgroundColor: theme.palette.background.paper,
	},
	updateStatusButton: {
		fontSize: '10px',
	},
	cancelBtn: {
		backgroundColor: 'rgba(0, 0, 0, 0.26)',
		margin: '12px 0 0 10px',
		color: 'white',
		fontSize: '10px!important',
	},
}));
