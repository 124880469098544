import { Constants } from '../../constants/Constants';
import AuthUser from '../../redux/lib/authUser';

export const getCustomerDetailsPageTitle = (authUser: AuthUser) => {
	let title = Constants.PAGE_TITLE.CUSTOMER_DETAILS;
	if (!authUser.record || !authUser.record.claims || authUser.record.claims['ALL']) {
		return title;
	}

	return 'Account Details';
};
