import createReducer from './createReducer';
import { AuditActions } from '../actions';
import { AUDIT } from '../constants/actions';

export const audit = createReducer(
	{},
	{
		[AUDIT.AUDIT_LOG_LIST_RESPONSE](state = {}, action: ReturnType<typeof AuditActions.setAuditLogList>) {
			return { ...state, auditList: action.payload };
		},

		[AUDIT.SET_EVENT_TYPES](state = {}, action: ReturnType<typeof AuditActions.setEventTypes>) {
			const eventTypesList = action.payload.map((eventType: string) => ({label: eventType, value: eventType}))
			return { ...state, eventTypes: eventTypesList };
		},
	},
);
