import { connect } from 'react-redux';
import { ParUsageComponent } from './par-usage.component';
import { InventoryManagementActions, TenantsActions, UIAction, UserActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	inventory: state.inventoryManagement.parInventory ? state.inventoryManagement.parInventory.result : [],
	totalRecord: state.inventoryManagement.parInventory ? state.inventoryManagement.parInventory.total_record : 0,
	groups: state.inventoryManagement.parInventoryGroup ? state.inventoryManagement.parInventoryGroup.result : [],
	totalGroupRecord: state.inventoryManagement.parInventoryGroup
		? state.inventoryManagement.parInventoryGroup.total_record
		: 0,
	groupInventories: state.inventoryManagement.parInventoryGroup
		? state.inventoryManagement.parInventoryGroup.inventories
		: [],
	groupInventoryRecords: state.inventoryManagement.parInventoryGroup
		? state.inventoryManagement.parInventoryGroup.inventory_records
		: [],
	user: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	authUser: state.user.authUser,
	properties:
		state.user?.authUser?.record?.properties && state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	selectedFilterValues: state.ui.selectedFilterValues,
});

const mapDispatchToProps = (dispatch: any) => ({
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getInventory: (filter: any | null) => dispatch(InventoryManagementActions.parInventoryRequestStartAction(filter)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export const ParUsageContainer = connect(mapStateToProps, mapDispatchToProps)(ParUsageComponent);
