import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { CabinetActions } from '../../redux/actions';
import { Alert, Autocomplete } from '@material-ui/lab';
import Loading from '../../components/loading';
import { CabinetStyles } from './cabinet.styles';
import { initUserType } from '../users/users.helper';
import { startCase, camelCase } from 'lodash';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Switch,
	TextField,
	Tooltip,
	Zoom,
} from '@material-ui/core';
import { SelectBox } from '../../components/selectBox';
import { DashboardStyles } from '../../hooks/styles';
import { valueMapper } from '../../hooks/functions';
import { snakeCase } from 'lodash';
import { formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';

const cabinetStates = ['10', '20', '21', '22', '30', '31', '40', '50', '60', '70'];

const CabinetProperties: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const classes = CabinetStyles();
	const styles = DashboardStyles();

	const userType = initUserType(props.authUser.record.claims) === 'admin' ? 'admin' : 'user';
	const [propertyList, setPropertyList] = React.useState<any>(null);
	const [editablePropertyList, setEditablePropertyList] = React.useState<any>(null);
	const [propertyListError, setPropertyListError] = React.useState<boolean>(false);
	const [cabinetCustomer, setCabinetCustomer] = React.useState<any>(null);
	const [cabinetCustomerId, setCabinetCustomerId] = React.useState<string>(props.cabinet.customerId || '');
	const [tempControl, setCabinetTempControl] = React.useState<string>(props.cabinet.tempControl || '');
	const [cabinetType, setCabinetType] = React.useState<string>(props.cabinet.cabinetType || '');
	const [cabinetStatus, setCabinetStatus] = React.useState<string>(props.cabinet.cabinetState || '');
	const [cabinetName, setCabinetName] = React.useState<string>(props.cabinet.cabinetName || '');
	const [editedProperties, setEditedProperties] = React.useState<any>(null);
	const [formElementHeight, setFormElementHeight] = React.useState<any>(0);
	const [apiError, setApiError] = React.useState<any>(null);
	const [formErrors, setFormErrors] = React.useState<any>({});
	const [submitting, setSubmitting] = React.useState<boolean>(false);
	const [refreshingProperties, setRefreshingProperties] = React.useState<boolean>(false);

	const fetchCabinetTypeProperties = () => {
		props.getCabinetTypeProperties({
			cabinetType: cabinetType,
			success: () => {
				setSubmitting(false);
				setRefreshingProperties(false);
			},
			error: () => {
				setSubmitting(false);
				setRefreshingProperties(false);
				setPropertyListError(true);
			},
		});
	};

	React.useEffect(() => {
		fetchCabinetTypeProperties();
	}, []);

	React.useEffect(() => {
		if (props.editMode) setFormElementHeight('auto');
		if (!props.editMode) {
			setTimeout(() => {
				setFormElementHeight(0);
			}, 100);
		}
	}, [props.editMode]);

	React.useEffect(() => {
		props.cabinetTypeProperties && initPropertyList(props.cabinetTypeProperties);
	}, [props.cabinetTypeProperties, props.editMode]);

	React.useEffect(() => {
		if (!cabinetType) return;
		setRefreshingProperties(true);
		fetchCabinetTypeProperties();
	}, [cabinetType]);

	const initPropertyList = (properties: any[]) => {
		const editablePropertyList = properties.filter((property: any) => {
			if (property.editable) {
				if (userType === 'admin') return true;
				if (property.editable_by === 'user') return true;
			}
			return false;
		});
		const propertiesMissingMeta = getPropertiesMissingMeta(properties);
		if (propertiesMissingMeta) {
			properties = [...properties, ...propertiesMissingMeta];
		}
		properties = properties.filter((property: any) => {
			return property.cabinet_type === cabinetType;
		});

		setPropertyList(properties);
		setCabinetCustomerId(props.cabinet.customerId);
		setCabinetTempControl(props.cabinet.tempControl);
		setCabinetCustomer(props.tenants.find((tenant: any) => tenant.customerId === props.cabinet.customerId));
		setCabinetType(cabinetType);
		setCabinetStatus(props.cabinet.cabinetState);
		setCabinetName(props.cabinet.cabinetName);
		setEditablePropertyList(editablePropertyList);
		initEditedProperties(editablePropertyList);
	};

	const getPropertiesMissingMeta = (properties: any[]) => {
		const propertiesWithMeta = properties.map((property: any) => {
			return property.property_key;
		});
		const allPropertiesOnCabinet = Object.keys(props.cabinet.cabinetProperties);
		const propertiesMissingMeta = allPropertiesOnCabinet.filter((propertyOnCabinet: string) => {
			return propertiesWithMeta.includes(propertyOnCabinet) ? false : true;
		});
		return (
			propertiesMissingMeta.map((propertyMissingMeta: string) => {
				return { property_key: propertyMissingMeta };
			}) || null
		);
	};

	const initEditedProperties = (properties: any[]) => {
		let initEditedProperties: any = {};
		properties.forEach((property: any) => {
			if (property.editable) {
				initEditedProperties[property.property_key] = props.cabinet.cabinetProperties[camelCase(property.property_key)];
			}
		});
		setEditedProperties(initEditedProperties);
	};

	const onPropertyValueChange = (propertyKey: string, newValue: any) => {
		setApiError(null);
		if (!props.cabinet.cabinetProperties[propertyKey] && !newValue) {
			let editedPropertiesCopy = { ...editedProperties };
			delete editedPropertiesCopy[propertyKey];
			setEditedProperties(editedPropertiesCopy);
			return;
		}
		setEditedProperties({ ...editedProperties, [propertyKey]: newValue || null });
	};

	const saveProperties = () => {
		if (Object.keys(formErrors).some((errorKey: string) => formErrors[errorKey])) {
			return;
		}

		setSubmitting(true);

		let cabinetData: any = { ...props.cabinet };
		for (const key in cabinetData) {
			if (cabinetData[key] === null) {
				delete cabinetData[key];
			}
		}
		cabinetData.customerId = cabinetCustomerId || '';
		cabinetData.tempControl = tempControl || '';
		cabinetData.cabinetType = cabinetType || '';
		cabinetData.cabinetState = cabinetStatus || '';
		cabinetData.cabinetName = cabinetName || '';

		const allProperties = { ...cabinetData.cabinetProperties, ...editedProperties };
		delete cabinetData.cabinetProperties;
		const parsedProperties: any = {};
		Object.keys(allProperties).forEach((propertyKey: string) => {
			const parsedPropertyKey = snakeCase(propertyKey);
			const propertyValue = props.cabinetTypeProperties.find((typeProp: any) => typeProp.property_key === propertyKey)
				? allProperties[propertyKey]
				: null;
			parsedProperties[parsedPropertyKey] = propertyValue;
		});
		if (parsedProperties['consignment'] && parsedProperties['consignment'] === true) {
			parsedProperties['consignment'] = 1;
		}
		cabinetData.properties = parsedProperties;
		const payload: any = {
			data: cabinetData,
			success: () => {
				setSubmitting(false);
				props.closeEditMode(true, cabinetCustomerId);
				setEditablePropertyList(null);
			},
			error: () => {
				setSubmitting(false);
				setApiError('Failed to save device properties.');
			},
		};
		props.saveCabinetProperties(payload);
	};

	const getPropertySelectBoxOptions = (property: any) => {
		return property.values.split(',').map((value: string) => {
			return { label: formatCabinetPropertyValue(property.property_key, value), value: value };
		});
	};

	const getPropertyInputField = (property: any, index: number) => {
		if (property.values) {
			const propertySelectBoxOptions = getPropertySelectBoxOptions(property);
			return props.editMode ? (
				<Grid item xs={6} sm={3} style={{ height: formElementHeight }} key={index}>
					<FormControl key={index} variant="outlined" className={classes.formControl}>
						<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
							<SelectBox
								inputLabel={startCase(property.property_key)}
								emptyItemLabel={'Choose'}
								listItems={propertySelectBoxOptions}
								onChangeItem={value => onPropertyValueChange(property.property_key, value)}
								selected={editedProperties[property.property_key]}
								style={{ width: '200px' }}
							/>
						</Box>
					</FormControl>
				</Grid>
			) : null;
		}

		if (property.value_type === 'boolean') {
			return props.editMode ? (
				<Grid item xs={6} sm={3} style={{ height: formElementHeight }} key={index}>
					<FormControl key={index} className={classes.formControl}>
						<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
							<FormControlLabel
								control={
									<Switch
										checked={
											editedProperties[property.property_key] === true ||
											editedProperties[property.property_key] === '1' ||
											editedProperties[property.property_key] === 'true'
												? true
												: false
										}
										onChange={() => {
											const currentValue =
												editedProperties[property.property_key] === true ||
												editedProperties[property.property_key] === '1' ||
												editedProperties[property.property_key] === 'true'
													? true
													: false;
											const newValue = !currentValue;
											onPropertyValueChange(property.property_key, newValue);
										}}
										name={property.property_key}
									/>
								}
								label={startCase(property.property_key)}
							/>
						</Box>
					</FormControl>
				</Grid>
			) : null;
		}

		return props.editMode ? (
			<Grid item xs={6} sm={3} style={{ height: formElementHeight }} key={index}>
				<FormControl key={index} variant="outlined" className={classes.formControl}>
					<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
						<TextField
							style={{ width: 200 }}
							type={property.value_type === 'string' ? 'text' : 'number'}
							label={startCase(property.property_key)}
							value={editedProperties[property.property_key]}
							onChange={e => onPropertyValueChange(property.property_key, e.target.value)}
							placeholder=""
							variant="outlined"
						/>
					</Box>
				</FormControl>
			</Grid>
		) : null;
	};

	const getPropertyValue = (property: any) => {
		let value = props.cabinet.cabinetProperties[camelCase(property.property_key)];
		if (value && property.value_type === 'boolean') {
			value = value === '1' || value === 'true' ? 'True' : 'False';
		}

		return value ? formatCabinetPropertyValue(property, value) : null;
	};

	const renderPropertyViewOnly = () => {
		return (
			<>
				{propertyList ? (
					propertyList.map((property: any, index: number) => {
						const propertyValue = getPropertyValue(property);
						if (!propertyValue) return <span key={index}></span>;
						if (props.editMode && property.editable) return <span key={index}></span>;
						if (property.visible_to === 'admin' && !props.authUser.record.claims['ALL'])
							return <span key={index}></span>;
						return (
							<Grid item xs={6} sm={3} key={index}>
								<div className={styles.detailTitle}>{startCase(property.property_key)}</div>
								<div className={styles.detailValue}>{propertyValue}</div>
							</Grid>
						);
					})
				) : (
					<>
						{propertyListError ? (
							<Alert severity="error">Failed to load properties for this cabinet.</Alert>
						) : (
							<>{!props.isLoading && <Loading message="" />}</>
						)}
					</>
				)}
			</>
		);
	};

	const renderPropertyEditForm = () => {
		return (
			<>
				{props.editMode && (
					<Grid item xs={6} sm={3} style={{ height: formElementHeight }}>
						<FormControl variant="outlined" className={classes.formControl}>
							<Autocomplete
								id="customer-combo-box"
								options={props.tenants}
								getOptionLabel={(option: any) => '(' + option.customerId + ') ' + option.name}
								value={cabinetCustomer || null}
								style={{ width: 280 }}
								onChange={(e: any, value: any) => {
									setApiError(null);
									setCabinetCustomerId(value?.customerId || '');
									setCabinetCustomer(value);
									let error = false;
									if (!value) {
										error = true;
									}
									setFormErrors({ ...formErrors, cabinetCustomerId: error });
								}}
								renderInput={(params: any) => (
									<TextField
										value={cabinetCustomer}
										{...params}
										label="Search Customers"
										variant="filled"
										error={formErrors.cabinetCustomerId ? true : false}
										helperText={formErrors.cabinetCustomerId ? 'A customer id is required' : ''}
									/>
								)}
							/>
						</FormControl>
					</Grid>
				)}
				{/* Leaving in case cabinet type is to be editable in the future.  Will remove eventually if not. */}
				{/* {props.editMode && <Grid item xs={6} sm={3} style={{ height: formElementHeight }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <SelectBox
              style={{ width: 200 }}
              inputLabel="Cabinet Type"
              emptyItemLabel={'Choose'}
              listItems={cabinetTypes.map((type: string) => ({ 'label': valueMapper(type, 'cabinetTypes'), 'value': type }))}
              onChangeItem={(value: string) => {
                setApiError(null)
                setCabinetType(value)
                let error = false
                if (!value) {
                  error = true
                }
                setFormErrors({ ...formErrors, cabinetType: error })
              }}
              error={formErrors.cabinetType}
              errorText={formErrors.cabinetType ? 'A cabinet type is required' : ''}
              selected={cabinetType}
            />
          </FormControl>
        </Grid>} */}
				{props.editMode && (
					<Grid item xs={6} sm={3} style={{ height: formElementHeight }}>
						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel="Temp Control"
								emptyItemLabel={'Choose'}
								listItems={[
									{ label: 'Ambient', value: 'Ambient' },
									{ label: '2° - 8° C', value: '2-8c' },
									{ label: 'Frozen', value: 'Frozen' },
								]}
								onChangeItem={(value: string) => {
									setApiError(null);
									setCabinetTempControl(value);
									let error = false;
									if (!value) {
										error = true;
									}
									setFormErrors({ ...formErrors, tempControl: error });
								}}
								error={formErrors.tempControl}
								errorText={formErrors.tempControl ? 'Temperature Control is required' : ''}
								selected={tempControl}
							/>
						</FormControl>
					</Grid>
				)}
				{props.editMode && (
					<Grid item xs={6} sm={3} style={{ height: formElementHeight }}>
						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel="Cabinet Status"
								emptyItemLabel={'Choose'}
								listItems={cabinetStates.map((state: string) => ({
									label: valueMapper(state, 'cabinetStates'),
									value: state,
								}))}
								onChangeItem={(value: string) => {
									setApiError(null);
									setCabinetStatus(value);
									let error = false;
									if (!value) {
										error = true;
									}
									setFormErrors({ ...formErrors, cabinetStatus: error });
								}}
								error={formErrors.cabinetStatus}
								errorText={formErrors.cabinetStatus ? 'A cabinet status is required' : ''}
								selected={cabinetStatus}
							/>
						</FormControl>
					</Grid>
				)}
				{props.editMode && (
					<Grid item xs={6} sm={3} style={{ height: formElementHeight }}>
						<FormControl variant="outlined" className={classes.formControl}>
							<TextField
								style={{ width: 200 }}
								type="text"
								label="Cabinet Name"
								value={cabinetName}
								onChange={e => {
									setApiError(null);
									setCabinetName(e.target.value);
									// leaving in case we want to force cabinet name to be set
									// let error = false
									// if (!e.target.value) {
									//   error = true
									// }
									// setFormErrors({ ...formErrors, cabinetName: error })
								}}
								placeholder="Enter Cabinet Name"
								variant="outlined"
								// error={formErrors.cabinetName ? true : false}
								// helperText={formErrors.cabinetName ? 'A cabinet name is required' : ''}
							/>
						</FormControl>
					</Grid>
				)}
				{props.editMode && refreshingProperties ? (
					<Loading message="Refreshing Properties..." />
				) : (
					<>
						{editablePropertyList &&
							editablePropertyList.map((property: any, index: number) => {
								return getPropertyInputField(property, index);
							})}
					</>
				)}
			</>
		);
	};

	return (
		<>
			{renderPropertyViewOnly()}
			{renderPropertyEditForm()}
			{props.editMode && (
				<>
					<Box width="100%" height="0" />
					{apiError && (
						<>
							<p className={classes.cabinetFormError}>{apiError}</p>
							<Box width="100%" height="0" />
						</>
					)}
					{!refreshingProperties && (
						<>
							{!submitting ? (
								<>
									<FormControl>
										<Button
											onClick={saveProperties}
											type="button"
											className={classes.searchButton}
											variant="contained"
											color="primary"
										>
											SAVE
										</Button>
									</FormControl>
									<FormControl>
										<Button
											className={classes.cancelPropertyEditBtn}
											variant="contained"
											color="default"
											onClick={() => {
												props.closeEditMode(false);
												setCabinetType(props.cabinet.cabinetType);
												setFormErrors({});
											}}
										>
											CANCEL
										</Button>
									</FormControl>
								</>
							) : (
								<Grid item xs={1}>
									<Loading message="" />
								</Grid>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	cabinetTypeProperties: state.cabinet.cabinetTypePropertyList,
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetTypeProperties: (payload: any) => dispatch(CabinetActions.getPropertiesByCabinetType(payload)),
	saveCabinetProperties: (payload: any) => dispatch(CabinetActions.saveCabinetProperties(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetProperties);
