import { action } from 'typesafe-actions';
import { PRODUCTS } from '../constants/actions';

export const getProductList = (filter: any) => action(PRODUCTS.LIST_REQUEST, filter);
export const setProductList = (data: any) => action(PRODUCTS.LIST_RESPONSE, data);
export const getProductAutoCompleteList = (filter: any) => action(PRODUCTS.LIST_AUTOCOMPLETE_REQUEST, filter);
export const setAutoCompleteProductList = (data: any) => action(PRODUCTS.LIST_AUTOCOMPLETE, data);
export const getAllProductsList = () => action(PRODUCTS.GET_ALL_PRODUCTS_LIST);
export const setAllProductsList = (payload: any) => action(PRODUCTS.SET_ALL_PRODUCTS_LIST, payload);
export const getProductGroups = (payload: any) => action(PRODUCTS.GET_PRODUCT_GROUPS, payload);
export const setProductGroups = (data: any) => action(PRODUCTS.SET_PRODUCT_GROUPS, data);
export const saveNewProduct = (payload: any) => action(PRODUCTS.SAVE_NEW_PRODUCT, payload);
export const updateProduct = (payload: any) => action(PRODUCTS.UPDATE_PRODUCT, payload);
