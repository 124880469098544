import * as React from 'react';
import { CommonStyles } from '../../hooks/styles';
import { Paper, Grid, ListItem, List } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { CabinetActions, ProductActions } from '../../redux/actions';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ManualReceiptComponent from './commands/manual-receipt.component';
import ManualCorrectionComponent from './commands/manual-correction.component';
import InventorySyncComponent from './commands/inventory-sync.component';

const moment = extendMoment(Moment as any);

enum CommandNames {
	manualReceipt = 'MANUAL_RECEIPT',
	manualCorrection = 'MANUAL_CORRECTION',
	inventorySync = 'INVENTORY_SYNC',
}

const CabinetCommandsComponent: React.FC<any> = (props: any) => {
	const classes = CommonStyles();
	const { cabinetId, customerId } = useParams<any>();
	const [activeCommand, setActiveCommand] = React.useState<CommandNames>(CommandNames.manualReceipt);

	React.useEffect(() => {
		if (props.authUser && !props.flexCabinetProductList) {
			props.getFlexConfig(cabinetId, customerId);
		}
	}, [props.authUser]);

	const getManualReceiptForm = () => (
		<ManualReceiptComponent
			flexCabinetProductList={props.flexCabinetProductList}
			sendManualReceipt={props.sendManualReceipt}
		/>
	);

	const getManualCorrectionForm = () => (
		<ManualCorrectionComponent
			authUser={props.authUser}
			flexConfig={props.flexConfig}
			sendManualCorrection={props.sendManualCorrection}
		/>
	);

	const getInventorySyncForm = () => (
		<InventorySyncComponent
			flexCabinetProductList={props.flexCabinetProductList}
			sendInventorySync={props.sendInventorySync}
		/>
	);

	return (
		<>
			<div>
				<Paper
					style={{
						padding: 20,
					}}
				>
					<Grid container>
						<Grid item xs={2}>
							<div>
								<List>
									<ListItem
										className={activeCommand === CommandNames.manualReceipt ? classes.menuItemActive : classes.menuItem}
										onClick={() => {
											setActiveCommand(CommandNames.manualReceipt);
										}}
									>
										Manual Receipt
									</ListItem>
									<ListItem
										className={
											activeCommand === CommandNames.manualCorrection ? classes.menuItemActive : classes.menuItem
										}
										onClick={() => {
											setActiveCommand(CommandNames.manualCorrection);
										}}
									>
										Manual Correction
									</ListItem>
									<ListItem
										className={activeCommand === CommandNames.inventorySync ? classes.menuItemActive : classes.menuItem}
										onClick={() => {
											setActiveCommand(CommandNames.inventorySync);
										}}
									>
										Inventory Sync
									</ListItem>
								</List>
							</div>
						</Grid>
						<Grid item xs={10}>
							<div style={{ marginLeft: 20 }}>
								{activeCommand === CommandNames.manualReceipt && getManualReceiptForm()}
								{activeCommand === CommandNames.manualCorrection && getManualCorrectionForm()}
								{activeCommand === CommandNames.inventorySync && getInventorySyncForm()}
							</div>
						</Grid>
					</Grid>
				</Paper>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	autoCompleteProducts: state.product.autoCompleteList?.result || [],
	flexCabinetProductList: state.cabinet.flexCabinetProductList,
	flexConfig: state.cabinet.cabinetFlexConfig,
});

const mapDispatchToProps = (dispatch: any) => ({
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	sendManualReceipt: (payload: any) => dispatch(CabinetActions.sendManualReceipt(payload)),
	sendManualCorrection: (payload: any) => dispatch(CabinetActions.sendManualCorrection(payload)),
	sendInventorySync: (payload: any) => dispatch(CabinetActions.sendInventorySync(payload)),
	getFlexConfig: (cabinetId: string, customerId: string) =>
		dispatch(
			CabinetActions.getFlexConfig({
				cabinetId: cabinetId,
				customerId: customerId,
			}),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetCommandsComponent);
