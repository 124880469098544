import Api from '../lib/api';
import TimestampNormalizer from '../lib/timestamp-normalizer';
import { ALERTS } from '../constants/api.url';

export class AlertsService {
	private static instance: AlertsService;
	private constructor() {}

	public static getInstance(): AlertsService {
		if (!AlertsService.instance) {
			AlertsService.instance = new AlertsService();
		}
		return AlertsService.instance;
	}

	public getAlerts = async (filter?: any): Promise<any> => {
		const params = [{ key: 'filter', value: filter }];
		const response = await Api.get(ALERTS.URL, params);
		return this.transformAlerts(response);
	};

	public getNotificationTypes = async (): Promise<any> => {
		return await Api.get(ALERTS.NOTIFICATION_TYPES);
	};

	public getChannelTypes = async (): Promise<any> => {
		return await Api.get(ALERTS.CHANNEL_TYPES);
	};

	public submitChannelGroups = async (payload: any): Promise<any> => {
		Api.put(`${ALERTS.CHANNEL_GROUPS}/${payload.customerId}/${payload.notificationType}`, payload.data);
	};

	public deleteChannelGroups = async (payload: any): Promise<any> => {
		Api.delete(`${ALERTS.CHANNEL_GROUPS}/${payload.customerId}/${payload.notificationType}`, null);
	};

	public getChannelGroups = async (customerId: string): Promise<any> => {
		const response = await Api.get(`${ALERTS.CHANNEL_GROUPS}/${customerId}`);
		return this.addNotificationTypeNames(response.data);
	};

	public getIncidents = async (payload: any): Promise<any> => {
		payload.map((param: any) => {
			if (param.key == 'options') {
				param.value = JSON.stringify(param.value);
			}
		});
	 
		const response = await Api.get(ALERTS.INCIDENTS, payload);
		const response2 = this.transformIncidents(response);
		return response2;
	};

	public getIncidentHistory = async (incidentId: number): Promise<any> => {
		return Api.get(`${ALERTS.INCIDENTS}/history/${incidentId}`);
	};

	public getIncidentRules = async (params: any): Promise<any> => {
		return Api.get(ALERTS.INCIDENT_RULES, params);
	};

	public updateIncident = async (incidentId: number, payload: any): Promise<any> => {
		return Api.post(`${ALERTS.INCIDENT}/${incidentId}`, payload);
	};

	private addNotificationTypeNames = (array: any[]) => {
		return array.map((element: any) => {
			if (element.notificationType) {
				const typeArray = element.notificationType.split('_');
				element.notificationTypeName = typeArray
					.map((word: string) => {
						return word[0].toUpperCase() + word.substring(1);
					})
					.join(' ');
			}
			return element;
		});
	};

	private transformIncidents = (response: any) => {
		const { result} = response.data;
		const savedCount = response.data.total_record.count;
	    result.map((alert: any) => {
			if (alert.alertType === "discrepancy"  &&
			    alert.alertDetails[0]?.reason !== undefined) {
				alert.alertType += " - " + alert.alertDetails[0]?.reason; 
			}
			return alert;
		});
		const data = { 
			result: result, 
			total_record: 
				{
				  count:savedCount
				}  
		};
		return {...response, data: data}
	}

	private transformAlerts = (response: any) => {
		const { result } = response.data;
		result.map((alert: any) => {
			alert.cabinetType = alert.sourceType.type;
			alert.message = alert.alertType?.alertType || null;
			return alert;
		});
		const timestampNormalizer = new TimestampNormalizer(result);
		const resultTimestampsConverted = timestampNormalizer.convertToLocal('alertTimestamp');
		const data = { result: resultTimestampsConverted, total_record: response.data.total_record.count };
		return { ...response, data: data };
	};
}
