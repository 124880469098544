import moment from 'moment';
import { UserProperty } from '../../redux/models/user/user';
import { getTimezoneOffset } from 'date-fns-tz';
import { GetUserTimezone } from './GetUserTimezone';
import { addMinutes } from 'date-fns';

export const ConvertDateTimeForRequest = (properties: UserProperty[], dateTime: any) => {
	const originalDateTime = new Date(dateTime);
	const localTimezoneOffset = getTimezoneOffset(moment.tz.guess()) / 1000 / 60;
	const userTimezoneOffset = getTimezoneOffset(GetUserTimezone(properties)) / 1000 / 60;
	const timezoneOffsetDifferece = localTimezoneOffset - userTimezoneOffset;
	const offsetDateTime = addMinutes(originalDateTime, timezoneOffsetDifferece);
	return offsetDateTime.toISOString();
};
