import * as React from 'react';
import { getModalClasses, getModalStyle, getMuiTheme } from '../../hooks/styles';
import {
	Button,
	FormControl,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Modal,
	MuiThemeProvider,
	Paper,
	TextField,
	Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MUIDataTable from 'mui-datatables';
import { CabinetActions, UserActions } from '../../redux/actions';
import { connect } from 'react-redux';
import EmptyContent from '../../components/emptyContent';
import Loading from '../../components/loading';
import dataList from '../../constants/dataList';
import { useMediaQuery } from 'react-responsive';
import { CabinetStyles } from './cabinet.styles';
import { constructEnqueueParams, findUserSelectedColumns } from '../../helpers/report-queue.helper';
import { GetUserTimezone } from '../../hooks/functions';

const fieldOptions = {
	display: true,
	change: true,
};

const formatExpDate = (value: any) => {
	return value ? value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6) : '';
};

const renderShipmentDate = (value: any) => {
	return value ? value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6) : '';
};

const addSuspendedToolTip = (value: any, tableMeta: any) => {
	if (tableMeta.rowData[8]) {
		return (
			<div>
				<b>{value}</b>&nbsp;
				<Tooltip title="Reported on hand inventory does not match expected on hand inventory for this product lot.">
					<ErrorIcon style={{ color: '#f44336', verticalAlign: -3, fontSize: 16 }} />
				</Tooltip>
			</div>
		);
	}
	return value;
};

const ActionsMenu: React.FC<any> = props => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
	if (!props.actions.receiveShipment && !props.actions.trackShipment) {
		return <></>;
	}
	return (
		<div>
			<IconButton
				style={{ padding: 0 }}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={e => {
					setMenuOpen(!menuOpen);
					setMenuAnchor(e.currentTarget);
					props.actions.setSuspended(false);
				}}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={menuAnchor}
				keepMounted
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
					setMenuAnchor(null);
				}}
				PaperProps={{
					style: {
						height: 'auto',
						width: '20ch',
					},
				}}
				MenuListProps={{ id: menuOpen ? 'shipment-actions' : '' }}
			>
				{props.actions.trackShipment && (
					<MenuItem
						id={menuOpen ? 'track-shipment' : ''}
						onClick={() => {
							props.actions.trackShipment(props.dataIndex);
							setMenuOpen(false);
						}}
					>
						Track
					</MenuItem>
				)}
				{props.actions.receiveShipment && (
					<MenuItem
						id={menuOpen ? 'receive-shipment' : ''}
						onClick={() => {
							props.actions.receiveShipment(props.dataIndex);
							setMenuOpen(false);
						}}
					>
						Receive
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};

const getColumns = (getActions: any) => {
	return [
		{
			label: 'Order Number',
			name: 'orderNumber',
			options: fieldOptions,
		},
		{
			label: 'Shipment ID',
			name: 'shipmentId',
			options: { ...fieldOptions, display: false },
		},
		{
			label: 'Tracking Number',
			name: 'trackingNumber',
			options: { ...fieldOptions, display: false },
		},
		{
			label: 'Customer Id',
			name: 'customerNumber',
			options: fieldOptions,
		},
		{
			label: 'Product Description',
			name: 'factorDescription',
			options: fieldOptions,
		},
		{
			label: 'Shipped Quantity',
			name: 'numPkgsShipped',
			options: {
				...fieldOptions,
				customBodyRender: (val: any) => {
					return val ? parseInt(val) : '';
				},
			},
		},
		{
			label: 'Lot',
			name: 'lotNumber',
			options: {
				...fieldOptions,
				customBodyRender: addSuspendedToolTip,
			},
		},
		{
			label: 'Estimated Delivery',
			name: 'estimatedDelivery',
			options: {
				...fieldOptions,
				customBodyRender: renderShipmentDate,
			},
		},
		{
			name: 'hasSuspendedLot',
			options: {
				display: false,
				viewColumns: false,
				change: false,
			},
		},
		{
			name: '',
			lable: '',
			options: {
				display: true,
				change: false,
				viewColumns: false,
				customBodyRenderLite: (dataIndex: number) => (
					<ActionsMenu dataIndex={dataIndex} actions={getActions(dataIndex)} />
				),
			},
		},
	];
};

const PendingShipments: React.FC<any> = (props: any) => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();
	const [shipmentsLoaded, setShipmentsLoaded] = React.useState(false);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [selectedShipment, setSelectedShipment] = React.useState<any>(null);
	const [qty, setQty] = React.useState<any>('');
	const [qtyError, setQtyError] = React.useState<any>(null);
	const [suspended, setSuspended] = React.useState(false);

	const trackShipment = (dataIndex: number) => {
		window.open(`https://www.fedex.com/fedextrack/?trknbr=${props.consignmentOrders[dataIndex].trackingNumber}`);
	};

	const receiveShipment = props.cabinet.cabinetProperties.consignment
		? (dataIndex: number) => {
				setModalOpen(true);
				setQty('');
				setSelectedShipment(props.consignmentOrders[dataIndex]);
		  }
		: null;

	const getColumnActions = (dataIndex: number) => {
		const actions: any = {
			setSuspended: setSuspended,
		};
		if (props.consignmentOrders[dataIndex].trackingNumber) {
			actions.trackShipment = trackShipment;
		}
		if (props.cabinet.cabinetProperties.consignment && !props.consignmentOrders[dataIndex].hasSuspendedLot) {
			actions.receiveShipment = receiveShipment;
		}
		return actions;
	};

	const qtyIsValid = () => {
		if (parseInt(qty) < 1) {
			setQtyError('Quantity cannot be less than 1.');
			return false;
		}
		if (!qty) {
			setQtyError('Quantity is required.');
			return false;
		}
		return true;
	};

	const submitReciept = () => {
		if (!qtyIsValid()) {
			return;
		}

		const inventoryPayload = {
			action: 'insert',
			callback: (isSuspended: boolean = false) => {
				if (isSuspended) {
					setSuspended(isSuspended);
					return;
				}
				handleModalClose(true);
			},
			isReceipt: true,
			data: {
				customerId: props.cabinet.customerId,
				cabinetId: props.cabinet.cabinetId,
				productItemId: selectedShipment.vendorItem,
				lot: selectedShipment.lotNumber,
				expiration: formatExpDate(selectedShipment.expDate),
				orderNumber: selectedShipment.orderNumber,
				orderLineNumber: selectedShipment.orderLineNumber,
				shipmentId: selectedShipment.shipmentId,
				trackingNumber: selectedShipment.trackingNumber,
				qty: parseInt(qty),
				packToLum: selectedShipment.packLum ? selectedShipment.packLum.packToLum : false,
			},
		};

		props.updateCabinetProductInventory(inventoryPayload);
	};

	React.useEffect(() => {
		if (!shipmentsLoaded && props.selectedCustomer && props.cabinet) {
			fetchPendingShipments();
		}
	}, [shipmentsLoaded, props.selectedCustomer, props.cabinet]);

	const fetchPendingShipments = () => {
		props.getConsignmentOrders({
			params: {
				shipments: [
					{ key: 'customerNumber', value: props.selectedCustomer.customerId },
					{ key: 'transmitStatus', value: 'Pending' },
					{ key: 'cabinetType', value: props.cabinet.cabinetType },
					{ key: 'limit', value: 9999 },
					{ key: 'offset', value: 0 },
				],
				suspendedTransactions: [
					{ key: 'customerId', value: props.selectedCustomer.customerId },
					{ key: 'status', value: 'Suspended' },
					{ key: 'limit', value: 9999 },
					{ key: 'offset', value: 0 },
				],
			},
			callback: setShipmentsLoaded,
		});
	};

	const handleModalClose = (refreshData: boolean) => {
		setModalOpen(false);
		if (refreshData) {
			setShipmentsLoaded(false);
			fetchPendingShipments();
		}
	};

	const enqueueCsv = (tableColumns: any) => {
		const columnsRequested: string[] = findUserSelectedColumns(tableColumns);

		const filter: any = {
			cabinetId: props.cabinet.cabinetId,
			customerId: props.cabinet.customerId,
			limit: 9999,
			transmitStatus: 'PENDING',
		};

		const params = constructEnqueueParams(
			'RNI_UIEXPORT_PendingShipmentsReport',
			props.authUser?.record.email,
			filter,
			{ columnsRequested: columnsRequested },
			GetUserTimezone(props.properties),
		);

		props.sendOneTimeReportRequest(params);
	};

	return (
		<div>
			{shipmentsLoaded ? (
				<div>
					{props.consignmentOrders && props.consignmentOrders.length > 0 ? (
						<MUIDataTable
							data={props.consignmentOrders}
							columns={getColumns(getColumnActions)}
							options={{
								fixedHeader: true,
								filterType: 'dropdown',
								responsive: 'simple',
								filter: false,
								search: false,
								download: true,
								print: true,
								selectableRows: 'none',
								serverSide: false,
								rowsPerPageOptions: dataList.PageLimitOptions,
								onDownload: (buildHead: any, buildBody: any, cols: any, data: any) => {
									enqueueCsv(cols);
									return false;
								},
							}}
						/>
					) : (
						<div style={{ marginTop: 20 }}>
							<EmptyContent message="Currently there are no pending shipments." />
						</div>
					)}
				</div>
			) : (
				<div>
					<Loading message="" />
				</div>
			)}
			<Modal
				disableBackdropClick={true}
				open={modalOpen}
				onClose={() => handleModalClose(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={{ ...getModalStyle(), padding: '20px', width: isDesktopOrLaptop ? 'auto' : '90%' }}
					className={modalClasses.paper}
				>
					{!suspended && selectedShipment && (
						<Grid item xs={12}>
							<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
								<div className={classes.inventoryActionHeading}>Receive Shipment</div>
								<Grid container>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Cabinet</div>
										<div className={classes.rightTableCell}>
											{props.cabinet.cabinetName
												? `${props.cabinet.cabinetName}(${props.cabinet.cabinetId})`
												: `(${props.cabinet.cabinetId})`}
										</div>
									</Grid>
									<Grid item xs={6}></Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Product</div>
										<div className={classes.rightTableCell}>{selectedShipment.factorDescription}</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Lot</div>
										<div className={classes.rightTableCell}>{selectedShipment.lotNumber}</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Order Number</div>
										<div className={classes.rightTableCell}>{selectedShipment.orderNumber}</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Shipment Id</div>
										<div className={classes.rightTableCell}>{selectedShipment.shipmentId}</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Tracking Number</div>
										<div className={classes.rightTableCell}>{selectedShipment.trackingNumber}</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.leftTableCell}>Shipped Quantity</div>
										<div className={classes.rightTableCell}>
											{selectedShipment.numPkgsShipped ? parseInt(selectedShipment.numPkgsShipped) : ''}
										</div>
									</Grid>
								</Grid>
								<div style={{ textAlign: 'center', marginTop: 10 }}>
									<FormControl required variant="outlined" className={classes.formControl}>
										<TextField
											required
											type="number"
											InputProps={{
												inputProps: {
													min: 1,
													step: '1',
													onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57,
												},
											}}
											label="Quantity"
											variant="outlined"
											style={{ width: '90px' }}
											error={qtyError ? true : false}
											helperText={qtyError || ''}
											value={qty || ''}
											FormHelperTextProps={{ style: { width: '130px', margin: 0 } }}
											onChange={e => {
												setQty(e.target.value);
												setQtyError(null);
											}}
										/>
									</FormControl>
								</div>
								{props.error && <p className={classes.cabinetFormError}>Failed to recieve shipment.</p>}
								<div style={{ textAlign: 'center', marginTop: 10 }}>
									<FormControl
										className={[classes.formControl, !isDesktopOrLaptop ? classes.formControlMobile : ''].join(' ')}
									>
										<Button
											className={classes.viewAllBtn}
											style={{ margin: 0 }}
											variant="contained"
											color="secondary"
											onClick={() => {
												submitReciept();
											}}
										>
											SUBMIT
										</Button>
									</FormControl>
									<FormControl className={classes.formControl}>
										<Button
											className={classes.cancelBtn}
											variant="contained"
											color="default"
											style={{ marginTop: 0 }}
											onClick={() => handleModalClose(false)}
										>
											CANCEL
										</Button>
									</FormControl>
								</div>
								{selectedShipment.packLum?.packToLum && (
									<p className={classes.packToLumNote}>
										This product will be broken down into individual units. 1 pack ={' '}
										{selectedShipment.packLum.convertedQty / selectedShipment.packLum.originalQty} units.
									</p>
								)}
							</Paper>
						</Grid>
					)}
					{suspended && (
						<div style={{ textAlign: 'center' }}>
							<b>This transaction is in review.</b>
							<br />
							Current on hand inventory will not reflect this transaction until it is resolved.
							<Grid>
								<Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
									<FormControl className={classes.formControl}>
										<Button
											className={classes.cancelBtn}
											variant="contained"
											color="default"
											style={{ marginTop: 0 }}
											onClick={() => handleModalClose(true)}
										>
											CLOSE
										</Button>
									</FormControl>
								</Grid>
							</Grid>
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	consignmentOrders: state.cabinet.consignmentOrders,
	error: state.cabinet.error,
	properties:
		state.user &&
		state.user.authUser &&
		state.user.authUser.record &&
		state.user.authUser.record.properties &&
		state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	authUser: state.user.authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
	getConsignmentOrders: (payload: any) => dispatch(CabinetActions.getConsignmentOrders(payload)),
	updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingShipments);
