import { put, select, call, all } from 'redux-saga/effects';
import { UIAction, InventoryManagementActions, CabinetActions, UserActions } from '../actions/index';
import { CabinetService, InventoryManagementService, FirebaseUserService } from '../services';
import { userResourceCheck } from '../../pages/users/users.helper';

export function* updateUINavigateState(action: ReturnType<typeof UIAction.setNavigationState>): any {
	try {
		yield put(UIAction.navigationStateSuccess(action.payload));
		const authUser = yield select(state => state.user.authUser);
		const newIdToken = yield call(authUser.refreshSession);
		const uid = localStorage.getItem('uid') || '';
		yield put(UserActions.userTokenRequestCompleteAction({ token: newIdToken, uid: uid }));
	} catch (err) {
		console.log('saga error', err);
	}
}

export function* updateInventoryFilterState(action: ReturnType<typeof UIAction.setInventoryReportFilterState>) {
	try {
		yield put(UIAction.setInventoryReportFilterStateSuccess(action.payload));
	} catch (err) {
		console.log('saga error', err);
	}
}

export function* setSelectedCustomer(action: ReturnType<typeof UIAction.setSelectedCustomer>): any {
	try {
		const authUser = yield select(state => state.user.authUser);
		const selectedCustomer = yield select(state => state.ui.selectedCustomer);
		if (!action.payload) {
			yield put(UIAction.storeCustomer(action.payload));
			return;
		}
		if (action.payload && action.payload.customerId !== selectedCustomer?.customerId) {
			yield put(UIAction.storeCustomer(action.payload));

			// Preload cabinets if usre has access
			const userHasCabinetAccess = userResourceCheck('cabinets', authUser.record.claims);
			let cabinetResponse: any;
			if (userHasCabinetAccess) {
				yield put(UIAction.cloudManagementLinkLoading(true));
				cabinetResponse = yield call(CabinetService.getInstance().getCabinetsWithProperties, {
					offset: 0,
					limit: 1,
					customerId: action.payload.customerId,
					cabinetType: 'Virtual',
					properties: { consignment: '1' },
				});
				yield put(UIAction.cloudManagementLinkLoading(false));
			}

			if (cabinetResponse && cabinetResponse.data.total_record > 0) {
				const virtualCabinet = cabinetResponse.data.result[0];
				yield put(
					UIAction.storeCustomer({
						...action.payload,
						virtualCabinet: virtualCabinet,
					}),
				);
			}
			const { data } = yield call(InventoryManagementService.getInstance().getTenantInventory, [
				{ key: 'customerId', value: action.payload.customerId },
			]);
			yield put(InventoryManagementActions.setCompleteCustomerInventory(data));
		}
	} catch (err) {
		console.log('saga error', err);
	}
}

export function* setGlobalRowsPerPage(action: ReturnType<typeof UIAction.setGlobalRowsPerPage>) {
	try {
		yield put(UIAction.setGlobalRowsPerPage(action.payload));
	} catch (err) {
		console.log('saga error', err);
	}
}
