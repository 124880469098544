import { connect, useDispatch } from 'react-redux';
import { SupportComponent } from './support.component';
import { UserManagementActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	// authUser: state.user.authUser,
	// vimeoAppToken: state.userManagement.vimeoAppToken
});

const mapDispatchToProps = (dispatch: any) => ({
	sendSupportRequest: (params: any) => dispatch(UserManagementActions.sendSupportRequest(params)),
});

const SupportContainer = connect(mapStateToProps, mapDispatchToProps)(SupportComponent);

export default SupportContainer;
