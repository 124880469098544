import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { CabinetsContainer } from './cabinets.container';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import { Constants } from '../../constants/Constants';
const Cabinets = () => {
	const styles = DashboardStyles();
	const firstRender = useRef(true);
	const dispatch = useDispatch();
	useEffect(() => {
		firstRender.current = false;
	}, [dispatch]);

	return (
		<BasePage title={Constants.PAGE_TITLE.CABINETS}>
			<div className={styles.appBarSpacer}></div>
			<Container maxWidth={false} className={styles.container}>
				<CabinetsContainer />
			</Container>
		</BasePage>
	);
};

export default Cabinets;
