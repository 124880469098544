import { connect } from 'react-redux';
import { InventoryManagementComponent } from './inventory-management.component';
import {
	InventoryManagementActions,
	TenantsActions,
	CabinetActions,
	UIAction,
	ProductActions,
	UserActions,
} from '../../redux/actions';

const mapStateToProps = (state: any) => ({
	authUser: state.user.authUser,
	tenantInventory: state.inventoryManagement.tenantInventory ? state.inventoryManagement.tenantInventory.result : [],
	tenantInventoryRecords: state.inventoryManagement.tenantInventory
		? state.inventoryManagement.tenantInventory.total_record
		: 0,
	tenantGroupResults: state.inventoryManagement.groupedTenantInventory
		? state.inventoryManagement.groupedTenantInventory.result
		: [],
	tenantGroupInventories: state.inventoryManagement.groupedTenantInventory
		? state.inventoryManagement.groupedTenantInventory.inventories
		: [],
	tenantGroupInventoriesRecords: state.inventoryManagement.groupedTenantInventory
		? state.inventoryManagement.groupedTenantInventory.total_record
		: 0,
	cartridgeInventory: state.inventoryManagement.cartridgeInventory
		? state.inventoryManagement.cartridgeInventory.result
		: [],
	cartridgeInventoryRecords: state.inventoryManagement.cartridgeInventory
		? state.inventoryManagement.cartridgeInventory.total_record
		: 0,
	cartridgeGroupResults: state.inventoryManagement.groupedCartridgeInventory
		? state.inventoryManagement.groupedCartridgeInventory.result
		: [],
	cartridgeGroupInventories: state.inventoryManagement.groupedCartridgeInventory
		? state.inventoryManagement.groupedCartridgeInventory.inventories
		: [],
	cartridgeGroupInventoriesRecords: state.inventoryManagement.groupedCartridgeInventory
		? state.inventoryManagement.groupedCartridgeInventory.total_record
		: 0,
	serialInventory: state.inventoryManagement.serialInventory ? state.inventoryManagement.serialInventory.result : [],
	serialInventoryRecords: state.inventoryManagement.serialInventory
		? state.inventoryManagement.serialInventory.total_record
		: 0,
	serialGroupResults: state.inventoryManagement.groupedSerialInventory
		? state.inventoryManagement.groupedSerialInventory.result
		: [],
	serialGroupInventories: state.inventoryManagement.groupedSerialInventory
		? state.inventoryManagement.groupedSerialInventory.inventories
		: [],
	serialGroupInventoriesRecords: state.inventoryManagement.groupedSerialInventory
		? state.inventoryManagement.groupedSerialInventory.total_record
		: 0,
	completeInventory: state.inventoryManagement.completeInventory
		? state.inventoryManagement.completeInventory.result
		: [],
	isLoading: state.ui.isLoading,
	urlFilter: state.ui.urlFilter,
	selectedCustomer: state.ui.selectedCustomer,
	selectedCabinetType: state.ui.selectedCabinetType,
	selectedExpiryDate: state.ui.selectedExpiryDate,
	errorMessage: state.ui.errorMessage,
	selectedFilterValues: state.ui.selectedFilterValues,
	properties:
		state.user?.authUser?.record?.properties && state.user.authUser.record.properties instanceof Array
			? state.user.authUser.record.properties
			: [],
	tenants: state.tenants.tenantsList && state.tenants.tenantsList.result ? state.tenants.tenantsList.result : [],
	autoCompleteProducts: state.product.autoCompleteList || [],
	globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
	suspendedTransactions: state.inventoryManagement.suspendedTransactions,
});

const mapDispatchToProps = (dispatch: any) => ({
	clearCabinetList: () => dispatch(CabinetActions.clearCabinetList()),
	searchTenants: (filter: any) => dispatch(TenantsActions.getTenantList(filter)),
	getTenantInventory: (payload: any) => dispatch(InventoryManagementActions.tenantInventoryRequestStartAction(payload)),
	getCartridgeInventory: (payload: any) =>
		dispatch(InventoryManagementActions.cartridgeInventoryRequestStartAction(payload)),
	getSerialInventory: (payload: any) => dispatch(InventoryManagementActions.serialInventoryRequestStartAction(payload)),
	resetTenants: () => dispatch(TenantsActions.clearTenantsList(true)),
	setSelectedCustomer: (customer: any) => dispatch(UIAction.setSelectedCustomer(customer)),
	setSelectedExpiryDate: (expiryDate: any) => dispatch(UIAction.setSelectedExpiryDate(expiryDate)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	getInventoryCsv: (payload: any) => dispatch(InventoryManagementActions.inventoryLogExport(payload)),
	disableSerialNumberFilter: (disabled: boolean) => dispatch(UIAction.disableSerialNumberFilter(disabled)),
	disableOwnershipFilter: (disabled: boolean) => dispatch(UIAction.disableOwnershipFilter(disabled)),
	sendOneTimeReportRequest: (params: any) => dispatch(UserActions.sendOneTimeReport(params)),
	getSuspendedTransactions: (filter: any) => dispatch(InventoryManagementActions.getSuspendedTransactions(filter)),
});

export const InventoryManagementContainer = connect(mapStateToProps, mapDispatchToProps)(InventoryManagementComponent);
