import { call, put, delay } from 'redux-saga/effects';
import { ProductActions, UIAction, UserActions } from '../actions/index';
import { ProductService } from '../services';
import { Constants } from '../../constants/Constants';

export function* getProductsList(action: ReturnType<typeof ProductActions.getProductList>) {
	try {
		yield put(UIAction.showLoader(true));
		yield put(UIAction.setApiError(null));
		const { data } = yield call(ProductService.getInstance().getProducts, action.payload);
		yield put(ProductActions.setProductList(data));
		yield put(UIAction.showLoader(false));
		if (!data.result || data.result.length === 0) {
			yield put(
				UserActions.setSnackbarMessage({
					message: Constants.ALERT.NO_RECORD_FOUND,
					type: 'info',
				}),
			);
		}
	} catch (err: any) {
		yield put(ProductActions.setProductList(null));
		yield put(UIAction.showLoader(false));
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;

		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in getProductsList`,
				type: 'error',
			}),
		);
	}
}

export function* getAutoCompleteProducts(action: ReturnType<typeof ProductActions.getProductAutoCompleteList>) {
	try {
		const { data } = yield call(ProductService.getInstance().getAutoCompleteProducts, action.payload);
		yield put(ProductActions.setAutoCompleteProductList(data));
	} catch (e) {
		console.log('product autocomplete saga error', e);
	}
}

export function* getAllProductsList(action: ReturnType<typeof ProductActions.getAllProductsList>) {
	try {
		const { data } = yield call(ProductService.getInstance().getAllProductsList);

		yield put(ProductActions.setAllProductsList(data));
	} catch (e) {
		console.log('product get all saga error', e);
	}
}

export function* saveNewProduct(action: ReturnType<typeof ProductActions.saveNewProduct>): any {
	try {
		let requestBody: any = action.payload;
		if (action.payload.requestBody) {
			requestBody = action.payload.requestBody;
		}
		yield put(UIAction.showLoader(true));
		yield call(ProductService.getInstance().createProduct, requestBody);
		// Refresh all products list after new product added
		const allProducts = yield call(ProductService.getInstance().getAllProductsList);
		yield put(ProductActions.setAllProductsList(allProducts.data));
		if (action.payload.success) {
			action.payload.success();
		}
		yield put(UIAction.showLoader(false));
	} catch (err: any) {
		yield put(UIAction.showLoader(false));
		let errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		if (err.response?.data) errorMessage = err.response.data;
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in saveNewProduct`,
				type: 'error',
			}),
		);
		if (action.payload.error) {
			action.payload.error(err.response.data);
		}
		return;
	}
}

export function* updateProduct(action: ReturnType<typeof ProductActions.updateProduct>) {
	try {
		yield call(ProductService.getInstance().updateProduct, action.payload.requestBody);
		action.payload.success();
	} catch (err: any) {
		const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
		yield put(
			UserActions.setSnackbarMessage({
				message: `An error occurred: ${errorMessage} in updateProduct`,
				type: 'error',
			}),
		);
		action.payload.error();
		return;
	}
}
