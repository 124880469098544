import * as React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ParInventoryRow } from '../../redux/models/inventory-management/inventory-management';
import { UserRecord } from '../../redux/models/user/user';
import { ParUsageStyles } from './par-usage.styles';
import EmptyContent from '../../components/emptyContent';
import ParUsageGroupTable from './par-usageGroupTable';
import GlobalFiltersComponentContainer from '../../components/globalFiltersComponent';
import moment from 'moment-timezone';
import { GetUserTimezone } from '../../hooks/functions';

interface ParUsageProps {
	inventory: ParInventoryRow[];
	totalRecord: any;
	groups: any;
	totalGroupRecord: any;
	groupInventories: any;
	groupInventoryRecords: any;
	user: UserRecord;
	tenants: any;
	selectedCustomer: any;
	getInventory: (filter: any | null) => void;
	searchTenants: (filter: any) => void;
	resetTenants: () => void;
	setSelectedCustomer: (customer: any) => void;
	sendOneTimeReportRequest: Function;
	authUser: any;
	properties: any;
	selectedFilterValues: any;
}

export const ParUsageComponent: React.FC<ParUsageProps> = props => {
	const cabinetClasses = ParUsageStyles();
	const [pagination, setPagination] = React.useState({ offset: 0, limit: 5, page: 0 } as any);
	const firstRender = React.useRef(true);

	const handleSearchClick = () => {
		const queryString = window.location.search ? `?${window.location.search.replace('?', '')}` : '';
		window.history.pushState({}, '', `/par-levels/${props.selectedCustomer?.customerId ?? ''}${queryString}`);
		props.setSelectedCustomer(props.selectedCustomer);
		setPagination({ offset: 0, limit: pagination.limit, page: 0 });
	};

	const enqueueCsvReportForCurrentQuery = (tableColumns?: any, groupName?: string) => {
		let productId
		let productGroup
		if(props.selectedFilterValues && props.selectedFilterValues['PRODUCT_NAME'] && props.selectedFilterValues['PRODUCT_NAME'].productItemId) productId = props.selectedFilterValues['PRODUCT_NAME'].productItemId
		if(props.selectedFilterValues && props.selectedFilterValues['PRODUCT_GROUP']) productGroup = props.selectedFilterValues['PRODUCT_GROUP']
		
		const filter = {
			groupBy: 'cabinet',
			customerId: props.selectedCustomer?.customerId,
			productId: productId,
			productGroup: productGroup,
			groupName: groupName,
		};

		const columnsRequested: string[] = [];
		columnsRequested.push('customerId');
		columnsRequested.push('customerName');

		if(tableColumns){
			tableColumns.forEach((tc: any) => {
				if (tc.display === 'true' || tc.display === true) {
					columnsRequested.push(tc.name);
				}
			});
		}

		columnsRequested.push('usageY');
		columnsRequested.push('usageM');
		columnsRequested.push('usageW');
		columnsRequested.push('ydoh');
		columnsRequested.push('mdoh');
		columnsRequested.push('wdoh');

		const params = {
			filter_object_from_ui: filter,
			report_type: 'RNI_UIEXPORT_ParAndUsageReport',
			report_frequency: 'OneTime',
			delivery_method: 'UserQueue',
			file_type: 'csv',
			user_name: props.authUser?.record.email,
			recipient_addresses: JSON.stringify([props.authUser?.record.email]), //probably not used, but in case we want to email reminder
			customers_reported_on_id: undefined, //left here for clarity but for this type of report, details of what we actually want to query will be on filter_object_from_ui
			customers_reported_on_name: undefined,
			timezone: GetUserTimezone(props.properties),
			additional_filters: undefined,
			custom_start_date: undefined,
			custom_end_date: undefined,
			origin_application: 'RNI',
			report_additional_metadata: {
				columnsRequested: columnsRequested,
			},
		};

		props.sendOneTimeReportRequest(params);
	};

	const getParUsageTable = (data: any, index: number = 0) => {
		return (
			<div className={cabinetClasses.tableWrap} key={index}>
				<ParUsageGroupTable
					data={data}
					inventory={props.groupInventories[index] ?? []}
					enqueueCsvReportForCurrentQuery={enqueueCsvReportForCurrentQuery}
				/>
			</div>
		);
	};

	React.useEffect(() => {
		if (!firstRender.current && props.user) {
			props.getInventory(getFilter());
		} else {
			firstRender.current = false;
		}
	}, [pagination]);

	const getFilter = () => {
		let urlCustomerId = window.location.pathname.split('/')[2] ?? '';
		const filterValues = props.selectedFilterValues || {};
		let filter: any = {
			customerId: urlCustomerId,
			groupBy: 'cabinet',
			offset: pagination.offset,
			limit: pagination.limit,
		};
		if(filterValues['PRODUCT_NAME']?.productItemId) filter = {...filter, productId: filterValues['PRODUCT_NAME']?.productItemId}
		if(filterValues['PRODUCT_GROUP']) filter = {...filter, productGroup: filterValues['PRODUCT_GROUP']}
		return filter;
	};

	const handlePagination = (tableEvent: any, value: any) => {
		const offset = (value - 1) * pagination.limit;
		setPagination({ offset: offset, limit: pagination.limit, page: value });
	};

	return (
		<div>
			<div className={cabinetClasses.searchWrap}>
				<Grid container>
					<Grid item xs={10}>
						<GlobalFiltersComponentContainer pageName="PAR" executeSearch={handleSearchClick} />
					</Grid>
					<Grid item xs={2}>
						<Button
							className={cabinetClasses.viewAllBtn}
							onClick={() => enqueueCsvReportForCurrentQuery()}
						>Export All</Button>
					</Grid>
				</Grid>
			</div>
			{props.groups && props.groups.length > 0 ? (
				props.groups.map((tableData: any, index: number) => getParUsageTable(tableData, index))
			) : (
				<EmptyContent message="Select the filter criteria above to view PAR & usage information." />
			)}
			<Pagination count={Math.ceil(props.totalGroupRecord / pagination.limit)} onChange={handlePagination} />
		</div>
	);
};
