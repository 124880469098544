import dataList from '../../constants/dataList';

export const InventoryCustomBodyRenderFunction = (value?: any, tableMeta?: any, updateValue?: any) => {
	if ('cabinetType' === tableMeta.columnData.name) {
		let index = dataList.CabinetTypeDropdownList.findIndex((obj: { value: string }) => obj.value === value);
		return dataList.CabinetTypeDropdownList[index] ? dataList.CabinetTypeDropdownList[index].label : value;
	} else if ('expiration' === tableMeta.columnData.name) {
		return value ? value.split('T')[0] : value;
	} else {
		return value;
	}
};
